// (c) Cognisoft Aps, 2022 

import type { HelpCount, Result } from '../types';
import { Level } from '../enums';

import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

export let result: Writable<Result> = writable({
  session: 0,
  startTime: new Date(),
  endTime: new Date(),
  pauseElapsed: 0,
  items: [],
  // responseDetails: [],
  lastStepCompleted: 0,
  wasInterrupted: false,
  comprehensionCorrectFirstTry: 0,
  comprehensionIndependencyIndex: 0,
  readingCorrectFirstTry: 0,
  readingIndependencyIndex: 0,
  spellingCorrectFirstTry: 0,
  spellingIndependencyIndex: 0,
  namingCorrectFirstTry: 0,
  namingIndependencyIndex: 0,
  currentLevel: Level.First
});

export let tasksCompleted: Writable<number> = writable(0);

export let helpCount: Writable<HelpCount> = writable({
  comprehension: {
    item11: 0,
    item12: 0,
    item13: 0,
    item14: 0,
    item41: 0,
    item42: 0,
    item43: 0
  },
  reading: {
    item21: 0,
    item22: 0,
    item23: 0,
    item24: 0,
    item31: 0,
    item32: 0,
    item33: 0,
    item34: 0
  },
  spelling: {
    item51: 0,
    item52: 0,
    item53: 0,
    item54: 0,
    item61: 0,
    item62: 0,
    item63: 0,
    item64: 0,
    item71: 0,
    item72: 0,
    item73: 0,
    item74: 0
  },
  naming: {
    item91: 0,
    item92: 0,
    item93: 0,
    item94: 0
  }
});
