<script lang="ts">
  // (c) Cognisoft Aps, 2022
  import { createEventDispatcher } from "svelte";

  import { IconButtonType, ModalCss, KeyboardKey } from "../enums";
  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  const dispatch = createEventDispatcher();

  const closeModal = () => {
    dispatch("loginMessageModalClosed");
  };

  const handleKeydown = (event: KeyboardEvent): void => {
    if (event.key === KeyboardKey.Enter || event.key === KeyboardKey.Escape) {
      closeModal();
    }
  };
</script>

<!-- HTML ===================================================== -->

<svelte:window on:keydown={handleKeydown} />
<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={closeModal}>
    <div class="content">
      <br />
      <p>
        MODAK web-app'en er udviklet for ViSP
        Kommuni&shy;ka&shy;tions&shy;center baseret på den manuelle
        MODAK-metode, som blev udviklet i Tyskland af Dr. Luise Lutz og oversat
        og tilpasset danske forhold af logopæd Karina Lønborg.
      </p>
      <br />
      <p>
        Karina Lønborg har sammen med logopæd Mads Møllevang Nielsen udviklet
        ideen til app'en. Lasse Lønborg har i processen medvirket til
        funktionalitet, storyboards samt bearbejdet og tilpasset alle
        billederne. Mads Møllevang Nielsen har foretaget alle indtalinger.
        App'en er udviklet med afprøvning af prototyper sammen med neuropsykolog
        Palle Møller Pedersen, som har stået for programmeringen. Mads Møllevang
        Nielsen har gennemført en multiple single-case afprøvning af app'en med
        seks borgere med kronisk afasi.
      </p>
      <br />
      <p class="alink">
        Vejledninger findes på:
        <a
          href="https://www.modak.dk/modak-app#vejledninger-manualer-til-modak-app-0b"
          target="_blank">modak.dk</a
        >
      </p>
      <p class="alink">
        Bestil abonnement: <a
          href=" https://cognisoft.dk/modak.html"
          target="_blank">cognisoft.dk/modak.html</a
        >
      </p>
      <p class="alink">
        Support: <a href="mailto:mail@cognisoft.dk">mail@cognisoft.dk</a>, mobil
        40 51 20 49
      </p>
    </div>
    <div class="bottom-box">
      <span class="ok-btn">
        <IconButton
          textScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          OK
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  .content {
    padding: 10px 8px 10px 16px;
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    user-select: none;
  }
  .bottom-box {
    display: flex;
    justify-content: center;
    padding: 1vmin;
  }
  .alink {
    line-height: 1.2em;
  }
</style>
