// (c) Cognisoft Aps, 2022 

export const versionNumber = "1.22, 26. marts 2024";  // REMEMBER: also put version into variable in global.css !!!!

export const firstWaitSecs = 1000;
export const animationBugWait = 500;
export const afterCorrectWaitSecs = 4000;

export const minHelpDelaySecs = 5;
export const maxHelpDelaySecs = 60;
export const helpDelaySecsStep = 5;
export const defaultHelpDelaySecs = 10;

export const minShowRepeatButtonDelaySecs = 1;
export const maxShowRepeatButtonDelaySecs = 28;
export const showRepeatButtonSecsStep = 1;
export const defaultShowRepeatButtonDelaySecs = 7;

export const correctSounds = ["yes", "jep", "sådan", "lige nøjagtig", "ja", "jaer", "hhhm"];

export const totalNumberOfTasksBaseLevels = 35;
export const totalNumberOfTasksBaseLevelsSimpleSpelling = 27;
export const totalNumberOfTasksVerbExtension = 43;
export const totalNumberOfTasksVerbExtensionSimpleSpelling = 35;
export const totalNumberOfTasksSubjectExtension = 51;
export const totalNumberOfTasksSubjectExtensionSimpleSpelling = 43;

export const maxComprehensionCorrectFirstTry = 7;
export const maxReadingCorrectFirstTry = 8;
export const maxSpellingCorrectFirstTry = 12;

export const sessionsBeforeLevelChange = 40;
export const minSessionsBeforeLevelChange = 1;
export const maxSessionsBeforeLevelChange = 50;

export const alfaKeyCaps: Array<string> = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "æ",
  "ø",
  "å",
];

export const qwertyKeyCaps: Array<string> = [
  "q",
  "w",
  "e",
  "r",
  "t",
  "y",
  "u",
  "i",
  "o",
  "p",
  "å",
  "a",
  "s",
  "d",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "æ",
  "ø",
  "z",
  "x",
  "c",
  "v",
  "b",
  "n",
  "m",
];
