// (c) Cognisoft Aps, 2022 
// Import the functions you need from the SDKs you need
// - should not be in git repository!
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

// The web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCWULaNCCf26MsWaedBnfRBTfQNfcFmcjc",
  authDomain: "modak-335415.firebaseapp.com",
  projectId: "modak-335415",
  storageBucket: "modak-335415.appspot.com",
  messagingSenderId: "1095965865756",
  appId: "1:1095965865756:web:dafa32a83dd799b81dacd9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// autorisation for log in:
export const auth = getAuth();
// variable for firestore database access:
export const db = getFirestore(app);

