<script lang="ts">
  // (c) Cognisoft Aps, 2022
  // first page after login - and back to this after results

  // url parameters for testing, - a specific step, level, extension:
  // afasi-app.dk/?trin=9&niveau=4&udvidelse=subjekt

  import "../global.css";

  import { auth, db } from "../firebase";
  import { doc, onSnapshot } from "firebase/firestore";
  import type { Unsubscribe } from "firebase/firestore";
  import { onMount, onDestroy } from "svelte";
  // import * as Sentry from "@sentry/svelte";

  import {
    Level,
    Pages,
    LevelExtensionProgress,
    Role,
    DocType,
  } from "../enums";

  import { currentRoute, returnRoute } from "../stores/route-store";
  import { targetVerbAndObjects } from "../stores/targets-store";
  import { currentSentenceNumberForSteps } from "../stores/targets-step567-store";
  import { progress } from "../stores/progress-store";
  import { helpCount, result, tasksCompleted } from "../stores/result-store";
  import {
    loggedIn,
    pwaEmailForResults,
    pwaNameForResults,
    studentUserData,
    user,
    userEmail,
  } from "../stores/user-store";
  import { levelExtensionProgress } from "../stores/level-extension-progress-store";

  import { icons } from "../utilities/icons";
  import {
    playSoundWithAudioTag,
    playSilentWithAudioTag,
  } from "../utilities/playSound";
  import { Icons } from "../utilities/icons";

  import {
    verbAndObjects,
    introVerbAndObjects,
    basis1VerbAndObjects,
    basis2VerbAndObjects,
  } from "../data/verbAndObjects";
  import {
    editPWAEmail,
    editPWAName,
    editPWAActive,
    editPWASettings,
    editPWALevel,
    editPWACompletedOnCurrentLevel,
    editPWARole,
  } from "../stores/edit-pwa-settings-store";

  import IconButton from "../components/IconButton.svelte";

  import YesNoModal from "../modals/YesNoModal.svelte";

  // PROPS ---------------------------------------------------

  export let audioTag: HTMLAudioElement;

  // STATE ---------------------------------------------------

  let unsubscribe: Unsubscribe;
  let showYesNoModal = false;
  let startPage = Pages.Step1;
  let testMessage = "";
  let levelText = "";

  // FUNCTIONS -----------------------------------------------

  $: {
    // has to do new targets if level etc changed i database
    makeTargets();
    // has to do new texts  if level etc changed i database
    let tempLevelText = "";
    if ($studentUserData.settings.doIntroNextSession) {
      tempLevelText = "Dette er et introduktions-gennemløb.";
    } else {
      if ($studentUserData.settings.doTestNextSession) {
        tempLevelText = "Basis-test - Niveau ";
      } else {
        tempLevelText = "Niveau ";
      }
      if ($studentUserData.currentLevel === Level.First) {
        tempLevelText = tempLevelText + "1";
      } else if ($studentUserData.currentLevel === Level.Second) {
        tempLevelText = tempLevelText + "2";
      } else if ($studentUserData.currentLevel === Level.SecondVerb) {
        tempLevelText = tempLevelText + "3";
      } else if ($studentUserData.currentLevel === Level.SecondSubject) {
        tempLevelText = tempLevelText + "4";
      } else {
        tempLevelText = tempLevelText + "*** (intet niveau angivet) ***";
      }
      if (!$studentUserData.settings.doIntroNextSession) {
        tempLevelText =
          tempLevelText +
          " Gennemførte: " +
          $studentUserData.completedOnCurrentLevel +
          " ";
      }
    }
    levelText = tempLevelText + testMessage;
  }

  // initialize training set of sentences ---
  // find four targets with unique features for:
  //   verb-semantics, object-semantics, stressed vowel and first letter
  //   AND has to have a certain combined length of object words
  //   + frequencies

  const makeTargets = async () => {
    let totalWordLength: number;
    let frequencyLimit: number;
    if ($studentUserData.currentLevel === Level.First) {
      frequencyLimit = 1000;
    } else {
      frequencyLimit = 0;
    }
    if ($studentUserData.settings.doIntroNextSession) {
      $targetVerbAndObjects = introVerbAndObjects;
    } else if ($studentUserData.settings.doTestNextSession) {
      if ($studentUserData.currentLevel === Level.First) {
        $targetVerbAndObjects = basis1VerbAndObjects;
      } else {
        $targetVerbAndObjects = basis2VerbAndObjects;
      }
    } else {
      let minTotalWordLength: number;
      let maxTotalWordLength: number;
      if ($studentUserData.currentLevel === Level.First) {
        minTotalWordLength = 0;
        maxTotalWordLength = 18;
      } else {
        minTotalWordLength = 18;
        maxTotalWordLength = 26;
      }
      do {
        $targetVerbAndObjects = [];
        $targetVerbAndObjects.push(
          verbAndObjects[Math.floor(Math.random() * verbAndObjects.length)],
        );
        $targetVerbAndObjects.push(
          verbAndObjects[Math.floor(Math.random() * verbAndObjects.length)],
        );
        $targetVerbAndObjects.push(
          verbAndObjects[Math.floor(Math.random() * verbAndObjects.length)],
        );
        $targetVerbAndObjects.push(
          verbAndObjects[Math.floor(Math.random() * verbAndObjects.length)],
        );
        totalWordLength =
          $targetVerbAndObjects[0].object.length +
          $targetVerbAndObjects[1].object.length +
          $targetVerbAndObjects[2].object.length +
          $targetVerbAndObjects[3].object.length;
      } while (
        totalWordLength < minTotalWordLength ||
        totalWordLength > maxTotalWordLength ||
        $targetVerbAndObjects[0].object.charAt(0) ===
          $targetVerbAndObjects[1].object.charAt(0) ||
        $targetVerbAndObjects[0].object.charAt(0) ===
          $targetVerbAndObjects[2].object.charAt(0) ||
        $targetVerbAndObjects[0].object.charAt(0) ===
          $targetVerbAndObjects[3].object.charAt(0) ||
        $targetVerbAndObjects[1].object.charAt(0) ===
          $targetVerbAndObjects[2].object.charAt(0) ||
        $targetVerbAndObjects[1].object.charAt(0) ===
          $targetVerbAndObjects[3].object.charAt(0) ||
        $targetVerbAndObjects[2].object.charAt(0) ===
          $targetVerbAndObjects[3].object.charAt(0) ||
        $targetVerbAndObjects[0].stressedVowel ===
          $targetVerbAndObjects[1].stressedVowel ||
        $targetVerbAndObjects[0].stressedVowel ===
          $targetVerbAndObjects[2].stressedVowel ||
        $targetVerbAndObjects[0].stressedVowel ===
          $targetVerbAndObjects[3].stressedVowel ||
        $targetVerbAndObjects[1].stressedVowel ===
          $targetVerbAndObjects[2].stressedVowel ||
        $targetVerbAndObjects[1].stressedVowel ===
          $targetVerbAndObjects[3].stressedVowel ||
        $targetVerbAndObjects[2].stressedVowel ===
          $targetVerbAndObjects[3].stressedVowel ||
        $targetVerbAndObjects[0].verb === $targetVerbAndObjects[1].verb ||
        $targetVerbAndObjects[0].verb === $targetVerbAndObjects[2].verb ||
        $targetVerbAndObjects[0].verb === $targetVerbAndObjects[3].verb ||
        $targetVerbAndObjects[1].verb === $targetVerbAndObjects[2].verb ||
        $targetVerbAndObjects[1].verb === $targetVerbAndObjects[3].verb ||
        $targetVerbAndObjects[2].verb === $targetVerbAndObjects[3].verb ||
        $targetVerbAndObjects[0].frequencyObject < frequencyLimit ||
        $targetVerbAndObjects[1].frequencyObject < frequencyLimit ||
        $targetVerbAndObjects[2].frequencyObject < frequencyLimit ||
        $targetVerbAndObjects[3].frequencyObject < frequencyLimit
      );
    }
  };

  const resetResults = () => {
    // reset results
    $result.lastStepCompleted = 0;
    $result.startTime = new Date();
    $result.pauseElapsed = 0;
    // $result.responseDetails = [];
    $result.items = [
      $targetVerbAndObjects[0].sentence,
      $targetVerbAndObjects[1].sentence,
      $targetVerbAndObjects[2].sentence,
      $targetVerbAndObjects[3].sentence,
    ];
    $result.comprehensionCorrectFirstTry = 0;
    $result.comprehensionIndependencyIndex = 0;
    $result.readingCorrectFirstTry = 0;
    $result.readingIndependencyIndex = 0;
    $result.spellingCorrectFirstTry = 0;
    $result.spellingIndependencyIndex = 0;
    $result.namingCorrectFirstTry = 0;
    $result.namingIndependencyIndex = 0;
    $result.wasInterrupted = false;
  };

  const resetHelpCount = () => {
    $helpCount.comprehension.item11 = 0;
    $helpCount.comprehension.item12 = 0;
    $helpCount.comprehension.item13 = 0;
    $helpCount.comprehension.item14 = 0;
    $helpCount.comprehension.item41 = 0;
    $helpCount.comprehension.item42 = 0;
    $helpCount.comprehension.item43 = 0;
    $helpCount.reading.item21 = 0;
    $helpCount.reading.item22 = 0;
    $helpCount.reading.item23 = 0;
    $helpCount.reading.item24 = 0;
    $helpCount.reading.item31 = 0;
    $helpCount.reading.item32 = 0;
    $helpCount.reading.item33 = 0;
    $helpCount.reading.item34 = 0;
    $helpCount.spelling.item51 = 0;
    $helpCount.spelling.item52 = 0;
    $helpCount.spelling.item53 = 0;
    $helpCount.spelling.item54 = 0;
    $helpCount.spelling.item61 = 0;
    $helpCount.spelling.item62 = 0;
    $helpCount.spelling.item63 = 0;
    $helpCount.spelling.item64 = 0;
    $helpCount.spelling.item71 = 0;
    $helpCount.spelling.item72 = 0;
    $helpCount.spelling.item73 = 0;
    $helpCount.spelling.item74 = 0;
    $helpCount.naming.item91 = 0;
    $helpCount.naming.item92 = 0;
    $helpCount.naming.item93 = 0;
    $helpCount.naming.item94 = 0;
  };

  // button click event routines ---

  const readyClicked = (): void => {
    // for development, adjust progress if jumping to step
    // 3 tasks on each step except step 4

    playSilentWithAudioTag(audioTag);
    $tasksCompleted = 0;
    resetResults();
    resetHelpCount();

    $currentSentenceNumberForSteps = 0; // have to reset for development if jumping pages

    // ONLY FOR TESTING !!!! : **************************************************

    // for development, to jump directly to page *******************************
    $currentRoute = startPage;

    // ***************************************************************************
  };

  const doLogOut = () => {
    auth.signOut().then(function () {
      $loggedIn = false;
      $currentRoute = Pages.Login;
    });
  };

  const logOutClicked = () => {
    showYesNoModal = true;
  };

  const resultsClicked = () => {
    $pwaEmailForResults = $userEmail;
    $pwaNameForResults = $user.name;
    $returnRoute = $currentRoute;
    $currentRoute = Pages.ResultList;
  };

  const settingsClicked = () => {
    $editPWAEmail = $userEmail;
    $editPWAName = $user.name;
    $editPWARole = $user.role;
    $editPWAActive = $user.active;
    $editPWASettings = $studentUserData.settings;
    $editPWALevel = $studentUserData.currentLevel;
    $editPWACompletedOnCurrentLevel = $studentUserData.completedOnCurrentLevel;
    $returnRoute = Pages.Ready;
    $currentRoute = Pages.Settings;
  };
  // INITIALIZATION -------------------------------------------------------------------

  // // TESTING THAT ALL IMAGES AND SOUNDS FOR SENTENCES EXISTS =================
  // // ONLY FOR DEBUG; DISABLE FOR PRODUCTION

  // // Tests if images exists:

  // import { Path, Extension } from "../enums";
  // import type { VerbAndObjects } from "../types";

  // const imgIsFound = async (item: string, missing: Array<string>) => {
  //   await fetch(Path.Img + item + Extension.Png).then((response) => {
  //     if (!response.ok) {
  //       missing.push("missing foreground: " + item + Extension.Png);
  //     }
  //   });
  // };

  // const bgImgIsFound = async (item: string, missing: Array<string>) => {
  //   await fetch(Path.ImgBg + item + Extension.ImgBg).then((response) => {
  //     if (!response.ok) {
  //       missing.push("missing background: " + item + Extension.ImgBg);
  //     }
  //   });
  // };

  // const testIfImgExits = async () => {
  //   let missing: Array<string> = [];
  //   for (const task of verbAndObjects) {
  //     await imgIsFound(task.image, missing);
  //     await bgImgIsFound(task.image, missing);
  //   }
  //   for (const item of missing) {
  //     console.log(item);
  //   }
  //   console.log("testIfImgExists completed");
  // };

  // // Test if sounds exists:

  // // import { Path, Extension } from "../types";

  // const sndIsFound = async (
  //   theKey: string,
  //   item: string,
  //   missing: Array<string>,
  // ) => {
  //   await fetch(Path.Snd + item + Extension.Mp3).then((response) => {
  //     try {
  //       if (!response.ok) {
  //         missing.push("missing sound, " + theKey + ": " + item);
  //       }
  //     } catch (error) {
  //       console.log(error, "missing sound: ", item);
  //     }
  //   });
  // };

  // const testIfSoundsExits = async (objectArray: VerbAndObjects) => {
  //   let missing: Array<string> = [];

  //   for (const task of objectArray) {
  //     await sndIsFound("sentence", task.sentence, missing);
  //     await sndIsFound("sentenceFull", task.sentenceFull, missing);
  //     await sndIsFound("sentenceOK", task.sentenceOK, missing);
  //     await sndIsFound("subjectName", task.subjectName, missing);
  //     await sndIsFound("verb", task.verb, missing);
  //     await sndIsFound("object", task.object, missing);
  //     await sndIsFound("namingIntro", task.namingIntro, missing);
  //     await sndIsFound(
  //       "namingSuggestionFalse",
  //       task.namingSuggestionFalse,
  //       missing,
  //     );
  //     await sndIsFound(
  //       "namingVerbSuggestionFalse",
  //       task.namingVerbSuggestionFalse,
  //       missing,
  //     );
  //     await sndIsFound("namingVerbIntro", task.namingVerbIntro, missing);
  //     await sndIsFound(
  //       "namingFullSentencePrompt",
  //       task.namingFullSentencePrompt,
  //       missing,
  //     );
  //     await sndIsFound("namingFullSentence", task.namingFullSentence, missing);
  //     await sndIsFound("namingShortPrompt", task.namingShortPrompt, missing);
  //   }
  //   for (const item of missing) {
  //     console.log(item);
  //   }
  //   console.log("testIfSoundExists completed");
  // };

  // testIfSoundsExits(introVerbAndObjects);
  // testIfSoundsExits(basis1VerbAndObjects);
  // testIfSoundsExits(basis2VerbAndObjects);
  // testIfSoundsExits(verbAndObjects);
  // testIfImgExits();

  // ==========================================================================

  unsubscribe = onSnapshot(
    doc(db, DocType.Users, $userEmail),
    (doc) => {
      const userData = doc.data();
      if (typeof userData === "undefined") {
        console.log("Ready.svelte - userData undefined");
      } else {
        $studentUserData.sessionsCompleted = userData.sessionsCompleted;
        $studentUserData.completedOnCurrentLevel =
          userData.completedOnCurrentLevel;
        $studentUserData.currentLevel = userData.currentLevel;
        $studentUserData.settings = userData.settings;
        // new for version 1.15: option to have only one spelling step
        if (!userData.settings.hasOwnProperty("simpleSpelling")) {
          userData.settings.simpleSpelling = false;
        }
        $studentUserData.settings = $studentUserData.settings;
      }
    },
    (error) => {
      // Sentry.captureMessage(
      //   "Ready, user not found, user uid: " +
      //     $userEmail +
      //     " - error " +
      //     error.message
      // );
      console.log(
        "Ready, user not found, user uid: ",
        $userEmail,
        " - error ",
        error.message,
      );
    },
  );

  onMount(() => {
    // for extened levels where steps 8 and 9 are repeated
    $levelExtensionProgress = LevelExtensionProgress.Object;
    // url parameters for testing, mostly for jumping to level 9, and extension levels within level 9
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (
      urlParams.has("niveau") ||
      urlParams.has("trin") ||
      urlParams.has("udvidelse")
    ) {
      console.log("startet med debug parametre");
      // Sentry.captureMessage("Started with debug urlParams");
      testMessage = "- Debug!";
      $studentUserData.settings.doTestNextSession = false;
      $studentUserData.settings.doIntroNextSession = false;
    }
    let extension = "objekt";
    if (urlParams.has("udvidelse")) {
      extension = urlParams.get("udvidelse") || "object";
      // Sentry.captureMessage("Started with extension: " + extension);
      console.log("udvidelse: ", extension);
      switch (extension) {
        case "objekt":
          $levelExtensionProgress = LevelExtensionProgress.Object;
          break;
        case "verbum":
          $levelExtensionProgress = LevelExtensionProgress.Verb;
          break;
        case "subjekt":
          $levelExtensionProgress = LevelExtensionProgress.Subject;
          break;
        default:
          $levelExtensionProgress = LevelExtensionProgress.Object;
          // Sentry.captureMessage(
          //   "Ready: error in extension switch-parameter: " +
          //     extension +
          //     " - set to LevelExtensionProgres.Oject"
          // );
          console.log(
            "Ready: error in extension switch-parameter: ",
            extension,
            " - set to LevelExtensionProgres.Oject",
          );
      }
    }
    if (urlParams.has("niveau")) {
      const level = urlParams.get("niveau");
      console.log("niveau: ", level);
      // Sentry.captureMessage("Started with level: " + level);
      switch (level) {
        case "1":
          $studentUserData.currentLevel = Level.First;
          break;
        case "2":
          $studentUserData.currentLevel = Level.Second;
          break;
        case "3":
          $studentUserData.currentLevel = Level.SecondVerb;
          break;
        case "4":
          $studentUserData.currentLevel = Level.SecondSubject;
          break;
        default:
          $studentUserData.currentLevel = Level.First;
          // Sentry.captureMessage(
          //   "Ready: error in i level switch parameter: " +
          //     level +
          //     " - set to Level.First"
          // );
          console.log(
            "Fejl i niveau switch parameter: ",
            level,
            " - sat til niveau 1",
          );
      }
    }
    if (urlParams.has("trin")) {
      const step = urlParams.get("trin");
      console.log("step: ", step);
      // Sentry.captureMessage("Started with step: " + step);
      switch (step) {
        case "1":
          startPage = Pages.Step1;
          console.log("trin 1");
          break;
        case "2":
          startPage = Pages.Step2;
          console.log("trin: 2");
          break;
        case "3":
          startPage = Pages.Step3;
          console.log("trin: 3");
          break;
        case "4":
          startPage = Pages.Step4;
          console.log("trin: 4");
          break;
        case "5":
          startPage = Pages.Step5;
          console.log("trin: 5");
          break;
        case "6":
          startPage = Pages.Step6;
          console.log("trin: 6");
          break;
        case "7":
          startPage = Pages.Step7;
          console.log("trin: 7");
          break;
        case "8":
          startPage = Pages.Step8;
          console.log("trin: 8");
          break;
        case "9":
          startPage = Pages.Step9;
          console.log("trin: 9");
          break;
        default:
          startPage = Pages.Step1;
          console.log("Fejl i trin switch parameter: ", step, " - sat til 1");
        // Sentry.captureMessage(
        //   "Ready: error in step-parameter: " + step + " - set to 1"
        // );
      }
    }
    $progress = 0;
    makeTargets();
    playSoundWithAudioTag(audioTag, "er du klar", null);
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="question">Er du klar?</div>
  <button class="button" on:click={readyClicked}>
    <svg class="icon" viewBox={icons[Icons.ThumbsUp].viewBox}>
      {@html icons[Icons.ThumbsUp].path}
    </svg>
    <div class="caption">JA</div>
  </button>
  <div class="info">
    <p>
      {levelText}
    </p>
  </div>
  <div class="bottom-buttons">
    <span class="left-bottom-btn">
      <IconButton
        on:click={logOutClicked}
        textMediumScaled={true}
        icon={Icons.SignOutAlt}
      >
        Log ud
      </IconButton>
    </span>
    {#if $user.role === Role.Private}
      <IconButton
        on:click={settingsClicked}
        textMediumScaled={true}
        icon={Icons.CogLight}
      >
        Indstillinger
      </IconButton>
    {/if}
    <span class="right-bottom-btn">
      <IconButton
        on:click={resultsClicked}
        textMediumScaled={true}
        icon={Icons.FileAltRegular}
      >
        Resultater
      </IconButton>
    </span>
  </div>
</main>

{#if showYesNoModal}
  <YesNoModal on:no={() => (showYesNoModal = false)} on:yes={doLogOut}
    >Vil du logge ud?</YesNoModal
  >
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    margin-left: auto;
    margin-right: auto;
    width: 120vh;
    padding-bottom: 3vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: space-around;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    main {
      width: 100vw;
    }
  }
  .question {
    font-size: 15vmin;
    line-height: 25vmin;
  }
  .info {
    font-size: 3vmin;
    line-height: 10vmin;
    color: hsl(0, 0%, 32%);
    font-style: italic;
  }
  .bottom-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .left-bottom-btn {
    margin-left: 5vmin;
    margin-right: 5vmin;
  }
  .right-bottom-btn {
    margin-left: 5vmin;
    margin-right: 5vmin;
  }
  button {
    flex-grow: 1;
    border: solid 6px;
    border-radius: 12px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: var(--button-background-color);
    border-color: var(--button-border-color);
  }
  button:disabled {
    border-color: var(--button-border-disabled);
  }
  button:not([disabled]):hover {
    cursor: pointer;
    background-color: var(--button-hover-color);
  }
  .icon {
    height: min(calc(var(--vh, 1vh) * 24), 24vmin);
    width: min(calc(var(--vh, 1vh) * 24), 24vmin);
  }
  .caption {
    font-size: min(7vh, 7vw);
  }
</style>
