<script lang="ts">
  // show list of Centers
  // use modals for new, edit and delete
  // new page for results

  import { db } from "../firebase";
  import { collection, query, orderBy, onSnapshot } from "@firebase/firestore";

  import type { Unsubscribe } from "@firebase/firestore";

  import { onMount, onDestroy } from "svelte";

  import { Pages, DocType } from "../enums";
  import type { Center } from "../types";

  import { Icons } from "../utilities/icons";

  import { currentRoute, returnRoute } from "../stores/route-store";

  import IconButton from "../components/IconButton.svelte";
  import ListBox from "../components/ListBox.svelte";
  import EditCenterModal from "../modals/EditCenterModal.svelte";
  import NewCenterModal from "../modals/NewCenterModal.svelte";

  // STATE -----------------------------------------------------------

  let unsubscribe: Unsubscribe;
  let centers: Array<Center> = [];

  // list of users to display, construct from firestore database
  let listItems: Array<string> = [];
  // selected in list
  let selectedIndex: number | null;

  let showEditCenterModal = false;
  let showNewCenterModal = false;

  // FUNCTIONS -------------------------------------------------------

  // database operations ---

  const getRealtimeUpdates = () => {
    const collectionRef = collection(db, DocType.Centers);
    const collectionQuery = query(collectionRef, orderBy("name"));
    unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
      centers = [];
      listItems = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        centers.push({
          uid: doc.id,
          name: data.name,
          maxActiveUsers: data.maxActiveUsers,
        });
        listItems.push(data.name + " - " + data.maxActiveUsers + " borgere");
      });
    });
  };

  // button clicks ---

  const backButtonClicked = () => {
    $currentRoute = Pages.AdminStart;
  };

  const newCenterButtonClicked = () => {
    showNewCenterModal = true;
  };

  const editCenterButtonClicked = () => {
    showEditCenterModal = true;
  };

  // item list functions ---

  const listItemSelected = (event: CustomEvent): void => {
    if (selectedIndex !== null) {
      selectedIndex = event.detail.index;
    }
  };

  // LIFE CYCLE --------------------------------------------------

  onMount(() => {
    getRealtimeUpdates();
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="heading">
    <h1>Administration af Centre</h1>
  </div>

  <ListBox
    bind:items={listItems}
    bind:selectedIndex
    on:clicked={listItemSelected}
  />

  <div class="bottom-area">
    <span class="button-spacing">
      <IconButton
        on:click={backButtonClicked}
        textMediumScaled={true}
        icon={Icons.ArrowLeft}
      >
        Tilbage
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        on:click={newCenterButtonClicked}
        textMediumScaled={true}
        icon={Icons.Plus}
      >
        Ny
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndex === null}
        on:click={editCenterButtonClicked}
        textMediumScaled={true}
        icon={Icons.CogLight}
      >
        Rediger
      </IconButton>
    </span>
  </div>
</main>

{#if showEditCenterModal}
  <EditCenterModal
    on:editCenterDialogClose={() => {
      showEditCenterModal = false;
    }}
    aCenter={centers[selectedIndex]}
  />
{/if}

{#if showNewCenterModal}
  <NewCenterModal
    on:newCenterDialogClose={() => {
      showNewCenterModal = false;
    }}
  />
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    padding: 1vmin;
  }
  h1 {
    font-size: 3vmin;
  }
  .heading {
    margin-bottom: 1vmin;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 2px solid var(--key-hover-border-color);
    margin-top: 1vmin;
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-right: 1vmin;
  }
  .log-out-button {
    margin-left: auto;
  }
</style>
