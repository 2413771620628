<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import { IconButtonType } from "../enums";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  const dispatch = createEventDispatcher();

  const closeModal = () => {
    dispatch("messageModalClosed");
  };
</script>

<!-- HTML ===================================================== -->

<Modal>
  <form on:submit|preventDefault={closeModal}>
    <div class="content">
      <div class="text">
        <slot />
      </div>
    </div>
    <div class="bottom-box">
      <span class="ok-btn">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          OK
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  .ok-btn {
    margin-left: auto;
  }
  .text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .content {
    font-size: 2.5vmin;
    padding: 3vmin 2vmin;
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
    padding: 2vmin;
  }
</style>
