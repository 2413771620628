<script lang="ts">
  // button for top panel showing an icon

  import { Icons, icons } from "../utilities/icons";

  // PROPS -----------------------------------------

  export let disabled: boolean = false;
  export let icon: Icons;
</script>

<!-- HTML ===================================================== -->

<button {disabled} on:click>
  <svg
    class="icon"
    class:icon-disabled={disabled}
    viewBox={icons[icon].viewBox}
  >
    {@html icons[icon].path}
  </svg>
</button>

<!-- CSS ===================================================== -->
<style>
  button {
    width: var(--round-button-diameter);
    height: var(--round-button-diameter);
    border: var(--round-button-border);
    border-radius: 50%;
    background-color: var(--button-background-color);
    border-color: var(--button-border-color);
    cursor: pointer;
  }

  @media screen and (max-aspect-ratio: 6/5) {
    button {
      width: var(--round-button-diameter-6-5);
      height: var(--round-button-diameter-6-5);
    }
  }
  @media screen and (max-aspect-ratio: 5/5) {
    button {
      width: var(--round-button-diameter-5-5);
      height: var(--round-button-diameter-5-5);
    }
  }
  @media screen and (max-aspect-ratio: 4/5) {
    button {
      width: var(--round-button-diameter-4-5);
      height: var(--round-button-diameter-4-5);
    }
  }
  @media screen and (max-aspect-ratio: 3/5) {
    button {
      width: var(--round-button-diameter-3-5);
      height: var(--round-button-diameter-3-5);
    }
  }

  button:not([disabled]):hover {
    background-color: var(--button-hover-color);
  }
  button:active {
    background-color: var(--button-active-color);
  }
  button:focus {
    outline: none;
  }
  button:disabled,
  button:disabled:hover,
  button:disabled:active {
    border-color: var(--button-border-disabled);
    background-color: var(--button-background-color);
    color: #959595;
    box-shadow: none;
    cursor: not-allowed;
  }
  .icon {
    height: 75%;
    width: 75%;
    fill: #000000;
  }
  .icon-disabled {
    fill: #808080;
    cursor: default;
  }
</style>
