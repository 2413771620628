// (c) Cognisoft Aps, 2022 
// class for help level, for encapsulation

export class HelpLevel {
  private _level: number;
  private _numberOfHelpLevels: number

  constructor(numberOfHelpLevels: number) {
    this._level = 1;
    this._numberOfHelpLevels = numberOfHelpLevels;
  }
  get level() {
    return this._level;
  }
  get numberOfHelpLevels() {
    return this._numberOfHelpLevels;
  }
  setNextLevel() {
    if (this._level < this._numberOfHelpLevels) {
      this._level++;
    } else {
      this._level = 1;
    }
  }
  reset() {
    this._level = 1;
  }
}
