// pwa user settings used when admin or slt edits student

import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import type { Settings } from '../types';
import { Role, Level } from '../enums'
import {
  defaultHelpDelaySecs, defaultShowRepeatButtonDelaySecs,
  sessionsBeforeLevelChange
} from '../constants';

export let editPWAEmail: Writable<string> = writable("");
export let editPWAName: Writable<string> = writable("");
export let editPWAActive: Writable<boolean> = writable(true);
export let editPWALevel: Writable<Level> = writable(Level.First);
export let editPWACompletedOnCurrentLevel: Writable<number> = writable(0);

export let editPWAHasFreeLicenses: Writable<boolean> = writable(false);

export let editPWASettings: Writable<Settings> = writable({
  helpDelaySecs: defaultHelpDelaySecs,
  repeatDelaySecs: defaultShowRepeatButtonDelaySecs,
  perseveration: false,
  doTestNextSession: false,
  doIntroNextSession: false,
  sessionsBeforeLevelChange: sessionsBeforeLevelChange,
  qwertyKeyboard: false,
  simpleSpelling: false
});

// to distinguish privat users on settings page
export let editPWARole: Writable<string> = writable(Role.PWA);