<script lang="ts">
  // (c) Cognisoft Aps, 2022

  // import * as Sentry from "@sentry/svelte";

  // Sentry.captureMessage("error in routing");
</script>

<!-- HTML ===================================================== -->

<h1>Intern fejl i Routing</h1>
<slot>Routing-variabel mangler</slot>
