<script lang="ts">
  // show result details
  // use modals for new, edit and delete
  // new page for results

  import { db } from "../firebase";
  import {
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
  } from "@firebase/firestore";
  import type { Unsubscribe } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { onMount, onDestroy } from "svelte";

  import { Icons } from "../utilities/icons";
  import { convertFirebaseDate } from "../utilities/convertFirebaseDate";

  import { Pages, DocType, Level, Role } from "../enums";
  import type { Result, Settings } from "../types";

  import { currentRoute, returnRoute } from "../stores/route-store";
  import { savedResults, savedSettings } from "../stores/saved-result-store";
  import { user } from "../stores/user-store";

  import { pwaEmailForResults, pwaNameForResults } from "../stores/user-store";

  import IconButton from "../components/IconButton.svelte";

  import DeleteResultsModal from "../modals/DeleteResultsModal.svelte";
  import WaitModal from "../modals/WaitModal.svelte";

  import MultiSelectListBox from "../components/MultiSelectListBox.svelte";

  // STATE -----------------------------------------------------------

  let unsubscribe: Unsubscribe;
  let results: Array<Result> = [];
  let settings: Array<Settings> = [];
  let uids: Array<string> = [];
  let selectedUids: Array<string> = [];

  // list of results to display, construct from firestore database
  let listItems: Array<string> = [];
  // selected in list

  let showDeleteResultsModal = false;
  let showWaitModal = true;

  let totalTimeDiffs = 0;
  let totalHoursWorked = "";
  let totalTimesWorked = 0;

  let editUid = "";
  let editDateStr = "";

  // selected in list
  let selectedItems: Array<string> = [];
  let selectedIndexes: Array<number> = [];

  // FUNCTIONS -------------------------------------------------------

  $: {
    let ss = totalTimeDiffs;
    const hh = Math.floor(ss / 60 / 60);
    ss -= hh * 60 * 60;
    const mm = Math.floor(ss / 60);
    ss -= mm * 60;
    ss = Math.floor(ss);
    const hhText = hh === 1 ? " time " : " timer ";
    totalHoursWorked = hh + hhText + mm + " min. " + ss + " sek.";
  }

  // database operations ---

  const getRealtimeUpdates = () => {
    let introOrTestText = "";
    let tempPauseElapsed = 0;

    const collectionRef = collection(db, DocType.Results);
    const collectionQuery = query(
      collectionRef,
      where("email", "==", $pwaEmailForResults),
      orderBy("startTime"),
    );
    unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
      results = [];
      settings = [];
      listItems = [];
      uids = [];
      totalTimeDiffs = 0;
      totalTimesWorked = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        uids.push(doc.id);
        results.push({
          session: data.session,
          startTime: convertFirebaseDate(data.startTime),
          endTime: convertFirebaseDate(data.endTime),
          pauseElapsed: data.pauseElapsed === undefined ? 0 : data.pauseElapsed,
          items: data.items,
          // responseDetails: data.responseDetails,
          comprehensionCorrectFirstTry: data.comprehensionCorrectFirstTry,
          comprehensionIndependencyIndex: data.comprehensionIndependencyIndex,
          readingCorrectFirstTry: data.readingCorrectFirstTry,
          readingIndependencyIndex: data.readingIndependencyIndex,
          spellingCorrectFirstTry: data.spellingCorrectFirstTry,
          spellingIndependencyIndex: data.spellingIndependencyIndex,
          namingCorrectFirstTry: data.namingCorrectFirstTry,
          namingIndependencyIndex: data.namingIndependencyIndex,
          lastStepCompleted: data.lastStepCompleted,
          currentLevel: data.currentLevel || Level.First,
          wasInterrupted: data.wasInterrupted || false,
        });
        // 12.02.24: take into account simpleSpelling property
        //   only found in new results
        let tempSettings = data.settings;
        if (!tempSettings.hasOwnProperty("simpleSpelling")) {
          tempSettings.simpleSpelling = false;
        }
        settings.push(tempSettings);

        if (data.wasInterrupted) {
          introOrTestText = " (afbrudt)";
        } else if (data.settings.doIntroNextSession) {
          // console.log(data.settings.doIntroNextSession);
          introOrTestText = " (intro)";
        } else if (data.settings.doTestNextSession) {
          // console.log(data.settings.doTestNextSession);
          introOrTestText = " (basis-test)";
        } else if (data.currentLevel === Level.First) {
          introOrTestText = " (niveau 1)";
        } else if (data.currentLevel === Level.Second) {
          introOrTestText = " (niveau 2)";
        } else if (data.currentLevel === Level.SecondVerb) {
          introOrTestText = " (niveau 3)";
        } else if (data.currentLevel === Level.SecondSubject) {
          introOrTestText = " (niveau 4)";
        } else {
          introOrTestText = "";
        }
        let tempPauseElapsed = listItems.push(
          new Intl.DateTimeFormat("da-DK", {
            dateStyle: "short",
            timeStyle: "short",
          }).format(data.endTime.toDate()) + introOrTestText,
        );
        totalTimesWorked = totalTimesWorked + 1;
        tempPauseElapsed =
          data.pauseElapsed === undefined ? 0 : data.pauseElapsed;
        totalTimeDiffs =
          totalTimeDiffs +
          Math.abs(data.endTime - data.startTime) -
          tempPauseElapsed / 1000;
      });
    });
    listItems = listItems;
    showWaitModal = false;
  };

  const populateDataStore = () => {
    if (selectedIndexes.length === 0) {
      return;
    }
    $savedResults = [];
    $savedSettings = [];
    selectedIndexes.forEach((index) => {
      $savedResults.push(results[index]);
      $savedSettings.push(settings[index]);
    });
  };

  const populateSelectedUids = () => {
    if (selectedIndexes.length === 0) {
      return;
    }
    selectedUids = [];
    selectedIndexes.forEach((index) => {
      selectedUids.push(uids[index]);
    });
  };

  // button clicks ---

  const backButtonClicked = () => {
    $currentRoute = $returnRoute;
  };

  const resultDetailsClicked = () => {
    if (selectedIndexes === null) {
      // Sentry.captureMessage(
      //   "ResultList, error in resultsDetailClicked for selectedIndexes: null"
      // );
      console.log("fejl i resultsDetailClicked for selectedIndexes");
      return;
    }
    if (selectedIndexes.length === 1) {
      populateDataStore();
      $currentRoute = Pages.ResultDetails;
    } else if (selectedIndexes.length > 1) {
      populateDataStore();
      $currentRoute = Pages.ResultsLineChart;
    }
  };

  const deleteResultsClicked = () => {
    if (selectedIndexes.length > 0) {
      populateSelectedUids();
      showDeleteResultsModal = true;
    }
  };

  // const dataButtonClicked = () => {
  //   if (selectedIndexes.length > 0) {
  //     populateDataStore();
  //     $currentRoute = Pages.ResultCsvData;
  //   }
  // };

  // event handling ---

  const resultsWasDeleted = () => {
    selectedIndexes = [];
    showDeleteResultsModal = false;
  };

  // LIFE CYCLE --------------------------------------------------

  onMount(() => {
    showWaitModal = true;
    getRealtimeUpdates();
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="heading">
    <h1>Resultater</h1>
    <h2>
      {$pwaNameForResults} - Træninger: {totalTimesWorked}
      - Samlet tid: {totalHoursWorked}
    </h2>
  </div>

  <MultiSelectListBox
    items={listItems}
    bind:selectedIndexes
    bind:selectedItems
  />

  <div class="bottom-area">
    <span class="button-spacing">
      <IconButton
        on:click={backButtonClicked}
        textMediumScaled={true}
        icon={Icons.ArrowLeft}
      >
        Tilbage
      </IconButton>
    </span>
    {#if $user.role !== Role.PWA}
      <span class="button-spacing">
        <IconButton
          disabled={selectedIndexes.length === 0}
          on:click={deleteResultsClicked}
          textMediumScaled={true}
          icon={Icons.TrashAltRegular}
        >
          Slet
        </IconButton>
      </span>
    {/if}
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndexes.length === 0}
        on:click={resultDetailsClicked}
        textMediumScaled={true}
        icon={Icons.FileAltRegular}
      >
        Vis resultater
      </IconButton>
    </span>
    <!-- {#if $user.role !== Role.PWA}
      <span class="button-spacing">
        <IconButton
          disabled={selectedIndexes.length === 0}
          on:click={dataButtonClicked}
          textMediumScaled={true}
          icon={Icons.FileAltRegular}
        >
          Data
        </IconButton>
      </span>
    {/if} -->
  </div>
</main>

{#if showDeleteResultsModal}
  <DeleteResultsModal
    {selectedUids}
    on:deleteResultsDialogCanceled={() => (showDeleteResultsModal = false)}
    on:resultsDeleted={resultsWasDeleted}
  />
{/if}

{#if showWaitModal}
  <WaitModal />
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    padding: 1vmin;
  }
  h1 {
    font-size: 3vmin;
  }
  h2 {
    font-size: 2vmin;
    padding-top: 0.5vmin;
  }
  .heading {
    margin-bottom: 1vmin;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 2px solid var(--key-hover-border-color);
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-right: 1vmin;
  }
</style>
