<script lang="ts">
  // (c) Cognisoft Aps, 2022
  // put written sentences on matching pictures
  // sentences shown above 4 pictures
  // when correct: green frame on picture + text moves to top of picture
  // error: red frame around picture, instruction "try again"
  // goes to step 3 after correct

  import { fly } from "svelte/transition";
  import { linear } from "svelte/easing";
  import { crossfade } from "svelte/transition";
  import { flip } from "svelte/animate";
  import { onDestroy } from "svelte";
  // import * as Sentry from "@sentry/svelte";

  import { Pages, DaDK, Path, Extension, State } from "../enums";
  import { HelpLevel } from "../classes";
  import { afterCorrectWaitSecs, firstWaitSecs } from "../constants";

  import { Icons } from "../utilities/icons";
  import { shuffleArray } from "../utilities/shuffleArray";
  import { playSoundWithAudioTag } from "../utilities/playSound";
  import { playCorrectRandomWithAudioTag } from "../utilities/playCorrectRandom";
  import { getTotalNumberOfTasks } from "../utilities/getTotalNumberOfTasks";

  import { studentUserData } from "../stores/user-store";
  import { targetVerbAndObjects } from "../stores/targets-store";
  import { currentRoute } from "../stores/route-store";
  import { progress } from "../stores/progress-store";
  import { helpCount, result, tasksCompleted } from "../stores/result-store";

  import ExitModal from "../modals/ExitModal.svelte";

  import RoundButton from "../components/RoundButton.svelte";
  import ProgressBar from "../components/ProgressBar.svelte";
  import PauseMessage from "../components/PauseMessage.svelte";

  // PROPS --------------------------------------------------

  // one audiotag in App.svelte is used on all pages in order to have
  //   sound played even before mouse click
  export let audioTag: HTMLAudioElement;

  // CONSTANTS --------------------------------------------

  // neded for Svelte animation
  const [send, receive] = crossfade({});

  const numberOfHelpLevels = 6;
  // const numberOfStages = 4;

  // STATE ------------------------------------------------

  // send message with Sentry if it appears to be frozen

  // let frozenAppTimeOutSecs =
  //   numberOfHelpLevels *
  //   ($studentUserData.settings.helpDelaySecs + 10) *
  //   numberOfStages *
  //   1000;

  // complicated state because of levels of help both timed and requested

  let state = State.WaitToStart; // state for task solution for each item/picture
  let oldState: State = state; // keep old state during pause and exitModal
  let stage = 0; // 0-3, one stage for each sentence presented
  let targets = [0, 1, 2, 3]; // is randomized at init
  let clickedItem = -1; // -1: no image clicked, otherwise index of clicked image

  // arrays for send and received sentences
  //  needed for animation to work
  let sendItems: Array<string> = [];
  let receiveItems: Array<string> = [];

  let targetFound = [false, false, false, false];

  let disableAllButtons = true; // mostly when playing sounds
  let requestedHelpPaused = false;
  let allowRepetition = false;

  let repeatButtonTimer: ReturnType<typeof setTimeout>;
  let helpTimer: ReturnType<typeof setTimeout>;
  let playNextAudioTimer: ReturnType<typeof setTimeout>;
  // let frozenAppTimer: ReturnType<typeof setTimeout>;

  // help both after a set time and requested - levels interact!
  let helpLevel = new HelpLevel(numberOfHelpLevels);
  let imgWiggle = [false, false, false, false];
  let fingerPointing = [false, false, false, false];

  // for results:
  let gotHelpForItem = false;
  let hasErrorForItem = false;

  // save results for each response, push to array in results.store
  let presentTime: Date;
  let numberOfTimedHelp = 0;
  let numberOfRequestedHelp = 0;
  let numberOfRequestedRepetitions = 0;
  let attempt = 1;
  // let responseDetails: Array<ResponseDetail> = [];

  // keep track of pause time
  let pauseStartTime: number;
  let pauseEndTime: number;

  // FUNCTIONS --------------------------------------------

  // help functions

  const countHelp = () => {
    switch (stage) {
      case 0:
        $helpCount.reading.item21++;
        break;
      case 1:
        $helpCount.reading.item22++;
        break;
      case 2:
        $helpCount.reading.item23++;
        break;
      case 3:
        $helpCount.reading.item24++;
        break;
    }
  };

  // save result details for a single item, used for cvs results
  // *** used for research disabled in current production

  const saveResultVariablesWithCorrect = (correct: boolean) => {
    // responseDetails.push({
    //   step: 2,
    //   attempt: attempt,
    //   presentTime: presentTime,
    //   responseTime: new Date(),
    //   targetItem: $targetVerbAndObjects[targets[stage]].sentence,
    //   correct: correct,
    //   numberOfTimedHelp: numberOfTimedHelp,
    //   numberOfRequestedHelp: numberOfRequestedHelp,
    //   numberOfRequestedRepetitions: numberOfRequestedRepetitions,
    // });
  };

  // show repeat button a defined time after showin task

  const startRepeatButtonTimer = () => {
    allowRepetition = false;
    repeatButtonTimer = setTimeout(() => {
      allowRepetition = true;
    }, $studentUserData.settings.repeatDelaySecs * 1000);
  };

  // showing help according to 2 level hierarkies (timed and requested help) ---

  const enableHelpButton = () => {
    disableAllButtons = false;
    requestedHelpPaused = false;
  };

  const startHelpTimer = () => {
    enableHelpButton();
    helpTimer = setTimeout(() => {
      timedHelp();
    }, $studentUserData.settings.helpDelaySecs * 1000);
  };

  const startHelpTimerAndWaitForResponse = () => {
    state = State.WaitResponse;
    startHelpTimer();
    // initializeResultVariables();  // 26.02.23 - SHOULD NOT BE HERE ?????
  };

  // showing help according to 2 level hierarkies (timed and requested help) ---

  // general instruction is repeated
  const generalInstructionHelp = () => {
    helpLevel.setNextLevel();
    playSoundWithAudioTag(
      audioTag,
      DaDK.Step2Intro,
      startHelpTimerAndWaitForResponse,
    );
  };

  const repeatSentence = () => {
    if (state !== State.ExitModalShown && state !== State.Paused) {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[targets[stage]].sentence,
        startHelpTimerAndWaitForResponse,
      );
    }
  };

  const repeatSentenceHelp = () => {
    helpLevel.setNextLevel();
    repeatSentence();
  };

  const readSentenceAgainHelp = () => {
    helpLevel.setNextLevel();
    playSoundWithAudioTag(
      audioTag,
      DaDK.ReadAgain,
      startHelpTimerAndWaitForResponse,
    );
  };

  const tryAgainSentenceHelp = () => {
    helpLevel.setNextLevel();
    playSoundWithAudioTag(audioTag, DaDK.TryAgain, repeatSentence);
  };

  // image vibrates and get glowing shadow, sentence sound repeated
  const vibrationHelp = () => {
    // set leve for both types of help
    helpLevel.setNextLevel();
    imgWiggle[targets[stage]] = true;
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[targets[stage]].sentence,
      startHelpTimerAndWaitForResponse,
    );
  };

  // finger slids up to correct sentence, sentence sound repeated
  const fingerPointingHelp = () => {
    // After finger-pointing help give correct answer and go on
    helpLevel.setNextLevel();
    fingerPointing[targets[stage]] = true;
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[targets[stage]].sentence,
      startHelpTimerAndWaitForResponse,
    );
  };

  // last help level is to provide correct answer
  const giveAnswerHelp = () => {
    $progress = $progress + 1;
    state = State.Correct;
    helpLevel.setNextLevel();
    setVariablesForCorrectResponse();
    targetFound[targets[stage]] = true;
    receiveItems = [...sendItems];
    sendItems = [];
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[targets[stage]].sentenceOK,
      null,
    );
    // next picture or task
    setTimeout(() => {
      requestedHelpPaused = true;
      clearTimeout(helpTimer);
      afterCorrectResponseOrLastHelpLevel();
    }, $studentUserData.settings.helpDelaySecs * 500); // half of help delay
  };

  // give requested help after clicking help button according to help level
  const afterErrorHelp = () => {
    countHelp();
    gotHelpForItem = true;
    requestedHelpPaused = true;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    clearTimeout(playNextAudioTimer);
    switch (helpLevel.level) {
      case 1:
        readSentenceAgainHelp();
        break;
      case 2:
      case 3:
        tryAgainSentenceHelp();
        break;
      case 4:
        vibrationHelp();
        break;
      case 5:
        fingerPointingHelp();
        break;
      case 6:
        giveAnswerHelp();
        break;
      default:
        // Sentry.captureMessage(
        //   "Step 2, error in errorAfterHelp() switch, helpLevel: " +
        //     helpLevel.level +
        //     " - stage: " +
        //     stage
        // );
        console.log(
          "error in errorAfterHelp() switch, helpLevel: ",
          helpLevel.level,
          " - stage: ",
          stage,
        );
    }
  };

  // give help after a duration according to help level
  const timedHelp = () => {
    countHelp();
    numberOfTimedHelp++;
    gotHelpForItem = true;
    requestedHelpPaused = true;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    clearTimeout(playNextAudioTimer);
    switch (helpLevel.level) {
      case 1:
        generalInstructionHelp();
        break;
      case 2:
      case 3:
        repeatSentenceHelp();
        break;
      case 4:
        vibrationHelp();
        break;
      case 5:
        fingerPointingHelp();
        break;
      case 6:
        giveAnswerHelp();
        break;
      default:
        // Sentry.captureMessage(
        //   "Step 2, error in timedHelp() switch, helpLevel: " +
        //     helpLevel.level +
        //     " - stage: " +
        //     stage
        // );
        console.log(
          "error in timedHelp() switch, helpLevel: ",
          helpLevel.level,
          " - stage: ",
          stage,
        );
    }
  };

  const requestedHelp = () => {
    countHelp();
    numberOfRequestedHelp++;
    requestedHelpPaused = true;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    playSoundWithAudioTag(audioTag, DaDK.Step2Intro, afterRequestedHelp);
  };

  const afterRequestedHelp = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[targets[stage]].sentence,
      startHelpTimer,
    );
  };

  // logic for going on with task ------

  // pause before sentence is played
  const waitAndStart = (): void => {
    playNextAudioTimer = setTimeout(() => {
      presentSentenceText();
    }, firstWaitSecs);
  };

  // show text to match with picture in top pane
  const presentSentenceText = (): void => {
    state = State.PresentTask;
    // animation sender:
    sendItems = [$targetVerbAndObjects[targets[stage]].sentence];
    playNextAudioTimer = setTimeout(() => {
      playIntro();
    }, 1000);
  };

  const playIntro = (): void => {
    playSoundWithAudioTag(audioTag, DaDK.Step2Intro, waitForResponse);
  };

  const initializeResultVariables = () => {
    presentTime = new Date();
    numberOfTimedHelp = 0;
    numberOfRequestedHelp = 0;
    numberOfRequestedRepetitions = 0;
  };

  // image buttons active while waiting for answer
  const waitForResponse = (): void => {
    state = State.WaitResponse;
    disableAllButtons = false;
    startRepeatButtonTimer();
    startHelpTimer();
    initializeResultVariables();
  };

  // common reset for chosen correct response and given as last stage of help
  const setVariablesForCorrectResponse = () => {
    if (!gotHelpForItem && !hasErrorForItem) {
      $result.readingCorrectFirstTry = $result.readingCorrectFirstTry + 1;
    }
    $progress = $progress + 1;
    clearTimeout(playNextAudioTimer);
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    allowRepetition = false;
    imgWiggle = [false, false, false, false];
    fingerPointing = [false, false, false, false];
    attempt = 1;
  };

  // correct ------

  const correctResponse = (): void => {
    // reset receive-items used for animation endpoints
    receiveItems = [...sendItems];
    sendItems = [];
    playCorrectRandomWithAudioTag(
      audioTag,
      repeatSentenceAfterCorrectOrLastHelpLevel,
    );
  };

  const repeatSentenceAfterCorrectOrLastHelpLevel = (): void => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[targets[stage]].sentenceOK,
      afterCorrectResponseOrLastHelpLevel,
    );
  };

  const afterCorrectResponseOrLastHelpLevel = (): void => {
    $tasksCompleted = $tasksCompleted + 1;
    if (stage < 3) {
      stage++;
      helpLevel.reset();
      gotHelpForItem = false;
      hasErrorForItem = false;
      presentSentenceText();
    } else {
      state = State.WaitAfterEnd;
      // $result.responseDetails.push(...responseDetails);
      $result.lastStepCompleted = 2;
      setTimeout(() => {
        $currentRoute = Pages.Step3;
      }, afterCorrectWaitSecs);
    }
  };

  // error -----
  const afterError = (): void => {
    clearTimeout(helpTimer);
    clearTimeout(repeatButtonTimer);
    clearTimeout(playNextAudioTimer);
    hasErrorForItem = true;
    allowRepetition = false;
    imgWiggle = [false, false, false, false];
    fingerPointing = [false, false, false, false];
    afterErrorHelp();
  };

  // button event handling ------

  // an image button was clicked, response evaluated
  const imgClicked = (index: number): void => {
    clearTimeout(playNextAudioTimer);
    clickedItem = index;
    if (index === targets[stage]) {
      saveResultVariablesWithCorrect(true);
      state = State.Correct;
      targetFound[index] = true;
      setVariablesForCorrectResponse();
      correctResponse();
    } else {
      saveResultVariablesWithCorrect(false);
      attempt++;
      state = State.Error;
      afterError();
    }
  };

  // when repeat button is pressed
  const repeatSound = () => {
    countHelp();
    numberOfRequestedRepetitions++;
    clearTimeout(helpTimer);
    requestedHelpPaused = true;
    disableAllButtons = true;
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[targets[stage]].sentence,
      startHelpTimer,
    );
  };

  // pause timed help
  const pauseClicked = (): void => {
    if (state === State.Paused) {
      pauseEndTime = Date.now();
      $result.pauseElapsed =
        $result.pauseElapsed + Math.abs(pauseEndTime - pauseStartTime);
      state = oldState;
      allowRepetition = true;
      startHelpTimer();
    } else {
      pauseStartTime = Date.now();
      // stopSoundWithAudioTag(audioTag);
      clearTimeout(playNextAudioTimer);
      clearTimeout(repeatButtonTimer);
      clearTimeout(helpTimer);
      oldState = state;
      state = State.Paused;
    }
  };

  // exit task prematurely after stop button clicked

  // stop button: show yes-no modal
  const exitButtonClicked = () => {
    // stopSoundWithAudioTag(audioTag);
    clearTimeout(playNextAudioTimer);
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    oldState = state;
    state = State.ExitModalShown;
  };

  // exit task (modal event "yes")
  const exitTask = () => {
    // stopSoundWithAudioTag(audioTag);
    clearTimeout(playNextAudioTimer);
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    $result.wasInterrupted = true;
    $currentRoute = Pages.EndResults;
  };

  // dismiss exit modal (event "no")
  const dismissExitModal = () => {
    state = oldState;
    allowRepetition = true;
    startHelpTimer();
  };

  // LIFE CYCLE ---------------------------------------------------

  onDestroy(() => {
    clearTimeout(helpTimer);
    helpTimer = null;
    // clearTimeout(frozenAppTimer);
    // frozenAppTimer = null;
  });

  // INITIALIZE --------------------------------------------

  // randomize order pictures are shown in
  targets = shuffleArray(targets);

  // // to find if app should freeze
  // frozenAppTimer = setTimeout(() => {
  //   if (state !== State.Paused) {
  //     Sentry.captureMessage(
  //       "Step 2 frozen, stage " +
  //         stage +
  //         ", state " +
  //         state +
  //         ", helpLevel " +
  //         helpLevel.level +
  //         ", helpDelaySecs " +
  //         $studentUserData.settings.helpDelaySecs +
  //         ", disableAllButtons " +
  //         disableAllButtons +
  //         ", numberOfTimedHelp " +
  //         numberOfTimedHelp +
  //         ", numberOfRequestedHelp " +
  //         numberOfRequestedHelp +
  //         ", numberOfRequestedRepetitions " +
  //         numberOfRequestedRepetitions +
  //         ", attempt " +
  //         attempt +
  //         ", requestedHelpPaused " +
  //         requestedHelpPaused +
  //         ", allowRepetition " +
  //         allowRepetition
  //     );
  //   }
  // }, frozenAppTimeOutSecs);

  // present text target from start - flies ind animated
  waitAndStart();
</script>

<!-- HTML ===================================================== -->

<body
  class:completed-bg-color={(state === State.WaitAfterEnd ||
    state === State.Correct) &&
    stage === 3}
>
  <main>
    <!-- top panel with button for help and space for text to fly in -->
    <div class="top-panel">
      <div class="buttons">
        <span class="stop-button">
          <RoundButton
            disabled={state !== State.WaitResponse || disableAllButtons}
            on:click={exitButtonClicked}
            icon={Icons.TrafficLightRed}
          />
        </span>
        <RoundButton
          disabled={(state !== State.WaitResponse && state !== State.Paused) ||
            disableAllButtons}
          on:click={pauseClicked}
          icon={state === State.Paused ? Icons.Play : Icons.Pause}
        />
      </div>

      {#if state !== State.Correct}
        <!-- #each even though only one at a time - in order to be svelte animated  -->
        {#each sendItems as item (item)}
          <div
            class="sentence"
            class:wide-sentence={() => item.length > 15}
            in:fly|global={{ duration: 1000, easing: linear, x: 2000 }}
            animate:flip
            out:send|global={{ key: item }}
          >
            {item}
          </div>
        {/each}
      {/if}
      <div class="buttons">
        <RoundButton
          disabled={state !== State.WaitResponse ||
            !allowRepetition ||
            requestedHelpPaused ||
            disableAllButtons}
          on:click={repeatSound}
          icon={Icons.VolumeRegular}
        />
        <span class="help-button">
          <RoundButton
            disabled={state !== State.WaitResponse ||
              requestedHelpPaused ||
              disableAllButtons}
            on:click={requestedHelp}
            icon={Icons.QuestionSolid}
          />
        </span>
      </div>
    </div>
    <!-- 4 clickable image buttons in 2x2 grid -->
    {#each $targetVerbAndObjects as target, i}
      <button
        style="background-image: url('{Path.ImgBg}{target.image}{Extension.ImgBg}'); background-image: image-set('{Path.ImgBg}{target.image}{Extension.WebpBg}');background-size: cover;"
        class={"img img" + i}
        class:img-wiggle={imgWiggle[i]}
        class:img-shadow-glow={imgWiggle[i]}
        class:img-wait={state !== State.WaitResponse}
        class:img-correct={(state === State.Correct ||
          state === State.WaitAfterEnd) &&
          i === targets[stage]}
        class:img-error={state === State.Error && clickedItem === i}
        disabled={state !== State.WaitResponse}
        on:click={() => imgClicked(i)}
      >
        <span class="button-text" class:no-border={!targetFound[i]}>
          <!-- #each even though only one at a time - in order to be svelte animated  -->
          {#if state === State.Correct && i === targets[stage]}
            {#each receiveItems as item (item)}
              <div animate:flip in:receive|global={{ key: item }}>
                {item}
              </div>
            {/each}
          {:else if targetFound[i]}
            {$targetVerbAndObjects[i].sentence}
          {/if}
        </span>
        <picture>
          <source
            type="image/webp"
            srcset={encodeURI(Path.Img + target.image + Extension.Webp)}
            draggable={false}
          />
          <img
            draggable={false}
            src={Path.Img + target.image + Extension.Png}
            alt=""
          />
        </picture>
      </button>
      <!-- finger pointing help -->
      <span
        class={"finger-img finger-img-" + i}
        class:finger-point={fingerPointing[i]}
      >
        <picture class="finger-png">
          <source
            type="image/webp"
            srcset={encodeURI("finger-2.webp")}
            draggable={false}
          />
          <img draggable={false} src={"finger-2.png"} alt="" />
        </picture>
      </span>
    {/each}
    <div class="progress">
      <ProgressBar
        max={getTotalNumberOfTasks($studentUserData.currentLevel)}
        position={$progress}
      />
    </div>
  </main>
</body>

{#if state === State.Paused}
  <PauseMessage on:click={pauseClicked} />
{/if}

{#if state === State.ExitModalShown}
  <ExitModal on:yes={exitTask} on:no={dismissExitModal} />
{/if}

<!-- CSS ====================================================== -->
<style>
  main {
    margin-left: auto;
    margin-right: auto;
    width: 120vh;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 14% 41% 41% 2%;
    grid-template-areas:
      "top top"
      "img1 img2"
      "img3 img4"
      "prog prog";
    gap: 2%;
    padding: 3%;
    padding-top: 1%;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    main {
      width: 100vw;
    }
  }
  .top-panel {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img0 {
    grid-area: img1;
  }
  .img1 {
    grid-area: img2;
  }
  .img2 {
    grid-area: img3;
  }
  .img3 {
    grid-area: img4;
  }
  img {
    width: 100%;
    height: 85%;
    object-fit: contain;
    object-position: bottom;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  .sentence {
    border: solid 1px black;
    background: white;
    height: 6vh;
    flex: 1 auto;
    margin-right: 2%;
    margin-left: 2%;
    font-size: 4vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .buttons {
    flex: 0 auto;
  }
  .button-text {
    flex-wrap: wrap;
    border: solid 1px black;
    background: white;
    width: 100%;
    height: 15%;
    color: black;
    font-size: 4vmin;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .no-border {
    border: none;
  }
  .correct-text {
    z-index: 200;
    border: solid 1px black;
    background: white;
    width: 100%;
    height: 100%;
    color: black;
    font-size: 4vmin;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .finger-png {
    object-position: bottom right;
  }
  /* finger pointing help with animated finger sliding up */
  .finger-img {
    z-index: 2;
    transform: translate(-5%, 15%);
    visibility: hidden;
    pointer-events: none;
  }
  .finger-img-0 {
    grid-area: img1;
  }
  .finger-img-1 {
    grid-area: img2;
  }
  .finger-img-2 {
    grid-area: img3;
  }
  .finger-img-3 {
    grid-area: img4;
  }
  .finger-point {
    visibility: visible;
    animation-duration: 2s;
    animation-name: slideup;
  }
  @keyframes slideup {
    from {
      transform: translate(-5%, 50vh);
    }
    to {
      transform: translate(-5%, 15%);
    }
  }
</style>
