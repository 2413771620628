<script lang="ts">
  // (c) Cognisoft Aps, 2022
  import { icons } from "../utilities/icons";
  import { IconButtonType } from "../enums";

  // PROPS --------------------------------------------

  export let icon: string = "";
  export let iconRight: boolean = false;
  export let iconOnTop: boolean = false;
  export let noText: boolean = false;
  export let small: boolean = false;
  export let disabled: boolean = false;
  export let textScaled: boolean = false;
  export let textMediumScaled: boolean = false;
  export let textLargeScaled: boolean = false;
  export let iconLargeScaled: boolean = false;
  export let iconMediumScaled: boolean = false;
  export let fat: boolean = false;
  export let title: string = "";
  export let type: IconButtonType = IconButtonType.Button; // for submit
  // for part of segmentedBtn:
  export let segmentleft: boolean = false;
  export let segmentmiddle: boolean = false;
  export let segmentright: boolean = false;
  export let wide: boolean = false;
  export let mediumWide: boolean = false;
  export let redColor: boolean = false;
</script>

<!-- HTML ===================================================== -->

<button
  {type}
  on:click
  {title}
  {disabled}
  class:scaled={textScaled}
  class:medium-scaled={textMediumScaled}
  class:large-scaled={textLargeScaled}
  class:small
  class:fat
  class:wide
  class:mediumWide
  class:segmentleft
  class:segmentmiddle
  class:segmentright
  class:redColor
>
  <span
    class="btn-content"
    class:icon-top={iconOnTop}
    class:icon-right={iconRight}
  >
    {#if icon !== ""}
      {#if icons[icon]}
        <svg
          class:svg-large-scaled={iconLargeScaled}
          class:svg-medium-scaled={iconMediumScaled}
          class:svg-right={iconRight}
          viewBox={icons[icon].viewBox}
        >
          {@html icons[icon].path}
        </svg>
      {/if}
      {#if !noText && !iconOnTop && !iconRight && icons[icon]}
        &nbsp;
      {/if}
    {/if}
    <slot />
    {#if !noText && !iconOnTop && iconRight && icons[icon]}
      &nbsp;
    {/if}
  </span>
</button>

<!-- CSS ===================================================== -->
<style>
  button {
    /* display: inline-block; */
    font: inherit;
    border: 3px solid var(--button-border-color);
    background-color: var(--button-background-color);
    padding: 0.5rem 1rem;
    border-radius: 1vmin;
    border-color: var(--button-border-color);
    box-shadow: 1px 1px 3px var(--shadow-color);
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    margin: 0;
    user-select: none;
    font-size: calc(0.5rem + 1vmin);
  }
  button:focus {
    outline: none;
  }
  button:not([disabled]):hover {
    background-color: var(--button-hover-color);
  }
  button:active {
    background-color: var(--button-active-color);
  }
  button:disabled,
  button:disabled:hover,
  button:disabled:active {
    border-color: var(--button-border-disabled);
    color: #959595;
    box-shadow: none;
    cursor: not-allowed;
  }
  .redColor {
    border-color: var(--button-border-color-red);
    background-color: var(--button-background-color-red);
  }
  .redColor:not([disabled]):hover,
  .redColor:active {
    background-color: lightpink;
    border-color: red;
  }
  .scaled {
    font-size: calc(0.5rem + 3vmin);
  }
  .medium-scaled {
    font-size: calc(0.5rem + 2vmin);
  }
  .large-scaled {
    font-size: calc(0.5rem + 4vmin);
  }
  .small {
    padding: 0.1rem 0.5rem;
  }
  .fat {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }
  .segmentright {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    margin-left: 0;
  }
  .segmentleft {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }
  .segmentmiddle {
    border-radius: 0;
    border-left: 0;
    margin-left: 0;
  }
  svg {
    height: 1.2em;
    width: 1.2em;
    align-self: center;
    fill: currentColor;
  }
  .svg-large-scaled {
    height: calc(0.8rem + 6vmin);
    width: calc(0.8rem + 6vmin);
  }
  .svg-medium-scaled {
    height: calc(0.5rem + 3vmin);
    width: calc(0.5rem + 3vmin);
  }
  .btn-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    justify-content: center;
    flex-wrap: wrap;
  }
  .icon-top {
    flex-direction: column;
  }
  .icon-right {
    flex-direction: row-reverse;
  }
  .svg-right {
    margin-left: 0.4rem;
  }
  .wide {
    width: 90%;
  }
  .mediumWide {
    width: 65%;
  }
</style>
