// (c) Cognisoft Aps, 2022 
import { Gender, Vowels } from '../enums';
import type { VerbAndObjects } from '../types';
export const introVerbAndObjects: VerbAndObjects = [
  {
    image: "børster hår",
    sentence: "børster hår",
    sentenceFull: "cecilie børster hår",
    sentenceOK: "børster hår_ok",
    subjectGender: Gender.Female,
    subjectName: "cecilie",
    verb: "børster",
    object: "hår",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_r",
    namingIntro: "hun børster hun børster",
    namingSuggestionFalse: "hun børster børster hun tænder nej hun børster",
    namingVerbSuggestionFalse: "hun gør noget med håret fletter hun hår nej hun",
    namingVerbIntro: "hun gør noget med håret hun",
    namingFullSentencePrompt: "hvad laver cecilie",
    namingFullSentence: "hun børster hår",
    namingShortPrompt: "hun børster_",
    frequencyObject: 6588
  },
  {
    image: "spiller fodbold",
    sentence: "spiller fodbold",
    sentenceFull: "viggo spiller fodbold",
    sentenceOK: "spiller fodbold_ok",
    subjectGender: Gender.Male,
    subjectName: "viggo",
    verb: "spiller",
    object: "fodbold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "f_dbold",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han klaver nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med fodbold ser han fodbold nej han",
    namingVerbIntro: "han gør noget med fodbolden han",
    namingFullSentencePrompt: "hvad laver viggo",
    namingFullSentence: "han spiller fodbold",
    namingShortPrompt: "han spiller_",
    frequencyObject: 5648
  },
  {
    image: "vasker tøj",
    sentence: "vasker tøj",
    sentenceFull: "anne vasker tøj",
    sentenceOK: "vasker tøj_ok",
    subjectGender: Gender.Female,
    subjectName: "anne",
    verb: "vasker",
    object: "tøj",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "t_j",
    namingIntro: "hun vasker hun vasker",
    namingSuggestionFalse: "hun vasker vasker hun bil nej hun vasker",
    namingVerbSuggestionFalse: "hun gør noget med tøjet prøver hun tøj nej hun",
    namingVerbIntro: "hun gør noget med tøjet hun",
    namingFullSentencePrompt: "hvad laver anne",
    namingFullSentence: "hun vasker tøj",
    namingShortPrompt: "hun vasker_",
    frequencyObject: 7087
  },
  {
    image: "hører musik",
    sentence: "hører musik",
    sentenceFull: "magnus hører musik",
    sentenceOK: "hører musik_ok",
    subjectGender: Gender.Male,
    subjectName: "magnus",
    verb: "hører",
    object: "musik",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "mus_k",
    namingIntro: "han hører han hører",
    namingSuggestionFalse: "han hører hører han radioavis nej han hører",
    namingVerbSuggestionFalse: "han gør noget med musik komponerer han musik nej han",
    namingVerbIntro: "han gør noget med musik han",
    namingFullSentencePrompt: "hvad laver magnus",
    namingFullSentence: "han hører musik",
    namingShortPrompt: "han hører_",
    frequencyObject: 11893
  }
];

export const basis1VerbAndObjects: VerbAndObjects = [
  {
    image: "drikker vin",
    sentence: "drikker vin",
    sentenceFull: "simone drikker vin",
    sentenceOK: "drikker vin_ok",
    subjectGender: Gender.Female,
    subjectName: "simone",
    verb: "drikker",
    object: "vin",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "v_n",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun mælk nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med vin sælger hun vin nej hun",
    namingVerbIntro: "hun gør noget med vin hun",
    namingFullSentencePrompt: "hvad laver simone",
    namingFullSentence: "hun drikker vin",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 5165
  },
  {
    image: "spiser kage",
    sentence: "spiser kage",
    sentenceFull: "karoline spiser kage",
    sentenceOK: "spiser kage_ok",
    subjectGender: Gender.Female,
    subjectName: "karoline",
    verb: "spiser",
    object: "kage",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "k_ge",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun suppe nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med kage bager hun kage nej hun",
    namingVerbIntro: "hun gør noget med kage hun",
    namingFullSentencePrompt: "hvad laver karoline",
    namingFullSentence: "hun spiser kage",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1560
  },
  {
    image: "klipper hæk",
    sentence: "klipper hæk",
    sentenceFull: "tim klipper hæk",
    sentenceOK: "klipper hæk_ok",
    subjectGender: Gender.Male,
    subjectName: "tim",
    verb: "klipper",
    object: "hæk",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "h_k",
    namingIntro: "han klipper han klipper",
    namingSuggestionFalse: "han klipper klipper han hår nej han klipper",
    namingVerbSuggestionFalse: "han gør noget med hækken planter han hæk nej han",
    namingVerbIntro: "han gør noget med hækken han",
    namingFullSentencePrompt: "hvad laver tim",
    namingFullSentence: "han klipper hæk",
    namingShortPrompt: "han klipper_",
    frequencyObject: 570
  },
  {
    image: "spiller bold",
    sentence: "spiller bold",
    sentenceFull: "jakob spiller bold",
    sentenceOK: "spiller bold_ok",
    subjectGender: Gender.Male,
    subjectName: "jakob",
    verb: "spiller",
    object: "bold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_ld",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han computer nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med bolden sælger han bolden nej han",
    namingVerbIntro: "han gør noget med bolden han",
    namingFullSentencePrompt: "hvad laver jakob",
    namingFullSentence: "han spiller bold",
    namingShortPrompt: "han spiller_",
    frequencyObject: 3801
  }
];

export const basis2VerbAndObjects: VerbAndObjects = [
  {
    image: "binder snørebånd",
    sentence: "binder sko",
    sentenceFull: "mikkel binder sko",
    sentenceOK: "binder sko_ok",
    subjectGender: Gender.Male,
    subjectName: "mikkel",
    verb: "binder",
    object: "sko",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "sk_",
    namingIntro: "han binder han binder",
    namingSuggestionFalse: "han binder binder han slips nej han binder",
    namingVerbSuggestionFalse: "han gør noget med sko pudser han sko nej han",
    namingVerbIntro: "han gør noget med sko han ",
    namingFullSentencePrompt: "hvad laver mikkel",
    namingFullSentence: "han binder sko",
    namingShortPrompt: "han binder_",
    frequencyObject: 2725
  },
  {
    image: "pudser vindue",
    sentence: "pudser vindue",
    sentenceFull: "gerda pudser vindue",
    sentenceOK: "pudser vindue_ok",
    subjectGender: Gender.Female,
    subjectName: "gerda",
    verb: "pudser",
    object: "vindue",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "v_ndue",
    namingIntro: "hun pudser hun pudser",
    namingSuggestionFalse: "hun pudser pudser hun sølvtøj nej hun pudser",
    namingVerbSuggestionFalse: "hun gør noget med vinduet sælger hun vinduet nej hun",
    namingVerbIntro: "hun gør noget med vinduet hun",
    namingFullSentencePrompt: "hvad laver gerda",
    namingFullSentence: "hun pudser vindue",
    namingShortPrompt: "hun pudser_",
    frequencyObject: 5756
  },
  {
    image: "vasker gulv",
    sentence: "vasker gulv",
    sentenceFull: "jenny vasker gulv",
    sentenceOK: "vasker gulv_ok",
    subjectGender: Gender.Female,
    subjectName: "jenny",
    verb: "vasker",
    object: "gulv",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "g_lv",
    namingIntro: "hun vasker hun vasker",
    namingSuggestionFalse: "hun vasker vasker hun hår nej hun vasker",
    namingVerbSuggestionFalse: "hun gør noget med gulvet sliber hun gulv nej hun",
    namingVerbIntro: "hun gør noget med gulvet hun",
    namingFullSentencePrompt: "hvad laver jenny",
    namingFullSentence: "hun vasker gulv",
    namingShortPrompt: "hun vasker_",
    frequencyObject: 5447
  },
  {
    image: "spidser blyant",
    sentence: "spidser blyant",
    sentenceFull: "kasper spidser blyant",
    sentenceOK: "spidser blyant_ok",
    subjectGender: Gender.Male,
    subjectName: "kasper",
    verb: "spidser",
    object: "blyant",
    stressedVowel: Vowels.Y,
    vowelTargetPosition: "bl_ant",
    namingIntro: "han spidser han spidser",
    namingSuggestionFalse: "han spidser spidser han ører nej han spidser",
    namingVerbSuggestionFalse: "han gør noget med en blyant køber han en blyant nej han",
    namingVerbIntro: "han gør noget med blyanten han",
    namingFullSentencePrompt: "hvad laver kasper",
    namingFullSentence: "han spidser blyant",
    namingShortPrompt: "han spidser_",
    frequencyObject: 516
  }
]

export const verbAndObjects: VerbAndObjects = [
  {
    image: "bager småkager",
    sentence: "bager småkager",
    sentenceFull: "anna bager småkager",
    sentenceOK: "bager småkager_ok",
    subjectGender: Gender.Female,
    subjectName: "anna",
    verb: "bager",
    object: "småkager",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "sm_kager",
    namingIntro: "hun bager hun bager",
    namingSuggestionFalse: "hun bager noget bager hun rugbrød nej hun bager",
    namingVerbSuggestionFalse: "hun gør noget med småkagerne spiser hun småkager nej hun",
    namingVerbIntro: "hun gør noget med småkager hun",
    namingFullSentencePrompt: "hvad laver anna",
    namingFullSentence: "hun bager småkager",
    namingShortPrompt: "hun bager_",
    frequencyObject: 268
  },
  {
    image: "spiller bordtennis",
    sentence: "spiller bordtennis",
    sentenceFull: "oliver spiller bordtennis",
    sentenceOK: "spiller bordtennis_ok",
    subjectGender: Gender.Male,
    subjectName: "oliver",
    verb: "spiller",
    object: "bordtennis",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_rdtennis",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han fodbold nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med bordtennis ser han bordtennis nej han",
    namingVerbIntro: "han gør noget med bordtennis han",
    namingFullSentencePrompt: "hvad laver oliver",
    namingFullSentence: "han spiller bordtennis",
    namingShortPrompt: "han spiller_",
    frequencyObject: 160
  },
  {
    image: "åbner køleskabet",
    sentence: "åbner køleskabet",
    sentenceFull: "phillip åbner køleskabet",
    sentenceOK: "åbner køleskabet_ok",
    subjectGender: Gender.Male,
    subjectName: "phillip",
    verb: "åbner",
    object: "køleskabet",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "k_leskabet",
    namingIntro: "han åbner han åbner",
    namingSuggestionFalse: "han åbner noget åbner han havelågen nej han åbner",
    namingVerbSuggestionFalse: "han gør noget med køleskabet vasker han køleskabet nej han",
    namingVerbIntro: "han gør noget med køleskabet han",
    namingFullSentencePrompt: "hvad laver phillip",
    namingFullSentence: "han åbner køleskabet",
    namingShortPrompt: "han åbner_",
    frequencyObject: 1477
  },
  {
    image: "tager sol",
    sentence: "tager sol",
    sentenceFull: "pernille tager sol",
    sentenceOK: "tager sol_ok",
    subjectGender: Gender.Female,
    subjectName: "pernille",
    verb: "tager",
    object: "sol",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "s_l",
    namingIntro: "hun tager hun tager",
    namingSuggestionFalse: "hun tager tager hun vitaminer nej hun tager",
    namingVerbSuggestionFalse: "hun gør noget med sol undgår hun sol nej hun",
    namingVerbIntro: "hun gør noget med sol hun",
    namingFullSentencePrompt: "hvad laver pernille",
    namingFullSentence: "hun tager sol",
    namingShortPrompt: "hun tager_",
    frequencyObject: 7805
  },
  {
    image: "spiser suppe",
    sentence: "spiser suppe",
    sentenceFull: "yrsa spiser suppe",
    sentenceOK: "spiser suppe_ok",
    subjectGender: Gender.Female,
    subjectName: "yrsa",
    verb: "spiser",
    object: "suppe",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "s_ppe",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun flæskesteg nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med suppen koger hun suppe nej hun",
    namingVerbIntro: "hun gør noget med suppen hun",
    namingFullSentencePrompt: "hvad laver yrsa",
    namingFullSentence: "hun spiser suppe",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1495
  },
  {
    image: "spiser chokolade",
    sentence: "spiser chokolade",
    sentenceFull: "lotte spiser chokolade",
    sentenceOK: "spiser chokolade_ok",
    subjectGender: Gender.Female,
    subjectName: "lotte",
    verb: "spiser",
    object: "chokolade",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "chokol_de",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun salat nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med chokolade sælger hun chokolade nej hun",
    namingVerbIntro: "hun gør noget med chokoladen hun",
    namingFullSentencePrompt: "hvad laver lotte",
    namingFullSentence: "hun spiser chokolade",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1009
  },
  {
    image: "spiser salat",
    sentence: "spiser salat",
    sentenceFull: "michelle spiser salat",
    sentenceOK: "spiser salat_ok",
    subjectGender: Gender.Female,
    subjectName: "michelle",
    verb: "spiser",
    object: "salat",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "sal_t",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun bøffer nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med salaten skærer hun salat nej hun",
    namingVerbIntro: "hun gør noget med salaten hun",
    namingFullSentencePrompt: "hvad laver michelle",
    namingFullSentence: "hun spiser salat",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1109
  },
  {
    image: "spiser pølse",
    sentence: "spiser pølse",
    sentenceFull: "thor spiser pølse",
    sentenceOK: "spiser pølse_ok",
    subjectGender: Gender.Male,
    subjectName: "thor",
    verb: "spiser",
    object: "pølse",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "p_lse",
    namingIntro: "han spiser han spiser",
    namingSuggestionFalse: "han spiser spiser han majs nej han spiser",
    namingVerbSuggestionFalse: "han gør noget med pølsen griller han pølse nej han",
    namingVerbIntro: "han gør noget med pølsen han",
    namingFullSentencePrompt: "hvad laver thor",
    namingFullSentence: "han spiser pølse",
    namingShortPrompt: "han spiser_",
    frequencyObject: 937
  },
  {
    image: "spiser kage",
    sentence: "spiser kage",
    sentenceFull: "karoline spiser kage",
    sentenceOK: "spiser kage_ok",
    subjectGender: Gender.Female,
    subjectName: "karoline",
    verb: "spiser",
    object: "kage",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "k_ge",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun suppe nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med kage bager hun kage nej hun",
    namingVerbIntro: "hun gør noget med kage hun",
    namingFullSentencePrompt: "hvad laver karoline",
    namingFullSentence: "hun spiser kage",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1560
  },
  {
    image: "spiser pasta",
    sentence: "spiser pasta",
    sentenceFull: "niels spiser pasta",
    sentenceOK: "spiser pasta_ok",
    subjectGender: Gender.Male,
    subjectName: "niels",
    verb: "spiser",
    object: "pasta",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "p_sta",
    namingIntro: "han spiser han spiser",
    namingSuggestionFalse: "han spiser spiser han havregrød nej han spiser",
    namingVerbSuggestionFalse: "han gør noget med pasta koger han pasta nej han",
    namingVerbIntro: "han gør noget med pasta han",
    namingFullSentencePrompt: "hvad laver niels",
    namingFullSentence: "han spiser pasta",
    namingShortPrompt: "han spiser_",
    frequencyObject: 417
  },
  {
    image: "spiller violin",
    sentence: "spiller violin",
    sentenceFull: "anne spiller violin",
    sentenceOK: "spiller violin_ok",
    subjectGender: Gender.Female,
    subjectName: "anne",
    verb: "spiller",
    object: "violin",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "viol_n",
    namingIntro: "hun spiller hun spiller",
    namingSuggestionFalse: "hun spiller spiller hun kort nej hun spiller",
    namingVerbSuggestionFalse: "hun gør noget med violin pudser hun violin nej hun",
    namingVerbIntro: "hun gør noget med sin violin hun",
    namingFullSentencePrompt: "hvad laver anne",
    namingFullSentence: "hun spiller violin",
    namingShortPrompt: "hun spiller_",
    frequencyObject: 433
  },
  {
    image: "spiller tennis",
    sentence: "spiller tennis",
    sentenceFull: "lauge spiller tennis",
    sentenceOK: "spiller tennis_ok",
    subjectGender: Gender.Male,
    subjectName: "lauge",
    verb: "spiller",
    object: "tennis",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "t_nnis",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han skak nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med tennis ser han tennis nej han",
    namingVerbIntro: "han gør noget med tennis han",
    namingFullSentencePrompt: "hvad laver lauge",
    namingFullSentence: "han spiller tennis",
    namingShortPrompt: "han spiller_",
    frequencyObject: 960
  },
  {
    image: "spiller ludo",
    sentence: "spiller ludo",
    sentenceFull: "mira spiller ludo",
    sentenceOK: "spiller ludo_ok",
    subjectGender: Gender.Female,
    subjectName: "mira",
    verb: "spiller",
    object: "ludo",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "l_do",
    namingIntro: "hun spiller hun spiller",
    namingSuggestionFalse: "hun spiller spiller hun guitar nej hun spiller",
    namingVerbSuggestionFalse: "hun gør noget med ludo køber hun ludo nej hun",
    namingVerbIntro: "hun gør noget med ludo hun",
    namingFullSentencePrompt: "hvad laver mira",
    namingFullSentence: "hun spiller ludo",
    namingShortPrompt: "hun spiller_",
    frequencyObject: 54
  },
  {
    image: "spiller klaver",
    sentence: "spiller klaver",
    sentenceFull: "james spiller klaver",
    sentenceOK: "spiller klaver_ok",
    subjectGender: Gender.Male,
    subjectName: "james",
    verb: "spiller",
    object: "klaver",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "klav_r",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han fodbold nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med klaveret pudser han klaveret nej han",
    namingVerbIntro: "han gør noget med klaveret han",
    namingFullSentencePrompt: "hvad laver james",
    namingFullSentence: "han spiller klaver",
    namingShortPrompt: "han spiller_",
    frequencyObject: 862
  },
  {
    image: "spiller håndbold",
    sentence: "spiller håndbold",
    sentenceFull: "pelle spiller håndbold",
    sentenceOK: "spiller håndbold_ok",
    subjectGender: Gender.Male,
    subjectName: "pelle",
    verb: "spiller",
    object: "håndbold",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_ndbold",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han fodbold nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med håndbold ser han håndbold nej han",
    namingVerbIntro: "han gør noget med håndbold han",
    namingFullSentencePrompt: "hvad laver pelle",
    namingFullSentence: "han spiller håndbold",
    namingShortPrompt: "han spiller_",
    frequencyObject: 1153
  },
  {
    image: "spiller guitar",
    sentence: "spiller guitar",
    sentenceFull: "børge spiller guitar",
    sentenceOK: "spiller guitar_ok",
    subjectGender: Gender.Male,
    subjectName: "børge",
    verb: "spiller",
    object: "guitar",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "guit_r",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han trompet nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med sin guitar pudser han guitar nej han",
    namingVerbIntro: "han gør noget med sin guitar han",
    namingFullSentencePrompt: "hvad laver børge",
    namingFullSentence: "han spiller guitar",
    namingShortPrompt: "han spiller_",
    frequencyObject: 867
  },
  {
    image: "spiser slik",
    sentence: "spiser slik",
    sentenceFull: "marie spiser slik",
    sentenceOK: "spiser slik_ok",
    subjectGender: Gender.Female,
    subjectName: "marie",
    verb: "spiser",
    object: "slik",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "sl_k",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser noget spiser hun grøntsager nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med slik sælger hun slik nej hun",
    namingVerbIntro: "hun gør noget med slik hun",
    namingFullSentencePrompt: "hvad laver marie",
    namingFullSentence: "hun spiser slik",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1848
  },
  {
    image: "spiller bold",
    sentence: "spiller bold",
    sentenceFull: "jakob spiller bold",
    sentenceOK: "spiller bold_ok",
    subjectGender: Gender.Male,
    subjectName: "jakob",
    verb: "spiller",
    object: "bold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_ld",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han computer nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med bolden sælger han bolden nej han",
    namingVerbIntro: "han gør noget med bolden han",
    namingFullSentencePrompt: "hvad laver jakob",
    namingFullSentence: "han spiller bold",
    namingShortPrompt: "han spiller_",
    frequencyObject: 3801
  },
  {
    image: "skifter hjul",
    sentence: "skifter hjul",
    sentenceFull: "ken skifter hjul",
    sentenceOK: "skifter hjul_ok",
    subjectGender: Gender.Male,
    subjectName: "ken",
    verb: "skifter",
    object: "hjul",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "hj_l",
    namingIntro: "han skifter han skifter",
    namingSuggestionFalse: "han skifter skifter han tøj nej han skifter",
    namingVerbSuggestionFalse: "han gør noget med et hjul pumper han hjul nej han",
    namingVerbIntro: "han gør noget med et hjul han",
    namingFullSentencePrompt: "hvad laver ken",
    namingFullSentence: "han skifter hjul",
    namingShortPrompt: "han skifter_",
    frequencyObject: 1426
  },
  {
    image: "ser fjernsyn",
    sentence: "ser fjernsyn",
    sentenceFull: "jonas ser fjernsyn",
    sentenceOK: "ser fjernsyn_ok",
    subjectGender: Gender.Male,
    subjectName: "jonas",
    verb: "ser",
    object: "fjernsyn",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "fj_rnsyn",
    namingIntro: "han ser han ser",
    namingSuggestionFalse: "han ser ser han håndbold nej han ser",
    namingVerbSuggestionFalse: "han gør noget med fjernsyn slukker han fjernsynet nej han",
    namingVerbIntro: "han gør noget med fjernsyn han",
    namingFullSentencePrompt: "hvad laver jonas",
    namingFullSentence: "han ser fjernsyn",
    namingShortPrompt: "han ser_",
    frequencyObject: 2915
  },
  {
    image: "ser film",
    sentence: "ser film",
    sentenceFull: "kristina ser film",
    sentenceOK: "ser film_ok",
    subjectGender: Gender.Female,
    subjectName: "kristina",
    verb: "ser",
    object: "film",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "f_lm",
    namingIntro: "hun ser hun ser",
    namingSuggestionFalse: "hun ser ser hun fodbold nej hun ser",
    namingVerbSuggestionFalse: "hun gør noget med film vælger hun film nej hun",
    namingVerbIntro: "hun gør noget med film hun",
    namingFullSentencePrompt: "hvad laver kristina",
    namingFullSentence: "hun ser film",
    namingShortPrompt: "hun ser_",
    frequencyObject: 19583
  },
  {
    image: "saver brænde",
    sentence: "saver brænde",
    sentenceFull: "kurt saver brænde",
    sentenceOK: "saver brænde_ok",
    subjectGender: Gender.Male,
    subjectName: "kurt",
    verb: "saver",
    object: "brænde",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "br_nde",
    namingIntro: "han saver han saver",
    namingSuggestionFalse: "han saver saver han spånplade nej han saver",
    namingVerbSuggestionFalse: "han gør noget med brænde køber han brænde nej han",
    namingVerbIntro: "han gør noget med brænde han",
    namingFullSentencePrompt: "hvad laver kurt",
    namingFullSentence: "han saver brænde",
    namingShortPrompt: "han saver_",
    frequencyObject: 4141
  },
  {
    image: "samler æg",
    sentence: "samler æg",
    sentenceFull: "lone samler æg",
    sentenceOK: "samler æg_ok",
    subjectGender: Gender.Female,
    subjectName: "lone",
    verb: "samler",
    object: "æg",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "_g",
    namingIntro: "hun samler hun samler",
    namingSuggestionFalse: "hun samler samler hun frimærker nej hun samler",
    namingVerbSuggestionFalse: "hun gør noget med æg køber hun æg nej hun",
    namingVerbIntro: "hun gør noget med æg hun",
    namingFullSentencePrompt: "hvad laver lone",
    namingFullSentence: "hun samler æg",
    namingShortPrompt: "hun samler_",
    frequencyObject: 3619
  },
  {
    image: "føntørrer hår",
    sentence: "føntørrer hår",
    sentenceFull: "sidsel føntørrer hår",
    sentenceOK: "føntørrer hår_ok",
    subjectGender: Gender.Female,
    subjectName: "sidsel",
    verb: "føntørrer",
    object: "hår",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_r",
    namingIntro: "hun føntørrer hun føntørrer",
    namingSuggestionFalse: "hun føntørrer føntørrer hun en trøje nej hun føntørrer",
    namingVerbSuggestionFalse: "hun gør noget med håret klipper hun hår nej hun",
    namingVerbIntro: "hun gør noget med håret hun",
    namingFullSentencePrompt: "hvad laver sidsel",
    namingFullSentence: "hun føntørrer hår",
    namingShortPrompt: "hun føntørrer_",
    frequencyObject: 6588
  },
  {
    image: "pudser næse",
    sentence: "pudser næse",
    sentenceFull: "ursula pudser næse",
    sentenceOK: "pudser næse_ok",
    subjectGender: Gender.Female,
    subjectName: "ursula",
    verb: "pudser",
    object: "næse",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "n_se",
    namingIntro: "hun pudser hun pudser",
    namingSuggestionFalse: "hun pudser pudser hun vinduer nej hun pudser",
    namingVerbSuggestionFalse: "hun gør noget med næsen piller hun næse nej hun",
    namingVerbIntro: "hun gør noget med næsen hun",
    namingFullSentencePrompt: "hvad laver ursula",
    namingFullSentence: "hun pudser næse",
    namingShortPrompt: "hun pudser_",
    frequencyObject: 3668
  },
  {
    image: "prøver sko",
    sentence: "prøver sko",
    sentenceFull: "ellen prøver sko",
    sentenceOK: "prøver sko_ok",
    subjectGender: Gender.Female,
    subjectName: "ellen",
    verb: "prøver",
    object: "sko",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "sk_",
    namingIntro: "hun prøver hun prøver",
    namingSuggestionFalse: "hun prøver prøver hun smykker nej hun prøver",
    namingVerbSuggestionFalse: "hun gør noget sko pudser hun sko nej hun",
    namingVerbIntro: "hun gør noget med sko hun",
    namingFullSentencePrompt: "hvad laver ellen",
    namingFullSentence: "hun prøver sko",
    namingShortPrompt: "hun prøver_",
    frequencyObject: 2725
  },
  {
    image: "plukker blomster",
    sentence: "plukker blomster",
    sentenceFull: "ditte plukker blomster",
    sentenceOK: "plukker blomster_ok",
    subjectGender: Gender.Female,
    subjectName: "ditte",
    verb: "plukker",
    object: "blomster",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "bl_mster",
    namingIntro: "hun plukker hun plukker",
    namingSuggestionFalse: "hun plukker plukker hun svampe nej hun plukker",
    namingVerbSuggestionFalse: "hun gør noget med blomster planter hun blomster nej hun",
    namingVerbIntro: "hun gør noget med blomster hun",
    namingFullSentencePrompt: "hvad laver ditte",
    namingFullSentence: "hun plukker blomster",
    namingShortPrompt: "hun plukker_",
    frequencyObject: 3677
  },
  {
    image: "skærer grøntsager",
    sentence: "skærer grøntsager",
    sentenceFull: "gorm skærer grøntsager",
    sentenceOK: "skærer grøntsager_ok",
    subjectGender: Gender.Male,
    subjectName: "gorm",
    verb: "skærer",
    object: "grøntsager",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "gr_ntsager",
    namingIntro: "han skærer han skærer",
    namingSuggestionFalse: "han skærer skærer han kød nej han skærer",
    namingVerbSuggestionFalse: "han gør noget med grøntsager køber han grøntsager nej han",
    namingVerbIntro: "han gør noget med grøntsager han",
    namingFullSentencePrompt: "hvad laver gorm",
    namingFullSentence: "han skærer grøntsager",
    namingShortPrompt: "han skærer_",
    frequencyObject: 1052
  },
  {
    image: "lægger kabale",
    sentence: "lægger kabale",
    sentenceFull: "oskar lægger kabale",
    sentenceOK: "lægger kabale_ok",
    subjectGender: Gender.Male,
    subjectName: "oskar",
    verb: "lægger",
    object: "kabale",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "kab_le",
    namingIntro: "han lægger han lægger",
    namingSuggestionFalse: "han lægger lægger han puslespil nej han lægger",
    namingVerbSuggestionFalse: "han gør noget med kabalen dropper han kabalen nej han",
    namingVerbIntro: "han gør noget med kabalen han",
    namingFullSentencePrompt: "hvad laver oskar",
    namingFullSentence: "han lægger kabale",
    namingShortPrompt: "han lægger_",
    frequencyObject: 119
  },
  {
    image: "pakker rygsæk",
    sentence: "pakker taske",
    sentenceFull: "ludvig pakker taske",
    sentenceOK: "pakker taske_ok",
    subjectGender: Gender.Male,
    subjectName: "ludvig",
    verb: "pakker",
    object: "taske",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "t_ske",
    namingIntro: "han pakker han pakker",
    namingSuggestionFalse: "han pakker pakker han kuffert nej han pakker",
    namingVerbSuggestionFalse: "han gør noget med tasken køber han en taske nej han",
    namingVerbIntro: "han gør noget med tasken han",
    namingFullSentencePrompt: "hvad laver ludvig",
    namingFullSentence: "han pakker taske",
    namingShortPrompt: "han pakker_",
    frequencyObject: 1521
  },
  {
    image: "pakker rygsæk",
    sentence: "pakker rygsæk",
    sentenceFull: "marius pakker rygsæk",
    sentenceOK: "pakker rygsæk_ok",
    subjectGender: Gender.Male,
    subjectName: "marius",
    verb: "pakker",
    object: "rygsæk",
    stressedVowel: Vowels.Y,
    vowelTargetPosition: "r_gsæk",
    namingIntro: "han pakker han pakker",
    namingSuggestionFalse: "han pakker pakker han kuffert nej han pakker",
    namingVerbSuggestionFalse: "han gør noget med en rygsæk køber han en rygsæk nej han",
    namingVerbIntro: "han gør noget med en rygsæk han",
    namingFullSentencePrompt: "hvad laver marius",
    namingFullSentence: "han pakker rygsæk",
    namingShortPrompt: "han pakker_",
    frequencyObject: 553
  },
  {
    image: "pakker kuffert",
    sentence: "pakker kuffert",
    sentenceFull: "laila pakker kuffert",
    sentenceOK: "pakker kuffert_ok",
    subjectGender: Gender.Female,
    subjectName: "laila",
    verb: "pakker",
    object: "kuffert",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "k_ffert",
    namingIntro: "hun pakker hun pakker",
    namingSuggestionFalse: "hun pakker pakker hun gaver ind nej hun pakker",
    namingVerbSuggestionFalse: "hun gør noget med kufferten vasker hun kuffert nej hun",
    namingVerbIntro: "hun gør noget med kufferten hun",
    namingFullSentencePrompt: "hvad laver laila",
    namingFullSentence: "hun pakker kuffert",
    namingShortPrompt: "hun pakker_",
    frequencyObject: 928
  },
  {
    image: "laver bål",
    sentence: "laver bål",
    sentenceFull: "tage laver bål",
    sentenceOK: "laver bål_ok",
    subjectGender: Gender.Male,
    subjectName: "tage",
    verb: "laver",
    object: "bål",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "b_l",
    namingIntro: "han laver han laver",
    namingSuggestionFalse: "han laver noget laver han kunst nej han laver",
    namingVerbSuggestionFalse: "han gør noget med bålet slukker han bålet nej han",
    namingVerbIntro: "han gør noget med bålet han",
    namingFullSentencePrompt: "hvad laver tage",
    namingFullSentence: "han laver bål",
    namingShortPrompt: "han laver_",
    frequencyObject: 992
  },
  {
    image: "køber kage",
    sentence: "køber kage",
    sentenceFull: "karsten køber kage",
    sentenceOK: "køber kage_ok",
    subjectGender: Gender.Male,
    subjectName: "karsten",
    verb: "køber",
    object: "kage",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "k_ge",
    namingIntro: "han køber han køber",
    namingSuggestionFalse: "han køber køber han postkort nej han køber",
    namingVerbSuggestionFalse: "han gør noget med kage bager han kage nej han",
    namingVerbIntro: "han gør noget med kage han",
    namingFullSentencePrompt: "hvad laver karsten",
    namingFullSentence: "han køber kage",
    namingShortPrompt: "han køber_",
    frequencyObject: 1560
  },
  {
    image: "holder tale",
    sentence: "holder tale",
    sentenceFull: "louise holder tale",
    sentenceOK: "holder tale_ok",
    subjectGender: Gender.Female,
    subjectName: "louise",
    verb: "holder",
    object: "tale",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "t_le",
    namingIntro: "hun holder hun holder",
    namingSuggestionFalse: "hun holder noget holder hun sabbatår nej hun holder",
    namingVerbSuggestionFalse: "hun gør noget med en tale filmer hun en tale nej hun",
    namingVerbIntro: "hun gør noget med en tale hun",
    namingFullSentencePrompt: "hvad laver louise",
    namingFullSentence: "hun holder tale",
    namingShortPrompt: "hun holder_",
    frequencyObject: 43774
  },
  {
    image: "holder fødselsdag",
    sentence: "holder fødselsdag",
    sentenceFull: "jasmin holder fødselsdag",
    sentenceOK: "holder fødselsdag_ok",
    subjectGender: Gender.Female,
    subjectName: "jasmin",
    verb: "holder",
    object: "fødselsdag",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "f_dselsdag",
    namingIntro: "hun holder hun holder",
    namingSuggestionFalse: "hun holder noget holder hun bryllup nej hun holder",
    namingVerbSuggestionFalse: "hun gør noget med fødselsdag aflyser hun sin fødselsdag nej hun",
    namingVerbIntro: "hun gør noget med fødselsdag hun",
    namingFullSentencePrompt: "hvad laver jasmin",
    namingFullSentence: "hun holder fødselsdag",
    namingShortPrompt: "hun holder_",
    frequencyObject: 2153
  },
  {
    image: "henter børn",
    sentence: "henter børn",
    sentenceFull: "elsa henter børn",
    sentenceOK: "henter børn_ok",
    subjectGender: Gender.Female,
    subjectName: "elsa",
    verb: "henter",
    object: "børn",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "b_rn",
    namingIntro: "hun henter hun henter",
    namingSuggestionFalse: "hun henter henter hun avisen nej hun henter",
    namingVerbSuggestionFalse: "hun gør noget med børn putter hun børn nej hun",
    namingVerbIntro: "hun gør noget med sine børn hun",
    namingFullSentencePrompt: "hvad laver elsa",
    namingFullSentence: "hun henter børn",
    namingShortPrompt: "hun henter_",
    frequencyObject: 63902
  },
  {
    image: "drikker mælk",
    sentence: "drikker mælk",
    sentenceFull: "helga drikker mælk",
    sentenceOK: "drikker mælk_ok",
    subjectGender: Gender.Female,
    subjectName: "helga",
    verb: "drikker",
    object: "mælk",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "m_lk",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun kaffe nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med mælk køber hun mælk nej hun",
    namingVerbIntro: "hun gør noget med mælk hun",
    namingFullSentencePrompt: "hvad laver helga",
    namingFullSentence: "hun drikker mælk",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 2663
  },
  {
    image: "kører knallert",
    sentence: "kører knallert",
    sentenceFull: "lukas kører knallert",
    sentenceOK: "kører knallert_ok",
    subjectGender: Gender.Male,
    subjectName: "lukas",
    verb: "kører",
    object: "knallert",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "kn_llert",
    namingIntro: "han kører han kører",
    namingSuggestionFalse: "han kører kører han traktor nej han kører",
    namingVerbSuggestionFalse: "han gør noget med knallerten reparerer han knallert nej han",
    namingVerbIntro: "han gør noget med knallerten han",
    namingFullSentencePrompt: "hvad laver lukas",
    namingFullSentence: "han kører knallert",
    namingShortPrompt: "han kører_",
    frequencyObject: 529
  },
  {
    image: "drikker vin",
    sentence: "drikker vin",
    sentenceFull: "simone drikker vin",
    sentenceOK: "drikker vin_ok",
    subjectGender: Gender.Female,
    subjectName: "simone",
    verb: "drikker",
    object: "vin",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "v_n",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun mælk nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med vin sælger hun vin nej hun",
    namingVerbIntro: "hun gør noget med vin hun",
    namingFullSentencePrompt: "hvad laver simone",
    namingFullSentence: "hun drikker vin",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 5165
  },
  {
    image: "spiller kort",
    sentence: "spiller kort",
    sentenceFull: "hans spiller kort",
    sentenceOK: "spiller kort_ok",
    subjectGender: Gender.Male,
    subjectName: "hans",
    verb: "spiller",
    object: "kort",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "k_rt",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han skak nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med kort skriver han kort nej han",
    namingVerbIntro: "han gør noget med kort han",
    namingFullSentencePrompt: "hvad laver hans",
    namingFullSentence: "han spiller kort",
    namingShortPrompt: "han spiller_",
    frequencyObject: 23852
  },
  {
    image: "lukker vinduet",
    sentence: "lukker vinduet",
    sentenceFull: "sarah lukker vinduet",
    sentenceOK: "lukker vinduet_ok",
    subjectGender: Gender.Female,
    subjectName: "sarah",
    verb: "lukker",
    object: "vinduet",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "v_nduet",
    namingIntro: "hun lukker hun lukker",
    namingSuggestionFalse: "hun lukker lukker hun øjnene nej hun lukker",
    namingVerbSuggestionFalse: "hun gør noget med vinduet pudser hun vinduet nej hun",
    namingVerbIntro: "hun gør noget med vinduet hun",
    namingFullSentencePrompt: "hvad laver sarah",
    namingFullSentence: "hun lukker vinduet",
    namingShortPrompt: "hun lukker_",
    frequencyObject: 5751
  },
  {
    image: "skræller gulerødder",
    sentence: "skræller gulerødder",
    sentenceFull: "andrea skræller gulerødder",
    sentenceOK: "skræller gulerødder_ok",
    subjectGender: Gender.Female,
    subjectName: "andrea",
    verb: "skræller",
    object: "gulerødder",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "g_lerødder",
    namingIntro: "hun skræller hun skræller",
    namingSuggestionFalse: "hun skræller skræller hun kartofler nej hun skræller",
    namingVerbSuggestionFalse: "hun gør noget med gulerødder spiser hun gulerødder nej hun",
    namingVerbIntro: "hun gør noget med gulerødder hun",
    namingFullSentencePrompt: "hvad laver andrea",
    namingFullSentence: "hun skræller gulerødder",
    namingShortPrompt: "hun skræller_",
    frequencyObject: 1123
  },
  {
    image: "fejer gulv",
    sentence: "fejer gulv",
    sentenceFull: "finn fejer gulv",
    sentenceOK: "fejer gulv_ok",
    subjectGender: Gender.Male,
    subjectName: "finn",
    verb: "fejer",
    object: "gulv",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "g_lv",
    namingIntro: "han fejer han fejer",
    namingSuggestionFalse: "han fejer fejer han fortov nej han fejer",
    namingVerbSuggestionFalse: "han gør noget med gulvet vasker han gulv nej han",
    namingVerbIntro: "han gør noget med gulvet han",
    namingFullSentencePrompt: "hvad laver finn",
    namingFullSentence: "han fejer gulv",
    namingShortPrompt: "han fejer_",
    frequencyObject: 5447
  },
  {
    image: "holder møde",
    sentence: "holder møde",
    sentenceFull: "dennis holder møde",
    sentenceOK: "holder møde_ok",
    subjectGender: Gender.Male,
    subjectName: "dennis",
    verb: "holder",
    object: "møde",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "m_de",
    namingIntro: "han holder han holder",
    namingSuggestionFalse: "han holder holder han fødselsdag nej han holder",
    namingVerbSuggestionFalse: "han gør noget med et møde aflyser han mødet nej han",
    namingVerbIntro: "han gør noget med et møde han",
    namingFullSentencePrompt: "hvad laver dennis",
    namingFullSentence: "han holder møde",
    namingShortPrompt: "han holder_",
    frequencyObject: 30246
  },
  {
    image: "ordner have",
    sentence: "ordner have",
    sentenceFull: "kim ordner have",
    sentenceOK: "ordner have_ok",
    subjectGender: Gender.Male,
    subjectName: "kim",
    verb: "ordner",
    object: "have",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "h_ve",
    namingIntro: "han ordner han ordner",
    namingSuggestionFalse: "han ordner noget ordner han hår nej han ordner",
    namingVerbSuggestionFalse: "han gør noget med haven kigger han på haven nej han",
    namingVerbIntro: "han gør noget med haven han",
    namingFullSentencePrompt: "hvad laver kim",
    namingFullSentence: "han ordner have",
    namingShortPrompt: "han ordner_",
    frequencyObject: 835553
  },
  {
    image: "bygger hus",
    sentence: "bygger hus",
    sentenceFull: "daniel bygger hus",
    sentenceOK: "bygger hus_ok",
    subjectGender: Gender.Male,
    subjectName: "daniel",
    verb: "bygger",
    object: "hus",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "h_s",
    namingIntro: "han bygger han bygger",
    namingSuggestionFalse: "han bygger noget bygger han fortov nej han bygger",
    namingVerbSuggestionFalse: "han gør noget med huset maler han huset nej han",
    namingVerbIntro: "han gør noget med huset han",
    namingFullSentencePrompt: "hvad laver daniel",
    namingFullSentence: "han bygger hus",
    namingShortPrompt: "han bygger_",
    frequencyObject: 23372
  },
  {
    image: "sælger blomster",
    sentence: "sælger blomster",
    sentenceFull: "birgitte sælger blomster",
    sentenceOK: "sælger blomster_ok",
    subjectGender: Gender.Female,
    subjectName: "birgitte",
    verb: "sælger",
    object: "blomster",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "bl_mster",
    namingIntro: "hun sælger hun sælger",
    namingSuggestionFalse: "hun sælger sælger hun porcelæn nej hun sælger",
    namingVerbSuggestionFalse: "hun gør noget med blomster plukker hun blomster nej hun",
    namingVerbIntro: "hun gør noget med blomster hun",
    namingFullSentencePrompt: "hvad laver birgitte",
    namingFullSentence: "hun sælger blomster",
    namingShortPrompt: "hun sælger_",
    frequencyObject: 3677
  },
  {
    image: "pumper cykel",
    sentence: "pumper cykel",
    sentenceFull: "signe pumper cykel",
    sentenceOK: "pumper cykel_ok",
    subjectGender: Gender.Female,
    subjectName: "signe",
    verb: "pumper",
    object: "cykel",
    stressedVowel: Vowels.Y,
    vowelTargetPosition: "c_kel",
    namingIntro: "hun pumper hun pumper",
    namingSuggestionFalse: "hun pumper noget pumper hun knallert nej hun pumper",
    namingVerbSuggestionFalse: "hun gør noget med cyklen sælger hun cyklen nej hun",
    namingVerbIntro: "hun gør noget med cyklen hun",
    namingFullSentencePrompt: "hvad laver signe",
    namingFullSentence: "hun pumper cykel",
    namingShortPrompt: "hun pumper_",
    frequencyObject: 3135
  },
  {
    image: "bygger tårn",
    sentence: "bygger tårn",
    sentenceFull: "iben bygger tårn",
    sentenceOK: "bygger tårn_ok",
    subjectGender: Gender.Female,
    subjectName: "iben",
    verb: "bygger",
    object: "tårn",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "t_rn",
    namingIntro: "hun bygger hun bygger",
    namingSuggestionFalse: "hun bygger bygger hun hule nej hun bygger",
    namingVerbSuggestionFalse: "hun gør noget med tårnet vælter hun tårnet nej hun",
    namingVerbIntro: "hun gør noget med tårnet hun",
    namingFullSentencePrompt: "hvad laver iben",
    namingFullSentence: "hun bygger tårn",
    namingShortPrompt: "hun bygger_",
    frequencyObject: 1199
  },
  {
    image: "tager piller",
    sentence: "tager piller",
    sentenceFull: "jørgen tager piller",
    sentenceOK: "tager piller_ok",
    subjectGender: Gender.Male,
    subjectName: "jørgen",
    verb: "tager",
    object: "piller",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "p_ller",
    namingIntro: "han tager han tager",
    namingSuggestionFalse: "han tager tager han opvasken nej han tager",
    namingVerbSuggestionFalse: "han gør noget med piller køber han piller nej han",
    namingVerbIntro: "han gør noget med piller han",
    namingFullSentencePrompt: "hvad laver jørgen",
    namingFullSentence: "han tager piller",
    namingShortPrompt: "han tager_",
    frequencyObject: 2498
  },
  {
    image: "børster hår",
    sentence: "børster hår",
    sentenceFull: "cecilie børster hår",
    sentenceOK: "børster hår_ok",
    subjectGender: Gender.Female,
    subjectName: "cecilie",
    verb: "børster",
    object: "hår",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_r",
    namingIntro: "hun børster hun børster",
    namingSuggestionFalse: "hun børster børster hun tænder nej hun børster",
    namingVerbSuggestionFalse: "hun gør noget med håret fletter hun hår nej hun",
    namingVerbIntro: "hun gør noget med håret hun",
    namingFullSentencePrompt: "hvad laver cecilie",
    namingFullSentence: "hun børster hår",
    namingShortPrompt: "hun børster_",
    frequencyObject: 6588
  },
  {
    image: "pynter lagkage",
    sentence: "pynter lagkage",
    sentenceFull: "gert pynter lagkage",
    sentenceOK: "pynter lagkage_ok",
    subjectGender: Gender.Male,
    subjectName: "gert",
    verb: "pynter",
    object: "lagkage",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "l_gkage",
    namingIntro: "han pynter han pynter",
    namingSuggestionFalse: "han pynter noget pynter han bordet nej han pynter",
    namingVerbSuggestionFalse: "han gør noget med lagkagen spiser han lagkage nej han",
    namingVerbIntro: "han gør noget med lagkagen han",
    namingFullSentencePrompt: "hvad laver gert",
    namingFullSentence: "han pynter lagkage",
    namingShortPrompt: "han pynter_",
    frequencyObject: 238
  },
  {
    image: "plukker svampe",
    sentence: "plukker svampe",
    sentenceFull: "rikke plukker svampe",
    sentenceOK: "plukker svampe_ok",
    subjectGender: Gender.Female,
    subjectName: "rikke",
    verb: "plukker",
    object: "svampe",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "sv_mpe",
    namingIntro: "hun plukker hun plukker",
    namingSuggestionFalse: "hun plukker plukker hun æbler nej hun plukker",
    namingVerbSuggestionFalse: "hun gør noget med svampe steger hun svampe nej hun",
    namingVerbIntro: "hun gør noget med svampe hun",
    namingFullSentencePrompt: "hvad laver rikke",
    namingFullSentence: "hun plukker svampe",
    namingShortPrompt: "hun plukker_",
    frequencyObject: 1018
  },
  {
    image: "plukker æbler",
    sentence: "plukker æbler",
    sentenceFull: "nikita plukker æbler",
    sentenceOK: "plukker æbler_ok",
    subjectGender: Gender.Female,
    subjectName: "nikita",
    verb: "plukker",
    object: "æbler",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "_bler",
    namingIntro: "hun plukker hun plukker",
    namingSuggestionFalse: "hun plukker plukker hun svampe nej hun plukker",
    namingVerbSuggestionFalse: "hun gør noget med æbler spiser hun æbler nej hun",
    namingVerbIntro: "hun gør noget med æbler hun",
    namingFullSentencePrompt: "hvad laver nikita",
    namingFullSentence: "hun plukker æbler",
    namingShortPrompt: "hun plukker_",
    frequencyObject: 1316
  },
  {
    image: "dækker bord",
    sentence: "dækker bord",
    sentenceFull: "line dækker bord",
    sentenceOK: "dækker bord_ok",
    subjectGender: Gender.Female,
    subjectName: "line",
    verb: "dækker",
    object: "bord",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_rd",
    namingIntro: "hun dækker hun dækker",
    namingSuggestionFalse: "hun dækker noget dækker hun øjnene nej hun dækker",
    namingVerbSuggestionFalse: "hun gør noget med bordet vasker hun bord nej hun",
    namingVerbIntro: "hun gør noget med bordet hun",
    namingFullSentencePrompt: "hvad laver line",
    namingFullSentence: "hun dækker bord",
    namingShortPrompt: "hun dækker_",
    frequencyObject: 10551
  },
  {
    image: "fejer trappe",
    sentence: "fejer trappe",
    sentenceFull: "poul fejer trappe",
    sentenceOK: "fejer trappe_ok",
    subjectGender: Gender.Male,
    subjectName: "poul",
    verb: "fejer",
    object: "trappe",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "tr_ppe",
    namingIntro: "han fejer han fejer",
    namingSuggestionFalse: "han fejer fejer han køkkengulvet nej han fejer",
    namingVerbSuggestionFalse: "han gør noget med trappen går han på trappen nej han",
    namingVerbIntro: "han gør noget med trappen han",
    namingFullSentencePrompt: "hvad laver poul",
    namingFullSentence: "han fejer trappe",
    namingShortPrompt: "han fejer_",
    frequencyObject: 2984
  },
  {
    image: "køber frugt",
    sentence: "køber frugt",
    sentenceFull: "maren køber frugt",
    sentenceOK: "køber frugt_ok",
    subjectGender: Gender.Female,
    subjectName: "maren",
    verb: "køber",
    object: "frugt",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "fr_gt",
    namingIntro: "hun køber hun køber",
    namingSuggestionFalse: "hun køber køber hun laks nej hun køber",
    namingVerbSuggestionFalse: "hun gør noget med frugt spiser hun frugt nej hun",
    namingVerbIntro: "hun gør noget med frugt hun",
    namingFullSentencePrompt: "hvad laver maren",
    namingFullSentence: "hun køber frugt",
    namingShortPrompt: "hun køber_",
    frequencyObject: 2428
  },
  {
    image: "køber slik",
    sentence: "køber slik",
    sentenceFull: "frederikke køber slik",
    sentenceOK: "køber slik_ok",
    subjectGender: Gender.Female,
    subjectName: "frederikke",
    verb: "køber",
    object: "slik",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "sl_k",
    namingIntro: "hun køber hun køber",
    namingSuggestionFalse: "hun køber køber hun æbler nej hun køber",
    namingVerbSuggestionFalse: "hun gør noget med slik sælger hun slik nej hun",
    namingVerbIntro: "hun gør noget med slik hun",
    namingFullSentencePrompt: "hvad laver frederikke",
    namingFullSentence: "hun køber slik",
    namingShortPrompt: "hun køber_",
    frequencyObject: 1848
  },
  {
    image: "maler maleri",
    sentence: "maler maleri",
    sentenceFull: "dario maler maleri",
    sentenceOK: "maler maleri_ok",
    subjectGender: Gender.Male,
    subjectName: "dario",
    verb: "maler",
    object: "maleri",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "m_leri",
    namingIntro: "han maler han maler",
    namingSuggestionFalse: "han maler maler han carport nej han maler",
    namingVerbSuggestionFalse: "han gør noget med et maleri køber han et maleri nej han",
    namingVerbIntro: "han gør noget med et maleri han",
    namingFullSentencePrompt: "hvad laver dario",
    namingFullSentence: "han maler maleri",
    namingShortPrompt: "han maler_",
    frequencyObject: 3276
  },
  {
    image: "børster tænder",
    sentence: "børster tænder",
    sentenceFull: "nikoline børster tænder",
    sentenceOK: "børster tænder_ok",
    subjectGender: Gender.Female,
    subjectName: "nikoline",
    verb: "børster",
    object: "tænder",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "t_nder",
    namingIntro: "hun børster hun børster",
    namingSuggestionFalse: "hun børster børster hun hår nej hun børster",
    namingVerbSuggestionFalse: "hun gør noget med tænder trækker hun tænder ud nej hun",
    namingVerbIntro: "hun gør noget med tænder hun",
    namingFullSentencePrompt: "hvad laver nikoline",
    namingFullSentence: "hun børster tænder",
    namingShortPrompt: "hun børster_",
    frequencyObject: 4992
  },
  {
    image: "bager pizza",
    sentence: "bager pizza",
    sentenceFull: "jim bager pizza",
    sentenceOK: "bager pizza_ok",
    subjectGender: Gender.Male,
    subjectName: "jim",
    verb: "bager",
    object: "pizza",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "p_zza",
    namingIntro: "han bager han bager",
    namingSuggestionFalse: "han bager noget bager han kage nej han bager",
    namingVerbSuggestionFalse: "han gør noget med pizza køber han pizza nej han",
    namingVerbIntro: "han gør noget med pizza han",
    namingFullSentencePrompt: "hvad laver jim",
    namingFullSentence: "han bager pizza",
    namingShortPrompt: "han bager_",
    frequencyObject: 482
  },
  {
    image: "ælter dej",
    sentence: "ælter dej",
    sentenceFull: "biver ælter dej",
    sentenceOK: "ælter dej_ok",
    subjectGender: Gender.Male,
    subjectName: "biver",
    verb: "ælter",
    object: "dej",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "d_j",
    namingIntro: "han ælter han ælter",
    namingSuggestionFalse: "han ælter ælter han smør nej han ælter",
    namingVerbSuggestionFalse: "han gør noget med dej køber han dej nej han",
    namingVerbIntro: "han gør noget med dej han",
    namingFullSentencePrompt: "hvad laver biver",
    namingFullSentence: "han ælter dej",
    namingShortPrompt: "han ælter_",
    frequencyObject: 1043
  },
  {
    image: "ser fodbold",
    sentence: "ser fodbold",
    sentenceFull: "alex ser fodbold",
    sentenceOK: "ser fodbold_ok",
    subjectGender: Gender.Male,
    subjectName: "alex",
    verb: "ser",
    object: "fodbold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "f_dbold",
    namingIntro: "han ser han ser",
    namingSuggestionFalse: "han ser noget ser han curling nej han ser",
    namingVerbSuggestionFalse: "han gør noget med fodbold spiller han fodbold nej han",
    namingVerbIntro: "han gør noget med fodbold han",
    namingFullSentencePrompt: "hvad laver alex",
    namingFullSentence: "han ser fodbold",
    namingShortPrompt: "han ser_",
    frequencyObject: 5648
  },
  {
    image: "skriver brev",
    sentence: "skriver brev",
    sentenceFull: "inge skriver brev",
    sentenceOK: "skriver brev_ok",
    subjectGender: Gender.Female,
    subjectName: "inge",
    verb: "skriver",
    object: "brev",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "br_v",
    namingIntro: "hun skriver hun skriver",
    namingSuggestionFalse: "hun skriver skriver hun emails nej hun skriver",
    namingVerbSuggestionFalse: "hun gør noget med brevet læser hun brevet nej hun",
    namingVerbIntro: "hun gør noget med brevet hun",
    namingFullSentencePrompt: "hvad laver inge",
    namingFullSentence: "hun skriver brev",
    namingShortPrompt: "hun skriver_",
    frequencyObject: 7937
  },
  {
    image: "spiser morgenmad",
    sentence: "spiser morgenmad",
    sentenceFull: "karina spiser morgenmad",
    sentenceOK: "spiser morgenmad_ok",
    subjectGender: Gender.Female,
    subjectName: "karina",
    verb: "spiser",
    object: "morgenmad",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "m_rgenmad",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun aftensmad nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med morgenmad laver hun morgenmad nej hun",
    namingVerbIntro: "hun gør noget med morgenmad hun",
    namingFullSentencePrompt: "hvad laver karina",
    namingFullSentence: "hun spiser morgenmad",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 1026
  },
  {
    image: "tænder stearinlys",
    sentence: "tænder stearinlys",
    sentenceFull: "jens tænder stearinlys",
    sentenceOK: "tænder stearinlys_ok",
    subjectGender: Gender.Male,
    subjectName: "jens",
    verb: "tænder",
    object: "stearinlys",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "stear_nlys",
    namingIntro: "han tænder han tænder",
    namingSuggestionFalse: "han tænder tænder han ovnen nej han tænder",
    namingVerbSuggestionFalse: "han gør noget med stearinlyset slukker han stearinlys nej han",
    namingVerbIntro: "han gør noget med stearinlys han",
    namingFullSentencePrompt: "hvad laver jens",
    namingFullSentence: "han tænder stearinlys",
    namingShortPrompt: "han tænder_",
    frequencyObject: 390
  },
  {
    image: "stryger skjorter",
    sentence: "stryger skjorter",
    sentenceFull: "ole stryger skjorter",
    sentenceOK: "stryger skjorter_ok",
    subjectGender: Gender.Male,
    subjectName: "ole",
    verb: "stryger",
    object: "skjorter",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "skj_rter",
    namingIntro: "han stryger han stryger",
    namingSuggestionFalse: "han stryger stryger han sokker nej han stryger",
    namingVerbSuggestionFalse: "han gør noget med skjorter vasker han skjorter nej han",
    namingVerbIntro: "han gør noget med skjorter han",
    namingFullSentencePrompt: "hvad laver ole",
    namingFullSentence: "han stryger skjorter",
    namingShortPrompt: "han stryger_",
    frequencyObject: 1125
  },
  {
    image: "stryger tøj",
    sentence: "stryger tøj",
    sentenceFull: "lene stryger tøj",
    sentenceOK: "stryger tøj_ok",
    subjectGender: Gender.Female,
    subjectName: "lene",
    verb: "stryger",
    object: "tøj",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "t_j",
    namingIntro: "hun stryger hun stryger",
    namingSuggestionFalse: "hun stryger stryger hun perleplader nej hun stryger",
    namingVerbSuggestionFalse: "hun gør noget med tøj vasker hun tøj nej hun",
    namingVerbIntro: "hun gør noget med tøj hun",
    namingFullSentencePrompt: "hvad laver lene",
    namingFullSentence: "hun stryger tøj",
    namingShortPrompt: "hun stryger_",
    frequencyObject: 7087
  },
  {
    image: "læser kort",
    sentence: "læser kort",
    sentenceFull: "adam læser kort",
    sentenceOK: "læser kort_ok",
    subjectGender: Gender.Male,
    subjectName: "adam",
    verb: "læser",
    object: "kort",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "k_rt",
    namingIntro: "han læser han læser",
    namingSuggestionFalse: "han læser læser han avis nej han læser",
    namingVerbSuggestionFalse: "han gør noget med et kort skriver han kort nej han",
    namingVerbIntro: "han gør noget med kort han",
    namingFullSentencePrompt: "hvad laver adam",
    namingFullSentence: "han læser kort",
    namingShortPrompt: "han læser_",
    frequencyObject: 23852
  },
  {
    image: "kører motorcykel",
    sentence: "kører motorcykel",
    sentenceFull: "jimmi kører motorcykel",
    sentenceOK: "kører motorcykel_ok",
    subjectGender: Gender.Male,
    subjectName: "jimmi",
    verb: "kører",
    object: "motorcykel",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "m_torcykel",
    namingIntro: "han kører han kører",
    namingSuggestionFalse: "han kører kører han cykel nej han kører",
    namingVerbSuggestionFalse: "han gør noget med motorcyklen sælger han motorcyklen nej han",
    namingVerbIntro: "han gør noget med motorcyklen han",
    namingFullSentencePrompt: "hvad laver jimmi",
    namingFullSentence: "han kører motorcykel",
    namingShortPrompt: "han kører_",
    frequencyObject: 745
  },
  {
    image: "får gaver",
    sentence: "får gaver",
    sentenceFull: "elisabeth får gaver",
    sentenceOK: "får gaver_ok",
    subjectGender: Gender.Female,
    subjectName: "elisabeth",
    verb: "får",
    object: "gaver",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "g_ver",
    namingIntro: "hun får hun får",
    namingSuggestionFalse: "hun får får hun aftensmad nej hun",
    namingVerbSuggestionFalse: "hun gør noget med gaver køber hun gaver nej hun",
    namingVerbIntro: "hun gør noget med gaver hun",
    namingFullSentencePrompt: "hvad laver elisabeth",
    namingFullSentence: "hun får gaver",
    namingShortPrompt: "hun får_",
    frequencyObject: 3043
  },
  {
    image: "finder vej",
    sentence: "finder vej",
    sentenceFull: "kenneth finder vej",
    sentenceOK: "finder vej_ok",
    subjectGender: Gender.Male,
    subjectName: "kenneth",
    verb: "finder",
    object: "vej",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "v_j",
    namingIntro: "han finder han finder",
    namingSuggestionFalse: "han finder finder han tøj nej han finder",
    namingVerbSuggestionFalse: "han gør noget med vej løber han sin vej nej han",
    namingVerbIntro: "han gør noget med vej han",
    namingFullSentencePrompt: "hvad laver kenneth",
    namingFullSentence: "han finder vej",
    namingShortPrompt: "han finder_",
    frequencyObject: 35441
  },
  {
    image: "filer negle",
    sentence: "filer negle",
    sentenceFull: "alma filer negle",
    sentenceOK: "filer negle_ok",
    subjectGender: Gender.Female,
    subjectName: "alma",
    verb: "filer",
    object: "negle",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "n_gle",
    namingIntro: "hun filer hun filer",
    namingSuggestionFalse: "hun filer filer hun fængselstremmer nej hun filer",
    namingVerbSuggestionFalse: "hun gør noget med negle klipper hun negle nej hun",
    namingVerbIntro: "hun gør noget med negle hun",
    namingFullSentencePrompt: "hvad laver alma",
    namingFullSentence: "hun filer negle",
    namingShortPrompt: "hun filer_",
    frequencyObject: 825
  },
  {

    image: "spiser brød",
    sentence: "spiser brød",
    sentenceFull: "søren spiser brød",
    sentenceOK: "spiser brød_ok",
    subjectGender: Gender.Male,
    subjectName: "søren",
    verb: "spiser",
    object: "brød",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "br_d",
    namingIntro: "han spiser han spiser",
    namingSuggestionFalse: "han spiser spiser han pizza nej han spiser",
    namingVerbSuggestionFalse: "han gør noget med brød smører han brød nej han",
    namingVerbIntro: "han gør noget med brød han",
    namingFullSentencePrompt: "hvad laver søren",
    namingFullSentence: "han spiser brød",
    namingShortPrompt: "han spiser_",
    frequencyObject: 13233
  },
  {
    image: "spiser vandmelon",
    sentence: "spiser vandmelon",
    sentenceFull: "liva spiser vandmelon",
    sentenceOK: "spiser vandmelon_ok",
    subjectGender: Gender.Female,
    subjectName: "liva",
    verb: "spiser",
    object: "vandmelon",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "v_ndmelon",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun æbler nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med vandmelon køber hun vandmelon nej hun",
    namingVerbIntro: "hun gør noget med vandmelon hun",
    namingFullSentencePrompt: "hvad laver liva",
    namingFullSentence: "hun spiser vandmelon",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 32
  },
  {
    image: "spiser banan",
    sentence: "spiser banan",
    sentenceFull: "tanja spiser banan",
    sentenceOK: "spiser banan_ok",
    subjectGender: Gender.Female,
    subjectName: "tanja",
    verb: "spiser",
    object: "banan",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "b_nan",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun æbler nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med banan skræller hun banan nej hun",
    namingVerbIntro: "hun gør noget med banan hun",
    namingFullSentencePrompt: "hvad laver tanja",
    namingFullSentence: "hun spiser banan",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 54
  },
  {
    image: "spiser østers",
    sentence: "spiser østers",
    sentenceFull: "sabine spiser østers",
    sentenceOK: "spiser østers_ok",
    subjectGender: Gender.Female,
    subjectName: "sabine",
    verb: "spiser",
    object: "østers",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "_sters",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun chokolade nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med østers fanger hun østers nej hun",
    namingVerbIntro: "hun gør noget med østers hun",
    namingFullSentencePrompt: "hvad laver sabine",
    namingFullSentence: "hun spiser østers",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 199
  },
  {
    image: "bærer poser",
    sentence: "bærer poser",
    sentenceFull: "lena bærer poser",
    sentenceOK: "bærer poser_ok",
    subjectGender: Gender.Female,
    subjectName: "lena",
    verb: "bærer",
    object: "poser",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "p_ser",
    namingIntro: "hun bærer hun bærer",
    namingSuggestionFalse: "hun bærer bærer hun kasser nej hun bærer",
    namingVerbSuggestionFalse: "hun gør noget med poser fylder hun poser nej hun",
    namingVerbIntro: "hun gør noget med poser hun",
    namingFullSentencePrompt: "hvad laver lena",
    namingFullSentence: "hun bærer poser",
    namingShortPrompt: "hun bærer_",
    frequencyObject: 1928
  },
  {
    image: "bærer kasser",
    sentence: "bærer kasser",
    sentenceFull: "lars bærer kasser",
    sentenceOK: "bærer kasser_ok",
    subjectGender: Gender.Male,
    subjectName: "lars",
    verb: "bærer",
    object: "kasser",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "k_sser",
    namingIntro: "han bærer han bærer",
    namingSuggestionFalse: "han bærer bærer han tasker nej han bærer",
    namingVerbSuggestionFalse: "han gør noget med kasser folder han kasser nej han",
    namingVerbIntro: "han gør noget med kasser han",
    namingFullSentencePrompt: "hvad laver lars",
    namingFullSentence: "han bærer kasser",
    namingShortPrompt: "han bærer_",
    frequencyObject: 4661
  },
  {
    image: "bærer kuffert",
    sentence: "bærer kuffert",
    sentenceFull: "arne bærer kuffert",
    sentenceOK: "bærer kuffert_ok",
    subjectGender: Gender.Male,
    subjectName: "arne",
    verb: "bærer",
    object: "kuffert",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "k_ffert",
    namingIntro: "han bærer han bærer",
    namingSuggestionFalse: "han bærer bærer han kasser nej han bærer",
    namingVerbSuggestionFalse: "han gør noget med kuffert pakker han kuffert nej han",
    namingVerbIntro: "han gør noget med kuffert han",
    namingFullSentencePrompt: "hvad laver arne",
    namingFullSentence: "han bærer kuffert",
    namingShortPrompt: "han bærer_",
    frequencyObject: 928
  },
  {
    image: "presser appelsin",
    sentence: "presser appelsin",
    sentenceFull: "mette presser appelsin",
    sentenceOK: "presser appelsin_ok",
    subjectGender: Gender.Female,
    subjectName: "mette",
    verb: "presser",
    object: "appelsin",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "appels_n",
    namingIntro: "hun presser hun presser",
    namingSuggestionFalse: "hun presser presser hun blomster nej hun presser",
    namingVerbSuggestionFalse: "hun gør noget med appelsin spiser hun appelsin nej hun",
    namingVerbIntro: "hun gør noget med appelsin hun",
    namingFullSentencePrompt: "hvad laver mette",
    namingFullSentence: "hun presser appelsin",
    namingShortPrompt: "hun presser_",
    frequencyObject: 497
  },
  {
    image: "fodrer kanin",
    sentence: "fodrer kanin",
    sentenceFull: "agnes fodrer kanin",
    sentenceOK: "fodrer kanin_ok",
    subjectGender: Gender.Female,
    subjectName: "agnes",
    verb: "fodrer",
    object: "kanin",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "kan_n",
    namingIntro: "hun fodrer hun fodrer",
    namingSuggestionFalse: "hun fodrer fodrer hun en kamel nej hun fodrer",
    namingVerbSuggestionFalse: "hun gør noget med kaninen slagter hun kaninen nej hun",
    namingVerbIntro: "hun gør noget med kanin hun",
    namingFullSentencePrompt: "hvad laver agnes",
    namingFullSentence: "hun fodrer kanin",
    namingShortPrompt: "hun fodrer_",
    frequencyObject: 619
  },
  {
    image: "smører brød",
    sentence: "smører brød",
    sentenceFull: "lea smører brød",
    sentenceOK: "smører brød_ok",
    subjectGender: Gender.Female,
    subjectName: "lea",
    verb: "smører",
    object: "brød",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "br_d",
    namingIntro: "hun smører hun smører",
    namingSuggestionFalse: "hun smører smører hun ski nej hun smører",
    namingVerbSuggestionFalse: "hun gør noget med brød spiser hun brød nej hun",
    namingVerbIntro: "hun gør noget med brød hun",
    namingFullSentencePrompt: "hvad laver lea",
    namingFullSentence: "hun smører brød",
    namingShortPrompt: "hun smører_",
    frequencyObject: 13233
  },
  {
    image: "steger bøf",
    sentence: "steger bøf",
    sentenceFull: "alfred steger bøf",
    sentenceOK: "steger bøf_ok",
    subjectGender: Gender.Male,
    subjectName: "alfred",
    verb: "steger",
    object: "bøf",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "b_f",
    namingIntro: "han steger han steger",
    namingSuggestionFalse: "han steger steger han squash nej han steger",
    namingVerbSuggestionFalse: "han gør noget med bøf griller han bøf nej han",
    namingVerbIntro: "han gør noget med bøf han",
    namingFullSentencePrompt: "hvad laver alfred",
    namingFullSentence: "han steger bøf",
    namingShortPrompt: "han steger_",
    frequencyObject: 498
  },
  {
    image: "dyrker sport",
    sentence: "dyrker sport",
    sentenceFull: "torben dyrker sport",
    sentenceOK: "dyrker sport_ok",
    subjectGender: Gender.Male,
    subjectName: "torben",
    verb: "dyrker",
    object: "sport",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "sp_rt",
    namingIntro: "han dyrker han dyrker",
    namingSuggestionFalse: "han dyrker dyrker han grøntsager nej han dyrker",
    namingVerbSuggestionFalse: "han gør noget med sport ser han sport nej han",
    namingVerbIntro: "han gør noget med sport han",
    namingFullSentencePrompt: "hvad laver torben",
    namingFullSentence: "han dyrker sport",
    namingShortPrompt: "han dyrker_",
    frequencyObject: 2833
  },
  {
    image: "blander drinks",
    sentence: "blander drinks",
    sentenceFull: "viktor blander drinks",
    sentenceOK: "blander drinks_ok",
    subjectGender: Gender.Male,
    subjectName: "viktor",
    verb: "blander",
    object: "drinks",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "dr_nks",
    namingIntro: "han blander han blander",
    namingSuggestionFalse: "han blander blander han kort nej han blander",
    namingVerbSuggestionFalse: "han gør noget med drinks drikker han drinks nej han",
    namingVerbIntro: "han gør noget med drinks han",
    namingFullSentencePrompt: "hvad laver viktor",
    namingFullSentence: "han blander drinks",
    namingShortPrompt: "han blander_",
    frequencyObject: 478
  },
  {
    image: "blander kort",
    sentence: "blander kort",
    sentenceFull: "flemming blander kort",
    sentenceOK: "blander kort_ok",
    subjectGender: Gender.Male,
    subjectName: "flemming",
    verb: "blander",
    object: "kort",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "k_rt",
    namingIntro: "han blander han blander",
    namingSuggestionFalse: "han blander blander han slik nej han blander",
    namingVerbSuggestionFalse: "han gør noget med kort køber han kort nej han",
    namingVerbIntro: "han gør noget med kort han",
    namingFullSentencePrompt: "hvad laver flemming",
    namingFullSentence: "han blander kort",
    namingShortPrompt: "han blander_",
    frequencyObject: 23852
  },
  {
    image: "bruger tandtråd",
    sentence: "bruger tandtråd",
    sentenceFull: "tine bruger tandtråd",
    sentenceOK: "bruger tandtråd_ok",
    subjectGender: Gender.Female,
    subjectName: "tine",
    verb: "bruger",
    object: "tandtråd",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "t_ndtråd",
    namingIntro: "hun bruger hun bruger",
    namingSuggestionFalse: "hun bruger bruger hun ble nej hun bruger",
    namingVerbSuggestionFalse: "hun gør noget med tandtråd køber hun tandtråd nej hun",
    namingVerbIntro: "hun gør noget med tandtråd hun",
    namingFullSentencePrompt: "hvad laver tine",
    namingFullSentence: "hun bruger tandtråd",
    namingShortPrompt: "hun bruger_",
    frequencyObject: 14
  },
  {
    image: "banker tæppe",
    sentence: "banker tæppe",
    sentenceFull: "else banker tæppe",
    sentenceOK: "banker tæppe_ok",
    subjectGender: Gender.Female,
    subjectName: "else",
    verb: "banker",
    object: "tæppe",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "t_ppe",
    namingIntro: "hun banker hun banker",
    namingSuggestionFalse: "hun banker noget banker hun manden nej hun banker",
    namingVerbSuggestionFalse: "hun gør noget med tæppet støvsuger hun tæppet nej hun",
    namingVerbIntro: "hun gør noget med tæppet hun",
    namingFullSentencePrompt: "hvad laver else",
    namingFullSentence: "hun banker tæppe",
    namingShortPrompt: "hun banker_",
    frequencyObject: 2196
  },
  {
    image: "binder krans",
    sentence: "binder krans",
    sentenceFull: "helle binder krans",
    sentenceOK: "binder krans_ok",
    subjectGender: Gender.Female,
    subjectName: "helle",
    verb: "binder",
    object: "krans",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "kr_ns",
    namingIntro: "hun binder hun binder",
    namingSuggestionFalse: "hun binder noget binder hun sløjfer nej hun binder",
    namingVerbSuggestionFalse: "hun gør noget med kransen køber hun krans nej hun",
    namingVerbIntro: "hun gør noget med kransen hun",
    namingFullSentencePrompt: "hvad laver helle",
    namingFullSentence: "hun binder krans",
    namingShortPrompt: "hun binder_",
    frequencyObject: 577
  },
  {
    image: "binder sløjfe",
    sentence: "binder sløjfe",
    sentenceFull: "karla binder sløjfe",
    sentenceOK: "binder sløjfe_ok",
    subjectGender: Gender.Female,
    subjectName: "karla",
    verb: "binder",
    object: "sløjfe",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "sl_jfe",
    namingIntro: "hun binder hun binder",
    namingSuggestionFalse: "hun binder noget binder hun adventskrans nej hun binder",
    namingVerbSuggestionFalse: "hun gør noget med sløjfen fjerner hun sløjfen nej hun",
    namingVerbIntro: "hun gør noget med sløjfen hun",
    namingFullSentencePrompt: "hvad laver karla",
    namingFullSentence: "hun binder sløjfe",
    namingShortPrompt: "hun binder_",
    frequencyObject: 468
  },
  {
    image: "danser mavedans",
    sentence: "danser mavedans",
    sentenceFull: "lulu danser mavedans",
    sentenceOK: "danser mavedans_ok",
    subjectGender: Gender.Female,
    subjectName: "lulu",
    verb: "danser",
    object: "mavedans",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "m_vedans",
    namingIntro: "hun danser hun danser",
    namingSuggestionFalse: "hun danser noget danser hun tango nej hun danser",
    namingVerbSuggestionFalse: "hun gør noget med mavedans ser hun mavedans nej hun",
    namingVerbIntro: "hun gør noget med mavedans hun",
    namingFullSentencePrompt: "hvad laver lulu",
    namingFullSentence: "hun danser mavedans",
    namingShortPrompt: "hun danser_",
    frequencyObject: 61
  },
  {
    image: "drikker bobler",
    sentence: "drikker bobler",
    sentenceFull: "lonni drikker bobler",
    sentenceOK: "drikker bobler_ok",
    subjectGender: Gender.Female,
    subjectName: "lonni",
    verb: "drikker",
    object: "bobler",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_bler",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun kaffe nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med bobler laver hun bobler nej hun",
    namingVerbIntro: "hun gør noget med bobler hun",
    namingFullSentencePrompt: "hvad laver lonni",
    namingFullSentence: "hun drikker bobler",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 645
  },
  {
    image: "drikker cola",
    sentence: "drikker cola",
    sentenceFull: "mads drikker cola",
    sentenceOK: "drikker cola_ok",
    subjectGender: Gender.Male,
    subjectName: "Mads",
    verb: "drikker",
    object: "cola",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "c_la",
    namingIntro: "han drikker han drikker",
    namingSuggestionFalse: "han drikker drikker han kaffe nej han drikker",
    namingVerbSuggestionFalse: "han gør noget med cola køber han cola nej han",
    namingVerbIntro: "han gør noget med cola han",
    namingFullSentencePrompt: "hvad laver mads",
    namingFullSentence: "han drikker cola",
    namingShortPrompt: "han drikker_",
    frequencyObject: 576
  },
  {
    image: "drikker kaffe",
    sentence: "drikker kaffe",
    sentenceFull: "hermann drikker kaffe",
    sentenceOK: "drikker kaffe_ok",
    subjectGender: Gender.Male,
    subjectName: "hermann",
    verb: "drikker",
    object: "kaffe",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "k_ffe",
    namingIntro: "han drikker han drikker",
    namingSuggestionFalse: "han drikker drikker han øl nej han drikker",
    namingVerbSuggestionFalse: "han gør noget med kaffe laver han kaffe nej han",
    namingVerbIntro: "han gør noget med kaffe han",
    namingFullSentencePrompt: "hvad laver hermann",
    namingFullSentence: "han drikker kaffe",
    namingShortPrompt: "han drikker_",
    frequencyObject: 576
  },
  {
    image: "drikker energidrik",
    sentence: "drikker energidrik",
    sentenceFull: "bjarne drikker energidrik",
    sentenceOK: "drikker energidrik_ok",
    subjectGender: Gender.Male,
    subjectName: "bjarne",
    verb: "drikker",
    object: "energidrik",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "energ_drik",
    namingIntro: "han drikker han drikker",
    namingSuggestionFalse: "han drikker drikker han øl nej han drikker",
    namingVerbSuggestionFalse: "han gør noget med energidrik køber han energidrik nej han",
    namingVerbIntro: "han gør noget med energidrik han",
    namingFullSentencePrompt: "hvad laver bjarne",
    namingFullSentence: "han drikker energidrik",
    namingShortPrompt: "han drikker_",
    frequencyObject: 10
  },
  {
    image: "drikker sodavand",
    sentence: "drikker sodavand",
    sentenceFull: "anders drikker sodavand",
    sentenceOK: "drikker sodavand_ok",
    subjectGender: Gender.Male,
    subjectName: "anders",
    verb: "drikker",
    object: "sodavand",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "s_davand",
    namingIntro: "han drikker han drikker",
    namingSuggestionFalse: "han drikker drikker han øl nej han drikker",
    namingVerbSuggestionFalse: "han gør noget med sodavand køber han sodavand nej han",
    namingVerbIntro: "han gør noget med sodavand han",
    namingFullSentencePrompt: "hvad laver anders",
    namingFullSentence: "han drikker sodavand",
    namingShortPrompt: "han drikker_",
    frequencyObject: 615
  },
  {

    image: "drikker rødvin",
    sentence: "drikker rødvin",
    sentenceFull: "leila drikker rødvin",
    sentenceOK: "drikker rødvin_ok",
    subjectGender: Gender.Female,
    subjectName: "leila",
    verb: "drikker",
    object: "rødvin",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "r_dvin",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun kaffe nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med rødvin skænker hun rødvin nej hun",
    namingVerbIntro: "hun gør noget med rødvin hun",
    namingFullSentencePrompt: "hvad laver leila",
    namingFullSentence: "hun drikker rødvin",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 949
  },
  {
    image: "drikker snaps",
    sentence: "drikker snaps",
    sentenceFull: "fie drikker snaps",
    sentenceOK: "drikker snaps_ok",
    subjectGender: Gender.Female,
    subjectName: "fie",
    verb: "drikker",
    object: "snaps",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "sn_ps",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun kaffe nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med snaps skænker hun snaps nej hun",
    namingVerbIntro: "hun gør noget med snaps hun",
    namingFullSentencePrompt: "hvad laver fie",
    namingFullSentence: "hun drikker snaps",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 573
  },
  {
    image: "graver hul",
    sentence: "graver hul",
    sentenceFull: "david graver hul",
    sentenceOK: "graver hul_ok",
    subjectGender: Gender.Male,
    subjectName: "david",
    verb: "graver",
    object: "hul",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "h_l",
    namingIntro: "han graver han graver",
    namingSuggestionFalse: "han graver graver han laks nej han graver",
    namingVerbSuggestionFalse: "han gør noget med hullet fylder han hullet nej han",
    namingVerbIntro: "han gør noget med hul han",
    namingFullSentencePrompt: "hvad laver david",
    namingFullSentence: "han graver hul",
    namingShortPrompt: "han graver_",
    frequencyObject: 7135
  },
  {
    image: "griber bold",
    sentence: "griber bold",
    sentenceFull: "hannah griber bold",
    sentenceOK: "griber bold_ok",
    subjectGender: Gender.Female,
    subjectName: "hannah",
    verb: "griber",
    object: "bold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_ld",
    namingIntro: "hun griber hun griber",
    namingSuggestionFalse: "hun griber griber hun en frisbee nej hun griber",
    namingVerbSuggestionFalse: "hun gør noget med bold pumper hun bold nej hun",
    namingVerbIntro: "hun gør noget med bold hun",
    namingFullSentencePrompt: "hvad laver hannah",
    namingFullSentence: "hun griber bold",
    namingShortPrompt: "hun griber_",
    frequencyObject: 3801
  },
  {
    image: "griller bøffer",
    sentence: "griller bøffer",
    sentenceFull: "hektor griller bøffer",
    sentenceOK: "griller bøffer_ok",
    subjectGender: Gender.Male,
    subjectName: "hektor",
    verb: "griller",
    object: "bøffer",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "b_ffer",
    namingIntro: "han griller han griller",
    namingSuggestionFalse: "han griller griller han grøntsager nej han griller",
    namingVerbSuggestionFalse: "han gør noget med bøffer former han bøffer nej han",
    namingVerbIntro: "han gør noget med bøffer han",
    namingFullSentencePrompt: "hvad laver hektor",
    namingFullSentence: "han griller bøffer",
    namingShortPrompt: "han griller_",
    frequencyObject: 498
  },
  {
    image: "hæver penge",
    sentence: "hæver penge",
    sentenceFull: "saga hæver penge",
    sentenceOK: "hæver penge_ok",
    subjectGender: Gender.Female,
    subjectName: "saga",
    verb: "hæver",
    object: "penge",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "p_nge",
    namingIntro: "hun hæver hun hæver",
    namingSuggestionFalse: "hun hæver hæver hun bordet nej hun hæver",
    namingVerbSuggestionFalse: "hun gør noget med penge tæller hun penge nej hun",
    namingVerbIntro: "hun gør noget med penge hun",
    namingFullSentencePrompt: "hvad laver saga",
    namingFullSentence: "hun hæver penge",
    namingShortPrompt: "hun hæver_",
    frequencyObject: 26236
  },
  {
    image: "kaster bold",
    sentence: "kaster bold",
    sentenceFull: "eskild kaster bold",
    sentenceOK: "kaster bold_ok",
    subjectGender: Gender.Male,
    subjectName: "eskild",
    verb: "kaster",
    object: "bold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_ld",
    namingIntro: "han kaster han kaster",
    namingSuggestionFalse: "han kaster kaster han pinde nej han kaster",
    namingVerbSuggestionFalse: "han gør noget med en bold køber han en bold nej han",
    namingVerbIntro: "han gør noget med en bold han",
    namingFullSentencePrompt: "hvad laver eskild",
    namingFullSentence: "han kaster bold",
    namingShortPrompt: "han kaster_",
    frequencyObject: 3801
  },
  {
    image: "klipper negle",
    sentence: "klipper negle",
    sentenceFull: "ebbe klipper negle",
    sentenceOK: "klipper negle_ok",
    subjectGender: Gender.Male,
    subjectName: "ebbe",
    verb: "klipper",
    object: "negle",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "n_gle",
    namingIntro: "han klipper han klipper",
    namingSuggestionFalse: "han klipper klipper han hår nej han klipper",
    namingVerbSuggestionFalse: "han gør noget med negle filer han negle nej han",
    namingVerbIntro: "han gør noget med negle han",
    namingFullSentencePrompt: "hvad laver ebbe",
    namingFullSentence: "han klipper negle",
    namingShortPrompt: "han klipper_",
    frequencyObject: 825
  },
  {
    image: "åbner julegave",
    sentence: "åbner julegave",
    sentenceFull: "vilma åbner julegave",
    sentenceOK: "åbner julegave_ok",
    subjectGender: Gender.Female,
    subjectName: "vilma",
    verb: "åbner",
    object: "julegave",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "j_legave",
    namingIntro: "hun åbner hun åbner",
    namingSuggestionFalse: "hun åbner åbner hun et brev nej hun åbner",
    namingVerbSuggestionFalse: "hun gør noget med en julegave giver hun en julegave nej hun",
    namingVerbIntro: "hun gør noget med en julegave hun",
    namingFullSentencePrompt: "hvad laver vilma",
    namingFullSentence: "hun åbner julegave",
    namingShortPrompt: "hun åbner_",
    frequencyObject: 489
  },
  {
    image: "åbner pakke",
    sentence: "åbner pakke",
    sentenceFull: "emily åbner pakke",
    sentenceOK: "åbner pakke_ok",
    subjectGender: Gender.Female,
    subjectName: "emily",
    verb: "åbner",
    object: "pakke",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "p_kke",
    namingIntro: "hun åbner hun åbner",
    namingSuggestionFalse: "hun åbner åbner hun havelågen nej hun åbner",
    namingVerbSuggestionFalse: "hun gør noget med en pakke køber hun pakken nej hun",
    namingVerbIntro: "hun gør noget med pakken hun",
    namingFullSentencePrompt: "hvad laver emily",
    namingFullSentence: "hun åbner pakke",
    namingShortPrompt: "hun åbner_",
    frequencyObject: 3939
  },
  {
    image: "åbner vandflaske",
    sentence: "åbner vandflaske",
    sentenceFull: "alberte åbner vandflaske",
    sentenceOK: "åbner vandflaske_ok",
    subjectGender: Gender.Female,
    subjectName: "alberte",
    verb: "åbner",
    object: "vandflaske",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "v_ndflaske",
    namingIntro: "hun åbner hun åbner",
    namingSuggestionFalse: "hun åbner åbner hun et brev nej hun åbner",
    namingVerbSuggestionFalse: "hun gør noget med vandflasken skyller hun vandflasken nej hun",
    namingVerbIntro: "hun gør noget med vandflasken hun",
    namingFullSentencePrompt: "hvad laver alberte",
    namingFullSentence: "hun åbner vandflaske",
    namingShortPrompt: "hun åbner_",
    frequencyObject: 15
  },
  {
    image: "vasker hænder",
    sentence: "vasker hænder",
    sentenceFull: "luna vasker hænder",
    sentenceOK: "vasker hænder_ok",
    subjectGender: Gender.Female,
    subjectName: "luna",
    verb: "vasker",
    object: "hænder",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "h_nder",
    namingIntro: "hun vasker hun vasker",
    namingSuggestionFalse: "hun vasker vasker hun fødder nej hun vasker",
    namingVerbSuggestionFalse: "hun gør noget med hænder tørrer hun hænder nej hun",
    namingVerbIntro: "hun gør noget med hænder hun",
    namingFullSentencePrompt: "hvad laver luna",
    namingFullSentence: "hun vasker hænder",
    namingShortPrompt: "hun vasker_",
    frequencyObject: 6221
  },
  {
    image: "vasker bil",
    sentence: "vasker bil",
    sentenceFull: "frode vasker bil",
    sentenceOK: "vasker bil_ok",
    subjectGender: Gender.Male,
    subjectName: "frode",
    verb: "vasker",
    object: "bil",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "b_l",
    namingIntro: "han vasker han vasker",
    namingSuggestionFalse: "han vasker vasker han hænder nej han vasker",
    namingVerbSuggestionFalse: "han gør noget med bilen kører han bil nej han",
    namingVerbIntro: "han gør noget med bilen han",
    namingFullSentencePrompt: "hvad laver frode",
    namingFullSentence: "han vasker bil",
    namingShortPrompt: "han vasker_",
    frequencyObject: 19257
  },
  {
    image: "tørrer hænder",
    sentence: "tørrer hænder",
    sentenceFull: "molly tørrer hænder",
    sentenceOK: "tørrer hænder_ok",
    subjectGender: Gender.Female,
    subjectName: "molly",
    verb: "tørrer",
    object: "hænder",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "h_nder",
    namingIntro: "hun tørrer hun tørrer",
    namingSuggestionFalse: "hun tørrer tørrer hun tøj nej hun tørrer",
    namingVerbSuggestionFalse: "hun gør noget med hænder vasker hun hænder nej hun",
    namingVerbIntro: "hun gør noget med hænder hun",
    namingFullSentencePrompt: "hvad laver molly",
    namingFullSentence: "hun tørrer hænder",
    namingShortPrompt: "hun tørrer_",
    frequencyObject: 6221
  },
  {
    image: "tæller penge",
    sentence: "tæller penge",
    sentenceFull: "ellie tæller penge",
    sentenceOK: "tæller penge_ok",
    subjectGender: Gender.Female,
    subjectName: "ellie",
    verb: "tæller",
    object: "penge",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "p_nge",
    namingIntro: "hun tæller hun tæller",
    namingSuggestionFalse: "hun tæller tæller hun får nej hun tæller",
    namingVerbSuggestionFalse: "hun gør noget med penge hæver hun penge nej hun",
    namingVerbIntro: "hun gør noget med penge hun",
    namingFullSentencePrompt: "hvad laver ellie",
    namingFullSentence: "hun tæller penge",
    namingShortPrompt: "hun tæller_",
    frequencyObject: 26236
  },
  {
    image: "tanker bil",
    sentence: "tanker bil",
    sentenceFull: "johannes tanker bil",
    sentenceOK: "tanker bil_ok",
    subjectGender: Gender.Male,
    subjectName: "johannes",
    verb: "tanker",
    object: "bil",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "b_l",
    namingIntro: "han tanker han tanker",
    namingSuggestionFalse: "han tanker tanker han motorcykel nej han tanker",
    namingVerbSuggestionFalse: "han gør noget med bil vasker han bil nej han",
    namingVerbIntro: "han gør noget med bil han",
    namingFullSentencePrompt: "hvad laver johannes",
    namingFullSentence: "han tanker bil",
    namingShortPrompt: "han tanker_",
    frequencyObject: 19257
  },
  {
    image: "tager brusebad",
    sentence: "tager brusebad",
    sentenceFull: "victoria tager brusebad",
    sentenceOK: "tager brusebad_ok",
    subjectGender: Gender.Female,
    subjectName: "victoria",
    verb: "tager",
    object: "brusebad",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "br_sebad",
    namingIntro: "hun tager hun tager",
    namingSuggestionFalse: "hun tager tager hun sol nej hun tager",
    namingVerbSuggestionFalse: "hun gør noget med brusebad udskyder hun brusebadet nej hun",
    namingVerbIntro: "hun gør noget med brusebad hun",
    namingFullSentencePrompt: "hvad laver victoria",
    namingFullSentence: "hun tager brusebad",
    namingShortPrompt: "hun tager_",
    frequencyObject: 179
  },
  {
    image: "tager billeder",
    sentence: "tager billeder",
    sentenceFull: "julius tager billeder",
    sentenceOK: "tager billeder_ok",
    subjectGender: Gender.Male,
    subjectName: "julius",
    verb: "tager",
    object: "billeder",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "b_lleder",
    namingIntro: "han tager han tager",
    namingSuggestionFalse: "han tager tager han piller nej han tager",
    namingVerbSuggestionFalse: "han gør noget med billeder køber han billeder nej han",
    namingVerbIntro: "han gør noget med billeder han",
    namingFullSentencePrompt: "hvad laver julius",
    namingFullSentence: "han tager billeder",
    namingShortPrompt: "han tager_",
    frequencyObject: 20996
  },
  {
    image: "tager bad",
    sentence: "tager bad",
    sentenceFull: "gry tager bad",
    sentenceOK: "tager bad_ok",
    subjectGender: Gender.Female,
    subjectName: "gry",
    verb: "tager",
    object: "bad",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "b_d",
    namingIntro: "hun tager hun tager",
    namingSuggestionFalse: "hun tager tager hun piller nej hun tager",
    namingVerbSuggestionFalse: "hun gør noget med bad forbereder hun et bad nej hun",
    namingVerbIntro: "hun gør noget med bad hun",
    namingFullSentencePrompt: "hvad laver gry",
    namingFullSentence: "hun tager bad",
    namingShortPrompt: "hun tager_",
    frequencyObject: 13679
  },
  {
    image: "tænder stearinlys",
    sentence: "tænder lys",
    sentenceFull: "kjeld tænder lys",
    sentenceOK: "tænder lys_ok",
    subjectGender: Gender.Male,
    subjectName: "kjeld",
    verb: "tænder",
    object: "lys",
    stressedVowel: Vowels.Y,
    vowelTargetPosition: "l_s",
    namingIntro: "han tænder han tænder",
    namingSuggestionFalse: "han tænder tænder han brændeovnen nej han tænder",
    namingVerbSuggestionFalse: "han gør noget med lys slukker han lyset nej han",
    namingVerbIntro: "han gør noget med lys han",
    namingFullSentencePrompt: "hvad laver kjeld",
    namingFullSentence: "han tænder lys",
    namingShortPrompt: "han tænder_",
    frequencyObject: 18686
  },
  {
    image: "klipper hår",
    sentence: "klipper hår",
    sentenceFull: "dorthe klipper hår",
    sentenceOK: "klipper hår_ok",
    subjectGender: Gender.Female,
    subjectName: "dorthe",
    verb: "klipper",
    object: "hår",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_r",
    namingIntro: "hun klipper hun klipper",
    namingSuggestionFalse: "hun klipper klipper hun negle nej hun klipper",
    namingVerbSuggestionFalse: "hun gør noget med hår farver hun hår nej hun",
    namingVerbIntro: "hun gør noget med hår hun",
    namingFullSentencePrompt: "hvad laver dorthe",
    namingFullSentence: "hun klipper hår",
    namingShortPrompt: "hun klipper_",
    frequencyObject: 6588
  },
  {
    image: "koger spaghetti",
    sentence: "koger spaghetti",
    sentenceFull: "ester koger spaghetti",
    sentenceOK: "koger spaghetti_ok",
    subjectGender: Gender.Female,
    subjectName: "ester",
    verb: "koger",
    object: "spaghetti",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "spagh_tti",
    namingIntro: "hun koger hun koger",
    namingSuggestionFalse: "hun koger noget koger hun æg nej hun koger",
    namingVerbSuggestionFalse: "hun gør noget med spaghetti køber hun spaghetti nej hun",
    namingVerbIntro: "hun gør noget med spaghetti hun",
    namingFullSentencePrompt: "hvad laver ester",
    namingFullSentence: "hun koger spaghetti",
    namingShortPrompt: "hun koger_",
    frequencyObject: 164
  },
  {
    image: "køber bil",
    sentence: "køber bil",
    sentenceFull: "elias køber bil",
    sentenceOK: "køber bil_ok",
    subjectGender: Gender.Male,
    subjectName: "elias",
    verb: "køber",
    object: "bil",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "b_l",
    namingIntro: "han køber han køber",
    namingSuggestionFalse: "han køber køber han dagligvarer nej han køber",
    namingVerbSuggestionFalse: "han gør noget med bilen tanker han bilen nej han",
    namingVerbIntro: "han gør noget med bilen han",
    namingFullSentencePrompt: "hvad laver elias",
    namingFullSentence: "han køber bil",
    namingShortPrompt: "han køber_",
    frequencyObject: 19257
  },
  {
    image: "køber billetter",
    sentence: "køber billetter",
    sentenceFull: "jasper køber billetter",
    sentenceOK: "køber billetter_ok",
    subjectGender: Gender.Male,
    subjectName: "jasper",
    verb: "køber",
    object: "billetter",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "bill_tter",
    namingIntro: "han køber han køber",
    namingSuggestionFalse: "han køber køber han dagligvarer nej han køber",
    namingVerbSuggestionFalse: "han gør noget med billetter sælger han billetter nej han",
    namingVerbIntro: "han gør noget med billetter han",
    namingFullSentencePrompt: "hvad laver jasper",
    namingFullSentence: "han køber billetter",
    namingShortPrompt: "han køber_",
    frequencyObject: 2851
  },
  {
    image: "maler hegn",
    sentence: "maler hegn",
    sentenceFull: "marius maler hegn",
    sentenceOK: "maler hegn_ok",
    subjectGender: Gender.Male,
    subjectName: "marius",
    verb: "maler",
    object: "hegn",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "h_gn",
    namingIntro: "han maler han maler",
    namingSuggestionFalse: "han maler maler han væggen nej han maler",
    namingVerbSuggestionFalse: "han gør noget med hegnet vælter han et hegn nej han",
    namingVerbIntro: "han gør noget med hegnet han",
    namingFullSentencePrompt: "hvad laver marius",
    namingFullSentence: "han maler hegn",
    namingShortPrompt: "han maler_",
    frequencyObject: 791
  },
  {
    image: "pakker bil",
    sentence: "pakker bil",
    sentenceFull: "sten pakker bil",
    sentenceOK: "pakker bil_ok",
    subjectGender: Gender.Male,
    subjectName: "sten",
    verb: "pakker",
    object: "bil",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "b_l",
    namingIntro: "han pakker han pakker",
    namingSuggestionFalse: "han pakker pakker han kuffert nej han pakker",
    namingVerbSuggestionFalse: "han gør noget med bilen sælger han bilen nej han",
    namingVerbIntro: "han gør noget med bilen han",
    namingFullSentencePrompt: "hvad laver sten",
    namingFullSentence: "han pakker bil",
    namingShortPrompt: "han pakker_",
    frequencyObject: 19257
  },
  {
    image: "planter blomster",
    sentence: "planter blomster",
    sentenceFull: "vibe planter blomster",
    sentenceOK: "planter blomster_ok",
    subjectGender: Gender.Female,
    subjectName: "vibe",
    verb: "planter",
    object: "blomster",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "bl_mster",
    namingIntro: "hun planter hun planter",
    namingSuggestionFalse: "hun planter planter hun juletræer nej hun planter",
    namingVerbSuggestionFalse: "hun gør noget med blomster plukker hun blomster nej hun",
    namingVerbIntro: "hun gør noget med blomster hun",
    namingFullSentencePrompt: "hvad laver vibe",
    namingFullSentence: "hun planter blomster",
    namingShortPrompt: "hun planter_",
    frequencyObject: 3677
  },
  {
    image: "plukker jordbær",
    sentence: "plukker jordbær",
    sentenceFull: "petra plukker jordbær",
    sentenceOK: "plukker jordbær_ok",
    subjectGender: Gender.Female,
    subjectName: "petra",
    verb: "plukker",
    object: "jordbær",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "j_rdbær",
    namingIntro: "hun plukker hun plukker",
    namingSuggestionFalse: "hun plukker plukker hun blomster nej hun plukker",
    namingVerbSuggestionFalse: "hun gør noget med jordbær spiser hun jordbær nej hun",
    namingVerbIntro: "hun gør noget med jordbær hun",
    namingFullSentencePrompt: "hvad laver petra",
    namingFullSentence: "hun plukker jordbær",
    namingShortPrompt: "hun plukker_",
    frequencyObject: 444
  },
  {
    image: "laver kaffe",
    sentence: "laver kaffe",
    sentenceFull: "rigmor laver kaffe",
    sentenceOK: "laver kaffe_ok",
    subjectGender: Gender.Female,
    subjectName: "rigmor",
    verb: "laver",
    object: "kaffe",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "k_ffe",
    namingIntro: "hun laver hun laver",
    namingSuggestionFalse: "hun laver noget laver hun aftensmad nej hun laver",
    namingVerbSuggestionFalse: "hun gør noget med kaffe køber hun kaffe nej hun",
    namingVerbIntro: "hun gør noget med kaffe hun",
    namingFullSentencePrompt: "hvad laver rigmor",
    namingFullSentence: "hun laver kaffe",
    namingShortPrompt: "hun laver_",
    frequencyObject: 4043
  },
  {
    image: "laver madpakke",
    sentence: "laver madpakke",
    sentenceFull: "kamma laver madpakke",
    sentenceOK: "laver madpakke_ok",
    subjectGender: Gender.Female,
    subjectName: "kamma",
    verb: "laver",
    object: "madpakke",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "m_dpakke",
    namingIntro: "hun laver hun laver",
    namingSuggestionFalse: "hun laver laver hun lektier nej hun laver",
    namingVerbSuggestionFalse: "hun gør noget med madpakke spiser hun madpakke nej hun",
    namingVerbIntro: "hun gør noget med madpakke hun",
    namingFullSentencePrompt: "hvad laver kamma",
    namingFullSentence: "hun laver madpakke",
    namingShortPrompt: "hun laver_",
    frequencyObject: 608
  },
  {
    image: "pudser vindue",
    sentence: "pudser vindue",
    sentenceFull: "gerda pudser vindue",
    sentenceOK: "pudser vindue_ok",
    subjectGender: Gender.Female,
    subjectName: "gerda",
    verb: "pudser",
    object: "vindue",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "v_ndue",
    namingIntro: "hun pudser hun pudser",
    namingSuggestionFalse: "hun pudser pudser hun sølvtøj nej hun pudser",
    namingVerbSuggestionFalse: "hun gør noget med vinduet sælger hun vinduet nej hun",
    namingVerbIntro: "hun gør noget med vinduet hun",
    namingFullSentencePrompt: "hvad laver gerda",
    namingFullSentence: "hun pudser vindue",
    namingShortPrompt: "hun pudser_",
    frequencyObject: 5756
  },
  {
    image: "binder snørebånd",
    sentence: "binder snørebånd",
    sentenceFull: "klaus binder snørebånd",
    sentenceOK: "binder snørebånd_ok",
    subjectGender: Gender.Male,
    subjectName: "klaus",
    verb: "binder",
    object: "snørebånd",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "sn_rebånd",
    namingIntro: "han binder han binder",
    namingSuggestionFalse: "han binder binder han slips nej han binder",
    namingVerbSuggestionFalse: "han gør noget med snørebånd køber han snørebånd nej han",
    namingVerbIntro: "han gør noget med snørebånd han",
    namingFullSentencePrompt: "hvad laver klaus",
    namingFullSentence: "han binder snørebånd",
    namingShortPrompt: "han binder_",
    frequencyObject: 94
  },
  {
    image: "binder snørebånd",
    sentence: "binder sko",
    sentenceFull: "mikkel binder sko",
    sentenceOK: "binder sko_ok",
    subjectGender: Gender.Male,
    subjectName: "mikkel",
    verb: "binder",
    object: "sko",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "sk_",
    namingIntro: "han binder han binder",
    namingSuggestionFalse: "han binder binder han slips nej han binder",
    namingVerbSuggestionFalse: "han gør noget med sko pudser han sko nej han",
    namingVerbIntro: "han gør noget med sko han",
    namingFullSentencePrompt: "hvad laver mikkel",
    namingFullSentence: "han binder sko",
    namingShortPrompt: "han binder_",
    frequencyObject: 2725
  },
  {
    image: "dyrker kartofler",
    sentence: "dyrker kartofler",
    sentenceFull: "solvej dyrker kartofler",
    sentenceOK: "dyrker kartofler_ok",
    subjectGender: Gender.Female,
    subjectName: "solvej",
    verb: "dyrker",
    object: "kartofler",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "kart_fler",
    namingIntro: "hun dyrker hun dyrker",
    namingSuggestionFalse: "hun dyrker dyrker hun sport nej hun dyrker",
    namingVerbSuggestionFalse: "hun gør noget med kartofler spiser hun kartofler nej hun",
    namingVerbIntro: "hun gør noget med kartofler hun",
    namingFullSentencePrompt: "hvad laver solvej",
    namingFullSentence: "hun dyrker kartofler",
    namingShortPrompt: "hun dyrker_",
    frequencyObject: 2712
  },
  {
    image: "klipper papir",
    sentence: "klipper papir",
    sentenceFull: "max klipper papir",
    sentenceOK: "klipper papir_ok",
    subjectGender: Gender.Male,
    subjectName: "max",
    verb: "klipper",
    object: "papir",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "pap_r",
    namingIntro: "han klipper han klipper",
    namingSuggestionFalse: "han klipper klipper han negle nej han klipper",
    namingVerbSuggestionFalse: "han gør noget med papir brænder han papiret nej han",
    namingVerbIntro: "han gør noget med papir han",
    namingFullSentencePrompt: "hvad laver max",
    namingFullSentence: "han klipper papir",
    namingShortPrompt: "han klipper_",
    frequencyObject: 5569
  },
  {
    image: "spiller trommer",
    sentence: "spiller trommer",
    sentenceFull: "william spiller trommer",
    sentenceOK: "spiller trommer_ok",
    subjectGender: Gender.Male,
    subjectName: "william",
    verb: "spiller",
    object: "trommer",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "tr_mmer",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han trompet nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med trommer køber han trommer nej han",
    namingVerbIntro: "han gør noget med trommer han",
    namingFullSentencePrompt: "hvad laver william",
    namingFullSentence: "han spiller trommer",
    namingShortPrompt: "han spiller_",
    frequencyObject: 754
  },
  {
    image: "vasker tøj",
    sentence: "vasker tøj",
    sentenceFull: "flora vasker tøj",
    sentenceOK: "vasker tøj_ok",
    subjectGender: Gender.Female,
    subjectName: "flora",
    verb: "vasker",
    object: "tøj",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "t_j",
    namingIntro: "hun vasker hun vasker",
    namingSuggestionFalse: "hun vasker vasker hun bil nej hun vasker",
    namingVerbSuggestionFalse: "hun gør noget med tøj prøver hun tøj nej hun",
    namingVerbIntro: "hun gør noget med tøj hun",
    namingFullSentencePrompt: "hvad laver flora",
    namingFullSentence: "hun vasker tøj",
    namingShortPrompt: "hun vasker_",
    frequencyObject: 7087
  },
  {
    image: "laver snobrød",
    sentence: "laver snobrød",
    sentenceFull: "lili laver snobrød",
    sentenceOK: "laver snobrød_ok",
    subjectGender: Gender.Female,
    subjectName: "lili",
    verb: "laver",
    object: "snobrød",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "sn_brød",
    namingIntro: "hun laver hun laver",
    namingSuggestionFalse: "hun laver noget laver hun kaffe nej hun laver",
    namingVerbSuggestionFalse: "hun gør noget med snobrød spiser hun snobrød nej hun",
    namingVerbIntro: "hun gør noget med snobrød hun",
    namingFullSentencePrompt: "hvad laver lili",
    namingFullSentence: "hun laver snobrød",
    namingShortPrompt: "hun laver_",
    frequencyObject: 37
  },
  {
    image: "plukker pærer",
    sentence: "plukker pærer",
    sentenceFull: "susi plukker pærer",
    sentenceOK: "plukker pærer_ok",
    subjectGender: Gender.Female,
    subjectName: "susi",
    verb: "plukker",
    object: "pærer",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "p_rer",
    namingIntro: "hun plukker hun plukker",
    namingSuggestionFalse: "hun plukker plukker hun svampe nej hun plukker",
    namingVerbSuggestionFalse: "hun gør noget med pærer spiser hun pærer nej hun",
    namingVerbIntro: "hun gør noget med pærer hun",
    namingFullSentencePrompt: "hvad laver susi",
    namingFullSentence: "hun plukker pærer",
    namingShortPrompt: "hun plukker_",
    frequencyObject: 734
  },
  {
    image: "prøver tøj",
    sentence: "prøver tøj",
    sentenceFull: "rosa prøver tøj",
    sentenceOK: "prøver tøj_ok",
    subjectGender: Gender.Female,
    subjectName: "rosa",
    verb: "prøver",
    object: "tøj",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "t_j",
    namingIntro: "hun prøver hun prøver",
    namingSuggestionFalse: "hun prøver prøver hun smykker nej hun prøver",
    namingVerbSuggestionFalse: "hun gør noget med tøj vasker hun tøj nej hun",
    namingVerbIntro: "hun gør noget med tøj hun",
    namingFullSentencePrompt: "hvad laver rosa",
    namingFullSentence: "hun prøver tøj",
    namingShortPrompt: "hun prøver_",
    frequencyObject: 7087
  },
  {
    image: "pynter juletræ",
    sentence: "pynter juletræ",
    sentenceFull: "tim pynter juletræ",
    sentenceOK: "pynter juletræ_ok",
    subjectGender: Gender.Male,
    subjectName: "tim",
    verb: "pynter",
    object: "juletræ",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "j_letræ",
    namingIntro: "han pynter han pynter",
    namingSuggestionFalse: "han pynter noget pynter han lagkage nej han pynter",
    namingVerbSuggestionFalse: "han gør noget med juletræ fælder han juletræ nej han",
    namingVerbIntro: "han gør noget med juletræ han",
    namingFullSentencePrompt: "hvad laver tim",
    namingFullSentence: "han pynter juletræ",
    namingShortPrompt: "han pynter_",
    frequencyObject: 787
  },
  {
    image: "rister brød",
    sentence: "rister brød",
    sentenceFull: "michael rister brød",
    sentenceOK: "rister brød_ok",
    subjectGender: Gender.Male,
    subjectName: "michael",
    verb: "rister",
    object: "brød",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "br_d",
    namingIntro: "han rister han rister",
    namingSuggestionFalse: "han rister rister han pølser nej han rister",
    namingVerbSuggestionFalse: "han gør noget med brød bager han brød nej han",
    namingVerbIntro: "han gør noget med brød han",
    namingFullSentencePrompt: "hvad laver michael",
    namingFullSentence: "han rister brød",
    namingShortPrompt: "han rister_",
    frequencyObject: 13233
  },
  {
    image: "ryger cigar",
    sentence: "ryger cigar",
    sentenceFull: "jan ryger cigar",
    sentenceOK: "ryger cigar_ok",
    subjectGender: Gender.Male,
    subjectName: "jan",
    verb: "ryger",
    object: "cigar",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "cig_r",
    namingIntro: "han ryger han ryger",
    namingSuggestionFalse: "han ryger ryger han pibe nej han ryger",
    namingVerbSuggestionFalse: "han gør noget med cigar køber han en cigar nej han",
    namingVerbIntro: "han gør noget med cigar han",
    namingFullSentencePrompt: "hvad laver jan",
    namingFullSentence: "han ryger cigar",
    namingShortPrompt: "han ryger_",
    frequencyObject: 619
  },
  {
    image: "ryger cigaret",
    sentence: "ryger cigaret",
    sentenceFull: "frede ryger cigaret",
    sentenceOK: "ryger cigaret_ok",
    subjectGender: Gender.Male,
    subjectName: "frede",
    verb: "ryger",
    object: "cigaret",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "cigar_t",
    namingIntro: "han ryger han ryger",
    namingSuggestionFalse: "han ryger ryger han pibe nej han ryger",
    namingVerbSuggestionFalse: "han gør noget med cigaretten køber han en cigaret nej han",
    namingVerbIntro: "han gør noget med cigaretten han",
    namingFullSentencePrompt: "hvad laver frede",
    namingFullSentence: "han ryger cigaret",
    namingShortPrompt: "han ryger_",
    frequencyObject: 1953
  },
  {
    image: "ryger pibe",
    sentence: "ryger pibe",
    sentenceFull: "bent ryger pibe",
    sentenceOK: "ryger pibe_ok",
    subjectGender: Gender.Male,
    subjectName: "bent",
    verb: "ryger",
    object: "pibe",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "p_be",
    namingIntro: "han ryger han ryger",
    namingSuggestionFalse: "han ryger ryger han cigar nej han ryger",
    namingVerbSuggestionFalse: "han gør noget med piben stopper han piben nej han",
    namingVerbIntro: "han gør noget med piben han",
    namingFullSentencePrompt: "hvad laver bent",
    namingFullSentence: "han ryger pibe",
    namingShortPrompt: "han ryger_",
    frequencyObject: 1087
  },
  {
    image: "bager boller",
    sentence: "bager boller",
    sentenceFull: "sofie bager boller",
    sentenceOK: "bager boller_ok",
    subjectGender: Gender.Female,
    subjectName: "sofie",
    verb: "bager",
    object: "boller",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_ller",
    namingIntro: "hun bager hun bager",
    namingSuggestionFalse: "hun bager bager hun kage nej hun bager",
    namingVerbSuggestionFalse: "hun gør noget med boller køber hun boller nej hun",
    namingVerbIntro: "hun gør noget med boller hun",
    namingFullSentencePrompt: "hvad laver sofie?",
    namingFullSentence: "hun bager boller",
    namingShortPrompt: "hun bager_",
    frequencyObject: 789
  },
  {
    image: "bager brød",
    sentence: "bager brød",
    sentenceFull: "mie bager brød",
    sentenceOK: "bager brød_ok",
    subjectGender: Gender.Female,
    subjectName: "mie",
    verb: "bager",
    object: "brød",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "br_d",
    namingIntro: "hun bager hun bager",
    namingSuggestionFalse: "hun bager bager hun kage nej hun bager",
    namingVerbSuggestionFalse: "hun gør noget med brødet køber hun brød nej hun",
    namingVerbIntro: "hun gør noget med brødet hun",
    namingFullSentencePrompt: "hvad laver mie",
    namingFullSentence: "hun bager brød",
    namingShortPrompt: "hun bager_",
    frequencyObject: 13233
  },
  {
    image: "binder slips",
    sentence: "binder slips",
    sentenceFull: "morten binder slips",
    sentenceOK: "binder slips_ok",
    subjectGender: Gender.Male,
    subjectName: "morten",
    verb: "binder",
    object: "slips",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "sl_ps",
    namingIntro: "han binder han binder",
    namingSuggestionFalse: "han binder binder han snørebånd nej han binder",
    namingVerbSuggestionFalse: "han gør noget med et slips prøver han slips nej han",
    namingVerbIntro: "han gør noget med et slips han",
    namingFullSentencePrompt: "hvad laver morten",
    namingFullSentence: "han binder slips",
    namingShortPrompt: "han binder_",
    frequencyObject: 1173
  },
  {
    image: "drikker vand",
    sentence: "drikker vand",
    sentenceFull: "tina drikker vand",
    sentenceOK: "drikker vand_ok",
    subjectGender: Gender.Female,
    subjectName: "tina",
    verb: "drikker",
    object: "vand",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "v_nd",
    namingIntro: "hun drikker hun drikker",
    namingSuggestionFalse: "hun drikker drikker hun kaffe nej hun drikker",
    namingVerbSuggestionFalse: "hun gør noget med vandet koger hun vand nej hun",
    namingVerbIntro: "hun gør noget med vand hun",
    namingFullSentencePrompt: "hvad laver tina",
    namingFullSentence: "hun drikker vand",
    namingShortPrompt: "hun drikker_",
    frequencyObject: 17505
  },
  {
    image: "fanger fisk",
    sentence: "fanger fisk",
    sentenceFull: "jeppe fanger fisk",
    sentenceOK: "fanger fisk_ok",
    subjectGender: Gender.Male,
    subjectName: "jeppe",
    verb: "fanger",
    object: "fisk",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "f_sk",
    namingIntro: "han fanger han fanger",
    namingSuggestionFalse: "han fanger fanger han mus nej han fanger",
    namingVerbSuggestionFalse: "han gør noget med fisk spiser han fisk nej han",
    namingVerbIntro: "han gør noget med fisk han",
    namingFullSentencePrompt: "hvad laver jeppe",
    namingFullSentence: "han fanger fisk",
    namingShortPrompt: "han fanger_",
    frequencyObject: 5826
  },
  {
    image: "fletter hår",
    sentence: "fletter hår",
    sentenceFull: "berit fletter hår",
    sentenceOK: "fletter hår_ok",
    subjectGender: Gender.Female,
    subjectName: "berit",
    verb: "fletter",
    object: "hår",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_r",
    namingIntro: "hun fletter hun fletter",
    namingSuggestionFalse: "hun fletter fletter hun julehjerter nej hun fletter",
    namingVerbSuggestionFalse: "hun gør noget med hår reder hun hår nej hun",
    namingVerbIntro: "hun gør noget med hår hun",
    namingFullSentencePrompt: "hvad laver berit",
    namingFullSentence: "hun fletter hår",
    namingShortPrompt: "hun fletter_",
    frequencyObject: 6588
  },
  {
    image: "fodrer due",
    sentence: "fodrer due",
    sentenceFull: "karen fodrer due",
    sentenceOK: "fodrer due_ok",
    subjectGender: Gender.Female,
    subjectName: "karen",
    verb: "fodrer",
    object: "due",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "d_e",
    namingIntro: "hun fodrer hun fodrer",
    namingSuggestionFalse: "hun fodrer fodrer hun svin nej hun fodrer",
    namingVerbSuggestionFalse: "hun gør noget med duen jagter hun duen nej hun",
    namingVerbIntro: "hun gør noget med duen hun",
    namingFullSentencePrompt: "hvad laver karen",
    namingFullSentence: "hun fodrer due",
    namingShortPrompt: "hun fodrer_",
    frequencyObject: 1168
  },
  {
    image: "griller pølser",
    sentence: "griller pølser",
    sentenceFull: "palle griller pølser",
    sentenceOK: "griller pølser_ok",
    subjectGender: Gender.Male,
    subjectName: "palle",
    verb: "griller",
    object: "pølser",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "p_lser",
    namingIntro: "han griller han griller",
    namingSuggestionFalse: "han griller griller han koteletter nej han griller",
    namingVerbSuggestionFalse: "han gør noget med pølser koger han pølser nej han",
    namingVerbIntro: "han gør noget med pølser han",
    namingFullSentencePrompt: "hvad laver palle",
    namingFullSentence: "han griller pølser",
    namingShortPrompt: "han griller_",
    frequencyObject: 937
  },
  {
    image: "går tur",
    sentence: "går tur",
    sentenceFull: "ole går tur",
    sentenceOK: "går tur_ok",
    subjectGender: Gender.Male,
    subjectName: "ole",
    verb: "går",
    object: "tur",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "t_r",
    namingIntro: "han går han går",
    namingSuggestionFalse: "han går går han stavgang nej han går",
    namingVerbSuggestionFalse: "han er i gang med en tur kører han tur nej han",
    namingVerbIntro: "han er i gang med en tur han",
    namingFullSentencePrompt: "hvad laver ole",
    namingFullSentence: "han går tur",
    namingShortPrompt: "han går_",
    frequencyObject: 9920
  },
  {
    image: "hugger brænde",
    sentence: "hugger brænde",
    sentenceFull: "martin hugger brænde",
    sentenceOK: "hugger brænde_ok",
    subjectGender: Gender.Male,
    subjectName: "martin",
    verb: "hugger",
    object: "brænde",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "br_nde",
    namingIntro: "han hugger han hugger",
    namingSuggestionFalse: "han hugger hugger han sukker nej han hugger",
    namingVerbSuggestionFalse: "han gør noget med brænde saver han brænde nej han",
    namingVerbIntro: "han gør noget med brænde han",
    namingFullSentencePrompt: "hvad laver martin",
    namingFullSentence: "han hugger brænde",
    namingShortPrompt: "han hugger_",
    frequencyObject: 4141
  },
  {
    image: "hører musik",
    sentence: "hører musik",
    sentenceFull: "magnus hører musik",
    sentenceOK: "hører musik_ok",
    subjectGender: Gender.Male,
    subjectName: "magnus",
    verb: "hører",
    object: "musik",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "mus_k",
    namingIntro: "han hører han hører",
    namingSuggestionFalse: "han hører hører han radioavis nej han hører",
    namingVerbSuggestionFalse: "han gør noget med musik komponerer han musik nej han",
    namingVerbIntro: "han gør noget med musik han",
    namingFullSentencePrompt: "hvad laver magnus",
    namingFullSentence: "han hører musik",
    namingShortPrompt: "han hører_",
    frequencyObject: 11893
  },
  {
    image: "klipper hæk",
    sentence: "klipper hæk",
    sentenceFull: "tim klipper hæk",
    sentenceOK: "klipper hæk_ok",
    subjectGender: Gender.Male,
    subjectName: "tim",
    verb: "klipper",
    object: "hæk",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "h_k",
    namingIntro: "han klipper han klipper",
    namingSuggestionFalse: "han klipper klipper han hår nej han klipper",
    namingVerbSuggestionFalse: "han gør noget med hækken planter han hæk nej han",
    namingVerbIntro: "han gør noget med hækken han",
    namingFullSentencePrompt: "hvad laver tim",
    namingFullSentence: "han klipper hæk",
    namingShortPrompt: "hun klipper_",
    frequencyObject: 570
  },
  {
    image: "køber tøj",
    sentence: "køber tøj",
    sentenceFull: "emilie køber tøj",
    sentenceOK: "køber tøj_ok",
    subjectGender: Gender.Female,
    subjectName: "emilie",
    verb: "køber",
    object: "tøj",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "t_j",
    namingIntro: "hun køber hun køber",
    namingSuggestionFalse: "hun køber køber hun mad nej hun køber",
    namingVerbSuggestionFalse: "hun gør noget med tøj vasker hun tøj nej hun",
    namingVerbIntro: "hun gør noget med tøj hun",
    namingFullSentencePrompt: "hvad laver emilie",
    namingFullSentence: "hun køber tøj",
    namingShortPrompt: "hun køber_",
    frequencyObject: 7087
  },
  {
    image: "laver pandekager",
    sentence: "laver pandekager",
    sentenceFull: "lise laver pandekager",
    sentenceOK: "laver pandekager_ok",
    subjectGender: Gender.Female,
    subjectName: "lise",
    verb: "laver",
    object: "pandekager",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "p_ndekager",
    namingIntro: "hun laver hun laver",
    namingSuggestionFalse: "hun laver laver hun pizza nej hun laver",
    namingVerbSuggestionFalse: "hun gør noget med pandekager spiser hun pandekager nej hun",
    namingVerbIntro: "hun gør noget med pandekager hun",
    namingFullSentencePrompt: "hvad laver lise",
    namingFullSentence: "hun laver pandekager",
    namingShortPrompt: "hun laver_",
    frequencyObject: 252
  },
  {
    image: "lufter hund",
    sentence: "lufter hund",
    sentenceFull: "kristine lufter hund",
    sentenceOK: "lufter hund_ok",
    subjectGender: Gender.Female,
    subjectName: "kristine",
    verb: "lufter",
    object: "hund",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "h_nd",
    namingIntro: "hun lufter hun lufter",
    namingSuggestionFalse: "hun lufter lufter hun en tanke nej hun lufter",
    namingVerbSuggestionFalse: "hun gør noget med hunden klapper hun hunden nej hun",
    namingVerbIntro: "hun gør noget med hunden hun",
    namingFullSentencePrompt: "hvad laver kristine",
    namingFullSentence: "hun lufter hund",
    namingShortPrompt: "hun lufter_",
    frequencyObject: 6675
  },
  {
    image: "læser avis",
    sentence: "læser avis",
    sentenceFull: "joakim læser avis",
    sentenceOK: "læser avis_ok",
    subjectGender: Gender.Male,
    subjectName: "joakim",
    verb: "læser",
    object: "avis",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "av_s",
    namingIntro: "han læser han læser",
    namingSuggestionFalse: "han læser læser han bøger nej han læser",
    namingVerbSuggestionFalse: "han gør noget med avisen køber han avis nej han",
    namingVerbIntro: "han gør noget med avisen han",
    namingFullSentencePrompt: "hvad laver joakim",
    namingFullSentence: "han læser avis",
    namingShortPrompt: "han læser_",
    frequencyObject: 9720
  },
  {
    image: "læser bog",
    sentence: "læser bog",
    sentenceFull: "amanda læser bog",
    sentenceOK: "læser bog_ok",
    subjectGender: Gender.Female,
    subjectName: "amanda",
    verb: "læser",
    object: "bog",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "b_g",
    namingIntro: "hun læser hun læser",
    namingSuggestionFalse: "hun læser læser hun avis nej hun læser",
    namingVerbSuggestionFalse: "hun gør noget med bogen skriver hun en bog nej hun",
    namingVerbIntro: "hun gør noget med bogen hun",
    namingFullSentencePrompt: "hvad laver amanda",
    namingFullSentence: "hun læser bog",
    namingShortPrompt: "hun læser_",
    frequencyObject: 21858
  },
  {
    image: "laver lektier",
    sentence: "laver lektier",
    sentenceFull: "asger laver lektier",
    sentenceOK: "laver lektier_ok",
    subjectGender: Gender.Male,
    subjectName: "asger",
    verb: "laver",
    object: "lektier",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "l_ktier",
    namingIntro: "han laver han laver",
    namingSuggestionFalse: "han laver laver han ballade nej han laver",
    namingVerbSuggestionFalse: "han gør noget med lektier giver han lektier for nej han",
    namingVerbIntro: "han gør noget med lektier han",
    namingFullSentencePrompt: "hvad laver asger",
    namingFullSentence: "han laver lektier",
    namingShortPrompt: "han laver_",
    frequencyObject: 713
  },
  {
    image: "pudser sko",
    sentence: "pudser sko",
    sentenceFull: "holger pudser sko",
    sentenceOK: "pudser sko_ok",
    subjectGender: Gender.Male,
    subjectName: "holger",
    verb: "pudser",
    object: "sko",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "sk_",
    namingIntro: "han pudser han pudser",
    namingSuggestionFalse: "han pudser pudser han vinduer nej han pudser",
    namingVerbSuggestionFalse: "han gør noget med sko køber han sko nej han",
    namingVerbIntro: "han gør noget med sko han",
    namingFullSentencePrompt: "hvad laver holger",
    namingFullSentence: "han pudser sko",
    namingShortPrompt: "han pudser_",
    frequencyObject: 2725
  },
  {
    image: "spiller fodbold",
    sentence: "spiller fodbold",
    sentenceFull: "viggo spiller fodbold",
    sentenceOK: "spiller fodbold_ok",
    subjectGender: Gender.Male,
    subjectName: "viggo",
    verb: "spiller",
    object: "fodbold",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "f_dbold",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han klaver nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med fodbold ser han fodbold nej han",
    namingVerbIntro: "han gør noget med fodbold han",
    namingFullSentencePrompt: "hvad laver viggo",
    namingFullSentence: "han spiller fodbold",
    namingShortPrompt: "han spiller_",
    frequencyObject: 5648
  },
  {
    image: "vasker gulv",
    sentence: "vasker gulv",
    sentenceFull: "jenny vasker gulv",
    sentenceOK: "vasker gulv_ok",
    subjectGender: Gender.Female,
    subjectName: "jenny",
    verb: "vasker",
    object: "gulv",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "g_lv",
    namingIntro: "hun vasker hun vasker",
    namingSuggestionFalse: "hun vasker vasker hun hår nej hun vasker",
    namingVerbSuggestionFalse: "hun gør noget med gulvet sliber hun gulv nej hun",
    namingVerbIntro: "hun gør noget med gulvet hun",
    namingFullSentencePrompt: "hvad laver jenny",
    namingFullSentence: "hun vasker gulv",
    namingShortPrompt: "hun vasker_",
    frequencyObject: 5447
  },
  {
    image: "spidser blyant",
    sentence: "spidser blyant",
    sentenceFull: "kasper spidser blyant",
    sentenceOK: "spidser blyant_ok",
    subjectGender: Gender.Male,
    subjectName: "kasper",
    verb: "spidser",
    object: "blyant",
    stressedVowel: Vowels.Y,
    vowelTargetPosition: "bl_ant",
    namingIntro: "han spidser han spidser",
    namingSuggestionFalse: "han spidser spidser han ører nej han spidser",
    namingVerbSuggestionFalse: "han gør noget med en blyant køber han en blyant nej han",
    namingVerbIntro: "han gør noget med blyanten han",
    namingFullSentencePrompt: "hvad laver kasper",
    namingFullSentence: "han spidser blyant",
    namingShortPrompt: "han spidser_",
    frequencyObject: 516
  },
  {
    image: "reder seng",
    sentence: "reder seng",
    sentenceFull: "julie reder seng",
    sentenceOK: "reder seng_ok",
    subjectGender: Gender.Female,
    subjectName: "julie",
    verb: "reder",
    object: "seng",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "s_ng",
    namingIntro: "hun reder hun reder",
    namingSuggestionFalse: "hun reder reder hun hår nej hun reder",
    namingVerbSuggestionFalse: "hun gør noget med en seng køber hun en seng nej hun",
    namingVerbIntro: "hun gør noget med en seng hun",
    namingFullSentencePrompt: "hvad laver julie",
    namingFullSentence: "hun reder seng",
    namingShortPrompt: "hun reder_",
    frequencyObject: 7011
  },
  {
    image: "samler flasker",
    sentence: "samler flasker",
    sentenceFull: "olga samler flasker",
    sentenceOK: "samler flasker_ok",
    subjectGender: Gender.Female,
    subjectName: "olga",
    verb: "samler",
    object: "flasker",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "fl_sker",
    namingIntro: "hun samler hun samler",
    namingSuggestionFalse: "hun samler samler hun frimærker nej hun samler",
    namingVerbSuggestionFalse: "hun gør noget med flasker fylder hun flasker nej hun",
    namingVerbIntro: "hun gør noget flasker hun",
    namingFullSentencePrompt: "hvad laver olga",
    namingFullSentence: "hun samler flasker",
    namingShortPrompt: "hun samler_",
    frequencyObject: 3854
  },
  {
    image: "slår græs",
    sentence: "slår græs",
    sentenceFull: "thomas slår græs",
    sentenceOK: "slår græs_ok",
    subjectGender: Gender.Male,
    subjectName: "thomas",
    verb: "slår",
    object: "græs",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "gr_s",
    namingIntro: "han slår han slår",
    namingSuggestionFalse: "han slår slår han konen nej han slår",
    namingVerbSuggestionFalse: "han gør noget med græs sår han græs nej han",
    namingVerbIntro: "han gør noget med græsset han",
    namingFullSentencePrompt: "hvad laver thomas",
    namingFullSentence: "han slår græs",
    namingShortPrompt: "han slår_",
    frequencyObject: 2562
  },
  {
    image: "spiller badminton",
    sentence: "spiller badminton",
    sentenceFull: "karoline spiller badminton",
    sentenceOK: "spiller badminton_ok",
    subjectGender: Gender.Female,
    subjectName: "karoline",
    verb: "spiller",
    object: "badminton",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "b_dminton",
    namingIntro: "hun spiller hun spiller",
    namingSuggestionFalse: "hun spiller spiller hun fodbold nej hun spiller",
    namingVerbSuggestionFalse: "hun gør noget med badminton ser hun badminton nej hun",
    namingVerbIntro: "hun gør noget med badminton hun",
    namingFullSentencePrompt: "hvad laver karoline",
    namingFullSentence: "hun spiller badminton",
    namingShortPrompt: "hun spiller_",
    frequencyObject: 536
  },
  {
    image: "spiller fløjte",
    sentence: "spiller fløjte",
    sentenceFull: "katrine spiller fløjte",
    sentenceOK: "spiller fløjte_ok",
    subjectGender: Gender.Female,
    subjectName: "katrine",
    verb: "spiller",
    object: "fløjte",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "fl_jte",
    namingIntro: "hun spiller hun spiller",
    namingSuggestionFalse: "hun spiller noget spiller hun klaver nej hun spiller",
    namingVerbSuggestionFalse: "hun gør noget med en fløjte renser hun fløjte nej hun",
    namingVerbIntro: "hun gør noget med fløjten hun",
    namingFullSentencePrompt: "hvad laver katrine",
    namingFullSentence: "hun spiller fløjte",
    namingShortPrompt: "hun spiller_",
    frequencyObject: 754
  },
  {
    image: "spiller golf",
    sentence: "spiller golf",
    sentenceFull: "jens spiller golf",
    sentenceOK: "spiller golf_ok",
    subjectGender: Gender.Male,
    subjectName: "jens",
    verb: "spiller",
    object: "golf",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "g_lf",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller noget spiller han ludo nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med golf ser han golf nej han",
    namingVerbIntro: "han gør noget med golf han",
    namingFullSentencePrompt: "hvad laver jens",
    namingFullSentence: "han spiller golf",
    namingShortPrompt: "han spiller_",
    frequencyObject: 982
  },
  {
    image: "spiller skak",
    sentence: "spiller skak",
    sentenceFull: "john spiller skak",
    sentenceOK: "spiller skak_ok",
    subjectGender: Gender.Male,
    subjectName: "john",
    verb: "spiller",
    object: "skak",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "sk_k",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han trompet nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med skak fortæller han om skak nej han",
    namingVerbIntro: "han gør noget med skak han",
    namingFullSentencePrompt: "hvad laver john",
    namingFullSentence: "han spiller skak",
    namingShortPrompt: "han spiller_",
    frequencyObject: 478
  },
  {
    image: "spiller teater",
    sentence: "spiller teater",
    sentenceFull: "tobias spiller teater",
    sentenceOK: "spiller teater_ok",
    subjectGender: Gender.Male,
    subjectName: "tobias",
    verb: "spiller",
    object: "teater",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "te_ter",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han violin nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med teater går han i teater nej han",
    namingVerbIntro: "han gør noget med teater han",
    namingFullSentencePrompt: "hvad laver tobias",
    namingFullSentence: "han spiller teater",
    namingShortPrompt: "han spiller_",
    frequencyObject: 8722
  },
  {
    image: "spiller trompet",
    sentence: "spiller trompet",
    sentenceFull: "charlie spiller trompet",
    sentenceOK: "spiller trompet_ok",
    subjectGender: Gender.Male,
    subjectName: "charlie",
    verb: "spiller",
    object: "trompet",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "tromp_t",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han badminton nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med trompet hører han trompet nej han",
    namingVerbIntro: "han gør noget med trompet han",
    namingFullSentencePrompt: "hvad laver charlie",
    namingFullSentence: "han spiller trompet",
    namingShortPrompt: "han spiller_",
    frequencyObject: 243
  },
  {
    image: "spiser pizza",
    sentence: "spiser pizza",
    sentenceFull: "emil spiser pizza",
    sentenceOK: "spiser pizza_ok",
    subjectGender: Gender.Male,
    subjectName: "emil",
    verb: "spiser",
    object: "pizza",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "p_zza",
    namingIntro: "han spiser han spiser",
    namingSuggestionFalse: "han spiser spiser han flæskesteg nej han spiser",
    namingVerbSuggestionFalse: "han gør noget med pizza køber han pizza nej han",
    namingVerbIntro: "han gør noget med pizza han",
    namingFullSentencePrompt: "hvad laver emil",
    namingFullSentence: "han spiser pizza",
    namingShortPrompt: "han spiser_",
    frequencyObject: 482
  },
  {
    image: "støvsuger gulv",
    sentence: "støvsuger gulv",
    sentenceFull: "eva støvsuger gulv",
    sentenceOK: "støvsuger gulv_ok",
    subjectGender: Gender.Female,
    subjectName: "eva",
    verb: "støvsuger",
    object: "gulv",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "g_lv",
    namingIntro: "hun støvsuger hun støvsuger",
    namingSuggestionFalse: "hun støvsuger støvsuger hun bilen nej hun støvsuger",
    namingVerbSuggestionFalse: "hun gør noget med gulvet vasker hun gulv nej hun",
    namingVerbIntro: "hun gør noget med gulvet hun",
    namingFullSentencePrompt: "hvad laver eva",
    namingFullSentence: "hun støvsuger gulv",
    namingShortPrompt: "hun støvsuger_",
    frequencyObject: 5447
  },
  {
    image: "vasker hår",
    sentence: "vasker hår",
    sentenceFull: "nanna vasker hår",
    sentenceOK: "vasker hår_ok",
    subjectGender: Gender.Female,
    subjectName: "nanna",
    verb: "vasker",
    object: "hår",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "h_r",
    namingIntro: "hun vasker hun vasker",
    namingSuggestionFalse: "hun vasker vasker hun tøj nej hun vasker",
    namingVerbSuggestionFalse: "hun gør noget med hår farver hun hår nej hun",
    namingVerbIntro: "hun gør noget med hår hun",
    namingFullSentencePrompt: "hvad laver nanna",
    namingFullSentence: "hun vasker hår",
    namingShortPrompt: "hun vasker_",
    frequencyObject: 6588
  },
  {
    image: "skriver beskeder",
    sentence: "skriver beskeder",
    sentenceFull: "helene skriver beskeder",
    sentenceOK: "skriver beskeder_ok",
    subjectGender: Gender.Female,
    subjectName: "helene",
    verb: "skriver",
    object: "beskeder",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "besk_der",
    namingIntro: "hun skriver hun skriver",
    namingSuggestionFalse: "hun skriver skriver hun digte nej hun skriver",
    namingVerbSuggestionFalse: "hun gør noget med beskeder sletter hun beskeder nej hun",
    namingVerbIntro: "hun gør noget med beskeder hun",
    namingFullSentencePrompt: "hvad laver helene",
    namingFullSentence: "hun skriver beskeder",
    namingShortPrompt: "hun skriver_",
    frequencyObject: 3801
  },
  {
    image: "fodrer køer",
    sentence: "fodrer køer",
    sentenceFull: "ib fodrer køer",
    sentenceOK: "fodrer køer_ok",
    subjectGender: Gender.Male,
    subjectName: "ib",
    verb: "fodrer",
    object: "køer",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "k_er",
    namingIntro: "han fodrer han fodrer",
    namingSuggestionFalse: "han fodrer fodrer han ænder nej han fodrer",
    namingVerbSuggestionFalse: "han gør noget med køerne slagter han køer nej han",
    namingVerbIntro: "han gør noget med køerne han",
    namingFullSentencePrompt: "hvad laver ib",
    namingFullSentence: "han fodrer køer",
    namingShortPrompt: "han fodrer_",
    frequencyObject: 3123
  },
  {
    image: "fanger sommerfugle",
    sentence: "fanger sommerfugle",
    sentenceFull: "gustav fanger sommerfugle",
    sentenceOK: "fanger sommerfugle_ok",
    subjectGender: Gender.Male,
    subjectName: "gustav",
    verb: "fanger",
    object: "sommerfugle",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "s_mmerfugle",
    namingIntro: "han fanger han fanger",
    namingSuggestionFalse: "han fanger fanger han fisk nej han fanger",
    namingVerbSuggestionFalse: "han gør noget med sommerfugle udstopper han sommerfugle nej han",
    namingVerbIntro: "han gør noget med sommerfugle han",
    namingFullSentencePrompt: "hvad laver gustav",
    namingFullSentence: "han fanger sommerfugle",
    namingShortPrompt: "han fanger_",
    frequencyObject: 492
  },
  {
    image: "spiser majs",
    sentence: "spiser majs",
    sentenceFull: "sidsel spiser majs",
    sentenceOK: "spiser majs_ok",
    subjectGender: Gender.Female,
    subjectName: "sidsel",
    verb: "spiser",
    object: "majs",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "m_js",
    namingIntro: "hun spiser hun spiser",
    namingSuggestionFalse: "hun spiser spiser hun flæskesteg nej hun spiser",
    namingVerbSuggestionFalse: "hun gør noget med majs koger hun majs nej hun",
    namingVerbIntro: "hun gør noget med majs hun",
    namingFullSentencePrompt: "hvad laver sidsel",
    namingFullSentence: "hun spiser majs",
    namingShortPrompt: "hun spiser_",
    frequencyObject: 7709
  },
  {
    image: "vinder pokal",
    sentence: "vinder pokal",
    sentenceFull: "brian vinder pokal",
    sentenceOK: "vinder pokal_ok",
    subjectGender: Gender.Male,
    subjectName: "brian",
    verb: "vinder",
    object: "pokal",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "pok_l",
    namingIntro: "han vinder han vinder",
    namingSuggestionFalse: "han vinder vinder han lotto nej han vinder",
    namingVerbSuggestionFalse: "han gør noget med pokalen pudser han pokalen nej han",
    namingVerbIntro: "han gør noget med pokal han",
    namingFullSentencePrompt: "hvad laver brian",
    namingFullSentence: "han vinder pokal",
    namingShortPrompt: "han vinder_",
    frequencyObject: 237
  },
  {
    image: "vogter får",
    sentence: "vogter får",
    sentenceFull: "rasmus vogter får",
    sentenceOK: "vogter får_ok",
    subjectGender: Gender.Male,
    subjectName: "rasmus",
    verb: "vogter",
    object: "får",
    stressedVowel: Vowels.Å,
    vowelTargetPosition: "f_r",
    namingIntro: "han vogter han vogter",
    namingSuggestionFalse: "han vogter vogter han fanger nej han vogter",
    namingVerbSuggestionFalse: "han gør noget med får klipper han får nej han",
    namingVerbIntro: "han gør noget med får han",
    namingFullSentencePrompt: "hvad laver rasmus",
    namingFullSentence: "han vogter får",
    namingShortPrompt: "han vogter_",
    frequencyObject: 252612
  },
  {
    image: "samler sten",
    sentence: "samler sten",
    sentenceFull: "ella samler sten",
    sentenceOK: "samler sten_ok",
    subjectGender: Gender.Female,
    subjectName: "ella",
    verb: "samler",
    object: "sten",
    stressedVowel: Vowels.E,
    vowelTargetPosition: "st_n",
    namingIntro: "hun samler hun samler",
    namingSuggestionFalse: "hun samler samler hun frimærker nej hun samler",
    namingVerbSuggestionFalse: "hun gør noget med sten kaster hun med sten nej hun",
    namingVerbIntro: "hun gør noget med sten hun",
    namingFullSentencePrompt: "hvad laver ella",
    namingFullSentence: "hun samler sten",
    namingShortPrompt: "hun samler_",
    frequencyObject: 5194
  },
  {
    image: "passer barnebarn",
    sentence: "passer barnebarn",
    sentenceFull: "tove passer barnebarn",
    sentenceOK: "passer barnebarn_ok",
    subjectGender: Gender.Female,
    subjectName: "tove",
    verb: "passer",
    object: "barnebarn",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "b_rnebarn",
    namingIntro: "hun passer hun passer",
    namingSuggestionFalse: "hun passer passer hun hund nej hun passer",
    namingVerbSuggestionFalse: "hun gør noget med barnebarnet ringer hun til barnebarnet nej hun",
    namingVerbIntro: "hun gør noget med barnebarnet hun",
    namingFullSentencePrompt: "hvad laver tove",
    namingFullSentence: "hun passer barnebarn",
    namingShortPrompt: "hun passer_",
    frequencyObject: 511
  },
  {
    image: "koger suppe",
    sentence: "koger suppe",
    sentenceFull: "susanne koger suppe",
    sentenceOK: "koger suppe_ok",
    subjectGender: Gender.Female,
    subjectName: "susanne",
    verb: "koger",
    object: "suppe",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "s_ppe",
    namingIntro: "hun koger hun koger",
    namingSuggestionFalse: "hun koger koger hun æg nej hun koger",
    namingVerbSuggestionFalse: "hun gør noget med suppen spiser hun suppe nej hun",
    namingVerbIntro: "hun gør noget med suppen hun",
    namingFullSentencePrompt: "hvad laver susanne",
    namingFullSentence: "hun koger suppe",
    namingShortPrompt: "hun koger_",
    frequencyObject: 1495
  },
  {
    image: "griller majs",
    sentence: "griller majs",
    sentenceFull: "jesper griller majs",
    sentenceOK: "griller majs_ok",
    subjectGender: Gender.Male,
    subjectName: "jesper",
    verb: "griller",
    object: "majs",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "m_js",
    namingIntro: "han griller han griller",
    namingSuggestionFalse: "han griller griller han koteletter nej han griller",
    namingVerbSuggestionFalse: "han gør noget med majs koger han majs nej han",
    namingVerbIntro: "han gør noget med majs han",
    namingFullSentencePrompt: "hvad laver jesper",
    namingFullSentence: "han griller majs",
    namingShortPrompt: "han griller_",
    frequencyObject: 7709
  },
  {
    image: "sorterer skrald",
    sentence: "sorterer skrald",
    sentenceFull: "jane sorterer skrald",
    sentenceOK: "sorterer skrald_ok",
    subjectGender: Gender.Female,
    subjectName: "jane",
    verb: "sorterer",
    object: "skrald",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "skr_ld",
    namingIntro: "hun sorterer hun sorterer",
    namingSuggestionFalse: "hun sorterer sorterer hun vasketøj nej hun sorterer",
    namingVerbSuggestionFalse: "hun gør noget med skrald smider hun skrald nej hun",
    namingVerbIntro: "hun gør noget med skrald hun",
    namingFullSentencePrompt: "hvad laver jane",
    namingFullSentence: "hun sorterer skrald",
    namingShortPrompt: "hun sorterer_",
    frequencyObject: 176
  },
  {
    image: "skifter pære",
    sentence: "skifter pære",
    sentenceFull: "karl skifter pære",
    sentenceOK: "skifter pære_ok",
    subjectGender: Gender.Male,
    subjectName: "karl",
    verb: "skifter",
    object: "pære",
    stressedVowel: Vowels.Æ,
    vowelTargetPosition: "p_re",
    namingIntro: "han skifter han skifter",
    namingSuggestionFalse: "han skifter skifter han tøj nej han skifter",
    namingVerbSuggestionFalse: "han gør noget med en pære spiser han en pære nej han",
    namingVerbIntro: "han gør noget med en pære han",
    namingFullSentencePrompt: "hvad laver karl",
    namingFullSentence: "han skifter pære",
    namingShortPrompt: "han skifter_",
    frequencyObject: 734
  },
  {
    image: "skriver mails",
    sentence: "skriver mails",
    sentenceFull: "clara skriver mails",
    sentenceOK: "skriver mails_ok",
    subjectGender: Gender.Female,
    subjectName: "clara",
    verb: "skriver",
    object: "mails",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "m_ils",
    namingIntro: "hun skriver hun skriver",
    namingSuggestionFalse: "hun skriver skriver hun digte nej hun skriver",
    namingVerbSuggestionFalse: "hun gør noget med mails sletter hun mails nej hun",
    namingVerbIntro: "hun gør noget med mails hun",
    namingFullSentencePrompt: "hvad laver clara",
    namingFullSentence: "hun skriver mails",
    namingShortPrompt: "hun skriver_",
    frequencyObject: 404
  },
  {
    image: "skræller banan",
    sentence: "skræller banan",
    sentenceFull: "emma skræller banan",
    sentenceOK: "skræller banan_ok",
    subjectGender: Gender.Female,
    subjectName: "emma",
    verb: "skræller",
    object: "banan",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "ban_n",
    namingIntro: "hun skræller hun skræller",
    namingSuggestionFalse: "hun skræller skræller hun kartofler nej hun skræller",
    namingVerbSuggestionFalse: "hun gør noget med en banan spiser hun en banan nej hun",
    namingVerbIntro: "hun gør noget med en banan hun",
    namingFullSentencePrompt: "hvad laver emma",
    namingFullSentence: "hun skræller banan",
    namingShortPrompt: "hun skræller_",
    frequencyObject: 534
  },
  {
    image: "skræller kartofler",
    sentence: "skræller kartofler",
    sentenceFull: "aksel skræller kartofler",
    sentenceOK: "skræller kartofler_ok",
    subjectGender: Gender.Male,
    subjectName: "aksel",
    verb: "skræller",
    object: "kartofler",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "kart_fler",
    namingIntro: "han skræller han skræller",
    namingSuggestionFalse: "han skræller skræller han gulerødder nej han skræller",
    namingVerbSuggestionFalse: "han gør noget med kartofler spiser han kartofler nej han",
    namingVerbIntro: "han gør noget med kartofler han",
    namingFullSentencePrompt: "hvad laver aksel",
    namingFullSentence: "han skræller kartofler",
    namingShortPrompt: "han skræller_",
    frequencyObject: 2712
  },
  {
    image: "skubber bil",
    sentence: "skubber bil",
    sentenceFull: "noah skubber bil",
    sentenceOK: "skubber bil_ok",
    subjectGender: Gender.Male,
    subjectName: "noah",
    verb: "skubber",
    object: "bil",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "b_l",
    namingIntro: "han skubber han skubber",
    namingSuggestionFalse: "han skubber skubber han til sin ven nej han skubber",
    namingVerbSuggestionFalse: "han gør noget med bilen tanker han bilen nej han",
    namingVerbIntro: "han gør noget med bilen han",
    namingFullSentencePrompt: "hvad laver noah",
    namingFullSentence: "han skubber bil",
    namingShortPrompt: "han skubber_",
    frequencyObject: 19257
  },
  {
    image: "skænker vin",
    sentence: "skænker vin",
    sentenceFull: "simon skænker vin",
    sentenceOK: "skænker vin_ok",
    subjectGender: Gender.Male,
    subjectName: "simon",
    verb: "skænker",
    object: "vin",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "v_n",
    namingIntro: "han skænker han skænker",
    namingSuggestionFalse: "han skænker skænker han kaffe nej han skænker",
    namingVerbSuggestionFalse: "han gør noget med vin drikker han vin nej han",
    namingVerbIntro: "han gør noget med vin han",
    namingFullSentencePrompt: "hvad laver simon",
    namingFullSentence: "han skænker vin",
    namingShortPrompt: "han skænker_",
    frequencyObject: 5165
  },
  {
    image: "skærer brød",
    sentence: "skærer brød",
    sentenceFull: "mathias skærer brød",
    sentenceOK: "skærer brød_ok",
    subjectGender: Gender.Male,
    subjectName: "mathias",
    verb: "skærer",
    object: "brød",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "br_d",
    namingIntro: "han skærer han skærer",
    namingSuggestionFalse: "han skærer skærer han grøntsager nej han skærer",
    namingVerbSuggestionFalse: "han gør noget med brød bager han brød nej han",
    namingVerbIntro: "han gør noget med brød han",
    namingFullSentencePrompt: "hvad laver mathias",
    namingFullSentence: "han skærer brød",
    namingShortPrompt: "han skærer_",
    frequencyObject: 13233
  },
  {
    image: "skærer løg",
    sentence: "skærer løg",
    sentenceFull: "valdemar skærer løg",
    sentenceOK: "skærer løg_ok",
    subjectGender: Gender.Male,
    subjectName: "valdemar",
    verb: "skærer",
    object: "løg",
    stressedVowel: Vowels.Ø,
    vowelTargetPosition: "l_g",
    namingIntro: "han skærer han skærer",
    namingSuggestionFalse: "han skærer skærer han brød nej han skærer",
    namingVerbSuggestionFalse: "han gør noget med løg spiser han løg nej han",
    namingVerbIntro: "han gør noget med løg han",
    namingFullSentencePrompt: "hvad laver valdemar",
    namingFullSentence: "han skærer løg",
    namingShortPrompt: "han skærer_",
    frequencyObject: 1560
  },
  {
    image: "sliber kniv",
    sentence: "sliber kniv",
    sentenceFull: "dan sliber kniv",
    sentenceOK: "sliber kniv_ok",
    subjectGender: Gender.Male,
    subjectName: "dan",
    verb: "sliber",
    object: "kniv",
    stressedVowel: Vowels.I,
    vowelTargetPosition: "kn_v",
    namingIntro: "han sliber han sliber",
    namingSuggestionFalse: "han sliber sliber han sakse nej han sliber",
    namingVerbSuggestionFalse: "han gør noget med kniven skærer han med kniven nej han",
    namingVerbIntro: "han gør noget med kniv han",
    namingFullSentencePrompt: "hvad laver dan",
    namingFullSentence: "han sliber kniv",
    namingShortPrompt: "han sliber_",
    frequencyObject: 2189
  },
  {
    image: "spiller bas",
    sentence: "spiller bas",
    sentenceFull: "oskar spiller bas",
    sentenceOK: "spiller bas_ok",
    subjectGender: Gender.Male,
    subjectName: "oskar",
    verb: "spiller",
    object: "bas",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "b_s",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han fodbold nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med bas køber han bas nej han",
    namingVerbIntro: "han gør noget med bas han",
    namingFullSentencePrompt: "hvad laver oskar",
    namingFullSentence: "han spiller bas",
    namingShortPrompt: "han spiller_",
    frequencyObject: 642
  },
  {
    image: "spiller dart",
    sentence: "spiller dart",
    sentenceFull: "august spiller dart",
    sentenceOK: "spiller dart_ok",
    subjectGender: Gender.Male,
    subjectName: "august",
    verb: "spiller",
    object: "dart",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "d_rt",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han fodbold nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med dart ser han dart nej han",
    namingVerbIntro: "han gør noget med dart han",
    namingFullSentencePrompt: "hvad laver august",
    namingFullSentence: "han spiller dart",
    namingShortPrompt: "han spiller_",
    frequencyObject: 37
  },
  {
    image: "spiller computer",
    sentence: "spiller computer",
    sentenceFull: "malthe spiller computer",
    sentenceOK: "spiller computer_ok",
    subjectGender: Gender.Male,
    subjectName: "malthe",
    verb: "spiller",
    object: "computer",
    stressedVowel: Vowels.U,
    vowelTargetPosition: "comp_ter",
    namingIntro: "han spiller han spiller",
    namingSuggestionFalse: "han spiller spiller han fodbold nej han spiller",
    namingVerbSuggestionFalse: "han gør noget med computer køber han en computer nej han",
    namingVerbIntro: "han gør noget med computer han",
    namingFullSentencePrompt: "hvad laver malthe",
    namingFullSentence: "han spiller computer",
    namingShortPrompt: "han spiller_",
    frequencyObject: 5189
  },
  {
    image: "spiller harpe",
    sentence: "spiller harpe",
    sentenceFull: "freja spiller harpe",
    sentenceOK: "spiller harpe_ok",
    subjectGender: Gender.Female,
    subjectName: "freja",
    verb: "spiller",
    object: "harpe",
    stressedVowel: Vowels.A,
    vowelTargetPosition: "h_rpe",
    namingIntro: "hun spiller hun spiller",
    namingSuggestionFalse: "hun spiller spiller hun kort nej hun spiller",
    namingVerbSuggestionFalse: "hun gør noget med harpe køber hun en harpe nej hun",
    namingVerbIntro: "hun gør noget med harpe hun",
    namingFullSentencePrompt: "hvad laver freja",
    namingFullSentence: "hun spiller harpe",
    namingShortPrompt: "hun spiller_",
    frequencyObject: 116
  },
  {
    image: "vander blomster",
    sentence: "vander blomster",
    sentenceFull: "arthur vander blomster",
    sentenceOK: "vander blomster_ok",
    subjectGender: Gender.Male,
    subjectName: "arthur",
    verb: "vander",
    object: "blomster",
    stressedVowel: Vowels.O,
    vowelTargetPosition: "bl_mster",
    namingIntro: "han vander han vander",
    namingSuggestionFalse: "han vander vander han græsplæne nej han vander",
    namingVerbSuggestionFalse: "han gør noget med blomster plukker han blomster nej han",
    namingVerbIntro: "han gør noget med blomster han",
    namingFullSentencePrompt: "hvad laver arthur",
    namingFullSentence: "han vander blomster",
    namingShortPrompt: "han vander_",
    frequencyObject: 3677
  },
];
