<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { IconButtonType, ModalCss, Level } from "../enums";
  import { Icons } from "../utilities/icons";

  import { editPWALevel } from "../stores/edit-pwa-settings-store";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();

  // FUNCTIONS ---------------------------------------------

  const changeLevel = () => {
    dispatch("changeLevelModalOK");
  };
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.XLarge}>
  <form on:submit|preventDefault={changeLevel}>
    <div class="headline">
      <h1>Skift niveau manuelt</h1>
    </div>
    <div class="content">
      <label>
        <input
          type="radio"
          bind:group={$editPWALevel}
          name="level"
          value={Level.First}
        />
        <strong>Niveau 1.</strong> <br />
        Højfrekvente, korte objekter.
      </label>
      <label>
        <input
          type="radio"
          bind:group={$editPWALevel}
          name="level"
          value={Level.Second}
        />
        <strong>Niveau 2.</strong> <br />
        Lav- og højfrekvente, korte og lange objekter.
      </label>
      <label>
        <input
          type="radio"
          bind:group={$editPWALevel}
          name="level"
          value={Level.SecondVerb}
        />
        <strong>Niveau 3.</strong> <br />
        Lav- og højfrekvente, korte og lange objekter. <br />
        <strong>Nyt:</strong> Inddragelse af verber i dialogen.
      </label>
      <label>
        <input
          type="radio"
          bind:group={$editPWALevel}
          name="level"
          value={Level.SecondSubject}
        />
        <strong>Niveau 4.</strong> <br />
        Lav- og højfrekvente, korte og lange objekter. <br />
        Inddragelse af verber i dialogen. <br />
        <strong>Nyt:</strong> Inddragelse af subjekter i dialogen.
      </label>
    </div>
    <div class="bottom-box">
      <span class="ok-button">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          OK
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  label {
    padding-bottom: 1vmin;
  }
  form {
    height: 100%;
    padding: 2vmin;
  }
  h1 {
    font-size: 4vmin;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
    line-height: 1.2em;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
  .ok-button {
    margin-left: auto;
  }
</style>
