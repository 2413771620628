<script lang="ts">
  import { icons } from "../utilities/icons";
  import Modal from "../components/Modal.svelte";
  import { ModalCss } from "../enums";
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.Large}>
  <div class="content">
    <svg class="svg" viewBox={icons.cloudxmark.viewBox}>
      {@html icons.cloudxmark.path}
    </svg>
    <div class="text">Ingen internet-forbindelse.</div>
  </div>
</Modal>

<!-- CSS ===================================================== -->
<style>
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    font-size: 3.5vmin;
    padding: 3vmin 2vmin 4vmin 2vmin;
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }
  .svg {
    height: 5vmin;
    width: 5vmin;
    margin-bottom: 1vmin;
    align-self: center;
    fill: red;
  }
</style>
