<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { db } from "../firebase";
  import { doc, updateDoc } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { IconButtonType, ModalCss, DocType } from "../enums";
  import type { Center } from "../types";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  // PROPS ------------------------------------------------

  export let aCenter: Center;

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();

  // FUNCTIONS ---------------------------------------------

  const cancel = () => {
    dispatch("editCenterDialogClose");
  };

  const saveEdit = async () => {
    const documentRef = doc(db, DocType.Centers, aCenter.uid);
    try {
      await updateDoc(documentRef, {
        name: aCenter.name,
        maxActiveUsers: aCenter.maxActiveUsers,
      });
    } catch (err) {
      // Sentry.captureException(err);
      console.log(
        "Kunne ikke opdatere center indstillinger: ",
        aCenter.name,
        " fejl: ",
        err
      );
    } finally {
      dispatch("editCenterDialogClose");
    }
  };
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={saveEdit}>
    <div class="headline">
      <h1>Rediger center</h1>
    </div>
    <div class="content">
      <div class="input">
        <label for="name">Navn:</label>
        <input type="text" id="name" bind:value={aCenter.name} />
      </div>
      <div class="input">
        <label for="maxActive">Antal borgere:</label>
        <input
          type="number"
          id="maxActive"
          bind:value={aCenter.maxActiveUsers}
        />
      </div>
    </div>
    <div class="bottom-box">
      <IconButton
        on:click={cancel}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
      <span class="ok-button">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          Gem
        </IconButton>
      </span>
    </div>
  </form></Modal
>

<!-- CSS ===================================================== -->
<style>
  form {
    height: 100%;
    padding: 2vmin;
    position: relative;
  }
  h1 {
    font-size: 4vmin;
    margin-bottom: 2vmin;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
    width: 100%;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
  .input {
    width: 100%;
  }
  input[type="text"] {
    font-size: 2.5vmin;
    width: 80%;
  }
  input[type="number"] {
    font-size: 2.5vmin;
    width: 20%;
    margin-top: 1.5vmin;
  }
</style>
