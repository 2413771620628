<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { db } from "../firebase";
  import { doc, getDoc, setDoc } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { IconButtonType, ModalCss, DocType, Role, Level } from "../enums";
  import type { StudentUserData, User } from "../types";
  import {
    defaultHelpDelaySecs,
    defaultShowRepeatButtonDelaySecs,
    sessionsBeforeLevelChange,
  } from "../constants";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";
  import { user } from "../stores/user-store";

  // PROPS ------------------------------------------------

  export let userType: Role;
  export let hasFreeLicenses: boolean = true;

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();

  const errorMsgTxt = "Der er allerede en bruger med denne mail.";
  const shortPswMsg = "Der skal være mindst 8 karakterer i password";

  // STATE -------------------------------------------------

  let email = "";
  let name = "";
  let psw = "";
  let active = false;
  let errorMsg = "&nbsp";
  let userTypeStr = "";

  // RESPONSIVE --------------------------------------------

  $: {
    switch (userTypeStr) {
      case Role.PWA:
        userTypeStr = "borger";
        break;
      case Role.SLT:
        userTypeStr = "logopæd";
        break;
      case Role.Admin:
        userTypeStr = "administrator";
        break;
      case Role.Private:
        userTypeStr = "privat";
    }
  }

  $: email = email.toLocaleLowerCase("da-DK");

  // FUNCTIONS ---------------------------------------------

  // button clicks ---

  const makeNewUser = async () => {
    if (psw.length < 8) {
      errorMsg = shortPswMsg;
      return;
    }
    const documentRef = doc(db, DocType.Users, email);
    const docSnap = await getDoc(documentRef);
    let userData: User | (User & StudentUserData);
    if (docSnap.exists()) {
      errorMsg = errorMsgTxt;
    } else {
      if (userType === Role.PWA || userType === Role.Private) {
        userData = {
          uid: "",
          name: name,
          role: userType,
          centerUid: $user.centerUid,
          active: active,
          sessionsCompleted: 0,
          currentLevel: Level.First,
          completedOnCurrentLevel: 0,
          password: psw,
          settings: {
            helpDelaySecs: defaultHelpDelaySecs,
            repeatDelaySecs: defaultShowRepeatButtonDelaySecs,
            perseveration: false,
            doTestNextSession: true,
            doIntroNextSession: true,
            sessionsBeforeLevelChange: sessionsBeforeLevelChange,
            qwertyKeyboard: false,
            simpleSpelling: false,
          },
        };
      } else {
        userData = {
          uid: "",
          password: psw,
          name: name,
          role: userType,
          centerUid: $user.centerUid,
          active: active,
        };
      }
      try {
        await setDoc(documentRef, userData);
      } catch (err) {
        // Sentry.captureException(err);
        console.log(
          "kunne ikke oprette " + userTypeStr + ": ",
          email,
          " fejl: ",
          err,
        );
      } finally {
        dispatch("newUserCreated");
      }
    }
  };

  const cancel = () => {
    dispatch("newUserDialogCanceled");
  };

  // initialization ---
  onMount(() => {
    active = hasFreeLicenses;
  });
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={makeNewUser}>
    <div class="headline">
      <h1>
        Opret ny {userTypeStr}
      </h1>
    </div>
    <div class="content">
      <div class="input">
        <label for="name">Kalde-navn:</label> <br />
        <input type="text" id="name" bind:value={name} />
      </div>
      <div class="input">
        <label for="email">E-mail:</label> <br />
        <input type="email" id="email" bind:value={email} />
      </div>
      <div class="input">
        <label for="pws">Password (mindst 8 karakterer):</label> <br />
        <input type="password" id="psw" bind:value={psw} />
      </div>
      <div class="input aktiv">
        <input
          type="checkbox"
          id="active"
          bind:checked={active}
          disabled={!hasFreeLicenses && userType === Role.PWA}
        />
        <label for="active">Aktiver</label>
      </div>
    </div>
    <div class="message">
      <p>{@html errorMsg}</p>
    </div>
    <div class="bottom-box">
      <IconButton
        on:click={cancel}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
      <span class="ok-btn">
        <IconButton
          disabled={email === "" || name === "" || psw.length < 8}
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          Opret
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  form {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 2vmin;
  }
  h1 {
    font-size: 4vmin;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
  .input {
    padding-top: 1vmin;
  }
  input[type="text"],
  input[type="password"],
  input[type="email"] {
    margin-top: 0.5vmin;
    font-size: 2.5vmin;
    width: 100%;
  }
  input[type="checkbox"] {
    margin-right: 1.5vmin;
    margin-left: 1vmin;
  }
  .aktiv {
    display: flex;
    align-items: center;
    margin-left: -0.5vmin;
    padding-top: 1.5vmin;
  }
  .message {
    font-weight: bold;
    color: red;
    margin-top: -2vmin;
    padding-bottom: 1vmin;
  }
</style>
