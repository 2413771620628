<script lang="ts">
  // (c) Cognisoft Aps, 2022

  // prompted naming, self-evaluaton
  // naming of object, verb-object or subject-verb-object (extension)
  // - suggest repetition if not satisfied with own naming

  import { onDestroy } from "svelte";
  import { crossfade } from "svelte/transition";
  import { flip } from "svelte/animate";
  // import * as Sentry from "@sentry/svelte";

  import {
    Pages,
    DaDK,
    Path,
    Extension,
    Level,
    LevelExtensionProgress,
    Gender,
  } from "../enums";
  import { firstWaitSecs } from "../constants";

  import { currentRoute } from "../stores/route-store";
  import { targetVerbAndObjects } from "../stores/targets-store"; // last picture shown in step 8
  import { studentUserData } from "../stores/user-store";
  import { progress } from "../stores/progress-store";
  import { helpCount, result, tasksCompleted } from "../stores/result-store";
  import { levelExtensionProgress } from "../stores/level-extension-progress-store";

  import { Icons } from "../utilities/icons";
  import { playSoundWithAudioTag } from "../utilities/playSound";
  import { getTotalNumberOfTasks } from "../utilities/getTotalNumberOfTasks";

  import ExitModal from "../modals/ExitModal.svelte";

  import IconButton from "../components/IconButton.svelte";
  import RoundButton from "../components/RoundButton.svelte";
  import ProgressBar from "../components/ProgressBar.svelte";
  import PauseMessage from "../components/PauseMessage.svelte";

  // PROPS --------------------------------------------------

  // one audiotag in App.svelte is used on all pages in order to have
  //   sound played even before mouse click
  export let audioTag: HTMLAudioElement;

  // TYPES --------------------------------------------------

  // state during working with task,
  //   tells whether image buttons should be disabled etc.
  enum State {
    WaitToStart = "waitToStart",
    PlayingInstruction = "playingInstruction",
    WaitToPromptInitial = "waitToPromptInitial",
    PlayingPromptInitial = "playingPromptInitial",
    WaitingForAnswerInitial = "waitingForAnswerInitial",
    PlayingPromptRepeat = "playingPromptRepeat",
    WaitingForAnswerAfterAnswerNo = "waitingForAnswerAfterAnswerNo",
    WaitForAnswerAfterTryAgain = "waitForAnswerAfterTryAgain",
    GotNoResponse = "gotNoResponse",
    WaitAfterYesOrFinished = "waitAfterYesOrFinished",
    Paused = "paused",
    ExitModalShown = "exitModalShown",
  }

  type imgAndVerb = {
    img: string;
    verb: string;
  };

  type HelpLevel = 1 | 2 | 3 | 4 | 5 | 6;

  // CONSTANTS --------------------------------------------

  // needed for Svelte animation
  const [send, receive] = crossfade({});

  const waitAfterAnswerDelay = 2000;
  const subjectNoResponseDelay = 2000;
  const waitAfterNoResponseDelay = 5000;

  // STATE ------------------------------------------------

  let state = State.WaitToStart;
  let oldState = State.WaitToStart; // state to return to after pause or exit dialog

  let newTask = true; // in order to not play recording from previous picture/task

  let stage = 0; // 0-3, one stage for each sentence presented
  let sendImgs: Array<imgAndVerb> = []; // animation: items at bottom that move up one at a time

  let receiveImgs: Array<imgAndVerb> = []; // an array in order to use animation

  let targetShowing = false; // object is hidden, except in repeat phase

  let helpLevel = 1;

  let repeatButtonTimer: ReturnType<typeof setTimeout>;
  let helpTimer: ReturnType<typeof setTimeout> | null = null;

  let disableAllButtons = true; // mostly when playing sounds
  let allowRepetition = false;
  let requestedHelpPaused = true; // help not active for some seconds after activated last time
  let enhanceEvaluateArea = false; //

  // for results:
  let gotHelpForItem = false;
  let hasErrorForItem = false;

  // save results for each response, push to array in results.store
  let presentTime: Date;
  let numberOfTimedHelp = 0;
  let numberOfRequestedHelp = 0;
  let numberOfRequestedRepetitions = 0;
  let attempt = 1;
  // let responseDetails: Array<ResponseDetail> = [];

  // underline for hidden word - length computed
  let underlineString = "";

  let waitToGotoNext = false; // OBS - should be a STATE ??? ************ !!!

  // keep track of pause time
  let pauseStartTime: number;
  let pauseEndTime: number;

  // FUNCTIONS --------------------------------------------

  const countHelp = () => {
    switch (stage) {
      case 0:
        $helpCount.naming.item91++;
        break;
      case 1:
        $helpCount.naming.item92++;
        break;
      case 2:
        $helpCount.naming.item93++;
        break;
      case 3:
        $helpCount.naming.item94++;
        break;
    }
  };

  const initializeResultVariables = () => {
    presentTime = new Date();
    numberOfTimedHelp = 0;
    numberOfRequestedHelp = 0;
    numberOfRequestedRepetitions = 0;
  };

  // save result details for a single item, used for cvs results
  // *** used for research disabled in current production

  const saveResultVariablesWithCorrect = (correct: boolean) => {
    // responseDetails.push({
    //   step: 9,
    //   attempt: attempt,
    //   presentTime: presentTime,
    //   responseTime: new Date(),
    //   targetItem: $targetVerbAndObjects[stage].sentence,
    //   correct: correct,
    //   numberOfTimedHelp: numberOfTimedHelp,
    //   numberOfRequestedHelp: numberOfRequestedHelp,
    //   numberOfRequestedRepetitions: numberOfRequestedRepetitions,
    // });
  };

  // show repeat button a defined time after showin task

  const startRepeatButtonTimer = () => {
    allowRepetition = false;
    repeatButtonTimer = setTimeout(() => {
      allowRepetition = true;
    }, $studentUserData.settings.repeatDelaySecs * 1000);
  };

  // showing help according to 2 level hierarkies (timed and requested help) ---

  const startHelpTimer = () => {
    if (!helpTimer) {
      disableAllButtons = false;
      helpTimer = setTimeout(() => {
        numberOfTimedHelp++;
        giveNoResponseTimedHelp();
      }, $studentUserData.settings.helpDelaySecs * 1000);
    } else {
      // Sentry.captureMessage(
      //   "Step 9, startHelpTimer(), ignored, already started, helpLevel: " +
      //     helpLevel
      // );
      console.log(
        "Step 9, startHelpTimer(), ignored, already started, helpLevel: ",
        helpLevel,
      );
    }
  };

  const enableHelpButton = () => {
    disableAllButtons = false;
    requestedHelpPaused = false;
  };

  const afterHelp = () => {
    startHelpTimer();
    enableHelpButton();
  };

  const NoResponseForLevelExtionsionProgressObject1 = () => {
    // if (
    //     state === State.Paused ||
    //     state === State.ExitModalShown ||
    //     state === State.WaitAfterYesOrFinished
    //   ) {
    //     return;
    //   }
    playSoundWithAudioTag(
      audioTag,
      DaDK.SaySentenceTogether,
      NoResponseForLevelExtionsionProgressObject2,
    );
  };

  const NoResponseForLevelExtionsionProgressObject2 = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].object,
      NoResponseForLevelExtionsionProgressObject3,
    );
  };

  const NoResponseForLevelExtionsionProgressObject3 = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].sentence,
      waitAfterNoResponse,
    );
  };

  const NoResponseForLevelExtionsionProgressVerb1 = () => {
    playSoundWithAudioTag(
      audioTag,
      DaDK.SaySentenceTogether,
      NoResponseForLevelExtionsionProgressVerb2,
    );
  };

  const NoResponseForLevelExtionsionProgressVerb2 = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].sentence,
      waitAfterNoResponse,
    );
  };

  const NoResponseForLevelExtionsionProgressSubject1 = () => {
    playSoundWithAudioTag(
      audioTag,
      DaDK.SaySentenceTogether,
      NoResponseForLevelExtionsionProgressSubject2,
    );
  };

  const NoResponseForLevelExtionsionProgressSubject2 = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].sentenceFull,
      NoResponseForLevelExtionsionProgressSubject3,
    );
  };

  const NoResponseForLevelExtionsionProgressSubject3 = () => {
    setTimeout(() => {
      NoResponseForLevelExtionsionProgressSubject4();
    }, subjectNoResponseDelay);
  };

  const NoResponseForLevelExtionsionProgressSubject4 = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].sentenceFull,
      waitAfterNoResponse,
    );
  };

  const heSheHelpPrompt = () => {
    if ($targetVerbAndObjects[stage].subjectGender === Gender.Male) {
      playSoundWithAudioTag(audioTag, DaDK.He_, afterHelp);
    } else {
      playSoundWithAudioTag(audioTag, DaDK.She_, afterHelp);
    }
  };

  const waitAfterNoResponse = () => {
    setTimeout(() => {
      nextItemOrLeavePage();
    }, waitAfterNoResponseDelay);
  };

  const giveNoResponseTimedHelp = () => {
    // console.log("giveNoResponseTimedHelp()");
    disableAllButtons = true;
    countHelp();
    clearTimeout(helpTimer);
    helpTimer = null;
    requestedHelpPaused = true;
    gotHelpForItem = true;
    switch (helpLevel) {
      case 1:
        helpLevel = 2;
        switch ($levelExtensionProgress) {
          case LevelExtensionProgress.Object:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].namingShortPrompt,
              afterHelp,
            );
            break;
          case LevelExtensionProgress.Verb:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].namingFullSentencePrompt,
              heSheHelpPrompt,
            );
            break;
          case LevelExtensionProgress.Subject:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].namingFullSentencePrompt,
              afterHelp,
            );
            break;
        }
        break;
      case 2:
        helpLevel = 3;
        switch ($levelExtensionProgress) {
          case LevelExtensionProgress.Object:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].namingShortPrompt,
              afterHelp,
            );
            break;
          case LevelExtensionProgress.Verb:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].namingFullSentencePrompt,
              heSheHelpPrompt,
            );
            break;
          case LevelExtensionProgress.Subject:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].subjectName,
              afterHelp,
            );
            break;
        }
        break;
      case 3:
        helpLevel = 4;
        switch ($levelExtensionProgress) {
          case LevelExtensionProgress.Object:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].object,
              afterHelp,
            );
            break;
          case LevelExtensionProgress.Verb:
            heSheHelpPrompt();
            break;
          case LevelExtensionProgress.Subject:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].sentenceFull,
              afterHelp,
            );
            break;
        }
        break;
      case 4:
        helpLevel = 5;
        switch ($levelExtensionProgress) {
          case LevelExtensionProgress.Object:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].object,
              afterHelp,
            );
            break;
          case LevelExtensionProgress.Verb:
            heSheHelpPrompt();
            break;
          case LevelExtensionProgress.Subject:
            playSoundWithAudioTag(
              audioTag,
              $targetVerbAndObjects[stage].sentenceFull,
              afterHelp,
            );
            break;
        }
        break;
      case 5:
        helpLevel = 6;
        enhanceEvaluateArea = true;
        playSoundWithAudioTag(audioTag, DaDK.AreYouSatisfied, afterHelp);
        break;
      case 6:
        state = State.GotNoResponse;
        switch ($levelExtensionProgress) {
          case LevelExtensionProgress.Object:
            NoResponseForLevelExtionsionProgressObject1();
            break;
          case LevelExtensionProgress.Verb:
            NoResponseForLevelExtionsionProgressVerb1();
            break;
          case LevelExtensionProgress.Subject:
            NoResponseForLevelExtionsionProgressSubject1();
            break;
        }
        break;
      default:
        // Sentry.captureMessage(
        //   "Step 9: error in giveNoResponseTimedHelp() switch, helpLevel: " +
        //     helpLevel
        // );
        console.log(
          "error in giveNoResponseTimedHelp() switch, helpLevel: ",
          helpLevel,
        );
    }
  };

  const afterRequestedHelp = () => {
    startHelpTimer();
    disableAllButtons = false;
    requestedHelpPaused = false;
  };

  const requestedHelp = () => {
    countHelp();
    disableAllButtons = true;
    numberOfRequestedHelp++;
    clearTimeout(helpTimer);
    helpTimer = null;
    requestedHelpPaused = true;
    if ($levelExtensionProgress === LevelExtensionProgress.Subject) {
      playSoundWithAudioTag(
        audioTag,
        DaDK.Step9HelpSubject,
        afterRequestedHelp,
      );
    } else {
      playSoundWithAudioTag(audioTag, DaDK.Step9Help, afterRequestedHelp);
    }
  };

  // pause before sentence is played
  const waitAndStart = (): void => {
    setTimeout(() => {
      playIntro();
    }, firstWaitSecs);
  };

  const playIntro = (): void => {
    if ($levelExtensionProgress === LevelExtensionProgress.Object) {
      playSoundWithAudioTag(audioTag, DaDK.Step9IntroObject, waitAfterIntro);
    } else if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
      playSoundWithAudioTag(audioTag, DaDK.Step9IntroVerb, waitAfterIntro);
    } else {
      playSoundWithAudioTag(audioTag, DaDK.Step9IntroSubject, waitAfterIntro);
    }
  };

  const waitAfterIntro = (): void => {
    setTimeout(() => {
      presentNextTask();
    }, firstWaitSecs);
  };

  const repeatSentence = () => {
    // console.log("repeatSentence()");
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].sentence,
      startHelpTimer,
    );
  };

  // repeat sentence (after speaker button press)
  const repeatSound = (): void => {
    // console.log("repeatSound()");
    disableAllButtons = true;
    countHelp();
    numberOfRequestedRepetitions++;
    clearTimeout(helpTimer);
    helpTimer = null;
    switch ($levelExtensionProgress) {
      case LevelExtensionProgress.Object:
        playSoundWithAudioTag(
          audioTag,
          $targetVerbAndObjects[stage].object,
          repeatSentence,
        );
        break;
      case LevelExtensionProgress.Verb:
        playSoundWithAudioTag(
          audioTag,
          $targetVerbAndObjects[stage].verb,
          repeatSentence,
        );
        break;
      case LevelExtensionProgress.Subject:
        playSoundWithAudioTag(
          audioTag,
          $targetVerbAndObjects[stage].sentenceFull,
          null,
        );
        startHelpTimer();
        break;
    }
  };

  const playPrompt = () => {
    let taskSentence: string;
    if ($levelExtensionProgress === LevelExtensionProgress.Subject) {
      taskSentence = $targetVerbAndObjects[stage].namingFullSentencePrompt;
    } else {
      if ($studentUserData.settings.perseveration) {
        if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
          taskSentence = $targetVerbAndObjects[stage].namingVerbIntro;
        } else {
          taskSentence = $targetVerbAndObjects[stage].namingIntro;
        }
      } else {
        if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
          taskSentence = $targetVerbAndObjects[stage].namingVerbSuggestionFalse;
        } else {
          taskSentence = $targetVerbAndObjects[stage].namingSuggestionFalse;
        }
      }
    }
    state = State.PlayingPromptInitial;
    playSoundWithAudioTag(audioTag, taskSentence, readyForResponse);
  };

  const readyForResponse = () => {
    // console.log("readyForResponse");
    state = State.WaitingForAnswerInitial;
    enableHelpButton();
    startHelpTimer();
    startRepeatButtonTimer();
  };

  // progress in task logic --

  const presentNextTask = () => {
    state = State.WaitToStart;
    initializeResultVariables();
    enhanceEvaluateArea = false;
    attempt = 1;
    helpLevel = 1;
    newTask = true;
    if (stage > 0) {
      targetShowing = false;
      // move target back down
      sendImgs.splice(stage - 1, 0, receiveImgs[0]);
      sendImgs = sendImgs;
      receiveImgs = [];
      setTimeout(() => {
        presentTask();
      }, firstWaitSecs);
    } else {
      presentTask();
    }
  };

  const presentTask = () => {
    setTimeout(() => {
      receiveImgs = [];
      receiveImgs.push(sendImgs[stage]);
      receiveImgs = receiveImgs;
      sendImgs.splice(stage, 1);
      sendImgs = sendImgs;
      targetShowing = true;
      playPrompt();
    }, firstWaitSecs);
  };

  // step 8 and 9 repeated for level 2.1 and 2.2 with
  //   verb or subject extension
  const gotoExtensionStep8 = () => {
    $currentRoute = Pages.Step8;
  };

  // no repetition of step 8 and nine for level 1 and 2
  //  without verb or subject extension
  const gotoResults = () => {
    $currentRoute = Pages.EndResults;
  };

  const nextItemOrLeavePage = () => {
    $tasksCompleted = $tasksCompleted + 1;
    allowRepetition = false;
    requestedHelpPaused = true;
    gotHelpForItem = false;
    hasErrorForItem = false;
    clearTimeout(helpTimer);
    helpTimer = null;
    clearTimeout(repeatButtonTimer);
    if (stage < 3) {
      stage++;
      presentNextTask();
    } else {
      targetShowing = false;
      waitToGotoNext = true;
      // $result.responseDetails.push(...responseDetails);
      $result.lastStepCompleted = 9;
      // end and go to results if:
      // first or second level -
      // or if intro or basis-test -
      // or if ended verb or subject part
      if (
        $studentUserData.currentLevel === Level.First ||
        $studentUserData.currentLevel === Level.Second ||
        $studentUserData.settings.doIntroNextSession ||
        $studentUserData.settings.doTestNextSession ||
        ($studentUserData.currentLevel === Level.SecondVerb &&
          $levelExtensionProgress === LevelExtensionProgress.Verb) ||
        ($studentUserData.currentLevel === Level.SecondSubject &&
          $levelExtensionProgress === LevelExtensionProgress.Subject)
      ) {
        setTimeout(() => {
          gotoResults();
        }, waitAfterAnswerDelay);
        return;
      } else {
        // update progress for level 8 and 9
        if ($levelExtensionProgress === LevelExtensionProgress.Object) {
          $levelExtensionProgress = LevelExtensionProgress.Verb;
        } else if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
          $levelExtensionProgress = LevelExtensionProgress.Subject;
        }
        // determine if step 8 or 9 should be repeated
        if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
          playSoundWithAudioTag(
            audioTag,
            "nu skal vi tale om hvad personerne gør",
            gotoExtensionStep8,
          );
        } else if ($levelExtensionProgress === LevelExtensionProgress.Subject) {
          playSoundWithAudioTag(
            audioTag,
            "nu får personerne navne",
            gotoExtensionStep8,
          );
        } else {
          // Sentry.captureMessage(
          //   "Step 9, error in levelExtensionProgress: " +
          //     $levelExtensionProgress
          // );
          console.log(
            "error step 9, levelExtensionProgress: ",
            $levelExtensionProgress,
          );
        }
      }
    }
  };

  // self evaluation of answer ---

  const acceptsOwnAnswer1 = () => {
    disableAllButtons = true;
    clearTimeout(helpTimer);
    helpTimer = null;
    state = State.WaitAfterYesOrFinished;
    saveResultVariablesWithCorrect(true);
    if (!gotHelpForItem && !hasErrorForItem) {
      $result.namingCorrectFirstTry = $result.namingCorrectFirstTry + 1;
    }
    $progress = $progress + 1;
    switch ($levelExtensionProgress) {
      case LevelExtensionProgress.Object:
        playSoundWithAudioTag(
          audioTag,
          $targetVerbAndObjects[stage].object,
          acceptsOwnAnswer2,
        );
        break;
      case LevelExtensionProgress.Verb:
        playSoundWithAudioTag(
          audioTag,
          $targetVerbAndObjects[stage].sentenceOK,
          nextItemOrLeavePage,
        );
        break;
      case LevelExtensionProgress.Subject:
        playSoundWithAudioTag(
          audioTag,
          $targetVerbAndObjects[stage].sentenceFull,
          nextItemOrLeavePage,
        );
        break;
    }
  };

  const acceptsOwnAnswer2 = () => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[stage].sentenceOK,
      nextItemOrLeavePage,
    );
  };

  const rejectsOwnAnswer = () => {
    clearTimeout(helpTimer);
    helpTimer = null;
    countHelp();
    saveResultVariablesWithCorrect(false);
    attempt++;
    initializeResultVariables();
    state = State.WaitingForAnswerAfterAnswerNo;
    hasErrorForItem = true;
    repeatWithMe();
  };

  const givingUp = () => {
    state = State.WaitAfterYesOrFinished;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    helpTimer = null;
    saveResultVariablesWithCorrect(false);
    hasErrorForItem = true;
    nextItemOrLeavePage();
  };

  // if answer is rejected then show repeat-with-me section and hide recording section

  const repeatWithMe = () => {
    disableAllButtons = true;
    if ($levelExtensionProgress === LevelExtensionProgress.Object) {
      playSoundWithAudioTag(audioTag, DaDK.SayWordTogether, repeatWithMe2);
    } else {
      playSoundWithAudioTag(audioTag, DaDK.SaySentenceTogether, repeatWithMe2);
    }
  };

  const repeatWithMe2 = () => {
    if ($levelExtensionProgress === LevelExtensionProgress.Object) {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[stage].object,
        repeatWithMe3,
      );
    } else if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[stage].verb,
        repeatWithMe3,
      );
    } else {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[stage].sentenceFull,
        delayBeforeRepeatWithMe3,
      );
    }
  };

  const delayBeforeRepeatWithMe3 = () => {
    setTimeout(() => {
      repeatWithMe3();
    }, waitAfterAnswerDelay);
  };

  const repeatWithMe3 = () => {
    if (
      $levelExtensionProgress === LevelExtensionProgress.Object ||
      $levelExtensionProgress === LevelExtensionProgress.Verb
    ) {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[stage].sentence,
        repeatWithMe4,
      );
    } else {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[stage].sentenceFull,
        repeatWithMe4,
      );
    }
  };

  const repeatWithMe4 = () => {
    // console.log("repeatWithMe4()");
    helpLevel = 6;
    startHelpTimer();
    state = State.WaitForAnswerAfterTryAgain;
    disableAllButtons = false;
  };

  // pause --------

  const pauseClicked = (): void => {
    if (state === State.Paused) {
      pauseEndTime = Date.now();
      $result.pauseElapsed =
        $result.pauseElapsed + Math.abs(pauseEndTime - pauseStartTime);
      state = oldState;
      allowRepetition = true;
      startHelpTimer();
    } else {
      pauseStartTime = Date.now();
      clearTimeout(repeatButtonTimer);
      clearTimeout(helpTimer);
      helpTimer = null;
      oldState = state;
      state = State.Paused;
    }
  };

  // exit task prematurely after stop button clicked

  // stop button: show yes-no modal
  const exitButtonClicked = () => {
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    helpTimer = null;
    oldState = state;
    state = State.ExitModalShown;
  };

  // exit task (modal event "yes")
  const exitTask = () => {
    // console.log("exitTask()");
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    $result.wasInterrupted = true;
    $currentRoute = Pages.EndResults;
  };

  // dismiss exit modal (event "no")
  const dismissExitModal = () => {
    // console.log("dismissExitModal()");
    state = oldState;
    allowRepetition = true;
    startHelpTimer();
  };

  // LIFE CYCLE ---------------------------------------------------

  onDestroy(() => {
    clearTimeout(helpTimer);
    helpTimer = null;
  });

  // INITIALIZE --------------------------------------------

  if ($levelExtensionProgress === LevelExtensionProgress.Object) {
    underlineString = "_".repeat($targetVerbAndObjects[stage].object.length);
  } else if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
    underlineString = "_".repeat($targetVerbAndObjects[stage].verb.length);
  } else {
    underlineString =
      "_".repeat($targetVerbAndObjects[stage].verb.length) +
      " " +
      "_".repeat($targetVerbAndObjects[stage].object.length);
  }

  // for items to chose from at bottom:
  $targetVerbAndObjects.forEach((element) => {
    sendImgs.push({ img: element.image, verb: element.verb });
  });

  // wait before presenting target sentence
  waitAndStart();
</script>

<!-- HTML ===================================================== -->

<body class:completed-bg-color={waitToGotoNext}>
  <main>
    <!-- top panel with help and repeat buttons -->
    <div class="top-panel">
      <div>
        <span class="stop-button">
          <RoundButton
            disabled={state === State.WaitToStart ||
              state === State.PlayingInstruction ||
              state === State.WaitToPromptInitial ||
              state == State.GotNoResponse ||
              state === State.WaitAfterYesOrFinished ||
              state === State.PlayingPromptRepeat ||
              disableAllButtons}
            on:click={exitButtonClicked}
            icon={Icons.TrafficLightRed}
          />
        </span>
        <RoundButton
          disabled={state === State.WaitToStart ||
            state === State.PlayingInstruction ||
            state === State.WaitToPromptInitial ||
            state == State.GotNoResponse ||
            state === State.WaitAfterYesOrFinished ||
            state === State.PlayingPromptRepeat ||
            disableAllButtons}
          on:click={pauseClicked}
          icon={state === State.Paused ? Icons.Play : Icons.Pause}
        />
      </div>
      <div>
        <RoundButton
          disabled={state === State.WaitToStart ||
            state === State.PlayingInstruction ||
            state === State.WaitToPromptInitial ||
            state == State.GotNoResponse ||
            state === State.WaitAfterYesOrFinished ||
            state === State.PlayingPromptRepeat ||
            !allowRepetition ||
            requestedHelpPaused ||
            disableAllButtons}
          on:click={repeatSound}
          icon={Icons.VolumeRegular}
        />
        <span class="help-button">
          <RoundButton
            disabled={state === State.WaitToStart ||
              state === State.PlayingInstruction ||
              state === State.WaitToPromptInitial ||
              state == State.GotNoResponse ||
              state === State.WaitAfterYesOrFinished ||
              state === State.PlayingPromptRepeat ||
              requestedHelpPaused ||
              disableAllButtons}
            on:click={requestedHelp}
            icon={Icons.QuestionSolid}
          />
        </span>
      </div>
    </div>

    <!-- middle area to show target picture and buttons for recording etc -->
    <!-- target image to left -->
    <div class="target-area">
      <div class="target">
        <!-- image showing target -->
        {#each receiveImgs as item (item)}
          <div
            class="image"
            animate:flip
            out:send|global={{ key: item }}
            in:receive|global={{ key: item }}
          >
            <picture>
              <source
                type="image/webp"
                srcset={encodeURI(Path.Img + item.img + Extension.Webp)}
                draggable={false}
              />
              <img
                class="target-img"
                style="background-image: url('{Path.ImgBg}{item.img}{Extension.ImgBg}'); 
                background-image: image-set('{Path.ImgBg}{item.img}{Extension.WebpBg}'); 
                background-size: cover;"
                draggable={false}
                src={Path.Img + item.img + Extension.Png}
                alt=""
              />
            </picture>
          </div>
        {/each}
      </div>
    </div>

    <div class="text">
      <!-- showing verb or subject, word hidden if not repeat task after error -->
      <div class="button-text" class:hidden={!targetShowing}>
        <div
          class="text-start"
          class:short-text-start={$levelExtensionProgress ===
            LevelExtensionProgress.Subject}
        >
          {#if $levelExtensionProgress === LevelExtensionProgress.Object}
            {$targetVerbAndObjects[stage].verb}
          {:else if $levelExtensionProgress === LevelExtensionProgress.Verb}
            {#if state === State.WaitToPromptInitial || state === State.PlayingPromptInitial || state === State.WaitingForAnswerInitial || state === State.Paused || state === State.ExitModalShown}
              {underlineString}
            {:else}
              {$targetVerbAndObjects[stage].verb}
            {/if}
          {:else}
            <span class="name-caps">
              {$targetVerbAndObjects[stage].subjectName}
            </span>
          {/if}
        </div>
        <!-- showing object or verb + object, word hidden for object tasks if not repeat after error -->
        <div
          class="text-end"
          class:long-text-end={$levelExtensionProgress ===
            LevelExtensionProgress.Subject}
        >
          {#if $levelExtensionProgress === LevelExtensionProgress.Verb}
            {$targetVerbAndObjects[stage].object}
          {:else if state === State.WaitToPromptInitial || state === State.PlayingPromptInitial || state === State.WaitingForAnswerInitial || state === State.Paused || state === State.ExitModalShown}
            {underlineString}
          {:else if $levelExtensionProgress === LevelExtensionProgress.Object}
            {$targetVerbAndObjects[stage].object}
          {:else if $levelExtensionProgress === LevelExtensionProgress.Subject}
            {$targetVerbAndObjects[stage].sentence}
          {/if}
        </div>
      </div>
    </div>

    <!-- bottom area -->
    <!-- 1) first show the four pictures as small - one a flows up to be target i middle section -->
    <!-- 2) then show evaluation buttons yes and no -->

    <!-- 1) show small pictures  -->
    {#if state === State.WaitToStart || state === State.PlayingInstruction || state === State.PlayingPromptInitial}
      <div class="bottom-area">
        {#each sendImgs as item (item)}
          <div
            class="send-img"
            animate:flip
            in:receive|global={{ key: item }}
            out:send|global={{ key: item }}
          >
            <picture>
              <source
                type="image/webp"
                srcset={encodeURI(Path.Img + item.img + Extension.Webp)}
                draggable={false}
              />
              <img
                class="small-img"
                style="background-image: url('{Path.ImgBg}{item.img}{Extension.ImgBg}');
                background-image: image-set('{Path.ImgBg}{item.img}{Extension.WebpBg}'); 
                background-size: cover;"
                draggable={false}
                src={Path.Img + item.img + Extension.Png}
                alt=""
              />
            </picture>
          </div>
        {/each}
      </div>
      <!-- 2) show yes and no evaluation buttons -->
    {:else if state !== State.GotNoResponse}
      <div class="evaluate-area" class:enhanceEvaluateArea>
        <!-- {#if state !== State.RepeatTogether && state !== State.RepeatWait} -->
        <div class="response-text">
          <!-- {#if state === State.ReceivingAnswer || state === State.ReadyForAnswer || state === State.HasReceivedAnswer || state === State.RepeatWait} -->
          Er du tilfreds?
          <!-- {:else}
              <span class="gray-text"> ... </span>
            {/if} -->
        </div>
        <div class="evaluate-button-area">
          <span class="evaluate-button">
            <IconButton
              disabled={disableAllButtons}
              on:click={acceptsOwnAnswer1}
              icon={Icons.ThumbsUp}
              textScaled={true}
            >
              Ja
            </IconButton>
          </span>
          <span class="evaluate-button">
            <IconButton
              disabled={disableAllButtons}
              on:click={rejectsOwnAnswer}
              icon={Icons.ThumbsDown}
              redColor={true}
              textScaled={true}
            >
              Nej
            </IconButton>
          </span>
          {#if state === State.WaitingForAnswerAfterAnswerNo || state === State.WaitForAnswerAfterTryAgain}
            <span class="evaluate-button">
              <IconButton
                disabled={disableAllButtons}
                on:click={repeatSound}
                icon={Icons.Repeat}
                textMediumScaled={true}>Prøv igen</IconButton
              >
            </span>
            <span class="evaluate-button">
              <IconButton
                disabled={disableAllButtons}
                on:click={givingUp}
                icon={Icons.StopCircleRegular}
                textMediumScaled={true}>Færdig</IconButton
              >
            </span>
          {/if}
        </div>
      </div>
    {/if}
    <!-- show progress of number of tasks -->
    <div class="progress">
      <ProgressBar
        max={getTotalNumberOfTasks($studentUserData.currentLevel)}
        position={$progress}
      />
    </div>
  </main>
</body>

<!-- dialog boxes -->

{#if state === State.Paused}
  <PauseMessage on:click={pauseClicked} />
{/if}

{#if state === State.ExitModalShown}
  <ExitModal on:yes={exitTask} on:no={dismissExitModal} />
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    margin-left: auto;
    margin-right: auto;
    width: 120vh;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: 96%;
    grid-template-rows: 14% 44% 10% 22% 2%;
    grid-template-areas:
      "top"
      "target"
      "text"
      "imgs"
      "prog";
    gap: 3%;
    padding: 3%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    main {
      width: 100vw;
    }
  }
  .top-panel {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .target-area {
    grid-area: target;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .target {
    height: 100%;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  .image {
    background: white;
    border: solid 1px black;
    width: 100%;
    height: 100%;
  }
  .target-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }
  .response-text {
    display: flex;
    justify-content: space-around;
    font-size: 6vmin;
  }
  .evaluate-area {
    grid-area: imgs;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-around;
    border: solid 1px black;
    border-radius: 1vmin;
  }
  .enhanceEvaluateArea {
    border: solid 8px blue;
    background: lightskyblue;
  }
  .evaluate-button-area {
    display: flex;
    justify-content: center;
  }
  .evaluate-button {
    margin-left: 2vmin;
    margin-right: 2vmin;
  }
  .text {
    grid-area: text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bottom-area {
    grid-area: imgs;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .send-img {
    height: 18vmin;
    width: 23vmin;
    background: white;
  }
  .small-img {
    border: solid 1px black;
    width: 100%;
    height: 90%;
    object-fit: contain;
  }
  .button-text {
    border: solid 1px black;
    background: white;
    width: 80%;
    color: black;
    font-size: 5vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding-top: 0.1vmin;
    padding-bottom: 0.5vmin;
  }
  .text-start {
    display: flex;
    height: 100%;
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5vmin;
  }
  .short-text-start {
    width: 40%;
  }
  .text-end {
    display: flex;
    height: 100%;
    width: 50%;
    border: none;
    align-items: center;
    padding-bottom: 0;
    justify-content: flex-start;
    border-left: 2px dotted black;
    padding-left: 0.5vmin;
  }
  .long-text-end {
    width: 60%;
  }
  .hidden {
    visibility: hidden;
  }
  .name-caps {
    text-transform: capitalize;
  }
</style>
