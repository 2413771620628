<script lang="ts">
  // (c) Cognisoft Aps, 2022

  import { db } from "../firebase";
  import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
  import { onMount } from "svelte";
  // import * as Sentry from "@sentry/svelte";

  import { Pages, DocType, Level } from "../enums";
  import type { Result, ResultUserEmail, ResultSettings } from "../types";

  import { currentRoute } from "../stores/route-store";
  import { userEmail, studentUserData } from "../stores/user-store";
  import { helpCount, result } from "../stores/result-store";

  import { icons, Icons } from "../utilities/icons";

  import IconButton from "../components/IconButton.svelte";

  import MessageModal from "../modals/MessageModal.svelte";

  // TYPES ---------------------------------------------------------------

  // CONSTANTS ------------------------------------------------------------------

  const maxIndependencyIndexScore = 2;
  const maxIndex = 100;

  // STATE ----------------------------------------------------------------------

  // error messaging
  let showMessageModal = false;
  let errorMessage = "";
  let saveResultsError = "Kunne ikke gemme resultater";

  // in order to show correct text even after update of training status variables
  let wasIntro = false;
  let wasBasisTest = false;
  let evalText = "";
  let numberOfSpeechTasks: 4 | 8 | 12 = 4;

  // variables to determine if modality-icons should be shown
  let goodReading = false;
  let goodComprehension = false;
  let goodSpelling = false;

  // FUNCTIONS ------------------------------------------------------------------

  const startOver = (): void => {
    $currentRoute = Pages.Ready;
  };

  // sum independency-indexes from all items in a modality
  // make 0-100 score by making percentage of obtained score of max score
  const independenceIndexFromItemIndices = (
    ...theArgs: Array<number>
  ): number => {
    const maxScore = theArgs.length * maxIndependencyIndexScore;
    const reducer = (accumulator: number, curr: number): number =>
      accumulator + curr;
    const sum = theArgs.reduce(reducer);
    return (sum / maxScore) * 100;
  };

  // reduce an help count that is from 0 to infinite to a 0-1-2 score;
  // no errors = 2, 1-2 errors = 1, > 2 errors = 0
  const itemIndexFromHelpCount = (count: number): 0 | 1 | 2 => {
    switch (count) {
      case 0:
        return 2;
        break;
      case 1:
      case 2:
        return 1;
        break;
      default:
        return 0;
    }
  };

  const computeIndependencyIndexes = () => {
    const comprehensionHelpArr: Array<number> = [
      itemIndexFromHelpCount($helpCount.comprehension.item11),
      itemIndexFromHelpCount($helpCount.comprehension.item12),
      itemIndexFromHelpCount($helpCount.comprehension.item13),
      itemIndexFromHelpCount($helpCount.comprehension.item14),
      itemIndexFromHelpCount($helpCount.comprehension.item41),
      itemIndexFromHelpCount($helpCount.comprehension.item42),
      itemIndexFromHelpCount($helpCount.comprehension.item43),
    ];
    $result.comprehensionIndependencyIndex = independenceIndexFromItemIndices(
      ...comprehensionHelpArr,
    );
    const readingHelpArr: Array<number> = [
      itemIndexFromHelpCount($helpCount.reading.item21),
      itemIndexFromHelpCount($helpCount.reading.item22),
      itemIndexFromHelpCount($helpCount.reading.item23),
      itemIndexFromHelpCount($helpCount.reading.item24),
      itemIndexFromHelpCount($helpCount.reading.item31),
      itemIndexFromHelpCount($helpCount.reading.item32),
      itemIndexFromHelpCount($helpCount.reading.item33),
      itemIndexFromHelpCount($helpCount.reading.item34),
    ];
    $result.readingIndependencyIndex = independenceIndexFromItemIndices(
      ...readingHelpArr,
    );
    // 13.02.24: now there may be either 1 or 3 spelling steps
    const spellingHelpArr: Array<number> = $studentUserData.settings
      .simpleSpelling
      ? [
          itemIndexFromHelpCount($helpCount.spelling.item51),
          itemIndexFromHelpCount($helpCount.spelling.item52),
          itemIndexFromHelpCount($helpCount.spelling.item53),
          itemIndexFromHelpCount($helpCount.spelling.item54),
        ]
      : [
          itemIndexFromHelpCount($helpCount.spelling.item51),
          itemIndexFromHelpCount($helpCount.spelling.item52),
          itemIndexFromHelpCount($helpCount.spelling.item53),
          itemIndexFromHelpCount($helpCount.spelling.item54),
          itemIndexFromHelpCount($helpCount.spelling.item61),
          itemIndexFromHelpCount($helpCount.spelling.item62),
          itemIndexFromHelpCount($helpCount.spelling.item63),
          itemIndexFromHelpCount($helpCount.spelling.item64),
          itemIndexFromHelpCount($helpCount.spelling.item71),
          itemIndexFromHelpCount($helpCount.spelling.item72),
          itemIndexFromHelpCount($helpCount.spelling.item73),
          itemIndexFromHelpCount($helpCount.spelling.item74),
        ];
    $result.spellingIndependencyIndex = independenceIndexFromItemIndices(
      ...spellingHelpArr,
    );
    const namingHelpArr: Array<number> = [
      itemIndexFromHelpCount($helpCount.naming.item91),
      itemIndexFromHelpCount($helpCount.naming.item92),
      itemIndexFromHelpCount($helpCount.naming.item93),
      itemIndexFromHelpCount($helpCount.naming.item94),
    ];
    $result.namingIndependencyIndex = independenceIndexFromItemIndices(
      ...namingHelpArr,
    );
  };

  const saveResults = async () => {
    // // add a new result doc for the user
    // // - but not if stopped before end of the last step 9

    let resultToSave: ResultUserEmail & Result & ResultSettings = {
      email: $userEmail,
      settings: { ...$studentUserData.settings },
      session: $studentUserData.sessionsCompleted,
      startTime: $result.startTime,
      endTime: $result.endTime,
      pauseElapsed: $result.pauseElapsed,
      items: $result.items,
      // responseDetails: $result.responseDetails,
      comprehensionCorrectFirstTry: $result.comprehensionCorrectFirstTry,
      comprehensionIndependencyIndex: $result.comprehensionIndependencyIndex,
      readingCorrectFirstTry: $result.readingCorrectFirstTry,
      readingIndependencyIndex: $result.readingIndependencyIndex,
      spellingCorrectFirstTry: $result.spellingCorrectFirstTry,
      spellingIndependencyIndex: $result.spellingIndependencyIndex,
      namingCorrectFirstTry: $result.namingCorrectFirstTry,
      namingIndependencyIndex: $result.namingIndependencyIndex,
      wasInterrupted: $result.wasInterrupted,
      lastStepCompleted: $result.lastStepCompleted,
      currentLevel: $studentUserData.currentLevel,
    };

    try {
      const docRef = await addDoc(
        collection(db, DocType.Results),
        resultToSave,
      );
    } catch (err) {
      // Sentry.captureException(err);
      console.log("kunne ikke gemme resultater, fejl: ", err);
    }
  };

  const updateSessionsCompleted = () => {
    if ($studentUserData.settings.doIntroNextSession) {
      wasIntro = true;
    } else if ($studentUserData.settings.doTestNextSession) {
      wasBasisTest = true;
    } else {
      $studentUserData.sessionsCompleted =
        $studentUserData.sessionsCompleted + 1;
      $studentUserData.completedOnCurrentLevel =
        $studentUserData.completedOnCurrentLevel + 1;
    }
  };

  const updateStudentUserDataStore = () => {
    // determine type of run for next time
    if ($studentUserData.settings.doIntroNextSession) {
      $studentUserData.settings.doIntroNextSession = false;
      $studentUserData.settings.doTestNextSession = true;
    } else if ($studentUserData.settings.doTestNextSession) {
      $studentUserData.settings.doTestNextSession = false;
      $studentUserData.settings.doIntroNextSession = false;
    } else {
      if (
        $studentUserData.completedOnCurrentLevel >=
          $studentUserData.settings.sessionsBeforeLevelChange &&
        $studentUserData.currentLevel !== Level.SecondSubject
      ) {
        if ($studentUserData.currentLevel === Level.First) {
          $studentUserData.currentLevel = Level.Second;
        } else if ($studentUserData.currentLevel === Level.Second) {
          $studentUserData.currentLevel = Level.SecondVerb;
        } else if ($studentUserData.currentLevel === Level.SecondVerb) {
          $studentUserData.currentLevel = Level.SecondSubject;
        }
        $studentUserData.completedOnCurrentLevel = 0;
        $studentUserData.settings.doTestNextSession = true;
      }
      // no levels beyond SecondSubject
    }
  };

  // update Level, number of sessions etc in user rec
  const updateFirestoreUserDoc = async () => {
    const documentRef = doc(db, DocType.Users, $userEmail);
    try {
      await updateDoc(documentRef, {
        sessionsCompleted: $studentUserData.sessionsCompleted,
        currentLevel: $studentUserData.currentLevel,
        completedOnCurrentLevel: $studentUserData.completedOnCurrentLevel,
        settings: $studentUserData.settings,
      });
    } catch (err) {
      // Sentry.captureException(err);
      console.log("kunne ikke opdatere antal sessioner mv, fejl: ", err);
      errorMessage = saveResultsError;
      showMessageModal = true;
    }
  };

  const makeEvaluationTexts = () => {
    // values to display in temp vars here before update with saving results

    // compute number of speech tasks on level
    if (
      $studentUserData.settings.doIntroNextSession ||
      $studentUserData.settings.doTestNextSession
    ) {
      numberOfSpeechTasks = 4;
    } else {
      if ($studentUserData.currentLevel === Level.SecondVerb) {
        numberOfSpeechTasks = 8;
      } else if ($studentUserData.currentLevel === Level.SecondSubject) {
        numberOfSpeechTasks = 12;
      } else {
        numberOfSpeechTasks = 4;
      }
    }
    // evaluation test for non-speech tasks
    if (
      // perfect for all 3
      $result.comprehensionIndependencyIndex === maxIndex &&
      $result.readingIndependencyIndex === maxIndex &&
      $result.spellingIndependencyIndex === maxIndex
    ) {
      evalText = "Du var både god til at forstå, læse og stave.";
      goodComprehension = true;
      goodReading = true;
      goodSpelling = true;
    } else if (
      // equally bad for all 3
      $result.comprehensionIndependencyIndex ===
        $result.readingIndependencyIndex &&
      $result.readingIndependencyIndex === $result.spellingIndependencyIndex &&
      $result.spellingIndependencyIndex ===
        $result.comprehensionIndependencyIndex
    ) {
      evalText = "";
    } else if (
      // comprehension best
      $result.comprehensionIndependencyIndex >
        $result.readingIndependencyIndex &&
      $result.comprehensionIndependencyIndex > $result.spellingIndependencyIndex
    ) {
      evalText = "Du var særlig god til at forstå tale.";
      goodComprehension = true;
    } else if (
      // reading best
      $result.readingIndependencyIndex >
        $result.comprehensionIndependencyIndex &&
      $result.readingIndependencyIndex > $result.spellingIndependencyIndex
    ) {
      evalText = "Du var særlig god til at læse.";
      goodReading = true;
    } else if (
      // spelling best
      $result.spellingIndependencyIndex > $result.readingIndependencyIndex &&
      $result.spellingIndependencyIndex > $result.comprehensionIndependencyIndex
    ) {
      evalText = "Du var særlig god til at stave.";
      goodSpelling = true;
    } else if (
      // comprehension eq reading, both > spelling
      $result.comprehensionIndependencyIndex ===
        $result.readingIndependencyIndex &&
      $result.comprehensionIndependencyIndex >
        $result.spellingIndependencyIndex &&
      $result.readingIndependencyIndex > $result.spellingIndependencyIndex
    ) {
      evalText = "I dag var du særlig god til at forstå og læse";
      goodComprehension = true;
      goodReading = true;
    } else if (
      // comprehension eq spelling, both > reading
      $result.comprehensionIndependencyIndex ===
        $result.spellingIndependencyIndex &&
      $result.comprehensionIndependencyIndex >
        $result.readingIndependencyIndex &&
      $result.spellingIndependencyIndex > $result.readingIndependencyIndex
    ) {
      evalText = "I dag var du særlig god til at forstå og stave";
      goodComprehension = true;
      goodSpelling = true;
    } else if (
      // reading eq spelling, both > comprehension
      $result.spellingIndependencyIndex === $result.readingIndependencyIndex &&
      $result.readingIndependencyIndex >
        $result.comprehensionIndependencyIndex &&
      $result.spellingIndependencyIndex > $result.comprehensionIndependencyIndex
    ) {
      evalText = "I dag var du særlig god til at læse og stave";
      goodReading = true;
      goodComprehension = true;
    } else {
      // Sentry.captureMessage("End-results: error in making evalText");
      console.log("End-results: error in making evalText");
    }
  };

  // INITIALIZE -----------------------------------------------------------

  // do before mount:

  $result.endTime = new Date();
  computeIndependencyIndexes();
  if (!$result.wasInterrupted) {
    updateSessionsCompleted();
  }
  makeEvaluationTexts();

  onMount(async () => {
    // save results before updating Level etc.
    await saveResults();
    if (!$result.wasInterrupted) {
      // update user record with new Level etc. in store
      updateStudentUserDataStore();
    }
    // update user record with new Level etc. in firestore database
    await updateFirestoreUserDoc();
  });
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="results">
    {#if !$result.wasInterrupted}
      {#if wasIntro}
        Du har nu gennemført et introduktionsforløb. <br />
      {:else if wasBasisTest}
        Basis-test gennemført. <br />
      {:else}
        Godt gået. Du har nu gennemført dit {$studentUserData.sessionsCompleted}.
        sæt.
        <br />
      {/if}
      {#if evalText !== ""}
        {#if goodComprehension}
          <svg class="svg" viewBox={icons[Icons.EarLight].viewBox}>
            {@html icons[Icons.EarLight].path}
          </svg>
        {/if}
        {#if goodReading}
          <svg class="svg" viewBox={icons[Icons.BookOpenLight].viewBox}>
            {@html icons[Icons.BookOpenLight].path}
          </svg>
        {/if}
        {#if goodSpelling}
          <svg class="svg" viewBox={icons[Icons.PencilLight].viewBox}>
            {@html icons[Icons.PencilLight].path}
          </svg>
        {/if}
        {evalText}
        <br />
      {/if}
      <svg class="svg" viewBox={icons[Icons.LipsLight].viewBox}>
        {@html icons[Icons.LipsLight].path}
      </svg>
      Du var tilfreds med din udtale {$result.namingCorrectFirstTry} ud af {numberOfSpeechTasks}
      gange.
    {/if}
  </div>
  <div class="result-details">
    {#if $result.wasInterrupted}
      {#if $result.lastStepCompleted === 0}
        Træningen blev afbrudt før nogen trin var gennemført.
      {:else if $result.lastStepCompleted < 9}
        Træningen blev afbrudt. Sidste gennemførte trin: {$result.lastStepCompleted}.
      {/if}
    {/if}
  </div>
  <div class="button-area">
    <IconButton on:click={startOver} textLargeScaled={true} icon={Icons.Repeat}>
      Ny træning
    </IconButton>
  </div>
</main>

<!-- MODALs with messages  -->

{#if showMessageModal}
  <MessageModal
    on:messageModalClosed={() => {
      showMessageModal = false;
    }}
  >
    {errorMessage}
  </MessageModal>
{/if}

<!-- CSS ====================================================== -->
<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: calc(var(--vh, 1vh) * 100);
  }
  .results {
    font-size: 4vmin;
    line-height: 8vmin;
    padding: 5vw;
  }
  .result-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3vmin;
    line-height: 4vmin;
  }
  .button-area {
    width: 80vw;
    display: flex;
    justify-content: center;
  }
  .svg {
    height: 5vmin;
    width: 5vmin;
    fill: currentColor;
    transform: translate(0, 1vmin);
  }
</style>
