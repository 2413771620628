<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { db } from "../firebase";
  import { doc, updateDoc } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { IconButtonType, ModalCss, DocType } from "../enums";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  // PROPS ------------------------------------------------

  export let email: string;
  export let name: string;
  export let active: boolean;

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();

  // STATE --------------------------------------------------

  let oldEmail: string;
  let oldNname: string;
  let oldActive: boolean;

  // FUNCTIONS ---------------------------------------------

  // button clicks ---

  const saveEdit = async () => {
    const documentRef = doc(db, DocType.Users, email);
    try {
      await updateDoc(documentRef, {
        name: name,
        active: active,
      });
    } catch (err) {
      // Sentry.captureException(err);
      console.log("kunne ikke opdatere logopæd: ", email, " fejl: ", err);
    } finally {
      dispatch("userEdited");
    }
  };

  const cancel = () => {
    email = oldEmail;
    name = oldNname;
    active = oldActive;
    dispatch("editUserDialogCanceled");
  };

  // INITIALIZE --------------------------------------

  onMount(() => {
    // enable cancel without changing
    oldEmail = email;
    oldNname = name;
    oldActive = active;
  });
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={saveEdit}>
    <div class="headline">
      <h1>Indstillinger</h1>
    </div>
    <div class="content">
      <p>E-mail: {email}</p>
      <div class="input">
        <label for="name">Kalde-navn:</label>
        <input type="text" id="name" bind:value={name} />
      </div>
      <div class="check">
        <input type="checkbox" id="onestep" bind:checked={active} />
        <label for="onestep">Aktiv</label>
      </div>
    </div>
    <div class="bottom-box">
      <IconButton
        on:click={cancel}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
      <span class="ok-btn">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          Gem
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  form {
    height: 100%;
    padding: 2vmin;
  }
  h1 {
    font-size: 4vmin;
    margin-bottom: 2vmin;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
  .input,
  .check {
    padding-top: 2vmin;
  }
  .input {
    width: 100%;
  }
  input[type="text"] {
    font-size: 2.5vmin;
    width: 70%;
  }
  input[type="checkbox"] {
    margin-right: 1vmin;
    font-size: 2.5vmin;
  }
</style>
