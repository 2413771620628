<script lang="ts">
  import { Icons } from "../utilities/icons";
  import { Pages } from "../enums";

  import { currentRoute } from "../stores/route-store";
  import { pwaEmailForResults, pwaNameForResults } from "../stores/user-store";
  import { savedResults } from "../stores/saved-result-store";
  import { user } from "../stores/user-store";
  import IconButton from "../components/IconButton.svelte";

  // STATE -------------------------------------------------------

  // list of results to display, construct from firestore database
  let listItems: Array<string> = [];

  // FUNCTIONS -------------------------------------------------------

  const makeList = () => {
    listItems.push(
      "SESSION;ACTION;ID;DATE;STARTTIME;ENDTIME;STEP;ITEM1;ITEM1;ITEM3;ITEM4;ATTEMPT;TARGET;CORRECT;RT"
    );
    let sortedResults = [...$savedResults];
    sortedResults.sort((a, b) => parseFloat(a.session) - parseFloat(b.session));

    sortedResults.forEach((result) => {
      if (result.responseDetails) {
        let responseDetails = [...$savedResults[0].responseDetails];
        let index = 0;
        responseDetails.forEach((response) => {
          index++;
          listItems.push(
            result.session.toString() +
              ";" +
              index.toString() +
              ";" +
              $user.name +
              ";" +
              new Intl.DateTimeFormat("da-DK", { dateStyle: "short" }).format(
                result.endTime.toDate()
              ) +
              ";" +
              new Intl.DateTimeFormat("da-DK", { timeStyle: "medium" }).format(
                result.startTime.toDate()
              ) +
              ";" +
              new Intl.DateTimeFormat("da-DK", { timeStyle: "medium" }).format(
                result.endTime.toDate()
              ) +
              ";" +
              response.step.toString() +
              ";" +
              result.items[0] +
              ";" +
              result.items[1] +
              ";" +
              result.items[2] +
              ";" +
              result.items[3] +
              ";" +
              response.attempt.toString() +
              ";" +
              response.targetItem +
              ";" +
              (response.correct ? "1" : "0") +
              ";" +
              Math.abs(response.responseTime - response.presentTime).toFixed(3)
          );
        });
      }
    });
  };

  // button click ---

  const backButtonClicked = () => {
    $currentRoute = Pages.ResultList;
  };

  // INITIALIZE ------------------------------------------------------

  makeList();
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="heading">
    <h1>Csv data: {$pwaNameForResults} - {$pwaEmailForResults}</h1>
  </div>

  <div class="list-area">
    {#each listItems as item}
      <p>{item}</p>
    {/each}
  </div>

  <div class="bottom-area">
    <span class="button-spacing">
      <IconButton
        on:click={backButtonClicked}
        textMediumScaled={true}
        icon={Icons.ArrowLeft}
      >
        Tilbage
      </IconButton>
    </span>
  </div>
</main>

<!-- CSS ===================================================== -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
  }
  h1 {
    font-size: 3vmin;
  }
  .heading {
    padding: 1vmin;
  }
  .list-area {
    border: rgb(55, 52, 103) solid;
    background-color: var(--list-background-color);
    margin: var(--std-padding);
    line-height: 1.3em;
    padding: 0 1% 0 1%;
    overflow: scroll;
    flex-grow: 1;
    font-size: 1rem;
    line-height: 1.2;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 1vmin;
    padding-bottom: 1vmin;
    border-top: 2px solid var(--key-hover-border-color);
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-left: 1vmin;
  }
</style>
