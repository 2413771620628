<script lang="ts">
  // show list of private users
  // use modals for new, edit and delete
  // new page for results

  import { db, auth } from "../firebase";
  import { loggedIn } from "../stores/user-store";
  // import * as Sentry from "@sentry/svelte";

  import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
  } from "@firebase/firestore";

  import type { Unsubscribe } from "@firebase/firestore";

  import { onMount, onDestroy } from "svelte";

  import { DocType, Role, Pages } from "../enums";
  import type { StudentUserData, EditUserEmail, User } from "../types";

  import { Icons } from "../utilities/icons";

  import { currentRoute, returnRoute } from "../stores/route-store";
  import {
    user,
    pwaEmailForResults,
    pwaNameForResults,
  } from "../stores/user-store";
  import {
    editPWAEmail,
    editPWAName,
    editPWAActive,
    editPWASettings,
    editPWALevel,
    editPWACompletedOnCurrentLevel,
  } from "../stores/edit-pwa-settings-store";

  import IconButton from "../components/IconButton.svelte";
  import ListBox from "../components/ListBox.svelte";

  import NewUserModal from "../modals/NewUserModal.svelte";
  import DeleteUserModal from "../modals/DeleteUserModal.svelte";
  import WaitModal from "../modals/WaitModal.svelte";
  import YesNoModal from "../modals/YesNoModal.svelte";

  // STATE -----------------------------------------------------------

  let unsubscribe: Unsubscribe;
  let privates: Array<User & StudentUserData & EditUserEmail> = [];

  let showNewUserModal = false;
  let showDeleteUserModal = false;
  let showWaitModal = false;
  let showYesNoModal = false;

  // list of users to display, construct from firestore database
  let listItems: Array<string> = [];
  // selected in list
  let selectedIndex: number | null;

  // email and name for delete to avoid being undefined after delete
  let emailForDelete = "";
  let nameForDelete = "";

  // FUNCTIONS -------------------------------------------------------

  // database operations ---

  const getRealtimeUpdates = () => {
    const collectionRef = collection(db, DocType.Users);
    const collectionQuery = query(
      collectionRef,
      where("centerUid", "==", $user.centerUid),
      where("role", "==", Role.Private),
      orderBy("name"),
    );
    unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
      privates = [];
      listItems = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        privates.push({
          email: doc.id,
          name: data.name,
          uid: data.uid,
          centerUid: data.centerUid,
          role: data.role,
          active: data.active,
          sessionsCompleted: data.sessionsCompleted,
          currentLevel: data.currentLevel,
          completedOnCurrentLevel: data.completedOnCurrentLevel,
          settings: data.settings,
        });
        const active = data.active ? "aktiv" : "deaktiveret";
        listItems.push(data.name + " - " + doc.id + " - " + active);
      });
      showWaitModal = false;
    });
  };

  // button clicks ---

  const backButtonClicked = () => {
    $currentRoute = Pages.AdminStart;
  };

  const newPWAButtonClicked = () => {
    showNewUserModal = true;
  };

  const editPWAButtonClicked = () => {
    if (selectedIndex === null) {
      console.log("Fejl i selectedIndex for editPWAButtonClicked");
      // Sentry.captureMessage(
      //   "Error in selectedIndex for editPWAButtonClicked: " + selectedIndex
      // );
      return;
    }
    $editPWAEmail = privates[selectedIndex!].email;
    $editPWAName = privates[selectedIndex!].name;
    $editPWAActive = privates[selectedIndex!].active;
    $editPWASettings = privates[selectedIndex!].settings;
    $editPWALevel = privates[selectedIndex!].currentLevel;
    $editPWACompletedOnCurrentLevel =
      privates[selectedIndex].completedOnCurrentLevel;
    $returnRoute = Pages.PrivateEdit;
    $currentRoute = Pages.Settings;
  };

  const deletePWAButtonClicked = () => {
    if (selectedIndex === null) {
      console.log("Fejl i selectedIndex for deletePWAButtonClicked");
      // Sentry.captureMessage(
      //   "Error in selectedIndex for deletePWAButtonClicked: " + selectedIndex
      // );
      return;
    }
    emailForDelete =
      privates.length === 0 || selectedIndex === null
        ? ""
        : privates[selectedIndex].email;
    nameForDelete =
      privates.length === 0 || selectedIndex === null
        ? ""
        : privates[selectedIndex].name;
    showDeleteUserModal = true;
  };

  const resultsButtonClicked = () => {
    $returnRoute = Pages.PrivateEdit;
    $currentRoute = Pages.ResultList;
  };

  // event functions ---

  const newUserWasCreated = () => {
    selectedIndex = null;
    showNewUserModal = false;
  };

  const userWasDeleted = () => {
    showDeleteUserModal = false;
    selectedIndex = null;
  };

  // item list functions ---

  const listItemSelected = (event: CustomEvent): void => {
    if (selectedIndex !== null) {
      selectedIndex = event.detail.index;
    }
    $pwaEmailForResults = privates[selectedIndex!].email;
    $pwaNameForResults = privates[selectedIndex!].name;
  };

  // log-out ------------------

  const doLogOut = () => {
    auth.signOut().then(function () {
      $loggedIn = false;
      $currentRoute = Pages.Login;
    });
  };

  const logOutClicked = () => {
    showYesNoModal = true;
  };

  // LIFE CYCLE --------------------------------------------------

  onMount(() => {
    showWaitModal = true;
    getRealtimeUpdates();
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="heading">
    <h1>Private brugere - indstillinger og resultater</h1>
  </div>

  <ListBox items={listItems} bind:selectedIndex on:clicked={listItemSelected} />

  <div class="bottom-area">
    {#if $user.role === Role.Admin || $user.role === Role.Super}
      <span class="button-spacing">
        <IconButton
          on:click={backButtonClicked}
          textMediumScaled={true}
          icon={Icons.ArrowLeft}
        >
          Tilbage
        </IconButton>
      </span>
    {/if}
    <span class="button-spacing">
      <IconButton
        on:click={newPWAButtonClicked}
        textMediumScaled={true}
        icon={Icons.Plus}
      >
        Ny
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndex === null}
        on:click={editPWAButtonClicked}
        textMediumScaled={true}
        icon={Icons.CogLight}
      >
        Indstillinger
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndex === null}
        on:click={deletePWAButtonClicked}
        textMediumScaled={true}
        icon={Icons.TrashAltRegular}
      >
        Slet
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndex === null}
        on:click={resultsButtonClicked}
        textMediumScaled={true}
        icon={Icons.FileAltRegular}
      >
        Resultater
      </IconButton>
    </span>
    {#if $user.role === Role.SLT}
      <span class="button-spacing log-out-button">
        <IconButton
          textMediumScaled={true}
          icon={Icons.SignOutAlt}
          on:click={logOutClicked}
        >
          Log ud
        </IconButton>
      </span>
    {/if}
  </div>
</main>

{#if showNewUserModal}
  <NewUserModal
    userType={Role.Private}
    on:newUserDialogCanceled={() => (showNewUserModal = false)}
    on:newUserCreated={newUserWasCreated}
  />
{/if}

{#if showDeleteUserModal}
  <DeleteUserModal
    userType={Role.Private}
    email={emailForDelete}
    name={nameForDelete}
    on:deleteUserDialogCanceled={() => (showDeleteUserModal = false)}
    on:userDeleted={userWasDeleted}
  />
{/if}

{#if showWaitModal}
  <WaitModal />
{/if}

{#if showYesNoModal}
  <YesNoModal on:no={() => (showYesNoModal = false)} on:yes={doLogOut}
    >Vil du logge ud?</YesNoModal
  >
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    padding: 1vmin;
  }
  h1 {
    font-size: 3vmin;
  }
  .heading {
    margin-bottom: 1vmin;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 2px solid var(--key-hover-border-color);
    margin-top: 1vmin;
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-right: 1vmin;
  }
  .log-out-button {
    margin-left: auto;
  }
</style>
