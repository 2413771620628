<script lang="ts">
  // show result details
  // use modals for new, edit and delete
  // new page for results

  import { onMount } from "svelte";
  import Chart from "chart.js/auto";
  // import * as Sentry from "@sentry/svelte";

  import { Pages, Level } from "../enums";
  import type { Result } from "../types";

  import { Icons } from "../utilities/icons";

  import { currentRoute } from "../stores/route-store";
  import { pwaNameForResults } from "../stores/user-store";
  import { savedResults, savedSettings } from "../stores/saved-result-store";

  import IconButton from "../components/IconButton.svelte";
  // import { withSentryConfig } from "@sentry/svelte";

  // CONSTANTS -------------------------------------------------------

  // GRAPH DEFS ------------------------------------------------------

  // STATE -----------------------------------------------------------

  let graphCanvas;
  let validResults: Array<Result> = [];
  let comprehensionPerformanceIndexes: Array<number> = [];
  let readingPerformanceIndexes: Array<number> = [];
  let spellingPerformanceIndexes: Array<number> = [];
  let namingSatisfactionIndexes: Array<number> = [];
  let dateLabels: Array<string> = [];

  // FUNCTIONS -------------------------------------------------------

  const namingSatisfactionIndex = (savedResult: Result): number => {
    let divider = 4;
    switch (savedResult.currentLevel) {
      case Level.First:
      case Level.Second:
        divider = 4;
        break;
      case Level.SecondVerb:
        divider = 8;
        break;
      case Level.SecondSubject:
        divider = 12;
        break;
      default:
        // Sentry.captureMessage(
        //   "ResultsLineChart, switch error, namingSatisfactionIndex(), savedResult].currentLevel: " +
        //     savedResult.currentLevel
        // );
        console.log(
          "switch error, namingSatisfactionIndex(), savedResult].currentLevel: ",
          savedResult.currentLevel,
        );
    }
    return Math.round((savedResult.namingCorrectFirstTry / divider) * 100);
  };

  // GRAPH DEFS ------------------------------------------------------

  let data = {
    labels: dateLabels,
    datasets: [
      {
        label: "forstå",
        data: comprehensionPerformanceIndexes,
        borderColor: "green",
        backgroundColor: "green",

        borderDash: [10, 10],
      },
      {
        label: "læse",
        data: readingPerformanceIndexes,
        borderColor: "blue",
        backgroundColor: "blue",
        borderDash: [5, 5],
      },
      {
        label: "stave",
        data: spellingPerformanceIndexes,
        borderColor: "red",
        backgroundColor: "red",
        borderDash: [15, 15],
      },
      {
        label: "tale",
        data: namingSatisfactionIndexes,
        borderColor: "gold",
        backgroundColor: "gold",
      },
    ],
  };

  let config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            minRotation: 75,
            maxRotation: 75,
            font: {
              size: 15,
            },
          },
        },
        y: {
          min: 0,
          max: 100,

          ticks: {
            font: {
              size: 15,
            },
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            font: {
              size: 20,
            },
          },
        },
      },
    },
  };

  // button clicks ---

  const backButtonClicked = () => {
    $currentRoute = Pages.ResultList;
  };

  onMount(() => {
    // 12.02.2024: now there may be either 1 or 3 spelling steps
    const numberOfSpellingTasks: Array<number> = [];
    for (let i = 0; i < $savedSettings.length; i++) {
      numberOfSpellingTasks.push($savedSettings[i].simpleSpelling ? 4 : 12);
    }

    // exclude aborted trainings + make data arrays + make date-labels
    let resultNumber = 0;
    $savedResults.forEach((result) => {
      if (result.lastStepCompleted > 8 && !result.wasInterrupted) {
        validResults.push(result);
        comprehensionPerformanceIndexes.push(
          Math.round((result.comprehensionCorrectFirstTry / 7) * 100),
        );
        readingPerformanceIndexes.push(
          Math.round((result.readingCorrectFirstTry / 8) * 100),
        );
        spellingPerformanceIndexes.push(
          Math.round(
            (result.spellingCorrectFirstTry /
              numberOfSpellingTasks[resultNumber]) *
              100,
          ),
        );
        namingSatisfactionIndexes.push(namingSatisfactionIndex(result));
        dateLabels.push(
          new Intl.DateTimeFormat("da-DK", {
            dateStyle: "short",
          })
            .format(result.startTime.toDate())
            .substring(0, 5),
        );
      }
      resultNumber++;
    });
    // console.log(data);
    const ctx = graphCanvas.getContext("2d");
    var myChart = new Chart(ctx, config);
  });
</script>

<!-- HTML ============================================= -->

<main>
  <div class="heading">
    <h1>Resultater (præstation): {$pwaNameForResults}</h1>
  </div>

  <div class="chart-container">
    <canvas bind:this={graphCanvas} id="canvas" />
  </div>

  <div class="bottom-area">
    <span class="button-spacing">
      <IconButton
        on:click={backButtonClicked}
        textMediumScaled={true}
        icon={Icons.ArrowLeft}
      >
        Tilbage
      </IconButton>
    </span>
  </div>
</main>

<!-- CSS ============================================================= -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
  h1 {
    font-size: 4vmin;
  }
  .heading {
    padding: 1vmin;
    border-bottom: 1px solid darkgreen;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 1vmin;
    padding-bottom: 1vmin;
    border-top: 2px solid var(--key-hover-border-color);
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-left: 1vmin;
  }
  #canvas {
    margin-left: 2.5%;
    width: 95%;
    height: 98%;
  }
  .chart-container {
    position: relative;
    height: calc(var(--vh, 1vh) * 73);
    width: 96vw;
  }
</style>
