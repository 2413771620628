// (c) Cognisoft Aps, 2022 

// sound play using a single audio-tag for entire app in App.svelte
// import * as Sentry from "@sentry/svelte";

import type { CallbackFunction } from '../types';
import { Path, Extension } from '../enums';

// play sound from snd folder; optional call-back for ended sound
export const playSoundWithAudioTag = (audioTag: HTMLAudioElement, theSound: string, audioEndedCallback: CallbackFunction = null): void => {
  if (!audioTag.paused || audioTag.currentTime > 0) {
    audioTag.pause();
    audioTag.currentTime = 0;
    audioTag.onended = null;
  }
  audioTag.src = Path.Snd + theSound + Extension.Mp3;
  audioTag.onended = audioEndedCallback;
  audioTag.volume = 1.0;
  audioTag.onerror = () => {
    // Sentry.captureMessage("error playing sound: " + theSound)
    console.log("error playing sound:", theSound);
    if (audioEndedCallback) {
      audioEndedCallback();
      return;
    }
  }
  audioTag.load();
  let playPromise = audioTag.play();
  playPromise.then(function () {
    // playback started!
  }).catch(function (error) {
    // Sentry.captureException(error);
    console.log('error playing sound:', error, " sound:", theSound);
    if (audioEndedCallback) {
      audioEndedCallback();
    }
  });
}

// stop playing sound
// export const stopSoundWithAudioTag = (audioTag: HTMLAudioElement): void => {
//   // if (!audioTag.paused || audioTag.currentTime > 0) {
//   audioTag.pause();
//   audioTag.currentTime = 0;
//   audioTag.onended = null;
//   audioTag.src = null;
//   // }
// }

// play silent sound - supposed to solve iPad problem - but did NOT !
export const playSilentWithAudioTag = (audioTag: HTMLAudioElement): void => {
  audioTag.src = "silent.mp3";
  audioTag.load();
  audioTag.play();
}