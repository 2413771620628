<script lang="ts">
  // (c) Cognisoft Aps, 2022
  // Passiv start to dialog phase.
  // Puts written sentences on matching pictures
  // sentences shown above 4 pictures
  // moves down to pictures - and sentence read aloud
  // finger pointing while sentence is read aloud
  // sentence is divided and object hidden
  // repeated for the four sentences

  // OBS: pause button here is not to pause help, but to pause what is show automatically

  import { fly } from "svelte/transition";
  import { linear } from "svelte/easing";

  import {
    Pages,
    Path,
    Extension,
    LevelExtensionProgress,
    DaDK,
  } from "../enums";

  import { firstWaitSecs } from "../constants";

  import { Icons } from "../utilities/icons";
  import { shuffleArray } from "../utilities/shuffleArray";
  import { playSoundWithAudioTag } from "../utilities/playSound";
  import { getTotalNumberOfTasks } from "../utilities/getTotalNumberOfTasks";

  import { targetVerbAndObjects } from "../stores/targets-store";
  import { currentRoute } from "../stores/route-store";
  import { progress } from "../stores/progress-store";
  import { result } from "../stores/result-store";
  import { studentUserData } from "../stores/user-store";
  import { levelExtensionProgress } from "../stores/level-extension-progress-store";

  import ExitModal from "../modals/ExitModal.svelte";

  import RoundButton from "../components/RoundButton.svelte";
  import ProgressBar from "../components/ProgressBar.svelte";
  import PauseMessage from "../components/PauseMessage.svelte";

  // PROPS --------------------------------------------------

  // one audiotag in App.svelte is used on all pages in order to have
  //   sound played even before mouse click
  export let audioTag: HTMLAudioElement;

  // TYPES --------------------------------------------------

  enum State {
    Introduction = "introduction",
    IsPointing = "isPointing",
    Paused = "paused",
    ExitModalShown = "exitModalShown",
  }

  // CONSTANTS --------------------------------------------

  const fingerPointDelaySecs = 800;
  const nextStepDelaySecs = 500;

  // STATE ------------------------------------------------

  let state = State.Introduction;
  let oldState = State.Introduction;

  let pointFingerStage = 0; // 0-3 one pointFingerStage for each picture finger pointed to
  let targets = [0, 1, 2, 3]; // is randomized at init

  // arrays for send and received sentences
  //  needed for animation to work
  // let sendItems: Array<string> = [$targetVerbAndObjects[targets[moveTextStage]].sentence];

  let fingerPointing = [false, false, false, false];
  let textTornApart = [false, false, false, false]; // for 2.2 not torn
  let objectHidden = [false, false, false, false]; // for 2.2 not hidden

  let waitToGotoNext = false;

  // keep track of pause time
  let pauseStartTime: number;
  let pauseEndTime: number;

  // FUNCTIONS --------------------------------------------

  // pause before sentence is played
  const waitAndStart = (): void => {
    playIntro();
  };

  const playIntro = (): void => {
    if ($levelExtensionProgress === LevelExtensionProgress.Object) {
      waitAfterIntro();
    } else if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
      playSoundWithAudioTag(audioTag, DaDK.Step8IntroVerb, waitAfterIntro);
    } else {
      playSoundWithAudioTag(audioTag, DaDK.Step8IntroSubject, waitAfterIntro);
    }
  };

  const waitAfterIntro = (): void => {
    setTimeout(() => {
      state = State.IsPointing;
      pointWithFinger();
    }, firstWaitSecs);
  };

  const pointWithFinger = (): void => {
    if (state !== State.IsPointing) {
      return;
    }
    // point
    fingerPointing = [false, false, false, false];
    fingerPointing[pointFingerStage] = true;
    fingerPointing = fingerPointing;
    if ($levelExtensionProgress === LevelExtensionProgress.Subject) {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[pointFingerStage].sentenceFull,
        null,
      );
    } else {
      playSoundWithAudioTag(
        audioTag,
        $targetVerbAndObjects[pointFingerStage].sentenceOK,
        null,
      );
    }
    setTimeout(() => {
      cutSentence();
    }, fingerPointDelaySecs);
  };

  const cutSentence = (): void => {
    if (state !== State.IsPointing) {
      return;
    }
    textTornApart[pointFingerStage] = true;
    setTimeout(
      () => {
        hideObject();
      },
      $levelExtensionProgress === LevelExtensionProgress.Subject
        ? nextStepDelaySecs * 3
        : nextStepDelaySecs,
    );
  };

  const hideObject = (): void => {
    if (state !== State.IsPointing) {
      return;
    }
    objectHidden[pointFingerStage] = true;
    pointNextOrEnd();
  };

  const pointNextOrEnd = (): void => {
    if (state !== State.IsPointing) {
      return;
    }

    $progress = $progress + 1;
    pointFingerStage++;
    if (pointFingerStage > 3) {
      waitToGotoNext = true;
    }
    setTimeout(() => {
      if (pointFingerStage > 3) {
        $result.lastStepCompleted = 8;
        $currentRoute = Pages.Step9;
      } else {
        pointWithFinger();
      }
    }, firstWaitSecs);
  };

  // pause --------

  const pauseClicked = (): void => {
    if (state === State.Paused) {
      pauseEndTime = Date.now();
      $result.pauseElapsed =
        $result.pauseElapsed + Math.abs(pauseEndTime - pauseStartTime);
      state = oldState;
      pointFingerStage = 0;
      fingerPointing = [false, false, false, false];
      textTornApart = [false, false, false, false];
      objectHidden = [false, false, false, false];
      pointWithFinger();
    } else {
      pauseStartTime = Date.now();
      oldState = state;
      state = State.Paused;
      fingerPointing = [false, false, false, false];
    }
  };

  // exit task prematurely after stop button clicked

  // stop button: show yes-no modal
  const exitButtonClicked = () => {
    oldState = state;
    state = State.ExitModalShown;
    fingerPointing = [false, false, false, false];
  };

  // exit task (modal event "yes")
  const exitTask = () => {
    $result.wasInterrupted = true;
    $currentRoute = Pages.EndResults;
  };

  // dismiss exit modal (event "no")
  const dismissExitModal = () => {
    state = oldState;
    pointFingerStage = 0;
    fingerPointing = [false, false, false, false];
    textTornApart = [false, false, false, false];
    if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
      objectHidden = [true, true, true, true];
    } else {
      objectHidden = [false, false, false, false];
    }
    pointWithFinger();
  };

  // INITIALIZE --------------------------------------------

  // randomize order pictures are shown in
  targets = shuffleArray(targets);
  if ($levelExtensionProgress === LevelExtensionProgress.Verb) {
    objectHidden = [true, true, true, true];
  }
  waitAndStart();
</script>

<!-- HTML ===================================================== -->

<body class:completed-bg-color={waitToGotoNext}>
  <main>
    <!-- top panel with button for help and space for text to fly in -->
    <div class="top-panel">
      <div>
        <div>
          <span class="stop-button">
            <RoundButton
              on:click={exitButtonClicked}
              icon={Icons.TrafficLightRed}
            />
          </span>
          <RoundButton
            on:click={pauseClicked}
            icon={state === State.Paused ? Icons.Play : Icons.Pause}
          />
        </div>
      </div>
    </div>
    <!-- 4 clickable image buttons in 2x2 grid -->
    {#each $targetVerbAndObjects as target, i}
      <div class={"image img" + i}>
        <div class="button-text">
          <!-- #each even though only one at a time - in order to be svelte animated  -->
          <!-- TODO: for 2.2/Level.SecondSubject subject has to fly in -->

          <!-- Not extenstion or first time through one of the extenstions with object  -->
          {#if $levelExtensionProgress === LevelExtensionProgress.Object}
            {#if textTornApart[i]}
              <div class="first-tab">
                {$targetVerbAndObjects[i].verb}
              </div>
              <div class="second-tab" class:hide-object={objectHidden[i]}>
                {$targetVerbAndObjects[i].object}
              </div>
            {:else}
              {$targetVerbAndObjects[i].sentence}
            {/if}
          {:else if $levelExtensionProgress === LevelExtensionProgress.Verb}
            <!-- Second and third time through with extension  -->
            <!-- Extensiont: Verb -->
            {#if textTornApart[i]}
              <div class="first-tab" class:hide-object={objectHidden[i]}>
                {$targetVerbAndObjects[i].verb}
              </div>
              <div class="second-tab">
                {$targetVerbAndObjects[i].object}
              </div>
            {:else}
              {$targetVerbAndObjects[i].sentence}
            {/if}
          {:else}
            <!-- Extenstion: Subject -->
            {#if textTornApart[i]}
              <!-- TODO: subject fly in -->
              <div
                class="first-tab name-caps short-first-tab"
                transition:fly|global={{
                  duration: 700,
                  easing: linear,
                  y: 700,
                }}
              >
                {$targetVerbAndObjects[i].subjectName}
              </div>
              <div
                class="second-tab undotted long-second-tab"
                class:hide-object={objectHidden[i]}
              >
                {$targetVerbAndObjects[i].sentence}
              </div>
            {:else}
              <!-- {$targetVerbAndObjects[i].sentence} -->
            {/if}
          {/if}
        </div>
        <picture>
          <source
            type="image/webp"
            srcset={encodeURI(Path.Img + target.image + Extension.Webp)}
            draggable={false}
          />
          <img
            style="background-image: url('{Path.ImgBg}{target.image}{Extension.ImgBg}'); 
            background-image: image-set('{Path.ImgBg}{target.image}{Extension.WebpBg}'); 
            background-size: cover;"
            draggable={false}
            src={Path.Img + target.image + Extension.Png}
            alt=""
          />
        </picture>
      </div>
      <div
        class={"finger-img finger-img-" + i}
        class:finger-point={fingerPointing[i]}
      >
        {#if (state = State.IsPointing)}
          <img draggable={false} src={"finger-2.png"} alt="" />
        {/if}
      </div>
    {/each}
    <div class="progress">
      <ProgressBar
        max={getTotalNumberOfTasks($studentUserData.currentLevel)}
        position={$progress}
      />
    </div>
  </main>
</body>

{#if state === State.Paused}
  <PauseMessage on:click={pauseClicked} />
{/if}

{#if state === State.ExitModalShown}
  <ExitModal on:yes={exitTask} on:no={dismissExitModal} />
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    margin-left: auto;
    margin-right: auto;
    width: 120vh;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 14% 41% 41% 2%;
    grid-template-areas:
      "top top"
      "img1 img2"
      "img3 img4"
      "prog prog";
    gap: 2%;
    padding: 3%;
    padding-top: 1%;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    main {
      width: 100vw;
    }
  }
  .top-panel {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .present-area {
    display: flex;
    flex-grow: 3;
    justify-content: center;
    align-items: center;
  }
  .image {
    border: solid 8px var(--button-border-disabled);
    background-color: white;
    padding: 0;
  }
  .img0 {
    grid-area: img1;
  }
  .img1 {
    grid-area: img2;
  }
  .img2 {
    grid-area: img3;
  }
  .img3 {
    grid-area: img4;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  img {
    width: 100%;
    height: 85%;
    object-fit: contain;
    object-position: bottom;
  }
  .sentence {
    border: solid 1px black;
    background: white;
    height: 8vh;
    width: 45vw;
    font-size: 6vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .wide-sentence {
    width: 65vw;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  .button-text {
    border: solid 1px black;
    background: white;
    width: 100%;
    height: 15%;
    color: black;
    font-size: 3.5vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .first-tab {
    display: flex;
    height: 100%;
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5vmin;
  }
  .short-first-tab {
    width: 40%;
  }
  .second-tab {
    display: flex;
    height: 100%;
    width: 50%;
    border: none;
    border-left: 2px dotted black;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.5vmin;
  }
  .long-second-tab {
    width: 60%;
  }
  .undotted {
    border-left: none;
  }
  .hide-object {
    transform: rotateX(-180deg);
    color: lightGray;
    background: lightGray;
    transition:
      transform 0.5s,
      background-color 0.5s,
      color 0.1s;
    transform-style: preserve-3d;
  }
  .hide-subject {
    visibility: hidden;
  }
  .finger-img {
    z-index: 2;
    visibility: hidden;
    pointer-events: none;
    transform: translate(10vw, 5vh);
  }
  .finger-img-0 {
    grid-area: img1;
  }
  .finger-img-1 {
    grid-area: img2;
  }
  .finger-img-2 {
    grid-area: img3;
  }
  .finger-img-3 {
    grid-area: img4;
  }
  /* finger pointing help with animated finger sliding up */
  .finger-point {
    visibility: visible;
    animation-duration: 2s;
    animation-name: slideup;
  }
  .name-caps {
    text-transform: capitalize;
  }
  @keyframes slideup {
    from {
      transform: translate(10vw, 50vh);
    }
    to {
      transform: translate(10vw, 5vh);
    }
  }
</style>
