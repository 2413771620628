<script lang="ts">
  import { icons } from "../utilities/icons";
  import YesNoModal from "../modals/YesNoModal.svelte";
</script>

<!-- HTML ===================================================== -->

<YesNoModal on:yes on:no>
  <svg class="svg" viewBox={icons.trafficLightRed.viewBox}>
    {@html icons.trafficLightRed.path}
  </svg>
  {@html "&nbsp;Vil du afbryde træningen?"}
</YesNoModal>

<!-- CSS ===================================================== -->
<style>
  .svg {
    height: 1.2em;
    width: 1.2em;
    align-self: center;
    fill: currentColor;
  }
</style>
