<script lang="ts">
  // show list of SLT's
  // use modals for new, edit and delete
  // new page for results

  import { db } from "../firebase";
  import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
  } from "@firebase/firestore";
  import type { Unsubscribe } from "@firebase/firestore";

  import { onMount, onDestroy } from "svelte";

  import { DocType, Role, Pages } from "../enums";
  import type { User, EditUserEmail } from "../types";

  import { Icons } from "../utilities/icons";

  import { currentRoute } from "../stores/route-store";
  import { user } from "../stores/user-store";

  import IconButton from "../components/IconButton.svelte";
  import ListBox from "../components/ListBox.svelte";

  import NewUserModal from "../modals/NewUserModal.svelte";
  import DeleteUserModal from "../modals/DeleteUserModal.svelte";
  import EditSLTModal from "../modals/EditSLTModal.svelte";
  import WaitModal from "../modals/WaitModal.svelte";

  // STATE -----------------------------------------------------------

  let unsubscribe: Unsubscribe;
  let slts: Array<User & EditUserEmail> = [];

  let showNewUserModal = false;
  let showDeleteUserModal = false;
  let showEditSLTModal = false;
  let showWaitModal = false;

  // list of users to display, construct from firestore database
  let listItems: Array<string> = [];
  // selected in list
  let selectedIndex: number | null;

  // variables for delete dialog
  let editEmail: string;
  let editName: string;
  let editActive: boolean;

  // FUNCTIONS -------------------------------------------------------

  // database operations ---

  const getRealtimeUpdates = () => {
    const collectionRef = collection(db, DocType.Users);
    const collectionQuery = query(
      collectionRef,
      where("centerUid", "==", $user.centerUid),
      where("role", "==", Role.SLT),
      orderBy("name"),
    );
    unsubscribe = onSnapshot(collectionQuery, (querySnapshot) => {
      slts = [];
      listItems = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        slts.push({
          email: doc.id,
          uid: data.uid,
          name: data.name,
          centerUid: data.centerUid,
          role: data.role,
          active: data.active,
        });
        const active = data.active ? "aktiv" : "deaktiveret";
        listItems.push(data.name + " - " + active);
      });
      showWaitModal = false;
    });
  };

  // button clicks ---

  const backButtonClicked = () => {
    $currentRoute = Pages.AdminStart;
  };

  const newSLTButtonClicked = () => {
    showNewUserModal = true;
  };

  const editSLTButtonClicked = () => {
    editEmail = slts[selectedIndex!].email;
    editName = slts[selectedIndex!].name;
    editActive = slts[selectedIndex!].active;
    showEditSLTModal = true;
  };

  const deleteSLTButtonClicked = () => {
    editEmail = slts[selectedIndex!].email;
    editName = slts[selectedIndex!].name;
    showDeleteUserModal = true;
  };

  const newUserWasCreated = () => {
    selectedIndex = null;
    showNewUserModal = false;
  };

  const userWasDeleted = () => {
    selectedIndex = null;
    showDeleteUserModal = false;
  };

  // item list functions ---

  const listItemSelected = (event: CustomEvent): void => {
    if (selectedIndex !== null) {
      selectedIndex = event.detail.index;
    }
  };

  // LIFE CYCLE --------------------------------------------------

  onMount(() => {
    showWaitModal = true;
    getRealtimeUpdates();
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="heading">
    <h1>Rediger logopæder</h1>
  </div>

  <ListBox
    bind:items={listItems}
    bind:selectedIndex
    on:clicked={listItemSelected}
  />

  <div class="bottom-area">
    <span class="left-button">
      <IconButton
        on:click={backButtonClicked}
        textMediumScaled={true}
        icon={Icons.ArrowLeft}
      >
        Tilbage
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        on:click={newSLTButtonClicked}
        textMediumScaled={true}
        icon={Icons.Plus}
      >
        Ny
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndex === null}
        on:click={editSLTButtonClicked}
        textMediumScaled={true}
        icon={Icons.EditRegular}
      >
        Rediger
      </IconButton>
    </span>
    <span class="button-spacing">
      <IconButton
        disabled={selectedIndex === null}
        on:click={deleteSLTButtonClicked}
        textMediumScaled={true}
        icon={Icons.TrashAltRegular}
      >
        Slet
      </IconButton>
    </span>
  </div>
</main>

{#if showNewUserModal}
  <NewUserModal
    userType={Role.SLT}
    on:newUserDialogCanceled={() => (showNewUserModal = false)}
    on:newUserCreated={newUserWasCreated}
  />
{/if}

{#if showDeleteUserModal}
  <DeleteUserModal
    userType={Role.SLT}
    email={editEmail}
    name={editName}
    on:deleteUserDialogCanceled={() => (showDeleteUserModal = false)}
    on:userDeleted={userWasDeleted}
  />
{/if}

{#if showEditSLTModal}
  <EditSLTModal
    email={editEmail}
    name={editName}
    active={editActive}
    on:editUserDialogCanceled={() => (showEditSLTModal = false)}
    on:userEdited={() => (showEditSLTModal = false)}
  />
{/if}

{#if showWaitModal}
  <WaitModal />
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    padding: 1vmin;
  }
  h1 {
    font-size: 3vmin;
  }
  .heading {
    padding: 1vmin;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 2px solid var(--key-hover-border-color);
    margin-top: 1vmin;
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-left: 1vmin;
  }
  .left-button {
    padding-top: 1vmin;
  }
</style>
