// https://medium.com/@peterkracik/firebase-timestamp-to-javascript-date-format-876a42978c10
// https://www.npmjs.com/package/convert-firebase-timestamp

// removed logic for arrays

import type { Timestamp } from "firebase/firestore";

export const convertFirebaseDate = (firebaseObject: any) => {
  if (!firebaseObject) return null;

  for (const [key, value] of Object.entries(firebaseObject)) {

    // covert items inside array
    // if (value && Array.isArray(value) )
    //   firebaseObject[key] = value.map(item => self.convertFirebasedate(item));

    // convert inner objects
    // if (value && typeof value === 'object' ){
    //   firebaseObject[key] = self.convertate(value);
    // }

    // convert simple properties
    if (value && value.hasOwnProperty('seconds'))
      firebaseObject[key] = (value as Timestamp).toDate();
  }
  return firebaseObject;
}