<script lang="ts">
  // administrator start
  // buttons lead to:
  // 1) slt admin
  // 2) pwa admin (may further go to pwa results)
  // 3) own training session
  // 4) own results

  import { auth } from "../firebase";

  import { Pages, Role } from "../enums";

  import { currentRoute } from "../stores/route-store";
  import { loggedIn, user, center } from "../stores/user-store";

  import { Icons } from "../utilities/icons";

  import YesNoModal from "../modals/YesNoModal.svelte";

  import IconButton from "../components/IconButton.svelte";

  // STATE -------------------------------------------------

  let showYesNoModal = false;

  // FUNCTIONS ---------------------------------------------

  const editCenterClicked = () => {
    $currentRoute = Pages.CenterEdit;
  };

  const editPrivateClicked = () => {
    $currentRoute = Pages.PrivateEdit;
  };

  const editSLTClicked = () => {
    $currentRoute = Pages.SLTEdit;
  };

  const editPWAClicked = () => {
    $currentRoute = Pages.PWAEdit;
  };

  const doLogOut = () => {
    auth.signOut().then(function () {
      $loggedIn = false;
      $currentRoute = Pages.Login;
    });
  };

  const logOutClicked = () => {
    showYesNoModal = true;
  };
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="headline">
    <h1>MODAK - Administrator</h1>
    <h2>{$user.name} - {$center.name}</h2>
  </div>
  {#if $user.role === Role.Super}
    <IconButton
      fat={true}
      mediumWide={true}
      textLargeScaled={true}
      icon={Icons.EditRegular}
      on:click={editCenterClicked}
    >
      Centre
    </IconButton>
    <IconButton
      fat={true}
      mediumWide={true}
      textLargeScaled={true}
      icon={Icons.EditRegular}
      on:click={editPrivateClicked}
    >
      Private
    </IconButton>
  {/if}
  <IconButton
    fat={true}
    mediumWide={true}
    textLargeScaled={true}
    icon={Icons.EditRegular}
    on:click={editSLTClicked}
  >
    Logopæder
  </IconButton>

  <IconButton
    fat={true}
    mediumWide={true}
    textLargeScaled={true}
    icon={Icons.EditRegular}
    on:click={editPWAClicked}
  >
    Borgere
  </IconButton>

  <IconButton
    textMediumScaled={true}
    icon={Icons.SignOutAlt}
    on:click={logOutClicked}
  >
    Log ud
  </IconButton>
</main>

{#if showYesNoModal}
  <YesNoModal on:no={() => (showYesNoModal = false)} on:yes={doLogOut}
    >Vil du logge ud?</YesNoModal
  >
{/if}

<!-- CSS ====================================================== -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 4vmin;
  }
  h1 {
    font-size: 6vmin;
    font-weight: bold;
    padding-bottom: 1vmin;
  }
  h2 {
    font-size: 3vmin;
    font-weight: bold;
  }
  .headline {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
</style>
