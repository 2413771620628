<script lang="ts">
	// (c) Cognisoft Aps, 2022
	// base component for entire single page app
	// - routing to pages - not reflected on url address line
	// - so no direct acces for user to pages via url

	import { Pages } from "./enums";

	import { currentRoute } from "./stores/route-store";
	import { isOnline } from "./stores/online-status-store";

	import OfflineModal from "./modals/OfflineModal.svelte";

	import Login from "./pages/Login.svelte";
	import AdminStart from "./pages/AdminStart.svelte";
	import PrivateEdit from "./pages/PrivateEdit.svelte";
	import CenterEdit from "./pages/CenterEdit.svelte";
	import SLTEdit from "./pages/SLTEdit.svelte";
	import PWAEdit from "./pages/PWAEdit.svelte";
	import ResultList from "./pages/ResultList.svelte";
	import ResultDetails from "./pages/ResultDetails.svelte";
	import ResultCsvData from "./pages/ResultCsvData.svelte";
	import Settings from "./pages/Settings.svelte";
	import Ready from "./pages/Ready.svelte";
	import Step1 from "./pages/Step1.svelte";
	import Step2 from "./pages/Step2.svelte";
	import Step3 from "./pages/Step3.svelte";
	import Step4 from "./pages/Step4.svelte";
	import Step5 from "./pages/Step5.svelte";
	import Step6 from "./pages/Step6.svelte";
	import Step7 from "./pages/Step7.svelte";
	import Step8 from "./pages/Step8.svelte";
	import Step9 from "./pages/Step9.svelte";
	import EndResults from "./pages/EndResults.svelte";
	import RoutingError from "./pages/RoutingError.svelte";
	import ResultsLineChart from "./pages/ResultsLineChart.svelte";

	// sentry for error reporting
	// *** currently disabled
	// import * as Sentry from "@sentry/svelte";

	// Sentry.init({
	// 	dsn: "https://ba343593c15f4a9cb2aaf830a337d4c8@o4505273499648000.ingest.sentry.io/4505274175324160",
	// });

	// STATE ---------------------------------------------

	// reference to audio tag in this base component to be used throughout app
	//  in order to have sound played even before mouse click
	let audioTag: HTMLAudioElement;

	// PWA - serviceworker

	if (navigator.serviceWorker) {
		navigator.serviceWorker.register("../serviceworker.js");
	}

	if (navigator.storage && navigator.storage.persist) {
		navigator.storage.persist();
	}

	// test for on/off-line
	$isOnline = navigator.onLine;

	// WINDOW SIZE FOR MOBILE BROWSERS HACK --------------

	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	// We listen to the resize event
	window.addEventListener("resize", () => {
		// We execute the same script as before
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	});

	/* use like this:
	.my-element {
  	height: calc(var(--vh, 1vh) * 100);
	*/

	// next 3 events to prevent pinch zoom on iPad:
	document.addEventListener("gesturestart", function (e) {
		e.preventDefault();
		// special hack to prevent zoom-to-tabs gesture in safari
	});

	document.addEventListener("gesturechange", function (e) {
		e.preventDefault();
		// special hack to prevent zoom-to-tabs gesture in safari
	});

	document.addEventListener("gestureend", function (e) {
		e.preventDefault();
		// special hack to prevent zoom-to-tabs gesture in safari
	});

	// prevent zoom on double tap on iPad:
	let lastTouchEnd = 0;
	const disableDoubletapZoom = (e: TouchEvent) => {
		let now = new Date().getTime();
		if (now - lastTouchEnd <= 300) {
			e.preventDefault();
		}
		lastTouchEnd = now;
	};

	// Goin on- or off-line

	const handleOffLine = () => {
		$isOnline = false;
	};

	const handleOnLine = () => {
		$isOnline = true;
	};

	// Warn user not to leave page
	function askBeforeUnload(event: BeforeUnloadEvent): string {
		event.returnValue = "Du er ved at forlade MODAK";
		return "Du er ved at forlade MODAK";
	}
</script>

<svelte:window
	on:beforeunload|preventDefault={askBeforeUnload}
	on:touchend={disableDoubletapZoom}
	on:offline={handleOffLine}
	on:online={handleOnLine}
/>

<main>
	<audio bind:this={audioTag} />

	<!-- internal SPA routing - NOT reflected on url adress line -->
	{#if $currentRoute === Pages.Login}
		<Login {audioTag} />
	{:else if $currentRoute === Pages.AdminStart}
		<AdminStart />
	{:else if $currentRoute === Pages.CenterEdit}
		<CenterEdit />
	{:else if $currentRoute === Pages.PrivateEdit}
		<PrivateEdit />
	{:else if $currentRoute === Pages.SLTEdit}
		<SLTEdit />
	{:else if $currentRoute === Pages.PWAEdit}
		<PWAEdit />
	{:else if $currentRoute === Pages.ResultList}
		<ResultList />
	{:else if $currentRoute === Pages.ResultDetails}
		<ResultDetails />
	{:else if $currentRoute === Pages.ResultsLineChart}
		<ResultsLineChart />
	{:else if $currentRoute === Pages.ResultCsvData}
		<ResultCsvData />
	{:else if $currentRoute === Pages.Ready}
		<Ready {audioTag} />
	{:else if $currentRoute === Pages.Step1}
		<Step1 {audioTag} />
	{:else if $currentRoute === Pages.Step2}
		<Step2 {audioTag} />
	{:else if $currentRoute === Pages.Step3}
		<Step3 {audioTag} />
	{:else if $currentRoute === Pages.Step4}
		<Step4 {audioTag} />
	{:else if $currentRoute === Pages.Step5}
		<Step5 {audioTag} />
	{:else if $currentRoute === Pages.Step6}
		<Step6 {audioTag} />
	{:else if $currentRoute === Pages.Step7}
		<Step7 {audioTag} />
	{:else if $currentRoute === Pages.Step8}
		<Step8 {audioTag} />
	{:else if $currentRoute === Pages.Step9}
		<Step9 {audioTag} />
	{:else if $currentRoute === Pages.EndResults}
		<EndResults />
	{:else if $currentRoute === Pages.Settings}
		<Settings />
	{:else}
		<RoutingError>currentRoute: {$currentRoute}</RoutingError>
	{/if}
</main>

{#if !$isOnline}
	<OfflineModal />
{/if}
