<script lang="ts">
  // Delete either PWA or SLT user.
  // User record in firestore database will be deleted.
  // This will also trigger cloud function to delete user from firestore auth.

  import { createEventDispatcher } from "svelte";
  import { db } from "../firebase";
  import { doc, deleteDoc } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { IconButtonType, ModalCss, DocType } from "../enums";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  // PROPS ------------------------------------------------

  export let selectedUids: Array<string> = [];

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();
  const confirmText = "SLET";

  // STATE ------------------------------------------------

  let confirmString = "";
  let isErasing = false;

  // FUNCTIONS --------------------------------------------

  // button clicks ---

  const deleteSingleResult = async (uid) => {
    const documentRef = doc(db, DocType.Results, uid);
    try {
      await deleteDoc(documentRef);
    } catch (err) {
      // Sentry.captureException(err);
      console.log("kunne ikke slette resultat: ", uid, " fejl: ", err);
    }
  };

  const deleteResults = async () => {
    isErasing = true;
    for (let i = 0; i < selectedUids.length; i++) {
      await deleteSingleResult(selectedUids[i]);
    }
    dispatch("resultsDeleted");
  };

  const cancel = () => {
    dispatch("deleteResultsDialogCanceled");
  };
</script>

<!-- HTML ===================================================== -->
<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={deleteResults}>
    <div class="headline">
      {#if selectedUids.length === 1}
        <h1>Vil du slette 1 resultat?</h1>
      {:else}
        <h1>Vil du slette {selectedUids.length} resultater?</h1>
      {/if}
    </div>
    <div class="content">
      <p>OBS: slettede resultater kan ikke genskabes.</p>
      <br />
      <p>Skriv: SLET</p>
      <br />
      <input type="text" placeholder="SLET" bind:value={confirmString} />
    </div>
    <div class="bottom-box">
      <IconButton
        disabled={isErasing}
        on:click={cancel}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
      <span class="ok-btn">
        <IconButton
          disabled={confirmString !== "SLET" || isErasing}
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          Slet
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  form {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 2vmin;
  }
  h1 {
    font-size: 4vmin;
  }
  input {
    font-size: 2.5vmin;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
</style>
