<script lang="ts">
  // show result details
  // use modals for new, edit and delete
  // new page for results

  import { onMount } from "svelte";
  import Chart from "chart.js/auto";
  // import * as Sentry from "@sentry/svelte";

  import { Level, Pages } from "../enums";

  import { Icons } from "../utilities/icons";
  import { makeTimeDiffStr } from "../utilities/makeTimeDiffStr";

  import { currentRoute } from "../stores/route-store";
  import { pwaEmailForResults, pwaNameForResults } from "../stores/user-store";
  import { savedResults, savedSettings } from "../stores/saved-result-store";

  import IconButton from "../components/IconButton.svelte";

  // CONSTANTS -------------------------------------------------------

  // GRAPH DEFS ------------------------------------------------------

  const namingSatisfactionIndex = () => {
    let divider = 4;
    switch ($savedResults[0].currentLevel) {
      case Level.First:
      case Level.Second:
        divider = 4;
        break;
      case Level.SecondVerb:
        divider = 8;
        break;
      case Level.SecondSubject:
        divider = 12;
        break;
      default:
        // Sentry.captureMessage(
        //   "switch error, namingSatisfactionIndex(), $savedResult[0].currentLevel: " +
        //     $savedResults[0].currentLevel
        // );
        console.log(
          "switch error, namingSatisfactionIndex(), $savedResult[0].currentLevel: ",
          $savedResults[0].currentLevel,
        );
    }
    return (($savedResults[0].namingCorrectFirstTry / divider) * 100).toFixed(
      0,
    );
  };

  const labels = ["forstå", "læse", "stave", "tale"];

  // 12.02.2024: now there may be either 1 or 3 spelling steps
  const numberOfSpellingTasks = $savedSettings[0].simpleSpelling ? 4 : 12;

  const data = {
    labels: labels,
    datasets: [
      {
        label: "præstation",
        data: [
          (($savedResults[0].comprehensionCorrectFirstTry / 7) * 100).toFixed(
            0,
          ),
          (($savedResults[0].readingCorrectFirstTry / 8) * 100).toFixed(0),
          (
            ($savedResults[0].spellingCorrectFirstTry / numberOfSpellingTasks) *
            100
          ).toFixed(0),
          namingSatisfactionIndex(),
        ],
        backgroundColor: ["green", "green", "green", "green", "green"],
        borderColor: ["black", "black", "black", "black", "black"],
        borderWidth: 1,
      },
      {
        label: "selvstændighed",
        data: [
          $savedResults[0].comprehensionIndependencyIndex.toFixed(0),
          $savedResults[0].readingIndependencyIndex.toFixed(0),
          $savedResults[0].spellingIndependencyIndex.toFixed(0),
          $savedResults[0].namingIndependencyIndex.toFixed(0),
        ],
        backgroundColor: ["yellow", "yellow", "yellow", "yellow", "yellow"],
        borderColor: ["black", "black", "black", "black", "black"],
        borderWidth: 1,
      },
    ],
  };

  const config = {
    type: "bar",
    data: data,
    options: {
      scales: {
        x: {
          ticks: {
            font: {
              size: 25,
            },
          },
        },
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            font: {
              size: 15,
            },
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            font: {
              size: 25,
            },
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    },
  };

  // STATE -----------------------------------------------------------

  let graphCanvas;
  let levelText = "";

  // FUNCTIONS -------------------------------------------------------

  // button clicks ---

  const backButtonClicked = () => {
    $currentRoute = Pages.ResultList;
  };

  const timeWorked = () => {
    return makeTimeDiffStr(
      $savedResults[0].startTime,
      $savedResults[0].endTime,
    );
  };

  onMount(() => {
    if ($savedResults[0].lastStepCompleted > 8) {
      const ctx = graphCanvas.getContext("2d");
      // Initialize chart using default config set
      var myChart = new Chart(ctx, config);
    }
    switch ($savedResults[0].currentLevel) {
      case Level.First:
        levelText = "Niveau 1";
        break;
      case Level.Second:
        levelText = "Niveau 2";
        break;
      case Level.SecondVerb:
        levelText = "Verbum udvidelse";
        break;
      case Level.SecondSubject:
        levelText = "Subjekt udvidelse";
        break;
      default:
        // Sentry.captureMessage(
        //   "ResultDetails, error in switch, $savedResult.currentLevel: " +
        //     $savedResults[0].currentLevel
        // );
        console.log(
          "ResultDetails, error in switch, $savedResult.currentLevel: ",
          $savedResults[0].currentLevel,
        );
    }
    if ($savedSettings[0].doIntroNextSession) {
      levelText = levelText + " - intro";
    } else if ($savedSettings[0].doTestNextSession) {
      levelText = levelText + " - basis-test";
    }
  });
</script>

<!-- HTML ============================================= -->

<main>
  <div class="heading">
    <h1>Resultater: {$pwaNameForResults}</h1>
  </div>

  <div class="result-details">
    <br />
    <p>
      {new Intl.DateTimeFormat("da-DK", {
        dateStyle: "short",
        timeStyle: "short",
      }).format($savedResults[0].startTime.toDate())} - Der blev arbejdet i {timeWorked()}
      (pause {Math.round($savedResults[0].pauseElapsed / 1000)} sek.) - {levelText}
    </p>
  </div>
  <div class="chart-container">
    {#if $savedResults[0].lastStepCompleted === 0}
      <p class="result-details">
        Træningen blev afbrud før nogen trin var gennemført.
      </p>
    {:else if $savedResults[0].lastStepCompleted < 9}
      <p class="result-details">
        Træningen blev afbrudt. Sidste gennemførte trin: {$savedResults[0]
          .lastStepCompleted}.
      </p>
    {:else}
      <canvas bind:this={graphCanvas} id="canvas" />
    {/if}
  </div>

  <div class="bottom-area">
    <span class="button-spacing">
      <IconButton
        on:click={backButtonClicked}
        textMediumScaled={true}
        icon={Icons.ArrowLeft}
      >
        Tilbage
      </IconButton>
    </span>
  </div>
</main>

<!-- CSS ============================================================= -->
<style>
  main {
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-rows: auto auto 1fr auto;
  }
  h1 {
    font-size: 4vmin;
  }
  .heading {
    padding: 1vmin;
    border-bottom: 1px solid darkgreen;
  }
  .result-details {
    padding: 2vmin;
    padding-top: 0;
    font-size: 3vmin;
    line-height: 3.5vmin;
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 1vmin;
    padding-bottom: 1vmin;
    border-top: 2px solid var(--key-hover-border-color);
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-left: 1vmin;
  }
  #canvas {
    margin-left: 2.5%;
    width: 95%;
    height: 98%;
  }
  .chart-container {
    position: relative;
    height: calc(var(--vh, 1vh) * 73);
    width: 96vw;
  }
</style>
