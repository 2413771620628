<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { KeyboardKey, IconButtonType, ModalCss } from "../enums";
  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  const dispatch = createEventDispatcher();

  const yesAnswer = (): void => {
    dispatch("yes");
  };

  const handleKeydown = (event: KeyboardEvent): void => {
    if (event.key === KeyboardKey.Enter) {
      yesAnswer();
    } else if (event.key === KeyboardKey.Escape) {
      dispatch("no");
    }
  };
</script>

<!-- HTML ===================================================== -->

<svelte:window on:keydown={handleKeydown} />
<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={yesAnswer}>
    <div class="content">
      <div class="text">
        <slot />
      </div>
    </div>
    <div class="bottom-box">
      <IconButton
        on:click={() => dispatch("no")}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Nej
      </IconButton>
      <span class="ok-btn">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
        >
          Ja
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ====================================================== -->
<style>
  .ok-btn {
    margin-left: auto;
  }
  .text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .content {
    font-size: 3.5vmin;
    padding: 3vmin 2vmin;
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
    padding: 2vmin;
  }
</style>
