// compute time worked bu subtracting start time from end time
//  first converting to seconds

export const makeTimeDiffStr = (startTime, endTime): string => {
  const distance = Math.abs(endTime.seconds - startTime.seconds);
  const minutes = Math.floor(distance / 60);
  const seconds = distance % 60;
  if (minutes > 0) {
    return minutes.toString() + " min. " + seconds.toString() + " sek.";
  } else {
    return seconds.toString() + " sek.";
  }
}