<script lang="ts">
  // (c) Cognisoft Aps, 2022
  // Login with firestore auth: only mail login, cannot be reset

  import { onMount } from "svelte";
  import { auth, db } from "../firebase";
  import { doc, getDoc } from "firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
  } from "firebase/auth";

  import type { UserCredential } from "firebase/auth";

  import { currentRoute } from "../stores/route-store";
  import {
    center,
    loggedIn,
    studentUserData,
    user,
    userEmail,
  } from "../stores/user-store";

  import { isOnline } from "../stores/online-status-store";

  import { IconButtonType, Pages, DocType, Role } from "../enums";
  import { versionNumber } from "../constants";

  import { Icons } from "../utilities/icons";
  import { playSilentWithAudioTag } from "../utilities/playSound";

  import IconButton from "../components/IconButton.svelte";

  import LoginMessageModal from "../modals/LoginMessageModal.svelte";
  import MessageModal from "../modals/MessageModal.svelte";

  // PROPS ---------------------------------------------------

  export let audioTag: HTMLAudioElement;

  // CONSTANTS -----------------------------------------------

  const emailOrPasswordNotFound = "Email eller password findes ikke";
  const emailNotFound = "Email findes ikke";
  const userNotActive = "Bruger er ikke aktiveret";
  const noCenterForUser = "Center ikke fundet for bruger";
  const switchError = "Programfejl, $user.role=";
  const noConnection = "Ingen internet-forbindelse";
  const emptyMessage = "&nbsp";

  // STATE ---------------------------------------------------

  let email = "";
  let password = "";
  let message = emptyMessage;
  let showPassword = false;
  let showLoginMessageModal = false;
  let showResetPasswordModal = false;
  let rememberMeOnThisDevice = false;
  let isLogginIn = false;

  // RESPONSIVE ----------------------------------------------

  $: email = email.toLocaleLowerCase("Da-DK");

  // FUNCTIONS -----------------------------------------------

  const getUserInfoAndGotoStartPage = async () => {
    // get user role
    const userDocRef = doc(db, DocType.Users, $userEmail);
    const userSnap = await getDoc(userDocRef);
    if (userSnap.exists()) {
      const userData = userSnap.data();
      if (!userData.active) {
        message = $isOnline ? userNotActive : noConnection;
        isLogginIn = false;
        return;
      }
      $user.name = userData.name;
      $user.role = userData.role.trim();
      $user.centerUid = userData.centerUid.trim();
      $user.active = userData.active;
      // if ($user.role === Role.PWA || $user.role === Role.Private) {
      //   $studentUserData.sessionsCompleted = userData.sessionsCompleted;
      //   $studentUserData.completedOnCurrentLevel =
      //     userData.completedOnCurrentLevel;
      //   $studentUserData.currentLevel = userData.currentLevel;
      //   // new for version 1.15: option to have only one spelling step
      //   if (!userData.settings.hasOwnProperty("simpleSpelling")) {
      //     userData.settings.simpleSpelling = false;
      //   }
      //   $studentUserData.settings = userData.settings;
      // }
      // get users center name and number of licenses
      const centerDocRef = doc(db, DocType.Centers, $user.centerUid);
      const centerSnap = await getDoc(centerDocRef);
      if (centerSnap.exists()) {
        const centerData = centerSnap.data();
        $center.name = centerData.name;
        // not needed if role is pwa...

        switch ($user.role) {
          case Role.Super:
          case Role.Admin:
            $center.maxActiveUsers = centerData.maxActiveUsers;
            $currentRoute = Pages.AdminStart;
            break;
          case Role.SLT:
            $center.maxActiveUsers = centerData.maxActiveUsers;
            $currentRoute = Pages.PWAEdit;
            break;
          case Role.PWA:
          case Role.Private:
            $currentRoute = Pages.Ready;
            break;
          default:
            // Sentry.captureMessage(
            //   "Login: Error in switch, $user.role: " +
            //     $user.role +
            //     ", user: " +
            //     $userEmail
            // );
            console.log("Error in switch, $user.role: ", $user.role);
            message = switchError + $user.role;
        }
      } else {
        // Sentry.captureMessage(
        //   "no center for user, centerUid: " +
        //     $user.centerUid +
        //     ", user: " +
        //     $userEmail
        // );
        console.log("Center not found, center uid: ", $user.centerUid);
        isLogginIn = false;
        message = $isOnline ? noCenterForUser : noConnection;
      }
    } else {
      console.log("Login, user not found, user uid: ", $userEmail);
      message = $isOnline ? emailOrPasswordNotFound : noConnection;
    }
  };

  // sign in from mail and password supplied by user
  const signUserIn = () => {
    isLogginIn = true;
    playSilentWithAudioTag(audioTag);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential: UserCredential) => {
        // Signed in
        $userEmail = userCredential.user.email!;
        $loggedIn = true;
        getUserInfoAndGotoStartPage();
      })
      .catch((error) => {
        isLogginIn = false;
        const errorCode = error.code;
        const errorMessage = error.message;
        message = $isOnline ? emailOrPasswordNotFound : noConnection;
        console.log("sign-in error: ", errorMessage, " ", errorCode);
      });
  };

  const loginButtonClicked = (): void => {
    signUserIn();
  };

  const resetErrorMsg = (): void => {
    message = $isOnline ? emptyMessage : noConnection;
  };

  const newPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        showResetPasswordModal = true;
      })
      .catch((error) => {
        // Sentry.captureException(error);
        message = $isOnline ? emailNotFound : noConnection;
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  };

  const passWordResetSent = () => {
    showResetPasswordModal = false;
    message = $isOnline ? emptyMessage : noConnection;
  };

  const handleOffLine = () => {
    message = noConnection;
  };

  const handleOnLine = () => {
    message = emptyMessage;
  };

  // update local storage for remember me when checkbox clicked
  $: localStorage.setItem(
    "rememberMeOnThisDevice",
    rememberMeOnThisDevice ? "yes" : "no",
  );

  // INITIALIZE --------------------------

  if (localStorage.getItem("rememberMeOnThisDevice")) {
    rememberMeOnThisDevice =
      localStorage.getItem("rememberMeOnThisDevice") === "yes";
  }

  if (!$isOnline) {
    message = noConnection;
  }

  // automatic user login ********************************************
  onMount(() => {
    if (!$loggedIn) {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser && rememberMeOnThisDevice) {
          $loggedIn = true;
          $userEmail = currentUser.email!;
          getUserInfoAndGotoStartPage();
        }
      });
    }
  });
</script>

<!-- HTML ===================================================== -->

<svelte:window on:offline={handleOffLine} on:online={handleOnLine} />

<main>
  <div class="svg">
    <svg viewBox="0 0 1366 768">
      <path
        class="st1"
        d="M303,447.5h-14.5l79.9-124.8c3.6-5.7,9.9-9.1,16.7-9.1h0c10.9,0,19.8,8.9,19.8,19.8v114
            c0,8.5-6.9,15.3-15.3,15.3h0c-8.5,0-15.3-6.9-15.3-15.3v-93l7.4,2.1l-59.2,91.8c-5.8,9-15.8,14.5-26.5,14.5h0
            c-10.7,0-20.7-5.4-26.5-14.4l-59.2-91.4l7.4-2.1v92.6c0,8.5-6.9,15.3-15.3,15.3h0c-8.5,0-15.3-6.9-15.3-15.3v-114
            c0-10.9,8.9-19.8,19.8-19.8h0c6.8,0,13.1,3.5,16.7,9.2L303,447.5z"
      />
      <path
        class="st1"
        d="M529.2,465.7c-19.8,0-36.8-3.1-50.8-9.3c-14.1-6.2-24.8-15.1-32.3-26.6c-7.5-11.5-11.2-25.4-11.2-41.5
            c0-16.3,3.7-30.2,11.2-41.7c7.5-11.5,18.2-20.4,32.3-26.6c14.1-6.2,31-9.3,50.8-9.3c20,0,37,3.1,51,9.3
            c14,6.2,24.7,15.1,32.2,26.6c7.5,11.5,11.2,25.4,11.2,41.7c0,16.1-3.7,30-11.2,41.5c-7.5,11.5-18.2,20.4-32.2,26.6
            C566.1,462.6,549.1,465.7,529.2,465.7z M572.2,493.8c39.4,0.6,31.7-0.4,51.5,5.4c9.6,2.8-6.3-15.9,2.8-27.8c5.6-7.3,8-17.1,8-28.6
            s-2.7-21.1-8-28.6c-5.3-7.5-12.6-13.2-21.9-16.9c-9.3-3.8-20.1-5.6-32.4-5.6c-12.1,0-23,1.9-32.5,5.6c-9.5,3.8-17,9.4-22.4,16.9
            c-5.4,7.5-8.1,17.1-8.1,28.6s2.7,21.1,8.1,28.6c5.4,7.5,12.8,13.1,22.4,16.8C549.2,491.9,560.1,493.6,572.2,493.8z"
      />
      <path
        class="st1"
        d="M738.8,313.5c16.8,0,30.7,2,42,6c11.2,4,20.2,9.4,26.9,16.3c6.7,6.8,11.4,14.7,14.3,23.6
            c2.8,8.9,4.3,18.3,4.3,28.1c0,9.8-1.6,19.3-4.7,28.4c-3.2,9.1-8.1,17.1-15,24.1c-6.8,7-15.8,12.5-27,16.6
            c-11.1,4.1-24.7,6.1-40.7,6.1h-72.2c-7.2,0-13.1-5.9-13.1-13.1v-123c0-7.2,5.9-13.1,13.1-13.1H738.8z M684.1,436.4h54
            c11.1,0,20.3-1.3,27.6-4c7.3-2.7,13.1-6.3,17.4-11s7.4-9.8,9.2-15.7c1.8-5.8,2.8-11.9,2.8-18.2c0-6.3-0.9-12.3-2.8-18
            c-1.8-5.7-4.9-10.8-9.2-15.2c-4.3-4.5-10.1-8-17.4-10.6c-7.3-2.6-16.5-3.9-27.6-3.9h-53.6c-0.2,0-0.3,0.1-0.3,0.3V436.4z"
      />
      <path
        class="st1"
        d="M844.7,441.4l53-112.8c4.3-9.2,13.6-15.1,23.7-15.1h0c10.1,0,19.3,5.8,23.7,15l53.5,112.7
            c4.7,10-2.5,21.5-13.6,21.5l0,0c-5.9,0-11.2-3.4-13.7-8.8L914,328.3h14.8l-56.9,125.6c-2.4,5.4-7.8,8.8-13.6,8.8h0
            C847.3,462.7,840,451.3,844.7,441.4z M871.8,430.2v-25.6h98.7v25.6H871.8z"
      />
      <path
        class="st1"
        d="M1038.4,313.5h7.3c8.5,0,15.3,6.9,15.3,15.3v118.5c0,8.5-6.9,15.3-15.3,15.3l0,0c-8.5,0-15.3-6.9-15.3-15.3
            V321.5C1030.4,317.1,1034,313.5,1038.4,313.5z M1094.3,390l-0.2-12.9l67.3,57.2c11.4,9.7,4.5,28.3-10.4,28.3h-0.6l-89.5-78.4
            l79.5-70.8l0,0c14.9,0,21.8,18.6,10.4,28.3L1094.3,390z"
      />
    </svg>
  </div>
  <form on:submit|preventDefault={loginButtonClicked}>
    <div>
      <label for="email-input">E-mail:</label><br />
      <input
        id="email-input"
        on:input={resetErrorMsg}
        type="email"
        bind:value={email}
      />
    </div>
    <div class="psw-div">
      <label for="password-input">Password:</label><br />
      <div class="psw-input">
        {#if showPassword}
          <input
            id="password-input"
            on:input={resetErrorMsg}
            type="text"
            autocapitalize="none"
            bind:value={password}
          />
          <IconButton
            icon={Icons.EyeSlashRegular}
            iconMediumScaled={true}
            noText={true}
            on:click={() => (showPassword = !showPassword)}
          />
        {:else}
          <input
            id="password-input"
            on:input={resetErrorMsg}
            type="password"
            bind:value={password}
          />
          <IconButton
            icon={Icons.EyeRegular}
            iconMediumScaled={true}
            noText={true}
            on:click={() => (showPassword = !showPassword)}
          />
        {/if}
      </div>
    </div>
    <div class="remember-div">
      <input
        id="remember"
        type="checkbox"
        bind:checked={rememberMeOnThisDevice}
      />
      <label for="remember">Husk mig på denne enhed</label>
    </div>
    <p class="message">
      {@html message}
    </p>
    <div class="login-button-div">
      <IconButton
        disabled={email === "" || isLogginIn || !isOnline}
        textMediumScaled={true}
        icon={Icons.Key}
        on:click={newPassword}
      >
        Nyt password
      </IconButton>
      <span class="submit-button">
        <IconButton
          type={IconButtonType.Submit}
          disabled={email === "" || password === "" || isLogginIn || !isOnline}
          textScaled={true}
          icon={Icons.SignInAlt}
        >
          Log ind
        </IconButton></span
      >
    </div>
  </form>
  <div class="version">
    <div class="version-text">
      version: {versionNumber}
    </div>
    <div class="version-button">
      <IconButton
        disabled={isLogginIn}
        on:click={() => (showLoginMessageModal = true)}
        icon={Icons.QuestionSolid}
      />
    </div>
  </div>
</main>

<!-- MODALs with messages  -->

{#if showLoginMessageModal}
  <LoginMessageModal
    on:loginMessageModalClosed={() => (showLoginMessageModal = false)}
  />
{/if}

{#if showResetPasswordModal}
  <MessageModal on:messageModalClosed={passWordResetSent}>
    Et link er sendt til din mail med instruktion til ændring af password.
  </MessageModal>
{/if}

<!-- CSS ===================================================== -->
<style>
  .st1 {
    fill: #365c77;
  }
  .svg {
    height: 20vmin;
  }
  .submit-button {
    margin-left: 1rem;
  }
  svg {
    height: 40vmin;
  }
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(var(--vh, 1vh) * 100);
    background: #d6eafa;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    border: solid 1px var(--button-border-color);
    padding: 5vmin;
    border-radius: 2vmin;
    background: white;
  }
  label {
    font-size: 3vmin;
  }
  input {
    font-size: 3vmin;
    padding: 1vmin;
  }
  #email-input {
    width: 60vw;
    max-width: 600px;
    margin-top: 0.5vmin;
  }
  .psw-input {
    display: flex;
    margin-top: 0.5vmin;
    margin-bottom: -1.5em;
  }
  #password-input {
    width: calc(60vw - 3em);
    max-width: calc(600px - 3em);
    margin-right: 0.3em;
  }
  .psw-div {
    padding-top: 3vmin;
    padding-bottom: 5vmin;
  }
  .message {
    font-size: 3vmin;
    padding-bottom: 2vmin;
    padding-top: 1.5vmin;
    color: red;
  }
  .version {
    font-size: 3vmin;
    display: flex;
    align-items: center;
    height: 10vmin;
  }
  .version-text {
    margin-right: 0.5em;
  }
  .version-button {
    font-size: 2vmin;
  }
  .login-button-div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #remember {
    margin-right: 1.5vmin;
  }
  .remember-div {
    display: flex;
    align-items: center;
  }
</style>
