<script lang="ts">
  // Delete either PWA or SLT user.
  // User record in firestore database will be deleted.
  // This will also trigger cloud function to delete user from firestore auth.

  import { createEventDispatcher, onMount } from "svelte";
  // import * as Sentry from "@sentry/svelte";
  import { db } from "../firebase";
  import {
    doc,
    deleteDoc,
    query,
    collection,
    where,
    getDocs,
  } from "@firebase/firestore";

  import { IconButtonType, ModalCss, DocType, Role } from "../enums";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  // PROPS ------------------------------------------------

  export let userType: Role;
  export let email = "";
  export let name = "";

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();

  const eraseOkTxt = "SLET";

  // STATE ---------------------------------------------

  let userTypeStr = "";
  let eraseOK: string = "";

  // REACTIVE VARIABLES ----------------------------------

  $: eraseOK = eraseOK.toUpperCase();

  // FUNCTIONS ---------------------------------------------

  // button clicks ---

  const deleteUser = async () => {
    const userDocRef = doc(db, DocType.Users, email);
    try {
      await deleteDoc(userDocRef);
      // delete results for user
      const resultsQuery = query(
        collection(db, DocType.Results),
        where("email", "==", email),
      );
      const resultsQuerySnapshot = await getDocs(resultsQuery);
      resultsQuerySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        try {
          deleteDoc(doc.ref);
        } catch (err) {
          console.log(
            "brugers opgavesæt kunne ikke slettes: ",
            email,
            ", fejl:",
            err,
          );
        }
      });
    } catch (err) {
      // Sentry.captureException(err);
      console.log(
        "kunne ikke slette  " + userTypeStr + ": ",
        email,
        " fejl: ",
        err,
      );
    } finally {
      dispatch("userDeleted");
    }
  };

  const cancel = () => {
    dispatch("deleteUserDialogCanceled");
  };

  // INITIALIZATION

  onMount(() => {
    switch (userType) {
      case Role.PWA:
        userTypeStr = "borger";
        break;
      case Role.SLT:
        userTypeStr = "logopæd";
        break;
      case Role.Admin:
        userTypeStr = "administrator";
        break;
      case Role.Private:
        userTypeStr = "privat bruger";
      default:
        userTypeStr = "";
    }
  });
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={deleteUser}>
    <div class="headline">
      <h1>
        Vil du slette denne {userTypeStr}?
      </h1>
    </div>
    <div class="content">
      <p>Kalde-navn: {name}</p>
      <br />
      <p>E-mail: {email}</p>
      <br />
      {#if userType === Role.PWA || userType === Role.Private}
        <p>OBS: sletter alle data, kan ikke genskabes.</p>
        <p>Du kan i stedet vælge at deaktivere borgeren.</p>
      {/if}
      <label for="slet">Skriv SLET:</label>
      <input id="slet" type="text" bind:value={eraseOK} placeholder="SLET" />
    </div>
    <div class="bottom-box">
      <IconButton
        on:click={cancel}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
      <span class="ok-btn">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
          disabled={eraseOK !== eraseOkTxt}
        >
          Slet
        </IconButton>
      </span>
    </div>
  </form>
</Modal>

<!-- CSS ===================================================== -->
<style>
  form {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 2vmin;
  }
  h1 {
    font-size: 4vmin;
  }
  input {
    font-family: inherit;
    font-size: inherit;
    padding: 0.4em;
    margin: 0 0 1vmin 0;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  label {
    font-family: inherit;
    font-size: inherit;
    margin: 1vmin 0 1vmin 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
</style>
