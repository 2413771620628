<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { db } from "../firebase";
  import { doc, collection, setDoc } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { IconButtonType, ModalCss, DocType } from "../enums";
  import type { Center, User } from "../types";

  import { Icons } from "../utilities/icons";

  import Modal from "../components/Modal.svelte";
  import IconButton from "../components/IconButton.svelte";

  // CONSTANTS --------------------------------------------

  const dispatch = createEventDispatcher();

  // STATE -------------------------------------------------

  let center: Center = {
    name: "",
    uid: "",
    maxActiveUsers: 5,
  };
  let user: User = {
    uid: "",
    name: "",
    centerUid: "",
    role: "admin",
    active: true,
    password: "",
  };
  let adminEmail = "";

  // FUNCTIONS ---------------------------------------------

  const cancel = () => {
    dispatch("newCenterDialogClose");
  };

  const saveEditAdmin = async () => {
    try {
      const newAdminRef = doc(db, DocType.Users, adminEmail);
      await setDoc(newAdminRef, {
        name: user.name,
        uid: "",
        password: user.password,
        role: user.role,
        active: user.active,
        centerUid: center.uid,
      });
    } catch (err) {
      // Sentry.captureException(err);
      console.log("kunne ikke oprette admin, fejl: ", err);
    }
    dispatch("newCenterDialogClose");
  };

  const saveEditCenter = async () => {
    try {
      const newCenterRef = doc(collection(db, DocType.Centers));
      await setDoc(newCenterRef, {
        name: center.name,
        maxActiveUsers: center.maxActiveUsers,
      });
      center.uid = newCenterRef.id;
    } catch (err) {
      // Sentry.captureException(err);
      console.log("kunne ikke oprette center, fejl: ", err);
      dispatch("newCenterDialogClose");
      return;
    }
    saveEditAdmin();
  };
</script>

<!-- HTML ===================================================== -->

<Modal size={ModalCss.Size.Large}>
  <form on:submit|preventDefault={saveEditCenter}>
    <div class="headline">
      <h1>Opret center</h1>
    </div>
    <div class="content">
      <div class="input">
        <label for="cname">Center:</label>
        <input type="text" id="cname" bind:value={center.name} />
      </div>
      <div class="input">
        <label for="maxActive">Antal borgere:</label>
        <input
          type="number"
          id="maxActive"
          bind:value={center.maxActiveUsers}
        />
      </div>
      <div class="input">
        <label for="aname">Admin:</label>
        <input type="text" id="aname" bind:value={user.name} />
      </div>
      <div class="input">
        <label for="email">E-mail:</label>
        <input type="email" id="email" bind:value={adminEmail} />
      </div>
      <div class="input pass">
        <label for="psw">Psw:</label>
        <input type="text" id="psw" bind:value={user.password} />
      </div>
    </div>
    <div class="bottom-box">
      <IconButton
        on:click={cancel}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
      <span class="ok-button">
        <IconButton
          textMediumScaled={true}
          type={IconButtonType.Submit}
          icon={Icons.Check}
          disabled={user.name === "" ||
            adminEmail === "" ||
            user.password === "" ||
            (center.maxActiveUsers !== 5 &&
              center.maxActiveUsers !== 10 &&
              center.maxActiveUsers !== 15)}
        >
          Gem
        </IconButton>
      </span>
    </div>
  </form></Modal
>

<!-- CSS ===================================================== -->
<style>
  form {
    height: 100%;
    padding: 2vmin;
    position: relative;
  }
  h1 {
    font-size: 4vmin;
    margin-bottom: 2vmin;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 2.5vmin;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1.5vmin;
    padding-bottom: 3vmin;
    width: 100%;
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
  .input {
    width: 100%;
  }
  input {
    font-size: 2.5vmin;
    margin-top: 1.5vmin;
  }
  input[type="text"] {
    width: 70%;
  }
  input[type="number"] {
    width: 20%;
  }
  input[type="email"] {
    width: 75%;
  }
</style>
