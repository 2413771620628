<script lang="ts">
  // (c) Cognisoft Aps, 2022

  import { db } from "../firebase";
  import { doc, updateDoc } from "@firebase/firestore";
  // import * as Sentry from "@sentry/svelte";

  import { IconButtonType, DocType, Level, Role } from "../enums";

  import {
    minHelpDelaySecs,
    maxHelpDelaySecs,
    helpDelaySecsStep,
    minShowRepeatButtonDelaySecs,
    maxShowRepeatButtonDelaySecs,
    showRepeatButtonSecsStep,
    minSessionsBeforeLevelChange,
    maxSessionsBeforeLevelChange,
  } from "../constants";

  import {
    editPWAEmail,
    editPWAName,
    editPWAActive,
    editPWASettings,
    editPWALevel,
    editPWACompletedOnCurrentLevel,
    editPWAHasFreeLicenses,
    editPWARole,
  } from "../stores/edit-pwa-settings-store";

  import { currentRoute, returnRoute } from "../stores/route-store";

  import { icons, Icons } from "../utilities/icons";

  import ChangeLevelModal from "../modals/ChangeLevelModal.svelte";

  import IconButton from "../components/IconButton.svelte";
  import MessageModal from "../modals/MessageModal.svelte";

  // STATE ------------------------------------------

  let showChangeLevelModal = false;
  let showErrorModal = false;
  let errorMessage = "";
  let manualLevelShift = false;
  let previousLevel = $editPWALevel; // detect manual level change in modal

  // FUNCTIONS --------------------------------------

  const changeLevelClicked = () => {
    showChangeLevelModal = true;
  };

  const changeLevelModalClosed = () => {
    showChangeLevelModal = false;
    // if level was changed:
    if (previousLevel !== $editPWALevel) {
      $editPWACompletedOnCurrentLevel = 0;
      $editPWASettings.doTestNextSession = true;
    }
  };

  const saveSettingsAndLeavePage = async () => {
    const documentRef = doc(db, DocType.Users, $editPWAEmail);
    try {
      await updateDoc(documentRef, {
        active: $editPWAActive,
        currentLevel: $editPWALevel,
        completedOnCurrentLevel: $editPWACompletedOnCurrentLevel,
        settings: $editPWASettings,
      });
    } catch (err) {
      // Sentry.captureException(err);
      console.log(
        "Kunne ikke opdatere indstillinger: ",
        $editPWAEmail,
        " fejl: ",
        err,
      );
      (errorMessage =
        "Kunne ikke opdatere indstillinger, rapporter venligst til mail@cognisoft.dk, fejl: "),
        err;
      showErrorModal = true;
    } finally {
      $currentRoute = $returnRoute;
    }
  };
</script>

<!-- HTML ===================================================== -->

<main>
  <div class="heading">
    <svg class="icon" viewBox={icons[Icons.CogLight].viewBox}>
      {@html icons[Icons.CogLight].path}
    </svg>
    <h1>Indstillinger</h1>
  </div>
  <div>
    <hr />
  </div>
  <div class="settings">
    <div class="input">
      <label for="name">Kalde-navn:</label>
      <input type="text" id="name" bind:value={$editPWAName} />
    </div>
    <hr />
    <div class="first-options">
      {#if $editPWARole !== Role.Private}
        <div class="check">
          <input
            type="checkbox"
            id="active"
            bind:checked={$editPWAActive}
            disabled={!$editPWAActive && !$editPWAHasFreeLicenses}
          />
          <label for="active">Aktiv</label>
        </div>
      {/if}
      <div class="check" class:qwerty-checkbox={$editPWARole !== Role.Private}>
        <input
          type="checkbox"
          id="qwerty"
          bind:checked={$editPWASettings.qwertyKeyboard}
        />
        <label for="qwerty">QWERTY-tastatur</label>
      </div>
    </div>
    <hr />
    <div class="check">
      <input
        type="checkbox"
        id="psw"
        bind:checked={$editPWASettings.perseveration}
      />
      <label for="psw">Tag højde for perseveration</label>
    </div>
    <div class="check">
      <input
        type="checkbox"
        id="spelling"
        bind:checked={$editPWASettings.simpleSpelling}
      />
      <label for="spelling">Kun let stavetrin (kun første af tre trin)</label>
    </div>
    <div class="gauge">
      <label for="pause-range">
        Tid før hjælp: {$editPWASettings.helpDelaySecs} sekunder
      </label>
      <input
        type="range"
        id="pause-range"
        class="range"
        min={minHelpDelaySecs}
        max={maxHelpDelaySecs}
        step={helpDelaySecsStep}
        bind:value={$editPWASettings.helpDelaySecs}
      />
    </div>
    <div class="gauge">
      <label for="repeat-delay-range">
        Tid før gentag-knap aktiv: {$editPWASettings.repeatDelaySecs}
        {$editPWASettings.repeatDelaySecs === 1 ? "sekund" : "sekunder"}
      </label>
      <input
        type="range"
        id="repeat-delay-range"
        class="range"
        min={minShowRepeatButtonDelaySecs}
        max={maxShowRepeatButtonDelaySecs}
        step={showRepeatButtonSecsStep}
        bind:value={$editPWASettings.repeatDelaySecs}
      />
    </div>
    <hr />
    <div class="check">
      <input
        type="checkbox"
        id="test"
        bind:checked={$editPWASettings.doIntroNextSession}
      />
      <label for="test">Næste gennemgang er introduktion.</label>
    </div>
    <div class="check">
      <input
        type="checkbox"
        id="test"
        disabled={$editPWASettings.doIntroNextSession}
        bind:checked={$editPWASettings.doTestNextSession}
      />
      <label for="test">Baseline/statustest ved næste gennemgang.</label>
    </div>
    <div>
      <p class="italics">
        Aktuelt niveau:
        {#if $editPWALevel === Level.First}
          Niveau 1.
        {:else if $editPWALevel === Level.Second}
          Niveau 2.
        {:else if $editPWALevel === Level.SecondVerb}
          Niveau 3 - Verbum udvidelse.
        {:else if $editPWALevel === Level.SecondSubject}
          Niveau 4 - Subjekt udvidelse.
        {/if}
        Antal gennemførte på dette niveau: {$editPWACompletedOnCurrentLevel}
      </p>
    </div>
    <div class="check">
      <input type="checkbox" id="levelshift" bind:checked={manualLevelShift} />
      <label for="levelshift">Manuel niveau skift.</label>
    </div>
    <div>
      {#if manualLevelShift}
        <IconButton
          on:click={changeLevelClicked}
          type={IconButtonType.Button}
          textMediumScaled={true}
        >
          Skift niveau
        </IconButton>
      {/if}
    </div>
    <hr />
    <div class="gauge">
      <label for="levelchangesessions">
        Sessioner før automatisk niveauskift: {$editPWASettings.sessionsBeforeLevelChange}
      </label>
      <input
        type="range"
        id="levelchangesessions"
        class="range"
        min={minSessionsBeforeLevelChange}
        max={maxSessionsBeforeLevelChange}
        step="1"
        bind:value={$editPWASettings.sessionsBeforeLevelChange}
      />
    </div>
  </div>

  <div class="bottom-area">
    <span class="button-spacing">
      <IconButton
        on:click={() => ($currentRoute = $returnRoute)}
        type={IconButtonType.Button}
        textMediumScaled={true}
        icon={Icons.WindowClose}
      >
        Fortryd
      </IconButton>
    </span>
    <span class="button-spacing save-button">
      <IconButton
        on:click={saveSettingsAndLeavePage}
        type={IconButtonType.Button}
        textMediumScaled={true}
        icon={Icons.Check}
      >
        Gem
      </IconButton>
    </span>
  </div>
</main>

{#if showChangeLevelModal}
  <ChangeLevelModal on:changeLevelModalOK={changeLevelModalClosed} />
{/if}

{#if showErrorModal}
  <MessageModal
    on:closeModal={() => {
      showErrorModal = false;
    }}
  >
    {errorMessage}
  </MessageModal>
{/if}

<!-- CSS ===================================================== -->
<style>
  main {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    padding: calc(var(--std-margin) * 2);
  }
  h1 {
    font-size: 4vmin;
    margin-left: var(--std-margin);
    margin-bottom: var(--std-margin);
  }
  p,
  label {
    font-size: 3vmin;
    line-height: 5vmin;
  }
  .first-options {
    display: flex;
  }
  .qwerty-checkbox {
    padding-left: calc(var(--std-margin) * 2);
  }
  .heading {
    display: flex;
    align-items: center;
  }
  .settings {
    flex-grow: 1;
    overflow: scroll;
  }
  .icon {
    height: min(calc(var(--vh, 1vh) * 5), 5vmin);
    width: min(calc(var(--vh, 1vh) * 5), 5vmin);
  }
  .range {
    width: 40vw;
  }
  input[type="range"] {
    height: 1vmin;
  }
  .mail {
    font-size: 3vmin;
  }
  .gauge {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  input[type="text"] {
    font-size: 2.5vmin;
    width: 70%;
  }
  input[type="checkbox"] {
    margin-right: 1vmin;
    /* font-size: 2.5vmin; */
  }
  .bottom-area {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 2px solid var(--key-hover-border-color);
  }
  .button-spacing {
    margin-top: var(--std-margin);
    margin-right: var(--std-margin);
  }
  .save-button {
    margin-left: auto;
    margin-right: 0;
  }
  .italics {
    font-style: italic;
    opacity: 0.8;
    font-size: 2.3vmin;
  }
</style>
