<script lang="ts">
  import Modal from "../components/Modal.svelte";

  import { icons } from "../utilities/icons";
</script>

<!-- HTML ===================================================== -->

<Modal>
  <div class="content">
    <svg class="svg" viewBox={icons.hourglassRegular.viewBox}>
      {@html icons.hourglassRegular.path}
    </svg>
    <div class="text">
      <slot>Vent venligst...</slot>
    </div>
  </div>
</Modal>

<!-- CSS ===================================================== -->
<style>
  .content {
    font-size: 3.5vmin;
    padding: 3vmin 2vmin 4vmin 2vmin;
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }
  .svg {
    height: 5vmin;
    width: 5vmin;
    margin-bottom: 1vmin;
    align-self: center;
    fill: black;
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
