// (c) Cognisoft Aps, 2022 

export enum Role {
  Super = "super",
  Admin = "admin",
  SLT = "slt",
  PWA = "pwa",
  Private = "private"
}

export enum DocType {
  Users = "users",
  Centers = "centers",
  Results = "results"
}

export enum Level {
  First = "first",
  Second = "second",
  SecondVerb = "secondVerb",
  SecondSubject = "secondSubject"
}

// implicit routing for SPA, pages are components
export enum Pages {
  Login = "Login",
  AdminStart = "AdminStart",
  PrivateEdit = "PrivateEdit",
  CenterEdit = "CenterEdit",
  PWAEdit = "PWAEdit",
  SLTEdit = "SLTEdit",
  ResultList = "ResultList",
  ResultDetails = "ResultDetails",
  ResultsLineChart = "ResultsLineChart",
  ResultCsvData = "ResultCsvData",
  Ready = "Ready",
  Step1 = "Step1",
  Step2 = "Step2",
  Step3 = "Step3",
  Step4 = "Step4",
  Step5 = "Step5",
  Step6 = "Step6",
  Step7 = "Step7",
  Step8 = "Step8",
  Step8Verbs = "Step8Verbs",
  Step8Subject = "Step8Subject",
  Step9 = "Step9",
  Step9Verbs = "Step9Verbs",
  Step9Subject = "Step9Subject",
  EndResults = "EndResults",
  Settings = "Settings"
}

// state used on all task steps
//   tells whether image buttons should be disabled etc.
export enum State {
  WaitToStart = "waitToStart",
  PlayIntro = "playIntro",
  PresentTask = "presentTask",
  WaitResponse = "waitResponse",
  Error = "error",
  Correct = "correct",
  Paused = "paused",
  ExitModalShown = "exitModalShown",
  WaitAfterEnd = "waitAfterEnd"
}


// to know if level 8 and 9 should use object, verb or subject with extended levels
export enum LevelExtensionProgress {
  Object = "object",
  Verb = "verb",
  Subject = "subject"
}

// used for task item

export enum Vowels {
  A = "a",
  E = "e",
  I = "i",
  O = "o",
  U = "u",
  Y = "y",
  Æ = "æ",
  Ø = "ø",
  Å = "å"
}

//   data definition of task items

export enum Gender {
  Male = "han",
  Female = "hun"
}

// other enums

export enum SvgColor {
  DisabledColor = "#808080",
  EnabledColor = "#000000"
}

// paths

export enum Path {
  Snd = "snd/",
  Img = "img/",
  ImgBg = "img-bg/"
}

export enum Extension {
  ImgBg = " bg.jpg",
  Png = ".png",
  Mp3 = ".mp3",
  Webp = ".webp",
  WebpBg = " bg.webp"
}

// button enums

export enum IconButtonType {
  Button = "button",
  Submit = "submit"
}

// Modal

export namespace ModalCss {
  export enum Size {
    Small = "small",
    Large = "large",
    XLarge = "xlarge"
  }
}

// keyboard key codes

export enum KeyboardKey {
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  Backspace = "Backspace",
  Enter = "Enter",
  Escape = "Escape"
}

// message sound names for Danish language:

export enum DaDK {
  HandMe = "handme",
  ListenAgain = "listenagain",
  PointTo = "pointto",
  ReadAgain = "readagain",
  SayWordTogether = "saywordtogether",
  SaySentenceTogether = "saysentencetogether",
  ShowMe = "showme",
  Step1Intro = "step1intro",
  Step2Intro = "step2intro",
  Step3Intro = "step3intro",
  Step4Intro = "step4intro",
  Step4LeaveLastPicture = "step4leavelastpicture",
  Step5Intro = "step5intro",
  Step5ObsMove = "step5obsmove",
  Step6Intro = "step6intro",
  Step6FoundVowel = "step6foundvowel",
  Step7Intro = "step7intro",
  Step8IntroVerb = "step8introverb",
  Step8IntroSubject = "step8introsubject",
  Step9Intro = "step9intro",
  Step9IntroObject = "step9introobject",
  Step9IntroVerb = "step9introverb",
  Step9IntroSubject = "step9introsubject",
  Step1Help = "step1help",

  Step2Help = "step2help",

  Step3Help = "step3help",

  Step4Help = "step4help",

  Step5Help = "step5help",

  Step6Help = "step6help",

  Step7Help = "step7help",

  Step8Help = "step8help",

  Step9Help = "step9help",
  Step9HelpSubject = "step9helpsubject",

  TryAgain = "tryagain",
  Write = "write",
  AndNowOneMoreTime = "andnowonemoretime",
  AreYouSatisfied = "er du tilfreds",
  He_ = "han_",
  She_ = "hun_"
}
