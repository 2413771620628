// (c) Cognisoft Aps, 2022 

import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

import type { User, StudentUserData, Center } from '../types';
import { Level } from '../enums';

import {
  defaultHelpDelaySecs, defaultShowRepeatButtonDelaySecs,
  sessionsBeforeLevelChange
} from '../constants';

export let loggedIn = writable(false);

export let userEmail: Writable<string> = writable("");

export let user: Writable<User> = writable({
  name: "",
  uid: "",
  role: "",
  centerUid: "",
  active: false
});

export let studentUserData: Writable<StudentUserData> = writable({
  sessionsCompleted: 0,
  currentLevel: Level.First,
  completedOnCurrentLevel: 0,
  settings: {
    helpDelaySecs: defaultHelpDelaySecs,
    repeatDelaySecs: defaultShowRepeatButtonDelaySecs,
    perseveration: false,
    doTestNextSession: false,
    doIntroNextSession: false,
    sessionsBeforeLevelChange: sessionsBeforeLevelChange,
    qwertyKeyboard: false,
    simpleSpelling: false
  }
});

export let level: Writable<Level> = writable(Level.First);

export let center: Writable<Center> = writable({
  name: "",
  uid: "",
  maxActiveUsers: 0
});

// to know which pwa user to show results for when logged in as admin or SLT
export let pwaNameForResults: Writable<string> = writable("");
export let pwaEmailForResults: Writable<string> = writable("");