// play correct with random wording

// import * as Sentry from "@sentry/svelte";

import type { CallbackFunction } from '../types';
import { correctSounds } from '../constants';
import { Path, Extension } from '../enums';

export const playCorrectRandomWithAudioTag = (audioTag: HTMLAudioElement, audioEndedCallback: CallbackFunction = null): void => {
  let theSound = correctSounds[Math.floor(Math.random() * correctSounds.length)];

  if (!audioTag.paused || audioTag.currentTime > 0) {
    audioTag.pause();
    audioTag.currentTime = 0;
    audioTag.onended = null;
  }
  audioTag.src = Path.Snd + theSound + Extension.Mp3;
  audioTag.onended = audioEndedCallback;
  audioTag.volume = 1.0;
  audioTag.onerror = () => {
    // Sentry.captureMessage("error playing sound: " + theSound)
    console.log("error playing sound:", theSound);
    if (audioEndedCallback) {
      audioEndedCallback();
      return;
    }
  }
  audioTag.load();
  let playPromise = audioTag.play();
  playPromise.then(function () {
    // playback started!
  }).catch(function (error) {
    // Sentry.captureException(error);
    console.log('error playing sound: ', error, " sound: ", theSound);
    if (audioEndedCallback) {
      audioEndedCallback();
    }
  });
}