<script lang="ts">
  export let position: number = 0;
  export let max: number = 100;
  export let background: string = "e0e0e0";
  export let color: string = "#659cef";

  let percent: number;
  $: percent = Math.floor((position * 100) / max);
</script>

<!-- HTML ===================================================== -->

<div class="progress-bar" style="background-color: {background};">
  <span
    class="progress-bar-fill"
    style="width: {percent}%; 
    background-color: {color};"
  />
</div>

<!-- CSS ===================================================== -->
<style>
  .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    padding: 1px;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .progress-bar-fill {
    display: block;
    height: 1vh;
    border-radius: 3px;
    transition: width 500ms ease-in-out;
  }
</style>
