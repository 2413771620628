<script lang="ts">
  // (c) Cognisoft Aps, 2022
  // spell noun from picture
  // model without stressed vowel
  // on-screen keyboard

  import { flip } from "svelte/animate";
  import { crossfade } from "svelte/transition";
  import { onDestroy } from "svelte";
  // import * as Sentry from "@sentry/svelte";

  import { Pages, DaDK, Path, Extension } from "../enums";
  import { HelpLevel } from "../classes";
  import {
    afterCorrectWaitSecs,
    alfaKeyCaps,
    qwertyKeyCaps,
  } from "../constants";

  import { Icons } from "../utilities/icons";
  import { playSoundWithAudioTag } from "../utilities/playSound";
  import { playCorrectRandomWithAudioTag } from "../utilities/playCorrectRandom";
  import { getTotalNumberOfTasks } from "../utilities/getTotalNumberOfTasks";

  import { studentUserData } from "../stores/user-store";
  import { currentRoute } from "../stores/route-store";
  import {
    currentSentenceNumberForSteps,
    targetsForSteps,
  } from "../stores/targets-step567-store"; // last picture shown in step 4
  import { targetVerbAndObjects } from "../stores/targets-store";
  import { progress } from "../stores/progress-store";
  import { helpCount, result, tasksCompleted } from "../stores/result-store";

  import ExitModal from "../modals/ExitModal.svelte";

  import RoundButton from "../components/RoundButton.svelte";
  import ProgressBar from "../components/ProgressBar.svelte";
  import PauseMessage from "../components/PauseMessage.svelte";

  // PROPS --------------------------------------------------

  // one audiotag in App.svelte is used on all pages in order to have
  //   sound played even before mouse click
  export let audioTag: HTMLAudioElement;

  // TYPES --------------------------------------------------

  // has to be object to make key for svelte animation
  //  because letter may be repeated, and repeated strings not allowed
  type Letter = {
    letter: string;
  };

  type Letters = Array<Letter>;

  enum State {
    WaitToStart = "waitToStart",
    PresentTask = "presentTask",
    WaitResponse = "waitResponse",
    Error = "error",
    Correct = "correct",
    Paused = "paused",
    ExitModalShown = "exitModalShown",
  }

  // CONSTANTS ---------------------------------------------

  const [send, receive] = crossfade({});

  const vowels = ["a", "e", "i", "o", "u", "y", "æ", "ø", "å"];

  const vowelTargetPostion =
    $targetVerbAndObjects[
      $targetsForSteps[$currentSentenceNumberForSteps]
    ].vowelTargetPosition.indexOf("_");

  const numberOfHelpLevels = 4;

  // STATE ------------------------------------------------

  let bipAudioTag: HTMLAudioElement;

  let keyCaps: Array<string>;

  let state = State.PresentTask; // state for task solution for each item/picture
  let oldState = State.PresentTask; // keep old state during pause and exitModal
  let answerWord: string = "";

  let indexOfLetterToRemove: number;

  let modelLetters: Letters = []; // model string made into array of objects
  let receivedLetters: Letters = []; // only one received but still has to be string because of Svelte animate

  let hideVowel = false;
  let hideRemoved = false;

  let repeatButtonTimer: ReturnType<typeof setTimeout>;
  let helpTimer: ReturnType<typeof setTimeout>;
  // let frozenAppTimer: ReturnType<typeof setTimeout>;

  // help both after a set time, requested and after erro
  let helpLevel = new HelpLevel(numberOfHelpLevels);

  let disableAllButtons = true; // mostly when playing sounds
  let allowRepetition = false;
  let requestedHelpPaused = false; // // help not active for some seconds after activted last time

  let helpLetters: Array<string> = [];

  // obs exception to help hierarky for stressed vowel, extra helpstep 3 highlighted vowels
  let hasShown3HelpVowels = false;

  // for results:
  let gotHelpForItem = false;
  let hasErrorForItem = false;

  // save results for each response, push to array in results.store
  let presentTime: Date;
  let numberOfTimedHelp = 0;
  let numberOfRequestedHelp = 0;
  let numberOfRequestedRepetitions = 0;
  let attempt = 1;
  // let responseDetails: Array<ResponseDetail> = [];

  let waitToGotoNext = false;

  // keep track of pause time
  let pauseStartTime: number;
  let pauseEndTime: number;

  // FUNCTIONS --------------------------------------------

  const playErrorSound = () => {
    bipAudioTag.src = Path.Snd + "bip" + Extension.Mp3;
    bipAudioTag.load();
    bipAudioTag.play();
  };

  const countHelp = () => {
    switch ($currentSentenceNumberForSteps) {
      case 0:
        $helpCount.spelling.item61++;
        break;
      case 1:
        $helpCount.spelling.item62++;
        break;
      case 2:
        $helpCount.spelling.item63++;
        break;
      case 3:
        $helpCount.spelling.item64++;
        break;
    }
  };

  // adjust help count by number of letters in word to get a mean
  const adjustHelpCount = () => {
    switch ($currentSentenceNumberForSteps) {
      case 0:
        $helpCount.spelling.item61 =
          $helpCount.spelling.item61 /
          $targetVerbAndObjects[
            $targetsForSteps[$currentSentenceNumberForSteps]
          ].object.length;
        break;
      case 1:
        $helpCount.spelling.item62 =
          $helpCount.spelling.item62 /
          $targetVerbAndObjects[
            $targetsForSteps[$currentSentenceNumberForSteps]
          ].object.length;
        break;
      case 2:
        $helpCount.spelling.item63 =
          $helpCount.spelling.item63 /
          $targetVerbAndObjects[
            $targetsForSteps[$currentSentenceNumberForSteps]
          ].object.length;
        break;
      case 3:
        $helpCount.spelling.item64 =
          $helpCount.spelling.item64 /
          $targetVerbAndObjects[
            $targetsForSteps[$currentSentenceNumberForSteps]
          ].object.length;
        break;
    }
  };

  const initializeResultVariables = () => {
    presentTime = new Date();
    numberOfTimedHelp = 0;
    numberOfRequestedHelp = 0;
    numberOfRequestedRepetitions = 0;
  };

  // save result details for a single item, used for cvs results
  // *** used for research disabled in current production

  const saveResultVariablesWithCorrect = (correct: boolean) => {
    // responseDetails.push({
    //   step: 6,
    //   attempt: attempt,
    //   presentTime: presentTime,
    //   responseTime: new Date(),
    //   targetItem:
    //     $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
    //       .object,
    //   correct: correct,
    //   numberOfTimedHelp: numberOfTimedHelp,
    //   numberOfRequestedHelp: numberOfRequestedHelp,
    //   numberOfRequestedRepetitions: numberOfRequestedRepetitions,
    // });
  };

  // show repeat button a defined time after showin task

  const startRepeatButtonTimer = () => {
    allowRepetition = false;
    repeatButtonTimer = setTimeout(() => {
      allowRepetition = true;
    }, $studentUserData.settings.repeatDelaySecs * 1000);
  };

  // showing help according to 2 level hierarkies (timed and requested help) ---

  const enableHelpButtonDelayed = () => {
    setTimeout(() => {
      disableAllButtons = false;
      requestedHelpPaused = false;
    }, 1000);
  };

  const enableHelpButton = () => {
    disableAllButtons = false;
    requestedHelpPaused = false;
    disableAllButtons = false;
  };

  const startHelpTimer = () => {
    enableHelpButton();
    disableAllButtons = false;
    helpTimer = setTimeout(() => {
      numberOfTimedHelp++;
      giveHelp(false);
    }, $studentUserData.settings.helpDelaySecs * 1000);
  };

  const startHelpTimerAndWaitForResponse = (afterError: boolean) => {
    state = State.WaitResponse;
    startHelpTimer();
    if (afterError) {
      initializeResultVariables();
    }
  };

  // showing help according to eval hierarchy

  // if error for stressed vowels 3 vowels should be highlighted
  const make3HelpVowels = () => {
    hasShown3HelpVowels = true;
    const firstInserted =
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .stressedVowel;
    helpLetters.push(firstInserted);
    let secondInserted: string;
    do {
      secondInserted = vowels[Math.floor(Math.random() * vowels.length)];
    } while (secondInserted === firstInserted);
    helpLetters.push(secondInserted);
    let thirdInserted: string;
    do {
      thirdInserted = vowels[Math.floor(Math.random() * vowels.length)];
    } while (
      thirdInserted === firstInserted ||
      thirdInserted == secondInserted
    );
    helpLetters.push(thirdInserted);
  };

  // sound both repeated with speaker button and at 2 first help stages
  const repeatObjectHelp = (afterError: boolean): void => {
    helpLevel.setNextLevel();
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      startHelpTimerAndWaitForResponse.bind(null, afterError),
    );
  };

  // obs exception to help hierarchy for stressed vowel, extra help step 3 highlighted vowels
  const showCorrectLetterHelp = (afterError: boolean) => {
    const letterPosition = answerWord.length;
    if (letterPosition === vowelTargetPostion && !hasShown3HelpVowels) {
      make3HelpVowels();
    } else {
      helpLevel.setNextLevel();
      helpLetters = [];
      helpLetters.push(
        $targetVerbAndObjects[
          $targetsForSteps[$currentSentenceNumberForSteps]
        ].object.charAt(letterPosition),
      );
    }
    helpLetters = helpLetters;
    state = State.WaitResponse;
    enableHelpButtonDelayed();
    startHelpTimerAndWaitForResponse(afterError);
  };

  const showCorrectAnswerHelp = (afterError: boolean) => {
    const letterPosition = answerWord.length - 1;
    const insertLetter = $targetVerbAndObjects[
      $targetsForSteps[$currentSentenceNumberForSteps]
    ].object.charAt(letterPosition + 1);
    playSoundWithAudioTag(audioTag, insertLetter, null);
    answerWord = answerWord + insertLetter;
    helpLetters = [];
    if (
      answerWord ===
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object
    ) {
      clearTimeout(helpTimer);
      afterCorrect();
    } else {
      helpLevel.setNextLevel();
      state = State.WaitResponse;
      enableHelpButtonDelayed();
      clearTimeout(helpTimer);
      startHelpTimerAndWaitForResponse(afterError);
    }
  };

  // give requested help after clicking help button or after timed help according to help level
  const giveHelp = (afterError: boolean) => {
    if (state !== State.WaitResponse && state !== State.Error) {
      return;
    }
    countHelp();
    gotHelpForItem = true;
    requestedHelpPaused = true;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    switch (helpLevel.level) {
      case 1:
      case 2:
        repeatObjectHelp(afterError);
        break;
      case 3:
        showCorrectLetterHelp(afterError);
        break;
      case 4:
        showCorrectAnswerHelp(afterError);
        break;
      default:
        // Sentry.captureMessage(
        //   "Step 6: error in giveHelp() switch, helpLevel: " + helpLevel.level
        // );
        console.log(
          "step 6: error in giveHelp() switch, helpLevel: ",
          helpLevel.level,
        );
    }
  };

  const requestedHelp = (): void => {
    if (state !== State.WaitResponse) {
      return;
    }
    countHelp();
    numberOfRequestedHelp++;
    requestedHelpPaused = true;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    playSoundWithAudioTag(audioTag, DaDK.Step6Help, startHelpTimer);
  };

  const repeatSound = () => {
    countHelp();
    numberOfRequestedRepetitions++;
    clearTimeout(helpTimer);
    requestedHelpPaused = true;
    disableAllButtons = true;
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      startHelpTimer,
    );
  };

  // start ---

  const startAndSayIntro = (): void => {
    playSoundWithAudioTag(audioTag, DaDK.Step6Intro, startAndSayWord);
  };

  const startAndSayWord = (): void => {
    hideVowel = true;
    receivedLetters.push(modelLetters[indexOfLetterToRemove]);
    receivedLetters = receivedLetters;
    modelLetters.splice(indexOfLetterToRemove, 1, { letter: "_" });
    modelLetters = modelLetters;
    playSoundWithAudioTag(audioTag, DaDK.Write, startAndSayWord2);
  };

  const startAndSayWord2 = (): void => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      waitForResponse,
    );
  };

  const waitForResponse = (): void => {
    state = State.WaitResponse;
    hideRemoved = true;
    disableAllButtons = false;
    startRepeatButtonTimer();
    startHelpTimer();
    initializeResultVariables();
  };

  // correct ---

  const afterCorrect = (): void => {
    $tasksCompleted = $tasksCompleted + 1;
    // $result.responseDetails.push(...responseDetails);
    $result.lastStepCompleted = 6;
    if (!gotHelpForItem && !hasErrorForItem) {
      $result.spellingCorrectFirstTry = $result.spellingCorrectFirstTry + 1;
    }
    // adjust help count by number of letters in word
    adjustHelpCount();
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      gotoNextTask,
    );
  };

  const gotoNextTask = () => {
    waitToGotoNext = true;
    setTimeout(() => {
      $currentRoute = Pages.Step7;
    }, afterCorrectWaitSecs);
  };

  // error ---
  const afterError = () => {
    giveHelp(true);
  };

  // button click events  ---

  const keyClicked = (letter: string): void => {
    const letterPosition = answerWord.length;
    if (
      letter ===
      $targetVerbAndObjects[
        $targetsForSteps[$currentSentenceNumberForSteps]
      ].object.charAt(letterPosition)
    ) {
      saveResultVariablesWithCorrect(true);
      helpLetters = [];
      answerWord = answerWord + letter;
      helpLevel.reset();
      // obs exception to help hierarky for stressed vowel, extra helpstep 3 highlighted vowels:
      hasShown3HelpVowels = false;
      clearTimeout(helpTimer);
      clearTimeout(repeatButtonTimer);
      if (
        answerWord.length ===
        $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
          .object.length
      ) {
        attempt = 1;
        state = State.Correct;
        $progress = $progress + 1;
        allowRepetition = false;
        playCorrectRandomWithAudioTag(audioTag, afterCorrect);
      } else {
        waitForResponse();
      }
    } else {
      saveResultVariablesWithCorrect(false);
      attempt++;
      state = State.Error;
      hasErrorForItem = true;
      if (helpLevel.level === numberOfHelpLevels) {
        afterError();
      } else {
        requestedHelpPaused = true;
        // test if first entry is the stressed vowel and give special message
        if (
          answerWord.length === 0 &&
          letter ===
            $targetVerbAndObjects[
              $targetsForSteps[$currentSentenceNumberForSteps]
            ].stressedVowel
        ) {
          // stop general help timer
          clearTimeout(helpTimer);
          // special messag about stressed vowel
          playSoundWithAudioTag(
            audioTag,
            DaDK.Step6FoundVowel,
            afterStressedVowelError,
          );
        } else {
          playSoundWithAudioTag(audioTag, DaDK.TryAgain, afterError);
        }
      }
    }
  };

  // special case, stressed vowel as error in first position i word
  // called after playing error message has finished
  // it does not go into general error hierarchy
  const afterStressedVowelError = () => {
    requestedHelpPaused = false;
    startHelpTimerAndWaitForResponse(true);
  };

  // keyboard input ---
  const handleKeydown = (event: KeyboardEvent): void => {
    if (state !== State.WaitResponse) {
      playErrorSound();
      return;
    }
    let letter = event.key.toLocaleLowerCase();
    if (keyCaps.includes(letter)) {
      keyClicked(letter);
    } else if (event.key === "?" && !requestedHelpPaused) {
      requestedHelp();
    }
  };

  // pause --------

  const pauseClicked = (): void => {
    if (state === State.Paused) {
      pauseEndTime = Date.now();
      $result.pauseElapsed =
        $result.pauseElapsed + Math.abs(pauseEndTime - pauseStartTime);
      state = oldState;
      allowRepetition = true;
      startHelpTimer();
    } else {
      pauseStartTime = Date.now();
      // stopSoundWithAudioTag(audioTag);
      clearTimeout(repeatButtonTimer);
      clearTimeout(helpTimer);
      oldState = state;
      state = State.Paused;
    }
  };

  // exit task prematurely after stop button clicked

  // stop button: show yes-no modal
  const exitButtonClicked = () => {
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    oldState = state;
    state = State.ExitModalShown;
  };

  // exit task (modal event "yes")
  const exitTask = () => {
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    $result.wasInterrupted = true;
    $currentRoute = Pages.EndResults;
  };

  // dismiss exit modal (event "no")
  const dismissExitModal = () => {
    state = oldState;
    allowRepetition = true;
    startHelpTimer();
  };

  // LIFE CYCLE ---------------------------------------------------

  onDestroy(() => {
    clearTimeout(helpTimer);
    helpTimer = null;
    // clearTimeout(frozenAppTimer);
    // frozenAppTimer = null;
  });

  // INITIALIZE --------------------------------------------

  if ($studentUserData.settings.qwertyKeyboard) {
    keyCaps = [...qwertyKeyCaps];
  } else {
    keyCaps = [...alfaKeyCaps];
  }

  indexOfLetterToRemove =
    $targetVerbAndObjects[
      $targetsForSteps[$currentSentenceNumberForSteps]
    ].vowelTargetPosition.indexOf("_");

  // make array of objects for model word because of svelte animate
  for (let char of $targetVerbAndObjects[
    $targetsForSteps[$currentSentenceNumberForSteps]
  ].object) {
    modelLetters.push({ letter: char });
  }

  // let frozenAppTimeOutSecs =
  //   numberOfHelpLevels *
  //   ($studentUserData.settings.helpDelaySecs + 10) *
  //   $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
  //     .object.length *
  //   1000;

  // // to find if app should freeze
  // frozenAppTimer = setTimeout(() => {
  //   if (state !== State.Paused) {
  //     Sentry.captureMessage(
  //       "Step 6 frozen, state " +
  //         state +
  //         ", helpLevel " +
  //         helpLevel.level +
  //         ", currentSentenceNumberForSteps " +
  //         $currentSentenceNumberForSteps +
  //         ",  helpDelaySecs " +
  //         $studentUserData.settings.helpDelaySecs +
  //         ", disableAllButtons " +
  //         disableAllButtons +
  //         ", numberOfTimedHelp " +
  //         numberOfTimedHelp +
  //         ", numberOfRequestedHelp " +
  //         numberOfRequestedHelp +
  //         ", numberOfRequestedRepetitions " +
  //         numberOfRequestedRepetitions +
  //         ", attempt " +
  //         attempt +
  //         ", requestedHelpPaused " +
  //         requestedHelpPaused +
  //         ", allowRepetition " +
  //         allowRepetition
  //     );
  //   }
  // }, frozenAppTimeOutSecs);

  startAndSayIntro();
</script>

<!-- HTML ===================================================== -->

<svelte:window on:keydown={handleKeydown} />
<body class:completed-bg-color={waitToGotoNext}>
  <audio bind:this={bipAudioTag} />
  <main>
    <!-- top panel with help and repeat buttons -->
    <div class="top-panel">
      <div>
        <span class="stop-button">
          <RoundButton
            disabled={state !== State.WaitResponse || disableAllButtons}
            on:click={exitButtonClicked}
            icon={Icons.TrafficLightRed}
          />
        </span>
        <RoundButton
          disabled={(state !== State.WaitResponse && state !== State.Paused) ||
            disableAllButtons}
          on:click={pauseClicked}
          icon={state === State.Paused ? Icons.Play : Icons.Pause}
        />
      </div>
      <div>
        <RoundButton
          disabled={state !== State.WaitResponse ||
            !allowRepetition ||
            requestedHelpPaused ||
            disableAllButtons}
          on:click={repeatSound}
          icon={Icons.VolumeRegular}
        />
        <span class="help-button">
          <RoundButton
            disabled={state !== State.WaitResponse ||
              requestedHelpPaused ||
              disableAllButtons}
            on:click={requestedHelp}
            icon={Icons.QuestionSolid}
          />
        </span>
      </div>
    </div>
    <!-- image showing target -->
    <div class="img-removed-area">
      <div
        class="image"
        style="background-image: url('{Path.ImgBg}{$targetVerbAndObjects[
          $targetsForSteps[$currentSentenceNumberForSteps]
        ].image}{Extension.ImgBg}'); 
        background-image: image-set('{Path.ImgBg}{$targetVerbAndObjects[
          $targetsForSteps[$currentSentenceNumberForSteps]
        ].image}{Extension.WebpBg}'); 
        background-size: cover;"
      >
        <picture>
          <source
            type="image/webp"
            srcset={encodeURI(
              Path.Img +
                $targetVerbAndObjects[
                  $targetsForSteps[$currentSentenceNumberForSteps]
                ].image +
                Extension.Webp,
            )}
            draggable={false}
          />
          <img
            draggable={false}
            src={Path.Img +
              $targetVerbAndObjects[
                $targetsForSteps[$currentSentenceNumberForSteps]
              ].image +
              Extension.Png}
            alt=""
          />
        </picture>
      </div>
      <div class="removed-letter">
        {#each receivedLetters as letter (letter)}
          <div
            class="model-letter"
            class:hide-removed={hideRemoved}
            animate:flip
            in:receive|global={{ key: letter }}
          >
            {letter.letter}
          </div>
        {/each}
      </div>
    </div>
    <!-- word model to copy from without stressed vowel -->
    <div class="model-div">
      {#each modelLetters as letter, index (letter)}
        <div
          class="model-letter"
          animate:flip
          out:send|global={{ key: letter }}
          class:hidden-letter={hideVowel && index === indexOfLetterToRemove}
        >
          {letter.letter}
        </div>
      {/each}
    </div>
    <!-- copied word -->
    <div class="copy-div">
      {answerWord}
    </div>

    <!-- on-screen keyboard -->
    <div
      class="keyboard-div"
      class:qwerty-keys={$studentUserData.settings.qwertyKeyboard}
    >
      {#each keyCaps as keyCap}
        <button
          class:key-help={helpLetters.includes(keyCap) &&
            state === State.WaitResponse}
          class="key-cap"
          disabled={state !== State.WaitResponse}
          on:click={() => keyClicked(keyCap)}
        >
          {keyCap}
        </button>
      {/each}
    </div>
    <div class="progress">
      <ProgressBar
        max={getTotalNumberOfTasks($studentUserData.currentLevel)}
        position={$progress}
      />
    </div>
  </main>
</body>

{#if state === State.Paused}
  <PauseMessage on:click={pauseClicked} />
{/if}

{#if state === State.ExitModalShown}
  <ExitModal on:yes={exitTask} on:no={dismissExitModal} />
{/if}

<!-- CSS ====================================================== -->
<style>
  main {
    margin-left: auto;
    margin-right: auto;
    width: 120vh;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 14% 30% 12% 10% 31% 2%;
    grid-template-areas:
      "top"
      "img-removed"
      "word-model"
      "word-copy"
      "keyboard"
      "prog";
    gap: 1%;
    padding: 3%;
    padding-top: 2%;
    padding-left: 2%;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    main {
      width: 100vw;
    }
  }
  .top-panel {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .image {
    background: white;
    border: solid 1px black;
    grid-area: img;
    width: 40%;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    .image {
      width: 50%;
    }
  }
  @media screen and (max-aspect-ratio: 5/5) {
    .image {
      width: 60%;
    }
  }
  @media screen and (max-aspect-ratio: 4/5) {
    .image {
      width: 70%;
    }
  }
  @media screen and (max-aspect-ratio: 3/5) {
    .image {
      width: 80%;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }
  .model-div {
    grid-area: word-model;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  .copy-div {
    grid-area: word-copy;
    background: white;
    border: solid 1px black;
    font-size: 7.5vmin;
    text-transform: uppercase;
    letter-spacing: 2vmin;
    padding-left: 2vmin;
    margin-bottom: 1vmin;
    line-height: 1.05;
  }
  .keyboard-div {
    grid-area: keyboard;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .qwerty-keys {
    grid-template-columns: repeat(11, 1fr);
  }
  .model-letter {
    width: 10vmin;
    height: 10vmin;
    font-size: 8vmin;
    text-transform: uppercase;
    border: solid 1px black;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5vmin;
    user-select: none;
    transition:
      transform 0.5s,
      background-color 0.5s,
      color 0.1s;
    transform-style: preserve-3d;
  }
  .img-removed-area {
    grid-area: img-removed;
    display: flex;
  }
  .removed-letter {
    align-self: center;
    margin-left: 2vw;
  }
  .hide-removed {
    transform: rotateY(180deg);
    color: lightGray;
    background-color: lightGray;
  }
  .hidden-letter {
    visibility: hidden;
  }
  .key-cap {
    /* width: 9vw; */
    width: 94%;
    height: calc(var(--vh, 1vh) * 9);
    font-size: 7vmin;
    padding: 0;
    text-transform: uppercase;
    border: solid 0.45vmin var(--button-border-color);
    border-radius: 1vmin;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;
    user-select: none;
  }
  .key-cap[disabled] {
    color: gray;
    border-color: gray;
    cursor: auto;
  }
  @media (hover) {
    .key-cap:not([disabled]):hover {
      background-color: var(--key-hover-background-color);
      border-color: var(--key-hover-border-color);
    }
  }
  .key-help {
    background: var(--key-help-background-color);
  }
</style>
