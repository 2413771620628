<script lang="ts">
  // (c) Cognisoft Aps, 2022
  // spell noun from picture
  // target last picture in step 4
  // first letter is pre-set-in
  // letter animates to target position
  // flyes back if error

  import { flip } from "svelte/animate";
  import { crossfade } from "svelte/transition";
  import { onDestroy } from "svelte";
  // import * as Sentry from "@sentry/svelte";

  import { Pages, DaDK, Path, Extension, State } from "../enums";
  import { HelpLevel } from "../classes";
  import {
    afterCorrectWaitSecs,
    firstWaitSecs,
    animationBugWait,
  } from "../constants";

  import { Icons } from "../utilities/icons";
  import { shuffleArray } from "../utilities/shuffleArray";
  import { playSoundWithAudioTag } from "../utilities/playSound";
  import { playCorrectRandomWithAudioTag } from "../utilities/playCorrectRandom";
  import { getTotalNumberOfTasks } from "../utilities/getTotalNumberOfTasks";

  import { studentUserData } from "../stores/user-store";
  import { currentRoute } from "../stores/route-store";
  import {
    currentSentenceNumberForSteps,
    targetsForSteps,
  } from "../stores/targets-step567-store"; // last picture shown in step 4
  import { targetVerbAndObjects } from "../stores/targets-store";
  import { progress } from "../stores/progress-store";
  import { helpCount, result, tasksCompleted } from "../stores/result-store";

  import ExitModal from "../modals/ExitModal.svelte";

  import RoundButton from "../components/RoundButton.svelte";
  import ProgressBar from "../components/ProgressBar.svelte";
  import PauseMessage from "../components/PauseMessage.svelte";

  // PROPS --------------------------------------------------

  // one audiotag in App.svelte is used on all pages in order to have
  //   sound played even before mouse click
  export let audioTag: HTMLAudioElement;

  // TYPES --------------------------------------------------

  // has to be object to make key for svelte animation
  //  because letter may be repeated, and repeated strings not allowed
  type Letter = {
    letter: string;
  };

  type Letters = Array<Letter>;

  // CONSTANTS --------------------------------------------

  const [send, receive] = crossfade({});

  const numberOfHelpLevels = 4;

  // STATE ------------------------------------------------

  let letters: Letters = []; // if letter is space it is hidden from shuffled letters to chose from
  let shuffledLetters: Letters = []; // letters to choose from
  let letterOrder: Array<number> = []; // shuffled letter order
  let receivedLetters: Letters = []; // received - for anmation
  let insertedLetters: Array<boolean> = []; // keep track of inserted
  let letterWiggle: Array<boolean> = []; // wiggle letter if move after error to correct postion
  let numberCorrect = 1; // keep track when finished, start at 1 as first letter is given as help from beginning
  // for help:
  let shuffledLettersHelpPosition = 0;
  let receivedLettersHelpPosition = 0;

  let selectedChoiceLetter = -1; // -1 = unselected, 0 or larger = index of selected choice letter

  let state = State.PresentTask; // state for task solution for each item/picture
  let oldState = State.PresentTask; // keep old state during pause and exitModal

  let repeatButtonTimer: ReturnType<typeof setTimeout>;
  let helpTimer: ReturnType<typeof setTimeout>;
  let playNextAudioTimer: ReturnType<typeof setTimeout>;
  // let frozenAppTimer: ReturnType<typeof setTimeout>;

  let showKeyHelp = false;

  // help both after a set time, requested and after error
  let helpLevel = new HelpLevel(numberOfHelpLevels);

  let disableAllButtons = true; // mostly when playing sounds
  let allowRepetition = false;
  let requestedHelpPaused = false; // help not active for some seconds after activated last time

  // same error 3 times in a row, then move letter to correct position
  let lastErrorPosition = 0; // we put first letter ind, so error can never be at pos 0
  let lastErrorLetter = "";
  let numberOfSameError = 0;

  // for results:
  let gotHelpForItem = false;
  let hasErrorForItem = false;

  // save results for each response, push to array in results.store
  let presentTime: Date;
  let numberOfTimedHelp = 0;
  let numberOfRequestedHelp = 0;
  let numberOfRequestedRepetitions = 0;
  let attempt = 1;
  // let responseDetails: Array<ResponseDetail> = [];

  let waitToGotoNext = false;

  // keep track of pause time
  let pauseStartTime: number;
  let pauseEndTime: number;

  // FUNCTIONS --------------------------------------------

  function initializeTask() {
    letters = []; // if letter is space it is hidden from shuffled letters to chose from
    shuffledLetters = []; // letters to choose from
    letterOrder = []; // shuffled letter order
    receivedLetters = []; // received - for anmation
    insertedLetters = []; // keep track of inserted
    letterWiggle = []; // wiggle letter if move after error to correct postion
    numberCorrect = 1; // keep track when finished, start at 1 as first letter is given as help from beginning
    // for help:
    shuffledLettersHelpPosition = 0;
    receivedLettersHelpPosition = 0;
    lastErrorPosition = 0; // we put first letter ind, so error can never be at pos 0
    lastErrorLetter = "";
    numberOfSameError = 0;

    // for results:
    gotHelpForItem = false;
    hasErrorForItem = false;

    // save results for each response, push to array in results.store
    numberOfTimedHelp = 0;
    numberOfRequestedHelp = 0;
    numberOfRequestedRepetitions = 0;
    attempt = 1;

    waitToGotoNext = false;
    selectedChoiceLetter = -1; // -1

    // push a number from 0 and increasing for each letter into array
    for (
      let i = 0;
      i <
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object.length;
      i++
    ) {
      letterOrder.push(i);
    }
    // console.log("step 5: letterOrder array unshuffled: ", letterOrder); // *** DEBUG ***
    // shuffle letter order arry randomly
    letterOrder = shuffleArray(letterOrder);
    // console.log("step 5: letterOrder array shuffled: ", letterOrder); // *** DEBUG ***
    // shuffle letter order array randomly make:
    // 1) array of letters in word: letters
    // 2) make array of shuffled letters for user to choose from when spelling (hide when used)
    // 3) make array of received letters = letters in word (show when chosen correctly)
    // 4) make boolean array to tell if each position has got a letter: insertedLetters
    for (let i = 0; i < letterOrder.length; i++) {
      letters.push({
        letter:
          $targetVerbAndObjects[
            $targetsForSteps[$currentSentenceNumberForSteps]
          ].object.charAt(i),
      });
      shuffledLetters.push({
        letter: $targetVerbAndObjects[
          $targetsForSteps[$currentSentenceNumberForSteps]
        ].object.charAt(letterOrder[i]),
      });
      receivedLetters.push({ letter: i.toString() }); // number until char is inserted
      insertedLetters.push(false);
      letterWiggle.push(false);
    }
    // for svelte responsive update:
    shuffledLetters = [...shuffledLetters];
    receivedLetters = [...receivedLetters];
    insertedLetters = [...insertedLetters];

    // send message with Sentry if it appears to be frozen

    // let frozenAppTimeOutSecs =
    //   numberOfHelpLevels *
    //   ($studentUserData.settings.helpDelaySecs + 10) *
    //   letters.length *
    //   1000;

    // // to find if app should freeze
    // frozenAppTimer = setTimeout(() => {
    //   if (state !== State.Paused) {
    //     Sentry.captureMessage(
    //       "Step 5 frozen, state: " +
    //         state +
    //         ", helpLevel " +
    //         helpLevel.level +
    //         ", currentSentenceNumberForSteps " +
    //         $currentSentenceNumberForSteps +
    //         ",  helpDelaySecs " +
    //         $studentUserData.settings.helpDelaySecs +
    //         ", disableAllButtons " +
    //         disableAllButtons +
    //         ", numberOfTimedHelp " +
    //         numberOfTimedHelp +
    //         ", numberOfRequestedHelp " +
    //         numberOfRequestedHelp +
    //         ", numberOfRequestedRepetitions " +
    //         numberOfRequestedRepetitions +
    //         ", attempt " +
    //         attempt +
    //         ", requestedHelpPaused " +
    //         requestedHelpPaused +
    //         ", allowRepetition " +
    //         allowRepetition
    //     );
    //   }
    // }, frozenAppTimeOutSecs);
    playSoundWithAudioTag(audioTag, DaDK.Step5Intro, playTargetWord);
  }

  const countHelp = () => {
    switch ($currentSentenceNumberForSteps) {
      case 0:
        $helpCount.spelling.item51++;
        break;
      case 1:
        $helpCount.spelling.item52++;
        break;
      case 2:
        $helpCount.spelling.item53++;
        break;
      case 3:
        $helpCount.spelling.item54++;
        break;
    }
  };

  // adjust help count by number of letters in word to get a mean
  const adjustHelpCount = () => {
    switch ($currentSentenceNumberForSteps) {
      case 0:
        $helpCount.spelling.item51 =
          $helpCount.spelling.item51 / letters.length;
        break;
      case 1:
        $helpCount.spelling.item52 =
          $helpCount.spelling.item52 / letters.length;
        break;
      case 2:
        $helpCount.spelling.item53 =
          $helpCount.spelling.item53 / letters.length;
        break;
      case 3:
        $helpCount.spelling.item54 =
          $helpCount.spelling.item54 / letters.length;
        break;
    }
  };

  const initializeResultVariables = () => {
    presentTime = new Date();
    numberOfTimedHelp = 0;
    numberOfRequestedHelp = 0;
    numberOfRequestedRepetitions = 0;
  };

  // save result details for a single item, used for cvs results
  // *** used for research disabled in current production

  const saveResultVariablesWithCorrect = (correct: boolean) => {
    // responseDetails.push({
    //   step: 5,
    //   attempt: attempt,
    //   presentTime: presentTime,
    //   responseTime: new Date(),
    //   targetItem:
    //     $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
    //       .object,
    //   correct: correct,
    //   numberOfTimedHelp: numberOfTimedHelp,
    //   numberOfRequestedHelp: numberOfRequestedHelp,
    //   numberOfRequestedRepetitions: numberOfRequestedRepetitions,
    // });
  };

  // showing help according to evel hierarky

  // show repeat button a defined time after showin task

  const startRepeatButtonTimer = () => {
    allowRepetition = false;
    repeatButtonTimer = setTimeout(() => {
      allowRepetition = true;
    }, $studentUserData.settings.repeatDelaySecs * 1000);
  };

  // showing help according to 2 level hierarkies (timed and requested help) ---

  const enableHelpButton = () => {
    disableAllButtons = false;
    requestedHelpPaused = false;
  };

  const startHelpTimer = () => {
    disableAllButtons = false;
    enableHelpButton();
    helpTimer = setTimeout(() => {
      numberOfTimedHelp++;
      giveHelp();
    }, $studentUserData.settings.helpDelaySecs * 1000);
  };

  const startHelpTimerAndWaitForResponse = () => {
    state = State.WaitResponse;
    startHelpTimer();
    initializeResultVariables();
  };

  // sound both repeated with speaker button and at 2 first help stages
  const repeatObjectHelp = (): void => {
    helpLevel.setNextLevel();
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      startHelpTimerAndWaitForResponse,
    );
  };

  // FÅR FEJLBESKED FRA DENNE FUNKTION <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  // første position i receivedLetters array er undefined
  // - kan det på nogen måde påvirke næste trin ???

  const findFirstEmptyReceivingPosition = (): number => {
    // empty positions contains a number
    for (let i = 0; i < receivedLetters.length; i++) {
      if (
        !$targetVerbAndObjects[
          $targetsForSteps[$currentSentenceNumberForSteps]
        ].object.includes(receivedLetters[i].letter)
      ) {
        return i;
      }
    }
    // Sentry.captureMessage(
    //   "step 5: error findFirstEmptyReceivingPosition, receivedLetters: " +
    //     JSON.stringify(receivedLetters)
    // );
    console.log(
      "step 5: error findFirstEmptyReceivingPosition, receivedLetters: ",
      JSON.stringify(receivedLetters),
    );
    return 0;
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>

  const showCorrectLetterHelp = () => {
    receivedLettersHelpPosition = findFirstEmptyReceivingPosition();
    const currentLetter = $targetVerbAndObjects[
      $targetsForSteps[$currentSentenceNumberForSteps]
    ].object.charAt(receivedLettersHelpPosition);
    shuffledLettersHelpPosition = shuffledLetters.findIndex((letter) => {
      return letter.letter === currentLetter;
    });
    showKeyHelp = true;
    helpLevel.setNextLevel();
    setTimeout(() => {
      requestedHelpPaused = false;
    }, 1000);
    startHelpTimerAndWaitForResponse();
  };

  const showCorrectAnswerHelp = () => {
    showKeyHelp = false;
    helpLevel.setNextLevel();
    const currentLetter = $targetVerbAndObjects[
      $targetsForSteps[$currentSentenceNumberForSteps]
    ].object.charAt(receivedLettersHelpPosition);
    const shuffledLettersPosition = shuffledLetters.findIndex((letter) => {
      return letter.letter === currentLetter;
    });
    let tempLetter: Letter = shuffledLetters[shuffledLettersPosition];
    shuffledLetters.splice(shuffledLettersPosition, 1, { letter: " " }); // " " signals to be hidden
    receivedLetters.splice(receivedLettersHelpPosition, 1, tempLetter); // insert in receivedletters
    receivedLetters = receivedLetters; // so svelte will update
    shuffledLetters = shuffledLetters;
    insertedLetters.splice(receivedLettersHelpPosition, 1, true); // tell position occupied
    insertedLetters = insertedLetters;
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    increaseCorrectAndCheckForFinished();
  };

  // give requested help after clicking help button or after timed help according to help level
  const giveHelp = () => {
    if (state !== State.WaitResponse) {
      return;
    }
    disableAllButtons = true;
    countHelp();
    gotHelpForItem = true;
    requestedHelpPaused = true;
    clearTimeout(playNextAudioTimer);
    clearTimeout(helpTimer);
    switch (helpLevel.level) {
      case 1:
      case 2:
        repeatObjectHelp();
        break;
      case 3:
        showCorrectLetterHelp();
        break;
      case 4:
        showCorrectAnswerHelp();
        break;
      default:
        // Sentry.captureMessage(
        //   "Step 5: error in giveHelp() switch, helpLevel: " + helpLevel.level
        // );
        console.log(
          "step 5: error in giveHelp() switch, helpLevel: ",
          helpLevel.level,
        );
    }
  };

  const requestedHelp = () => {
    if (state !== State.WaitResponse) {
      return;
    }
    countHelp();
    numberOfRequestedHelp++;
    requestedHelpPaused = true;
    disableAllButtons = true;
    clearTimeout(helpTimer);
    playSoundWithAudioTag(audioTag, DaDK.Step5Help, startHelpTimer);
  };

  // start task logic

  // play word as first thing after intro
  const playTargetWord = (): void => {
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      waitToBegin,
    );
  };

  // wait to start
  const waitToBegin = (): void => {
    setTimeout(() => {
      moveFirstLetter();
    }, firstWaitSecs);
  };

  // insert first letter as help at start of task
  const moveFirstLetter = (): void => {
    disableAllButtons = false;
    let letter: Letter;
    // find the target letter, put it ind and prepare svelte animation arrays
    for (let i = 0; i < shuffledLetters.length; i++) {
      if (
        shuffledLetters[i].letter ===
        $targetVerbAndObjects[
          $targetsForSteps[$currentSentenceNumberForSteps]
        ].object.charAt(0)
      ) {
        letter = shuffledLetters.splice(i, 1, { letter: " " })[0]; // " " signals to be hidden
        receivedLetters.splice(0, 1, letter); // insert in received letters
        break;
      }
    }
    // register that first letter is inserted
    insertedLetters.splice(0, 1, true);
    insertedLetters = [...insertedLetters];
    receivedLetters = [...receivedLetters];
    shuffledLetters = [...shuffledLetters];
    state = State.WaitResponse;
    startHelpTimerAndWaitForResponse();
    initializeResultVariables();
    startRepeatButtonTimer();
  };

  // toggle selected status for choice letter
  const choiceLetterClicked = (index: number): void => {
    if (selectedChoiceLetter === index) {
      selectedChoiceLetter = -1;
    } else {
      selectedChoiceLetter = index;
    }
  };

  const increaseCorrectAndCheckForFinished = () => {
    numberCorrect++;
    selectedChoiceLetter = -1; // signals no choice
    // reset count for identical errors:
    resetRepetitionErrors();
    // if all letters correctly inserted, end:
    if (numberCorrect >= letters.length) {
      $progress = $progress + 1;
      state = State.Correct;
      if (!gotHelpForItem && !hasErrorForItem) {
        $result.spellingCorrectFirstTry = $result.spellingCorrectFirstTry + 1;
      }
      // adjust help count by number of letters in word
      adjustHelpCount();
      playCorrectRandomWithAudioTag(audioTag, afterCorrect);
    } else {
      setTimeout(() => {
        requestedHelpPaused = false;
      }, 1000);
      startHelpTimerAndWaitForResponse();
    }
  };

  // button click event handlers ----------

  // inset choice letter in target word if letter position not already filled
  //   if correct: stays in position, if error should jump back to choice letters
  const targetClicked = (index: number): void => {
    if (selectedChoiceLetter >= 0 && index > 0 && !insertedLetters[index]) {
      let tempLetter: Letter = shuffledLetters[selectedChoiceLetter];
      shuffledLetters.splice(selectedChoiceLetter, 1, { letter: " " }); // " " signals to be hidden
      receivedLetters.splice(index, 1, tempLetter); // insert in receivedletters
      receivedLetters = receivedLetters; // so svelte will update
      shuffledLetters = shuffledLetters;
      insertedLetters.splice(index, 1, true); // tell position occupied
      insertedLetters = insertedLetters;
      // check if correct
      if (tempLetter.letter !== letters[index].letter) {
        // error:
        saveResultVariablesWithCorrect(false);
        attempt++;
        state = State.Error;
        setTimeout(() => {
          afterError(index, tempLetter);
        }, firstWaitSecs);
      } else {
        // correct:
        saveResultVariablesWithCorrect(true);
        attempt = 1;
        showKeyHelp = false;
        clearTimeout(repeatButtonTimer);
        clearTimeout(helpTimer);
        helpLevel.reset();
        increaseCorrectAndCheckForFinished();
      }
    }
  };

  // correct -----

  // after all letters correct, go on
  const afterCorrect = (): void => {
    // stopSoundWithAudioTag(audioTag);
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      gotoNextTask,
    );
  };

  const gotoNextTask = () => {
    $tasksCompleted = $tasksCompleted + 1;
    // $result.responseDetails.push(...responseDetails);
    $result.lastStepCompleted = 5;
    waitToGotoNext = true;
    setTimeout(() => {
      if ($studentUserData.settings.simpleSpelling) {
        if ($currentSentenceNumberForSteps < 3) {
          $currentSentenceNumberForSteps = $currentSentenceNumberForSteps + 1;
          initializeTask();
        } else {
          $currentRoute = Pages.Step8;
        }
      } else {
        $currentRoute = Pages.Step6;
      }
    }, afterCorrectWaitSecs);
  };

  // error -----

  const resetRepetitionErrors = () => {
    numberOfSameError = 0;
    lastErrorLetter = "";
    lastErrorPosition = 0;
  };

  const afterError = (index: number, chosenLetter: Letter): void => {
    clearTimeout(helpTimer);
    // have to move letter by manipulating arrays
    hasErrorForItem = true;
    insertedLetters.splice(index, 1, false);
    receivedLetters.splice(index, 1, { letter: index.toString() }); // number signals to show with receive border
    // count number of same error
    if (
      chosenLetter.letter === lastErrorLetter &&
      index === lastErrorPosition
    ) {
      numberOfSameError++;
    }
    if (numberOfSameError > 1) {
      // move letter to correct position
      resetRepetitionErrors();
      helpLevel.reset();
      state = State.WaitResponse;
      selectedChoiceLetter = -1;
      playSoundWithAudioTag(audioTag, DaDK.Step5ObsMove, null);
      // move letter to correct position
      // time out here is HACK !!! for flip animation bug in svelte ***:
      setTimeout(() => {
        moveToCorrectPosition(chosenLetter);
        startHelpTimer();
      }, animationBugWait);
    } else {
      lastErrorLetter = chosenLetter.letter;
      lastErrorPosition = index;
      // move letter back
      // time out here is HACK !!! for flip animation bug in svelte ***:
      setTimeout(() => {
        afterError2(index, chosenLetter);
      }, animationBugWait);
    }
  };

  const afterError2 = (index: number, chosenLetter: Letter): void => {
    // move letter back after error
    shuffledLetters.splice(selectedChoiceLetter, 1, chosenLetter);
    shuffledLetters = shuffledLetters;
    selectedChoiceLetter = -1;
    state = State.WaitResponse;
    giveHelp();
  };

  const findCorrectPosition = (letter: Letter): number => {
    // find correct position even though letter may be in more positions and one may already be found
    const tempObject =
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object;
    for (let i = 0; i < tempObject.length; i++) {
      if (
        tempObject.charAt(i) === letter.letter &&
        receivedLetters[i].letter !== letter.letter
      ) {
        return i;
      }
    }
    console.log(
      "step 5: findCorrectPosition, correctPosition not found, letter: ",
      JSON.stringify(letter.letter),
    );
    return -1;
  };

  const moveToCorrectPosition = (chosenLetter: Letter): void => {
    numberCorrect++;
    const correctPosition = findCorrectPosition(chosenLetter);
    receivedLetters.splice(correctPosition, 1, chosenLetter);
    insertedLetters.splice(correctPosition, 1, true);
    receivedLetters = receivedLetters;
    insertedLetters = insertedLetters;
    letterWiggle[correctPosition] = true;
    letterWiggle = letterWiggle;
    receivedLettersHelpPosition = -1; // stop help highlight
    setTimeout(() => {
      stopWiggle(correctPosition);
    }, 1000);
  };

  const stopWiggle = (index: number) => {
    letterWiggle[index] = false;
    letterWiggle = letterWiggle;
    startHelpTimer();
  };

  // when repeat button is pressed
  const repeatSound = () => {
    countHelp();
    numberOfRequestedRepetitions++;
    clearTimeout(helpTimer);
    requestedHelpPaused = true;
    disableAllButtons = true;
    playSoundWithAudioTag(
      audioTag,
      $targetVerbAndObjects[$targetsForSteps[$currentSentenceNumberForSteps]]
        .object,
      startHelpTimer,
    );
  };

  // DRAG and DROP -----------------------------------------

  const dragStart = (index: number): void => {
    shuffledLettersHelpPosition = -1; // stop help highligt on drag
    selectedChoiceLetter = index;
  };

  const drop = (index: number): void => {
    targetClicked(index);
  };

  // pause --------

  const pauseClicked = (): void => {
    if (state === State.Paused) {
      pauseEndTime = Date.now();
      $result.pauseElapsed =
        $result.pauseElapsed + Math.abs(pauseEndTime - pauseStartTime);
      state = oldState;
      allowRepetition = true;
      startHelpTimer();
    } else {
      pauseStartTime = Date.now();
      // stopSoundWithAudioTag(audioTag);
      clearTimeout(playNextAudioTimer);
      clearTimeout(repeatButtonTimer);
      clearTimeout(helpTimer);
      oldState = state;
      state = State.Paused;
    }
  };

  // exit task prematurely after stop button clicked

  // stop button: show yes-no modal
  const exitButtonClicked = () => {
    // stopSoundWithAudioTag(audioTag);
    clearTimeout(playNextAudioTimer);
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    oldState = state;
    state = State.ExitModalShown;
  };

  // exit task (modal event "yes")
  const exitTask = () => {
    // stopSoundWithAudioTag(audioTag);
    clearTimeout(playNextAudioTimer);
    clearTimeout(repeatButtonTimer);
    clearTimeout(helpTimer);
    $result.wasInterrupted = true;
    $currentRoute = Pages.EndResults;
  };

  // dismiss exit modal (event "no")
  const dismissExitModal = () => {
    state = oldState;
    allowRepetition = true;
    startHelpTimer();
  };

  // LIFE CYCLE ---------------------------------------------------

  onDestroy(() => {
    clearTimeout(helpTimer);
    helpTimer = null;
    // clearTimeout(frozenAppTimer);
    // frozenAppTimer = null;
  });

  // INITIALIZE --------------------------------------------

  initializeTask();
</script>

<!-- HTML ===================================================== -->

<body class:completed-bg-color={waitToGotoNext}>
  <main>
    <!-- top panel with help and repeat buttons -->
    <div class="top-panel">
      <div>
        <span class="stop-button">
          <RoundButton
            disabled={state !== State.WaitResponse || disableAllButtons}
            on:click={exitButtonClicked}
            icon={Icons.TrafficLightRed}
          />
        </span>
        <RoundButton
          disabled={(state !== State.WaitResponse && state !== State.Paused) ||
            disableAllButtons}
          on:click={pauseClicked}
          icon={state === State.Paused ? Icons.Play : Icons.Pause}
        />
      </div>
      <div>
        <RoundButton
          disabled={state !== State.WaitResponse ||
            !allowRepetition ||
            requestedHelpPaused ||
            disableAllButtons}
          on:click={repeatSound}
          icon={Icons.VolumeRegular}
        />
        <span class="help-button">
          <RoundButton
            disabled={state !== State.WaitResponse ||
              requestedHelpPaused ||
              disableAllButtons}
            on:click={requestedHelp}
            icon={Icons.QuestionSolid}
          />
        </span>
      </div>
    </div>
    <!-- image showing target -->
    <div
      class="image"
      style="background-image: url('{Path.ImgBg}{$targetVerbAndObjects[
        $targetsForSteps[$currentSentenceNumberForSteps]
      ].image}{Extension.ImgBg}');
      background-image: image-set('{Path.ImgBg}{$targetVerbAndObjects[
        $targetsForSteps[$currentSentenceNumberForSteps]
      ].image}{Extension.WebpBg}'); 
       background-size: cover;"
    >
      <picture>
        <source
          type="image/webp"
          srcset={encodeURI(
            Path.Img +
              $targetVerbAndObjects[
                $targetsForSteps[$currentSentenceNumberForSteps]
              ].image +
              Extension.Webp,
          )}
          draggable={false}
        />
        <img
          draggable={false}
          src={Path.Img +
            $targetVerbAndObjects[
              $targetsForSteps[$currentSentenceNumberForSteps]
            ].image +
            Extension.Png}
          alt=""
        />
      </picture>
    </div>
    <!-- the spelled word under picture -->
    <div class="spelling">
      {#each receivedLetters as letter, index (letter)}
        <button
          class:key-help={showKeyHelp &&
            receivedLettersHelpPosition === index &&
            state === State.WaitResponse}
          class:img-wiggle={letterWiggle[index]}
          class:gray-text={state !== State.WaitResponse}
          on:drop|preventDefault={() => drop(index)}
          ondragover="return false"
          animate:flip
          in:receive|globale={{ key: letter }}
          out:send|global={{ key: letter }}
          disabled={state !== State.WaitResponse}
          class="receive-letter"
          class:receive-letter-content={insertedLetters[index]}
          on:click={() => targetClicked(index)}
        >
          {#if insertedLetters[index]}
            {letter.letter}
          {/if}
        </button>
      {/each}
    </div>
    <!-- panel to choose letters to spell with from -->
    <div class="right-panel">
      {#each shuffledLetters as letter, index (letter)}
        <button
          class:key-help={showKeyHelp &&
            shuffledLettersHelpPosition === index &&
            state === State.WaitResponse}
          class:gray-text={state !== State.WaitResponse}
          draggable={state === State.WaitResponse}
          on:dragstart={() => dragStart(index)}
          animate:flip
          in:receive|global={{ key: letter }}
          out:send|global={{ key: letter }}
          disabled={state !== State.WaitResponse}
          class={"letter letter-" + letterOrder[index]}
          class:letterHidden={letter.letter === " "}
          class:letter-selected={selectedChoiceLetter === index}
          on:click={() => choiceLetterClicked(index)}
        >
          {letter.letter}
        </button>
      {/each}
    </div>
    <div class="progress">
      <ProgressBar
        max={getTotalNumberOfTasks($studentUserData.currentLevel)}
        position={$progress}
      />
    </div>
  </main>
</body>

{#if state === State.Paused}
  <PauseMessage on:click={pauseClicked} />
{/if}

{#if state === State.ExitModalShown}
  <ExitModal on:yes={exitTask} on:no={dismissExitModal} />
{/if}

<!-- CSS ====================================================== -->
<style>
  main {
    margin-left: auto;
    margin-right: auto;
    width: 120vh;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: 74% 24%;
    grid-template-rows: 14% 58% 22%;
    grid-template-areas:
      "top top"
      "img letters"
      "word letters"
      "prog prog";
    gap: 2%;
    padding: 3%;
    padding-top: 2%;
    padding-left: 2%;
  }
  @media screen and (max-aspect-ratio: 6/5) {
    main {
      width: 100vw;
    }
  }
  .top-panel {
    grid-area: top;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .image {
    background: white;
    border: solid 1px black;
    grid-area: img;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }
  .right-panel {
    border: solid 2px var(--button-border-color);
    grid-area: letters;
    position: relative;
  }
  .spelling {
    grid-area: word;
    border: solid 2px var(--button-border-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .progress {
    grid-area: prog;
    width: 100%;
  }
  .letter {
    position: absolute;
    width: 8vmin;
    height: 10vmin;
    font-size: 8vmin;
    text-transform: uppercase;
    border: solid 1px black;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5vmin;
    cursor: pointer;
    user-select: none;
  }
  .gray-text {
    color: darkgray;
  }
  .letter-0 {
    top: 50%;
    left: 2%;
  }
  .letter-1 {
    top: 48%;
    left: 51%;
  }
  .letter-2 {
    top: 26%;
    left: 5%;
  }
  .letter-3 {
    top: 65%;
    left: 55%;
  }
  .letter-4 {
    top: 10%;
    left: 10%;
  }
  .letter-5 {
    top: 5%;
    left: 51%;
  }
  .letter-6 {
    top: 67%;
    left: 8%;
  }
  .letter-7 {
    top: 32%;
    left: 44%;
  }
  .letter-8 {
    top: 85%;
    left: 40%;
  }
  .letter-9 {
    top: 83%;
    left: 2%;
  }
  .letter-selected {
    border: solid 6px var(--button-border-selected);
    background: lightyellow;
  }
  .letter:hover {
    border: solid 6px var(--button-border-active);
  }
  .letterHidden {
    visibility: hidden;
  }
  .receive-letter {
    width: 10vmin;
    height: 10vmin;
    font-size: 8vmin;
    text-transform: uppercase;
    border: dashed 5px var(--button-active-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vmin;
    cursor: pointer;
    user-select: none;
  }
  .receive-letter-content {
    border: solid 1px black;
    background: white;
    cursor: default;
  }
  .key-help {
    background: var(--key-help-background-color);
  }
</style>
