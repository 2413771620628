// (c) Cognisoft Aps, 2022 

// Order for 4 sentences for step 5, 6, 7 
// because first picture has to be last, not chosen picture
// in step 4 - so is set in step 4
// - here also predefined for testing purposes

import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';

// The sentence currently worked with on step 5-6-7
// - because step 5-6-7 first run with one sentence,
// - then again with the next until all 4 sentences used
// so we can go on to the last steps - step 8 and 9
export let currentSentenceNumberForSteps: Writable<number> = writable(0);

// indexes for targets for step 5-6-7: on extra to avoid undefined on last task step 7
export let targetsForSteps: Writable<Array<number>> = writable([3, 0, 2, 1, 1]);  // default data for testing
