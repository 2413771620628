<script lang="ts">
  // show listBox from array
  // dispatch selected message with selected item and index of selected item
  // for single selection only

  import { createEventDispatcher, onDestroy } from "svelte";

  // PROPS ---------------------------------------------------

  export let items: Array<string> | null = [];
  export let selectedItem: string = "";
  export let selectedIndex: number | null = null;
  export let pre: boolean = false;
  export let grayed: boolean = false;
  export let largefont: boolean = false;

  // CONSTANTS ---------------------------------------------------

  const dispatch = createEventDispatcher();

  // COMPONENT LIFECYCLE -----------------------------------------

  onDestroy(() => {
    // clear array --- may not be necessary for garbage collection ???
    items = null;
  });

  // FUNCTIONS ---------------------------------------------------

  const listItemClicked = (index: number): void => {
    if (selectedIndex === index) {
      selectedIndex = null;
      selectedItem = "";
    } else {
      selectedIndex = index;
      selectedItem = items![index];
    }
    dispatch("clicked", { index: selectedIndex });
  };
</script>

<!-- HTML ===================================================== -->

<!-- <div class="page"> -->
<div class="listbox" class:grayed class:largefont>
  {#each items as item, index}
    <div
      class:selected={selectedIndex === index}
      class="listitem"
      on:click={() => listItemClicked(index)}
    >
      {#if pre}
        <pre>{item}</pre>
      {:else}
        {item}
      {/if}
    </div>
  {/each}
</div>
<!-- </div> -->

<!-- CSS ===================================================== -->
<style>
  pre {
    font-family: "Courier New", Courier, monospace;
  }
  .page {
    flex-grow: 1;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .listbox {
    border: rgb(55, 52, 103) solid;
    line-height: 1.2em;
    padding: 0 1% 0 1%;
    height: 100%;
    overflow: scroll;
  }
  .largefont {
    font-size: 1.2em;
    line-height: 1.4;
  }
  .selected {
    color: white;
    background: var(--key-hover-border-color);
  }
  .grayed {
    border: gray solid;
  }
  .listitem {
    cursor: pointer;
  }
</style>
