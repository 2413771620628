<script lang="ts">
</script>

<!-- HTML ===================================================== -->

<div on:click class="pause-message">Sat på pause...</div>

<!-- CSS ===================================================== -->
<style>
  .pause-message {
    background-color: #808080;
    color: white;
    border: 3px solid #505050;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40%;
    height: 15%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4vmin;
    font-weight: bold;
    user-select: none;
    z-index: 2;
  }
</style>
