// (c) Cognisoft Aps, 2022 

import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import { Pages } from '../enums';

export let currentRoute: Writable<Pages> = writable(Pages.Login);
export let returnRoute: Writable<Pages> = writable(Pages.Login);
// export let currentRoute: Writable<Pages> = writable(Pages.Ready); // ----------------------- RESET TO Login after dev ***********
// // export let currentRoute: Writable<Pages> = writable(Pages.Settings); // ----------------------- RESET TO Login after dev ***********
