// determine number of tasks in training-set in order to get max for progress bar
// 12.02.24: - if only 1 (rather than 3) spelling step - the total number of task returned is modified accordingly

// import * as Sentry from "@sentry/svelte";

import { get } from 'svelte/store';
import { Level } from '../enums';
import { totalNumberOfTasksBaseLevels, totalNumberOfTasksVerbExtension, totalNumberOfTasksSubjectExtension, totalNumberOfTasksBaseLevelsSimpleSpelling, totalNumberOfTasksVerbExtensionSimpleSpelling, totalNumberOfTasksSubjectExtensionSimpleSpelling } from "../constants";
import { studentUserData } from "../stores/user-store";

export const getTotalNumberOfTasks = (level: Level): number => {
  if (level === Level.First || level === Level.Second || get(studentUserData).settings.doIntroNextSession || get(studentUserData).settings.doTestNextSession) {
    return get(studentUserData).settings.simpleSpelling ? totalNumberOfTasksBaseLevelsSimpleSpelling : totalNumberOfTasksBaseLevels;
  } else if (level === Level.SecondVerb) {
    return get(studentUserData).settings.simpleSpelling ? totalNumberOfTasksVerbExtensionSimpleSpelling : totalNumberOfTasksVerbExtension;
  } else if (level === Level.SecondSubject) {
    return get(studentUserData).settings.simpleSpelling ? totalNumberOfTasksSubjectExtensionSimpleSpelling : totalNumberOfTasksSubjectExtension
  } else {
    // Sentry.captureMessage("Error in: getTotalNumberOfTasks, level: " + level);
    console.log("error in: getTotalNumberOfTasks, level: ", level)
    return totalNumberOfTasksBaseLevels;
  }
}