<script lang="ts">
  // (c) Cognisoft Aps, 2022
  import { ModalCss } from "../enums";

  export let size: ModalCss.Size = ModalCss.Size.Small;
</script>

<!-- HTML ===================================================== -->

<div class="modal-background">
  <div
    class="modal"
    class:large={size === ModalCss.Size.Large}
    class:xlarge={size === ModalCss.Size.XLarge}
  >
    <slot />
  </div>
</div>

<!-- CSS ===================================================== -->
<style>
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 5;
  }

  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100vw - 3em);
    max-width: 24em;
    max-height: calc((var(--vh, 1vh) * 100) - 10em);
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background: white;
    border: 3px solid var(--button-border-color);
  }
  .large {
    width: calc(100vw - 1em);
    max-width: 32em;
  }
  .xlarge {
    max-width: min(90%, 40em);
  }
</style>
