<script lang="ts">
  // show listBox from array
  // dispatch selected message with array of selected items and array with selected indexes

  import IconButton from "./IconButton.svelte";

  export let items: Array<string> = [];
  export let selectedItems: Array<string> = [];
  export let selectedIndexes: Array<number> = [];
  export let grayed: boolean = false;
  export let disabled: boolean = false;

  const listItemClicked = (index: number): void => {
    if (!disabled) {
      if (selectedIndexes.includes(index)) {
        const posToRemove = selectedIndexes.indexOf(index);
        selectedIndexes.splice(posToRemove, 1);
        selectedItems.splice(posToRemove, 1);
      } else {
        selectedIndexes.push(index);
        selectedItems.push(items[index]);
      }
      selectedIndexes = [...selectedIndexes];
      selectedItems = [...selectedItems];
    }
  };

  const selectAll = (): void => {
    selectedItems = [...items];
    selectedIndexes = [];
    for (let i = 0; i < items.length; i++) {
      selectedIndexes.push(i);
    }
    selectedIndexes = [...selectedIndexes];
  };

  const selectNone = (): void => {
    selectedItems = [];
    selectedIndexes = [];
  };

  $: if (disabled) {
    selectNone();
  }
</script>

<!-- HTML ===================================================== -->

<!-- <div class="page"> -->
<div class="listbox" class:grayed>
  {#each items as item, index}
    <div
      class:selected={selectedIndexes.includes(index) && !disabled}
      class="listitem"
      on:click={() => listItemClicked(index)}
    >
      {item}
    </div>
  {/each}
</div>

<div class="edit-box">
  <span class="button-spacing">
    <IconButton {disabled} on:click={selectAll}>Vælg alt</IconButton>
  </span>
  <span class="button-spacing">
    <IconButton {disabled} on:click={selectNone}>Vælg intet</IconButton>
  </span>
</div>
<!-- </div> -->

<!-- CSS ===================================================== -->
<style>
  .page {
    flex-grow: 1;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .listbox {
    border: rgb(55, 52, 103) solid;
    line-height: 1.2em;
    padding: 0 1% 0 1%;
    height: 100%;
    overflow: scroll;
  }
  .selected {
    color: white;
    background: var(--key-hover-border-color);
  }
  .grayed {
    border: gray solid;
  }
  .listitem {
    cursor: pointer;
  }
  .edit-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: var(--std-padding);
    margin-top: 0;
    margin-left: 0;
  }
  .button-spacing {
    padding-top: 1vmin;
    padding-right: 1vmin;
  }
</style>
